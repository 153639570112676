import Loader from "components/loader";
import React from "react";
import { StyledButton } from "./icon-button.styles";
import { ButtonProps } from "./icon-button.types";

const IconButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      color,
      type = "button",
      children,
      isLoading,
      loaderColor = "$gray12",
      ...props
    },
    ref
  ) => {
    return (
      <StyledButton color={color} ref={ref} type={type} {...props}>
        {isLoading ? <Loader size={"xs"} color={loaderColor} /> : children}
      </StyledButton>
    );
  }
);

IconButton.displayName = "IconButton";

export default IconButton;
