import { allValuesAreTruthy, pick } from "utils/object";
import { PaymentMethodType, TransferMoneyState } from "./move-money.types";
import PaymentType from "./components/payment-type";
import Recipient from "./components/recipient";
import Amount from "./components/amount";
import MoreInfo from "./components/more-info";
import Schedule from "./components/schedule";
import ReviewTransaction from "./components/review-transaction";
import TransactionSuccess from "./components/success";
import PaymentFields from "./components/payment-fields";
import { JournalEntry, PaymentMethod } from "generated/__generated_graphql";
import { isAfter } from "date-fns";

export const REVERSAL_NARRATION = "REVERSAL- ";
export const TRANSFER_METHOD_MAP: Record<PaymentMethodType, string> = {
  domestic_wire: "DomesticWirePaymentMethod",
  international_wire: "InternationalWirePaymentMethod",
  ach: "AchPaymentMethod",
};

export function findMethodDetails(paymentMethod: PaymentMethodType, paymentMethods: Array<PaymentMethod>) {
  const method = TRANSFER_METHOD_MAP[paymentMethod];

  return method;
}

const Sections = ["recipient", "paymentType", "paymentDetails", "amount", "moreInfo", "schedule"] as const;


export const sectionValidators: Record<
  typeof Sections[number],
  (input: TransferMoneyState) => boolean
> = {
  recipient: (input) => {
    if (input.notifyRecipient) {
      return !!input.recipientName && !!input.recipientEmail;
    }

    return !!input.recipientName;
  },
  paymentType: (input) => !!input.methodOfPayment && sectionValidators.recipient(input),
  paymentDetails: (input) => {
    const address = pick(input, [
      "recipientAddress",
      "recipientCity",
      "recipientCountry",
      "recipientState",
      "recipientZip",
    ]);
    const domesticFields = pick(input, [
      "accountNumber",
      "routingNumber",
      "accountType",
    ]);
    const internationalFields = pick(input, [
      "accountNumber",
      "swiftCode",
      "recipientType",
    ]);

    const domesticFieldsAreValid = allValuesAreTruthy(domesticFields);
    const internationalFieldsAreValid = allValuesAreTruthy(internationalFields);
    const addressIsValid = allValuesAreTruthy(address);

    if (["domestic_wire", "ach"].includes(input.methodOfPayment)) {
      return domesticFieldsAreValid && addressIsValid;
    }
    return internationalFieldsAreValid && addressIsValid;
  },

  amount: (input) =>
    !!input.amount &&
    !!input.bankAccountId &&
    sectionValidators.paymentDetails(input),
  moreInfo: (input) => !!input.purposeOfPayment && !!input.sourceOfFunds,
  schedule: (input) => !!input.effectiveDate && sectionValidators.moreInfo(input)
};


interface MultiStepFlowStep {
  index?: number;
  id?: string | number;
  title?: React.ReactNode;
  description?: React.ReactNode;
  component?: any;
  path?: string;
  isAvailable?(input: TransferMoneyState): boolean;
  isComplete?(input: TransferMoneyState): boolean;
}


export function splitScheduled(transactions: Array<JournalEntry>): { scheduled: Array<JournalEntry>, nonScheduled: Array<JournalEntry> } {
  const scheduled: Array<JournalEntry> = [];
  const nonScheduled: Array<JournalEntry> = [];

  transactions.forEach((transaction) => {
    if (isAfter(new Date(transaction.effectiveDate), new Date)) {
      scheduled.push(transaction);
    } else {
      nonScheduled.push(transaction);
    }
  }
  );

  return { scheduled, nonScheduled };

}



export const moveMoneySteps: Array<MultiStepFlowStep> = [
  {
    index: 0,
    title: "Recipient",
    description: "Select the person or business you want to pay",
    component: Recipient,
    path: "/",
    isAvailable: () => true,
    isComplete: sectionValidators.recipient,
  },
  {
    index: 1,
    title: "Payment type",
    component: PaymentType,
    path: "/payment-type",
    isAvailable: sectionValidators.recipient,
    isComplete: (input) => sectionValidators.paymentType(input) && sectionValidators.recipient(input),
  },
  {
    index: 2,
    title: "Payment details",
    component: PaymentFields,
    path: "/payment-details",
    isAvailable: sectionValidators.paymentType,
    isComplete: sectionValidators.paymentDetails,
  },
  {
    index: 3,
    title: "Amount",
    component: Amount,
    path: "/amount",
    isAvailable: sectionValidators.paymentDetails,
    isComplete: sectionValidators.amount,
  },
  {
    index: 4,
    title: "Additional information",
    component: MoreInfo,
    path: "/more-info",
    isAvailable: sectionValidators.amount,
    isComplete: sectionValidators.moreInfo,
  },
  {
    index: 5,
    title: "Schedule payment",
    component: Schedule,
    path: "/schedule",
    isAvailable: (input) => sectionValidators.moreInfo(input) && sectionValidators.amount(input),
    isComplete: (input) =>
      !!input.effectiveDate && sectionValidators.moreInfo(input)
  },
  {
    index: 6,
    title: "Review",
    component: ReviewTransaction,
    path: "/review-transaction",
    isAvailable: sectionValidators.moreInfo,
  },
  {
    index: 7,
    title: "Success",
    component: TransactionSuccess,
    path: "/success",
  },
];