import CheckBox from "components/checkbox/checkbox";
import DateInput from "components/date-input";
import Input, { getDateString } from "components/input";
import InputLabel from "components/input-label";
import { Flex } from "components/layout";
import RadioCard from "components/radio-card";
import RadioGroup from "components/radio-group";
import SelectExperimental from "components/select/select-experimental";
import Sticker from "components/sticker";
import Text from "components/text";
import { addBusinessDays, addDays, format, isValid } from "date-fns";
import React from "react";
import { HiArrowSmRight, HiOutlineExclamationCircle } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { SendMoneyComponentProps } from "../move-money.types";
import { sectionValidators } from "../move-money.utils";
import { BackButton, NextButton } from "./nav-buttons";

function description(date: Date, duration: number = 3) {
  return `${format(date, "d MMM")} / Arrives between ${format(
    addBusinessDays(date, 1),
    "d MMM"
  )} - ${format(addBusinessDays(date, duration), "d MMM")}`;
}

type DateConfig = {
  label: string;
  date: Date;
  dateString?: string;
  value?: string;
};

const dates: Array<DateConfig> = [
  { label: "Today", date: new Date(), value: "today" },
  { label: "Tomorrow", date: addDays(new Date(), 1), value: "tomorrow" },
];

function findDateValueByDate(date: Date) {
  if (!isValid(date)) return null;

  const selected =
    dates.find(
      (d) => format(d.date, "d MMM yyyy") === format(date, "d MMM yyyy")
    )?.value || "custom";

  return selected as DateOption;
}

type DateOption = "today" | "tomorrow" | "next_business_day" | "custom";

const Schedule = React.forwardRef<HTMLDivElement, SendMoneyComponentProps>(
  ({ form }, ref) => {
    const [selected, setSelected] = React.useState<DateOption | null>(
      findDateValueByDate(form.values.effectiveDate!)
    );

    const durationMap: any = {
      domestic_wire: 2,
      international_wire: 3,
    };

    function onChange(e: React.ChangeEvent<HTMLInputElement>) {
      const value = e.target.value as DateOption;

      setSelected(value);

      if (value === "custom") {
        form.setInputValue("effectiveDate", new Date());
        return;
      }

      form.setInputValue(
        "effectiveDate",
        dates.find((d) => d.value === value)?.date
      );
    }

    return (
      <Flex ref={ref} direction="column" gap={10}>
        <Text color="black" size="xl">
          When do you want to send it ?
        </Text>
        <Flex direction={"column"} gap={4}>
          <RadioGroup value={selected || undefined} onChange={onChange}>
            {dates.map((date, index) => (
              <RadioCard
                key={`${date.label}-${index}`}
                description={description(
                  date.date,
                  durationMap[form.values.methodOfPayment]
                )}
                title={date.label}
                value={date.value!}
              />
            ))}

            <RadioCard title="Custom Date" value="custom">
              {selected === "custom" && (
                <Input
                  value={getDateString(form.values.effectiveDate!)}
                  appearance={"outline"}
                  size="sm"
                  css={{
                    ".input-wrapper": { border: "none" },
                  }}
                  onChange={(e) => {
                    const value = e.target.value;

                    const date = new Date(value);

                    if (!isValid(date)) {
                      form.setInputValue("effectiveDate", new Date());
                      return;
                    }

                    form.setInputValue("effectiveDate", date);
                  }}
                  type="date"
                ></Input>
              )}
            </RadioCard>
          </RadioGroup>
        </Flex>

        <Flex gap={3}>
          <BackButton to="/more-info">Back</BackButton>
          <NextButton
            disabled={!sectionValidators.schedule(form.values)}
            to="/review-transaction"
          >
            Continue
          </NextButton>
        </Flex>
      </Flex>
    );
  }
);

Schedule.displayName = "Recipient";

export default Schedule;
