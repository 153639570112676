import { useEffect } from "react";
import { ToastProps, ToastVariants } from "./toast.types";
import { AlertButton, AlertContent, AlertWrapper } from "./toast.styles";
import { motion, Variants } from "framer-motion";
import {
  HiCheckCircle,
  HiOutlineCheckCircle,
  HiOutlineExclamationCircle,
  HiX,
} from "react-icons/hi";
import Text from "components/text";
import Box from "components/box";

const toastMotionVariants: Variants = {
  initial: (props) => {
    const { position } = props;

    const dir = ["top", "bottom"].includes(position) ? "y" : "x";

    let factor = ["top-right", "bottom-right"].includes(position) ? 1 : -1;
    if (position === "bottom") factor = 1;

    return {
      opacity: 0,
      [dir]: factor * 24,
    };
  },
  animate: {
    opacity: 1,
    y: 0,
    x: 0,
    scale: 1,
    transition: {
      duration: 0.4,
      ease: [0.4, 0, 0.2, 1],
    },
  },
  exit: {
    opacity: 0,
    scale: 0.85,
    transition: {
      duration: 0.2,
      ease: [0.4, 0, 1, 1],
    },
  },
};

const statusIcons: Record<ToastVariants, React.ReactNode> = {
  default: <HiOutlineCheckCircle size={18} />,
  success: <HiOutlineCheckCircle size={18} />,
  error: <HiOutlineExclamationCircle size={18} />,
  warning: <HiOutlineExclamationCircle size={18} />,
  dark: <HiOutlineExclamationCircle size={18} />,
  info: <HiOutlineExclamationCircle style={{ rotate: "180deg" }} size={18} />,
};

const ToastAlert: React.FC<ToastProps> = ({
  content,
  destroy,
  size,
  position,
  status = "default",
  duration = 10000,
  showIcon = false,
  closable = true,
}: ToastProps) => {
  useEffect(() => {
    if (!duration) return;

    const timer = setTimeout(() => {
      destroy();
    }, duration);

    return () => clearTimeout(timer);
  }, [destroy, duration]);

  return (
    <motion.div
      layout
      className="toast-alert"
      variants={toastMotionVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      custom={{ position }}
    >
      <AlertWrapper variant={status} size={size}>
        <AlertContent gap={1} align={"center"}>
          {showIcon && statusIcons[status]}
          <Text
            css={{ "&:first-letter": { textTransform: "uppercase" } }}
            color="inherit"
          >
            {content}
          </Text>
        </AlertContent>

        {closable && (
          <AlertButton onClick={destroy}>
            <HiX className="close-icon" size={16}></HiX>
          </AlertButton>
        )}
      </AlertWrapper>
    </motion.div>
  );
};

export default ToastAlert;
