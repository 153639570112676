import Avatar from "components/avatar";
import Box from "components/box";
import CountryChip from "components/country-chip";
import CurrencyInput from "components/currency-input/currency-input";
import { Flex } from "components/layout";
import RadioCard from "components/radio-card";
import SelectExperimental from "components/select/select-experimental";
import Separator from "components/separator";
import Sticker from "components/sticker";
import Tag from "components/tag";
import Text from "components/text";
import Timeline from "components/timeline";
import { useGetServiceFeeQuery } from "generated/__generated_graphql";
import { useAppProvider } from "providers/app-provider/app-provider";
import React from "react";
import { HiBan, HiLibrary } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { formatMoney } from "utils/helpers";
import { SendMoneyComponentProps } from "../move-money.types";
import { sectionValidators } from "../move-money.utils";
import DetailTimeline from "./detail-timeline";
import { BackButton, NextButton } from "./nav-buttons";

const Amount = React.forwardRef<HTMLDivElement, SendMoneyComponentProps>(
  ({ form }, ref) => {
    const { user } = useAppProvider();
    const navigate = useNavigate();

    const input = form.values;

    const selectedBankAccount = user?.business?.bankAccounts.find(
      (account) => account.id === input.bankAccountId
    );

    const [{ fetching, data }] = useGetServiceFeeQuery({
      variables: {
        serviceType: `outgoing_${input?.methodOfPayment}` as any,
        amount: input?.amount,
        accountNumber: input.accountNumber,
        routingNumber: input.routingNumber,
      },
      pause: !!!input.amount || !!!input.methodOfPayment,
    });

    const total = (data?.getServiceFee || 0) + (input?.amount || 0);

    const exceedsBalance =
      selectedBankAccount && (selectedBankAccount?.balance || 0) < total;

    React.useEffect(() => {
      form.setInputValue("fee", data?.getServiceFee || 0);
    }, [data?.getServiceFee]);

    return (
      <Flex direction={"column"} gap={10}>
        <Text align={"center"} color={"black"} size="xl">
          How much are you sending?
        </Text>
        <Flex gap={4} direction="column">
          <Flex stack gap={1}>
            <CurrencyInput
              max={selectedBankAccount?.balance || 100000}
              value={input.amount}
              onValueChange={(value) => form.setInputValue("amount", value)}
              append={
                <Flex align="center" gap={2}>
                  <Flex
                    justify={"center"}
                    align="center"
                    css={{ size: 18, overflow: "hidden", borderRadius: "50%" }}
                  >
                    <CountryChip size={30} country="US" />
                  </Flex>
                  <Text weight={"semi"}>USD</Text>
                </Flex>
              }
              label="You send"
              size={"md"}
            />
          </Flex>

          <Flex
            className="fee"
            css={{
              background: "white",
              borderRadius: 6,
              border: "1px solid $gray3",

              ".item": {
                px: 12,
                py: 8,
              },
            }}
            stack
            stretchX
          >
            <Flex className="item" stretchX align="center" justify={"between"}>
              <Text size="sm" color="light">
                Fee
              </Text>
              <Text color="light" size="sm">
                {formatMoney(data?.getServiceFee || "0")}
              </Text>
            </Flex>
            <Separator spacing={0} />
            <Flex className="item" stretchX align="center" justify={"between"}>
              <Text size="sm">Total</Text>
              <Text size="sm">{formatMoney(total)}</Text>
            </Flex>
          </Flex>

          {exceedsBalance && (
            <Sticker align={"center"} variant={"light"} color="tomato">
              <Avatar
                css={{ border: "none", color: "$tomato11" }}
                variant={"light"}
                size="xs"
                color="tomato"
              >
                <HiBan size={16} />
              </Avatar>
              <Text color="inherit" size={"xs"}>
                You only have {formatMoney(selectedBankAccount?.balance)} in
                your selected account
              </Text>
            </Sticker>
          )}

          <SelectExperimental
            value={form.values.bankAccountId}
            valueKey="id"
            onChange={(value) =>
              form.setInputValue("bankAccountId", value as string)
            }
            showCheckmark={false}
            renderOption={(option: any) => (
              <Flex stretchX justify={"between"} align={"center"} gap={2}>
                <Flex align={"center"} gap={2}>
                  <Avatar
                    css={{
                      background: "transparent",
                      border: "2px solid $gray5",
                      color: "$gray11",
                    }}
                    size="sm"
                    variant={"light"}
                  >
                    <HiLibrary size={14} />
                  </Avatar>
                  <Text>
                    {option.name} • • {option.accountNumber.slice(-4)}
                  </Text>
                </Flex>

                <Text color="light">{formatMoney(option.balance)}</Text>
              </Flex>
            )}
            renderValue={(option: any) => (
              <Flex align={"center"} gap={2}>
                <Avatar
                  css={{
                    background: "transparent",
                    border: "2px solid $gray5",
                    color: "$gray11",
                  }}
                  size="sm"
                  variant={"light"}
                >
                  <HiLibrary size={14} />
                </Avatar>
                <Flex gap={3}>
                  <Text>
                    {option.name} • • {option.accountNumber.slice(-4)}
                  </Text>
                  <Text color="light">{formatMoney(option.balance)}</Text>
                </Flex>
              </Flex>
            )}
            options={user?.business?.bankAccounts || []}
            label="Send From"
            size="md"
          ></SelectExperimental>
        </Flex>
        <Flex gap={3}>
          <BackButton to="/payment-details">Back</BackButton>
          <NextButton
            disabled={
              !sectionValidators.amount(input) || exceedsBalance || fetching
            }
            to="/more-info"
          >
            Continue
          </NextButton>
        </Flex>
      </Flex>
    );
  }
);

Amount.displayName = "Recipient";

export default Amount;
