import Input from "components/input";
import { Flex } from "components/layout";
import Modal, { ModalProps } from "components/modal";
import Text from "components/text";
import { useToast } from "components/toast";
import { useChangePasswordMutation } from "generated/__generated_graphql";
import useForm from "hooks/useForm";
import { setToken } from "utils/auth";

export default function ChangePassword({ onOpenChange, ...props }: ModalProps) {
  const notify = useToast();
  const { register, onSubmit, values } = useForm({
    fields: {
      currentPassword: "",
      password: "",
    },
  });
  const [{ fetching }, changePassword] = useChangePasswordMutation();

  async function onPasswordChange() {
    try {
      const response = await changePassword({
        oldPassword: values.currentPassword,
        newPassword: values.password,
      });

      const status = response.data?.changePassword?.status;

      if (status !== 200) {
        notify({
          content: "Password change failed",
          status: "error",
          position: "top",
        });
        return;
      }

      notify({
        content: "Password changed successfully",
        status: "success",
        position: "top",
      });

      setToken(null);
      window.location.pathname = "/login";
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Modal onOpenChange={onOpenChange} title="Change passowrd" {...props}>
      <form onSubmit={(e) => onSubmit(e, onPasswordChange)}>
        <Modal.Body css={{ width: 400 }}>
          <Flex stack gap={6}>
            <Text size="xs" lineHeight={"tall"} color="light">
              Enter your current password and your new password. You will be
              logged out of all your sessions including this one.
            </Text>
            <Flex gap={5} stack>
              <Input
                {...register("currentPassword")}
                size="md"
                type="password"
                name="currentPassword"
                label="Current password"
              ></Input>
              <Input
                {...register("password")}
                size="md"
                type="password"
                name="password"
                label="New password"
              ></Input>
            </Flex>
          </Flex>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Cancel onClick={() => onOpenChange?.(false)}>
            Cancel
          </Modal.Cancel>
          <Modal.Confirm isLoading={fetching} type="submit">
            Change password
          </Modal.Confirm>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
