import { css } from "stitches/stitches.config";

export const supportedColors = [
  "sand",
  "green",
  "blue",
  "pink",
  "orange",
  "teal",
  "purple",
  "white",
  "violet",
  "cyan",
  "tomato",
  "amber",
  "indigo",
  "gray",
  "grass",
  "sky",
  "mint",
  "lime"
] as const;

export const SharedColorStyles = css({
  variants: {
    color: {
      lime: {},
      sand: {},
      green: {},
      blue: {},
      pink: {},
      orange: {},
      purple: {},
      violet: {},
      cyan: {},
      teal: {},
      white: {},
      tomato: {},
      amber: {},
      indigo: {},
      gray: {},
      grass: {},
      sky: {},
      mint: {},
      transparent: {
        background: "none",
        "&:hover": { backgroundColor: "$gray3" },
        "&:active": { backgroundColor: "$gray2" },
      },
    },
    variant: {
      solid: {},

      light: {},
      outline: {},
    },
  },
  compoundVariants: [...getColorStyles("light"), ...getColorStyles("solid"), ...getColorStyles("outline")],
});

function getColorStyles(variant: "solid" | "light" | "outline") {
  return supportedColors.map((color) => {
    if (variant === "light") {
      return {
        color: color,
        variant: variant,
        css: {
          background: `$${color}4`,
          color: `$${color}12`,
          fill: `$${color}12`,
          border: `1.5px solid $${color}5`
          // "&:hover": { backgroundColor: `$${color}5` },
          // "&:active": { backgroundColor: `$${color}6` },
        },
      };
    }

    if (variant === "outline") {
      return {
        color: color,
        variant: variant,
        css: {
          background: "white",
          color: `$${color}11`,
          fill: `$${color}11`,
          border: `1.5px solid $${color}5`
          // "&:hover": { backgroundColor: `$${color}5` },
          // "&:active": { backgroundColor: `$${color}6` },
        },
      };
    }

    return {
      color: color,
      variant: variant,
      css: {
        background: color === "gray" ? `$${color}12` : `$${color}10`,
        color: "white",
        fill: "white",
        // "&:hover": { backgroundColor: `$${color}8` },
        // "&:active": { backgroundColor: `$${color}11` },
      },
    };
  });
}
