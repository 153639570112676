import Input, { InputProps } from "components/input";
import React from "react";
import { mergeRefs } from "utils/dom";
import { formatMoney } from "utils/helpers";

type CurrencyInputProps = InputProps & {
  onValueChange?(value: number): void;
  preventLeadingZeros?: boolean;
  currency?: string;
  max?: number;
  min?: number;
};

const CurrencyInput = React.forwardRef<HTMLDivElement, CurrencyInputProps>(
  (
    {
      onChange,
      value,
      onValueChange,
      onKeyDown,
      onFocus,
      onBlur,
      preventLeadingZeros = false,
      currency = "₦",
      max,
      min,
      ...props
    },
    ref
  ) => {
    const [internalValue, setInternalValue] = React.useState(0);
    const [cursorPosition, setCursorPosition] = React.useState(0);
    const [isTouched, setIsTouched] = React.useState(false);
    const [keyPressed, setKeyPressed] = React.useState<string | null>(null);
    const inputRef = React.useRef<HTMLInputElement>(null);

    const valueToUse = value !== undefined ? value : internalValue;

    function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
      setKeyPressed(e.key);
      onKeyDown?.(e);
    }

    function handleFocus(e: React.FocusEvent<HTMLInputElement>) {
      // select all text on focus when the value is 0
      if (valueToUse === 0) {
        e.preventDefault();
        inputRef.current?.setSelectionRange?.(0, 4);
      }
      onFocus?.(e);
    }

    function handleBlur(e: React.FocusEvent<HTMLInputElement>) {
      onBlur?.(e);
    }

    function positionCursor(start: number, end: number) {
      inputRef.current?.setSelectionRange?.(start, end);
    }

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
      let { selectionStart: cursorPosition, value } = e.target;

      const hasLeadingDecimal = e.target.value.match(/^\./g);
      const hasMultipleDecimals = e.target.value.match(/\./g)?.length! > 1;
      const hasLeadingZeros =
        e.target.value.match(/^0[0-9]/g) && preventLeadingZeros;
      const hasInvalidInput =
        hasLeadingDecimal || hasMultipleDecimals || hasLeadingZeros;

      if (hasInvalidInput) {
      }

      if (keyPressed === "." && value[cursorPosition! + 1] === ".") {
        setCursorPosition(cursorPosition! + 1);
      }

      if (hasMultipleDecimals) {
        value = value.split(".").slice(0, 2).join(".");
      }

      function processString() {
        if (valueToUse === 0) {
          return value.replace(/[^0-9.]/g, "").replace(/0/g, "");
        }
        const numberWithRemovedCommas = value.replace(/[^0-9.]/g, "");

        return numberWithRemovedCommas;
      }

      const cleanValue = Number(processString());

      // if (max && cleanValue > max) {
      //   return;
      // }

      // if (min && cleanValue < min) {
      //   return;
      // }

      setInternalValue(cleanValue);
      setCursorPosition(cursorPosition!);
      onValueChange?.(cleanValue);
    }

    function renderDisplay() {
      return formatMoney(Number(valueToUse), "");
    }

    let prevCommasRef = React.useRef(0);

    React.useLayoutEffect(() => {
      const currentNumberOfCommas = (renderDisplay().match(/,/g) || []).length;

      if (!valueToUse) {
        positionCursor(0, 0);
        return;
      }

      const adjustedPos =
        currentNumberOfCommas > prevCommasRef.current
          ? cursorPosition + 1
          : currentNumberOfCommas < prevCommasRef.current
          ? cursorPosition - 1
          : cursorPosition;

      prevCommasRef.current = currentNumberOfCommas;

      positionCursor(adjustedPos, adjustedPos);
    }, [valueToUse, cursorPosition]);

    return (
      <Input
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        value={renderDisplay()}
        onKeyDown={handleKeyDown}
        ref={mergeRefs(ref)}
        inputRef={inputRef}
        {...props}
      ></Input>
    );
  }
);

CurrencyInput.displayName = "CurrencyInput";

export default CurrencyInput;
