import { defaultValidators, InputValidator } from "components/input";
import React from "react";
import { isUndefinedOrNull } from "utils/assertions";
import { createCleanObject, objectKeys, omit } from "utils/object";
import { ID } from "utils/types";

interface IUseFormConfig<T> {
  fields: T;
  validators?: Partial<Record<keyof T, InputValidator<T>>>;
  optional?: Array<keyof T>;
  validateOnChange?: boolean;
  dependencies?: Partial<Record<keyof T, Array<keyof T>>>;
  beforeUpdate?: (values: T) => void;
  afterUpdate?: (values: T) => void;
  beforeValidate?: (values: T) => void;
  afterValidate?: (values: T) => void;
  loading?: boolean;
  refreshToken?: ID;
}

type ValidateFieldFn = (e: any) => void;

// typeof of use Form
export type UseFormReturnType<T> = {
  values: T;
  errors: Partial<Record<keyof T, string>>;
  onChange: <E>(e: React.ChangeEvent<E>) => void;
  onSubmit: (
    e: React.FormEvent<HTMLFormElement>,
    submitFn: (...args: any) => void
  ) => void;
  hasErrors: boolean;
  formIsComplete: boolean;
  validateField: ValidateFieldFn;
  getFieldProps: (fieldName: keyof T) => {
    value: string | number;
    error: string;
    name: keyof T;
    onBlur: ValidateFieldFn;
    onChange: <E>(e: React.ChangeEvent<E>) => void;
  };
  register: (
    fieldName: keyof T
  ) => ReturnType<UseFormReturnType<T>["getFieldProps"]>;
  setInputValue: (fieldName: keyof T, value: T[keyof T] | null) => void;
  setError: (fieldName: keyof T, value: string) => void;
  bulkUpdate: (values: Partial<T>) => void;
};

export default function useForm<T = any>({
  fields,
  validateOnChange = true,
  validators,
  dependencies = {},
  optional = [],
  loading,
  refreshToken,
}: IUseFormConfig<T>): UseFormReturnType<T> {
  const [values, setValues] =
    React.useState<Record<keyof typeof fields, any>>(fields);
  const [errors, setErrors] = React.useState<Record<keyof typeof fields, any>>(
    createCleanObject(fields) as any
  );

  const touched = React.useRef(new Set<keyof T>()).current;

  // this makes sure we are always reading the latest values and errors
  // cant rely on state because it is async
  const valuesRef = React.useRef(values);
  const errorsRef = React.useRef(errors);

  const hasErrors = (errors: Record<keyof T, string>) => {
    return Object.values(errors).filter(Boolean).length > 0;
  };

  function bulkUpdate(payload: Partial<T>) {
    const newValues = { ...values, ...payload };
    const newErrors = validateFields(objectKeys(payload) as Array<keyof T>);
    _setValues(newValues);
    _setErrors({ ...errors, ...newErrors });
  }

  function _setValues(newValues: T) {
    setValues(newValues);
    valuesRef.current = newValues;
  }

  function _setErrors(newErrors: Record<keyof T, string>) {
    setErrors(newErrors);
    errorsRef.current = newErrors;
  }

  function updateValues(key: keyof T, value: any) {
    const newValues = { ...values, [key]: value };
    const dependentFields = dependencies[key];
    _setValues(newValues);
    touched.add(key);

    if (validateOnChange && !optional.includes(key)) {
      updateErrors(key, value);
    }

    if (dependentFields) {
      const newErrors = validateFields(dependentFields);

      _setErrors({ ...errors, ...newErrors });
    }
  }

  function getValidator(key: keyof T) {
    const _validators: typeof defaultValidators & typeof validators = {
      ...defaultValidators,
      ...validators,
    };

    const validator = _validators[key] || _validators["any"];
    return validator;
  }

  function updateErrors(key: keyof T, value: any) {
    const validator = getValidator(key);

    const error = validator?.(value, values);
    const newErrors = { ...errors, [key]: error };
    setErrors(newErrors);
    errorsRef.current = newErrors;
  }

  function onChange(e: any) {
    const { name, value } = e.target;
    updateValues(name, value);
  }

  function setInputValue(name: keyof T, value: any) {
    updateValues(name, value);
  }

  function setError(name: keyof T, value: string) {
    updateErrors(name, value);
  }

  function validateField(e: any): void {
    const { name, value } = e.target;
    if (optional.includes(name)) return;

    updateErrors(name, value);
  }

  function validateFields(fields: Array<keyof T>, checkForTouched = true) {
    const errors = fields.reduce((acc, key) => {
      const isOptional = optional.includes(key as keyof T);
      const isTouched = touched.has(key);
      const value = values[key];
      const error =
        isOptional || (checkForTouched && !isTouched)
          ? ""
          : getValidator(key)(value, valuesRef.current);
      return { ...acc, [key]: error };
    }, {}) as Record<keyof T, any>;

    return errors;
  }

  function validateAllFields() {
    return validateFields(objectKeys(values) as Array<keyof T>, false);
  }

  function onSubmit(e: any, submitFn: (...args: any) => void) {
    e.preventDefault();

    const errors = validateAllFields();

    if (hasErrors(errors)) {
      setErrors(errors);
      return;
    }

    submitFn();
  }

  function getFieldProps(field: keyof T) {
    return {
      onChange,
      value: values[field as keyof T] as string | number,
      error: errors[field as keyof T],
      name: field,
      onBlur: optional.includes(field as keyof T) ? () => {} : validateField,
    };
  }

  function register(field: keyof T) {
    return getFieldProps(field);
  }

  function getFormCompleteness() {
    const values = valuesRef.current;

    const errors = validateFields(objectKeys(values) as Array<keyof T>, false);
    return !hasErrors(errors);
  }

  const formIsComplete = getFormCompleteness();

  // TESTING *** MAY BE UNSAFE //
  React.useEffect(() => {
    if (!isUndefinedOrNull(refreshToken)) {
      _setValues(fields);
    }
  }, [refreshToken]);

  return {
    values,
    onChange,
    errors,
    validateField,
    hasErrors: hasErrors(errors),
    onSubmit,
    formIsComplete,
    getFieldProps,
    setInputValue,
    register,
    setError,
    bulkUpdate,
  };
}
