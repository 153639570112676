import { css, styled } from "stitches/stitches.config";
import { SharedColorStyles } from "components/_settings/styles/SharedColorStyles";

const SharedTagStyles = css({
  display: "inline-flex",
  alignItems: "center",
  fontFamily: "$semibold",
  fontWeight: 500,
  letterSpacing: 0.3,

  textTransform: "capitalize",
  variants: {
    type: {
      danger: {
        background: "$tomato3",
        color: "$tomato11",
      },
      success: {
        background: "$green3",
        color: "$green10",
      },
      warning: {
        background: "$amber2",
        color: "$amber7",
      },
      default: {
        background: "$gray3",
        color: "$gray11",
      },
    },
    size: {
      "3xs": { height: 20, px: 8, fontSize: 11 },
      "2xs": { height: 20, px: 8, fontSize: 12 },
      xs: { height: 24, px: 8, fontSize: 13 },
      sm: {
        height: 28,
        px: 12,
        fontSize: "$2",
      },
      md: { height: 32, px: 12, fontSize: "$3" },
      lg: { height: 40, px: 12, fontSize: "$3" },
    },
    shape: {
      pill: {},
      rounded: {},
      flat: { borderRadius: 0 },
    },
  },

  compoundVariants: [
    { size: "3xs", shape: "rounded", css: { borderRadius: 4 } },
    { size: "2xs", shape: "rounded", css: { borderRadius: 4 } },
    { size: "xs", shape: "rounded", css: { borderRadius: 6 } },
    { size: "sm", shape: "rounded", css: { borderRadius: 6 } },
    { size: "md", shape: "rounded", css: { borderRadius: 6 } },
    { size: "lg", shape: "rounded", css: { borderRadius: 8 } },

    { size: "3xs", shape: "pill", css: { borderRadius: 10 } },
    { size: "2xs", shape: "pill", css: { borderRadius: 10 } },
    { size: "xs", shape: "pill", css: { borderRadius: 12 } },
    { size: "sm", shape: "pill", css: { borderRadius: 14 } },
    { size: "md", shape: "pill", css: { borderRadius: 16 } },
    { size: "lg", shape: "pill", css: { borderRadius: 20 } },
  ],

  defaultVariants: {
    type: "default",
    shape: "rounded",
    size: "sm",
  },
});

export const StyledNativeTag = styled(
  "span",
  SharedColorStyles,
  SharedTagStyles
);

export const StyledButtonTag = styled(
  "button",
  SharedColorStyles,
  SharedTagStyles,
  {
    variants: {
      state: {
        active: {
          borderWidth: "2px",
          borderStyle: "solid",
          borderColor: "inherit",
        },
        inactive: {
          border: "2px hidden",
          appearance: "none",
        },
      },
    },
    defaultVariants: { state: "inactive" },
  }
);
