import React from "react";
import Button from "components/button";
import Input from "components/input";
import Flex from "components/layout/flex";
import Stack from "components/layout/stack";
import Loader from "components/loader";
import Text from "components/text";
import {
  useFinalizeOnboardingMutation,
  useFindOnboardeeQuery,
} from "generated/__generated_graphql";
import { useNavigate, useParams } from "react-router-dom";
import { styled } from "stitches/stitches.config";
import Logo from "assets/images/wordmark-dark.svg";
import NewFloatLogo from "assets/images/new-float-logo.svg";
import LinkButton from "components/link-button";
import useForm from "hooks/useForm";
import AvatarGroup from "components/avatar/avatar-group";
import Avatar from "components/avatar";
import { useToast } from "components/toast";
import { ActionStatus } from "utils/types";
import { HiCheck, HiChevronRight } from "react-icons/hi";
import LOGOMARK from "assets/images/logomark--white.svg";

const Container = styled(Flex, { height: "80vh" });

export default function JoinTeam() {
  const { code } = useParams();
  const toast = useToast();
  const navigate = useNavigate();

  const [createPasswordStatus, setCreatePasswordStatus] =
    React.useState<ActionStatus>("idle");

  const [{ fetching: findingUser, data, error }] = useFindOnboardeeQuery({
    variables: { onboardingCode: code! },
    pause: !code,
  });

  const [{ fetching: isSavingPassword }, finalizeOnboarding] =
    useFinalizeOnboardingMutation();

  const { getFieldProps, values, onSubmit, formIsComplete } = useForm({
    fields: { password: "" },
  });

  const onboardee = data?.findOnboardee;

  async function joinTeam() {
    const response = await finalizeOnboarding({
      onboardingCode: code!,
      password: values.password,
    });

    if (
      response.error ||
      response.data?.finalizeOnboarding?.errors?.length! > 0
    ) {
      toast({ content: "something went wrong", status: "error" });
      return;
    }

    setCreatePasswordStatus("success");
  }

  if (findingUser) {
    return (
      <Container justify={"center"} align="center">
        <Loader size="sm" color="$gray12"></Loader>
      </Container>
    );
  }

  if (error || !data?.findOnboardee) {
    return (
      <Container direction={"column"} justify={"center"} align="center">
        <Stack align="center" spacing={36} css={{ width: 300 }}>
          <Flex justify={"center"}>
            <img width={80} src={Logo} alt="float-logo" />
          </Flex>
          <Stack spacing={8} align={"center"}>
            <Text lineHeight={"short"} align={"center"} size={"2xl"}>
              We couldn't find an invitation for this user
            </Text>
            <Text
              lineHeight={"short"}
              align={"center"}
              size={"sm"}
              color="light"
            >
              Sorry,this link has already been used or has expired. Please
              contact your administrator
            </Text>
          </Stack>
          <LinkButton
            append={<HiChevronRight />}
            href="https://help.float.co"
            appearance={"secondary"}
          >
            Contact support
          </LinkButton>
        </Stack>
      </Container>
    );
  }

  if (createPasswordStatus === "success") {
    return (
      <Container justify={"center"} align="center">
        <Flex gap={10} stack align={"center"}>
          <Avatar variant={"solid"} color="teal" size="md">
            <HiCheck size={20}></HiCheck>
          </Avatar>
          <Stack spacing={8} css={{ width: 360 }}>
            <Text color="black" align={"center"} size={"2xl"}>
              Account created successfully !
            </Text>
            <Text lineHeight={"tall"} color="light" align={"center"} size="sm">
              Your account is ready. Please login to continue using your float
              account.
            </Text>
          </Stack>
          <Button
            size="md"
            shape={"pill"}
            onClick={() => navigate("/login")}
            append={<HiChevronRight size={16} />}
            appearance={"secondary"}
          >
            Go to login page
          </Button>
        </Flex>
      </Container>
    );
  }

  return (
    <Container justify={"center"} align="center">
      <Stack align={"center"} isInline spacing={72}>
        <Stack css={{ maxWidth: 400 }} spacing={8}>
          <AvatarGroup spacing={-16} size="xl">
            <Avatar variant={"light"} name={onboardee?.business?.name}></Avatar>
            <Avatar color="gray" variant={"light"}>
              <img
                style={{ filter: "invert(1)" }}
                width={30}
                src={LOGOMARK}
              ></img>
            </Avatar>
          </AvatarGroup>

          <Text color="light" lineHeight={"short"} weight={"semi"} size="2xl">
            Hi {onboardee?.firstName}! You have been invited to join{" "}
            <Text weight={"semi"} size="inherit" color="dark" as="span">
              {onboardee?.business?.name}
            </Text>{" "}
            on Float!
          </Text>
          <Text size="sm" color="light">
            Set a password to join the {onboardee?.business?.name} team
          </Text>
        </Stack>

        <form className="wrapper" onSubmit={(e) => onSubmit(e, joinTeam)}>
          <Stack
            css={{ width: 400 }}
            align="stretch"
            justify="center"
            spacing={12}
          >
            <Stack isInline>
              <Input
                label="First name"
                size="lg"
                value={onboardee?.firstName}
                name="firstName"
                disabled
              ></Input>
              <Input
                label="Last name"
                size={"lg"}
                value={onboardee?.lastName}
                name="lastName"
                disabled
              ></Input>
            </Stack>
            <Input
              label="Email"
              size={"lg"}
              value={onboardee?.email}
              name="email"
              disabled
            ></Input>
            <Input
              label="Password"
              size="lg"
              placeholder="Enter password"
              type="password"
              {...getFieldProps("password")}
            ></Input>

            <Button
              isLoading={isSavingPassword}
              appearance={"secondary"}
              size="lg"
              disabled={!formIsComplete}
              type="submit"
            >
              Create account
            </Button>
          </Stack>
        </form>
      </Stack>
    </Container>
  );
}
