import React from "react";
import FloatLogoDark from "assets/images/boundless-logo.svg";
import { styled } from "stitches/stitches.config";

const StyledImg = styled("img", {});

type LogoProps = React.ComponentProps<typeof StyledImg>;

const Logo = React.forwardRef<HTMLImageElement, LogoProps>((props, ref) => {
  return (
    <StyledImg
      style={{ height: "auto", ...props.style }}
      src={FloatLogoDark}
      alt="logo"
      ref={ref}
      {...props}
    />
  );
});

Logo.displayName = "Logo";

export default Logo;
