import { EmptyStateProps } from "components/empty-state";
import { IPagination } from "components/pagination/pagination";
import { TableDetailProps } from "components/table-detail/table-detail";
import React from "react";
import { Dict, ID } from "utils/types";

export enum FieldType {
  NUMBER = "number",
  MONEY = "money",
  DATE = "date",
  TEXT = "text",
  OPTION = "option",
  SELECT = "select",
}

export interface ITableColumn<T = unknown> {
  title?: string;
  dataIndex?: keyof T;
  key: ID;
  render?(
    value: any,
    item: T,
  ): React.ReactNode;
  type?: IFilter;
  filter?: IFilter;
  width?: number;
  minWidth?: number;
  maxWidth?: number;
  header?: (data: Array<T>) => React.ReactNode;
}

export interface IFilter {
  key?: string;
  type?: FieldType;
  filter?: FieldType;
  data?: Dict;
}

export type Operator = "BETWEEN" | "=" | "<" | ">" | "<>";
export interface IFilterValue {
  fieldType?: FieldType;
  field: string;
  operator: Operator;
  values: Array<any>;
}

export interface FilterTypeData {
  label: string;
  value: string;
}

type OptionFilterType = {
  name: string;
  data: FilterTypeData[] | any[];
};

export type ResultField = Omit<IFilterValue, "type">;

export type ResultFilter = {
  q: string;
  fields: Array<ResultField>;
};

export interface SimpleFilter {
  option?: OptionFilterType;
  placeholder?: string;
  showSearch?: boolean;
  showDate?: boolean;
}



export interface ITableControls {
  columnFilter: SimpleFilter;
  onFilter?(filters: Array<IFilterValue>): void;
}

export type AddOnFilter = { filter: IFilter; title?: string; key?: string };


export type TableCell = {
  value: any;
  column: string;
  render?: React.ReactNode;
};

export type TableRow<T = any> = {
  id: ID;
  cells: Array<TableCell>;
  onClick?: RowActions<T>["click"];
  record: T;
  onDoubleClick?: RowActions<T>["doubleClick"];
  onMouseEnter?: RowActions<T>["mouseEnter"];
  onMouseLeave?: RowActions<T>["mouseLeave"];
};


type RowActions<T> = {
  click?: (event: React.MouseEvent<HTMLElement>, row: TableRow<T>) => void;
  doubleClick?: (event: React.MouseEvent<HTMLElement>, row: TableRow<T>) => void;
  mouseEnter?: (event: React.MouseEvent<HTMLElement>, row: TableRow<T>) => void;
  mouseLeave?: (event: React.MouseEvent<HTMLElement>, row: TableRow<T>) => void;
}


export interface ITable<T = any> {
  loading?: boolean;
  columns: Array<ITableColumn<T>>;
  data: Array<T>;
  title?: string;
  pagination?: IPagination | null;
  emptyStateProps?: EmptyStateProps;
  rowActions?(rowData: T): React.ReactNode;
  renderRow?(rowData: T, index: number, isLastRow: boolean): React.ReactNode;
  DetailComponent?: React.ComponentType<TableDetailProps<T>>;
  onRow?: RowActions<T>
  selectedRow?: ID | null;
}

