import { omit, pick } from "./object";
import { Dict } from "./types";

export function range(start = 0, end: number, step = 1): Array<number> {
  const result: Array<number> = [];

  for (let index = start; index <= end; index += step) {
    result.push(index);
  }

  return result;
}

export function findObjectInArray<O extends Dict>(
  array: Array<O>,
  key: keyof O,
  value: any
): O | undefined {
  return array.find((item) => {
    return item[key] === value;
  });
}

export function omitArray<O extends Dict>(array: Array<O>, keys: (keyof O)[]) {
  return array.map((item) => omit(item, keys));
}

export function pickArray<O extends Dict>(array: Array<O>, keys: (keyof O)[]) {
  return array.map((item) => pick(item, keys));
}
