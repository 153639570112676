import { User } from "generated/__generated_graphql";
import useWayFinder from "hooks/use-way-finder";
import PageWithSidebarLayout from "layouts/page-with-sidebar-layout";
import AppProvider, {
  useAppProvider,
} from "providers/app-provider/app-provider";
import { Route, Routes } from "react-router-dom";
import dashboardRoutes from "routes/dashboard-routes";

export default function Dashboard() {
  const { user } = useAppProvider();

  useWayFinder(user as User);

  return (
    <Routes>
      {dashboardRoutes.map(
        (
          { element: Element, path, isIndex, layout, provider: Provider },
          index
        ) => {
          const Layout = layout ? layout : PageWithSidebarLayout;

          if (Provider) {
            return (
              <Route
                key={index}
                index={isIndex}
                path={path as string}
                element={
                  <Provider>
                    <Layout>
                      <Element />
                    </Layout>
                  </Provider>
                }
              ></Route>
            );
          }

          return (
            <Route
              key={index}
              index={isIndex}
              path={path as string}
              element={
                <Layout>
                  <Element />
                </Layout>
              }
            ></Route>
          );
        }
      )}
    </Routes>
  );
}
