/* eslint-disable react/prop-types */
import React from "react";
import Box from "components/box";
import {
  Provider,
  TooltipRoot,
  StyledTrigger,
  StyledContent,
  StyledArrow,
  UnstyledContent,
} from "./tooltip.styles";
import { Portal } from "@radix-ui/react-tooltip";
import { CSS } from "stitches/stitches.config";

export type TooltipProps = React.ComponentProps<typeof TooltipRoot> & {
  children: React.ReactNode;
  description?: string;
  placement?: "top" | "bottom" | "left" | "right";
  align?: "start" | "center" | "end";
  css?: CSS;
  content?: React.ReactNode;
  render?(): React.ReactNode;
};

const Tooltip = React.forwardRef<HTMLDivElement, TooltipProps>(
  (
    {
      children,
      description,
      placement,
      align,
      css,
      content,
      delayDuration = 50,
      render,
      ...props
    },
    ref
  ) => {
    const contentToRender = content || description;

    return (
      <Provider>
        <TooltipRoot {...props} delayDuration={delayDuration}>
          <StyledTrigger>{children}</StyledTrigger>
          <Portal>
            {render ? (
              <UnstyledContent>{render()}</UnstyledContent>
            ) : (
              <StyledContent align={align} side={placement} sideOffset={5}>
                {contentToRender}
                {/* <StyledArrow /> */}
              </StyledContent>
            )}
          </Portal>
        </TooltipRoot>
      </Provider>
    );
  }
);

Tooltip.displayName = "Tooltip";

export default Tooltip;
