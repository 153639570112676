import Accordion from "components/accordion";
import Avatar from "components/avatar";
import Button from "components/button";
import { Flex, Stack } from "components/layout";
import Separator from "components/separator";
import Sticker from "components/sticker";
import Tag from "components/tag";
import Text from "components/text";
import { useToast } from "components/toast";
import {
  BusinessDirector,
  BusinessDocument,
  CoownerInvite,
  useCompleteOnboardingMutation,
  useGetStatesQuery,
} from "generated/__generated_graphql";
import React from "react";
import { FiEdit } from "react-icons/fi";
import {
  HiArrowLeft,
  HiChevronLeft,
  HiChevronRight,
  HiDocumentText,
  HiOutlineExclamation,
  HiPencil,
} from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { styled } from "stitches/stitches.config";
import { extractGraphqlErrors } from "utils/helpers";
import { OnboardingComponentProps } from "../onboarding";
import BusinessDirectorForm from "./business-director-form";
import VerifyIdentityModal from "./verify-identity-modal";
import CheckBox from "components/checkbox/checkbox";

const SectionWrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
});

function Details({
  name,
  value,
}: {
  name: string;
  value?: React.ReactNode | null;
}) {
  return (
    <Flex direction="column" gap="2">
      <Text size="xs" color="light" weight="regular">
        {name}
      </Text>
      <Text lineHeight={"tall"} as="div" size="sm" color="dark" weight="medium">
        {value}
      </Text>
    </Flex>
  );
}

const ReviewDetails = React.forwardRef<
  HTMLDivElement,
  OnboardingComponentProps
>(({ data }, ref) => {
  const business = data?.profile.business;
  const checklist = business?.checklist || {};
  const businessDirectorChecklist = checklist?.business_directors || [];
  const directors = business?.businessDirectors;
  const navigate = useNavigate();
  const toast = useToast();

  const canSubmit = Object.keys(checklist).length === 0;
  const missingBusinessDirectorInformation =
    directors?.length === 0 ||
    businessDirectorChecklist.some((item: any) => Object.keys(item).length > 0);

  const [{ fetching }, completeOnboarding] = useCompleteOnboardingMutation();

  function notifyError(error?: string) {
    toast({ content: error ?? "something went wrong", status: "error" });
  }

  async function submit() {
    try {
      const response = await completeOnboarding({});

      const error = extractGraphqlErrors(response, "completeOnboarding");

      if (error || response.data?.completeOnboarding?.status === 422) {
        notifyError(error!);
        return;
      }

      navigate("/waiting-room");
    } catch (error) {
      notifyError();
    }
  }

  function TitleHeader({
    title,
    navigateTo,
  }: {
    title: string;
    navigateTo: string;
  }) {
    return (
      <Flex
        css={{
          width: "100%",
          pb: 12,
        }}
        align="center"
        justify="between"
      >
        <Text size="md" color="black" weight="medium">
          {title}
        </Text>
        <Button
          append={<HiPencil />}
          onClick={() => navigate(navigateTo)}
          appearance="outline"
          size="sm"
        >
          Edit section
        </Button>
      </Flex>
    );
  }

  return (
    <>
      <Flex stack ref={ref} gap={16}>
        <Text size="xl" weight={"semi"} color="black">
          Review Details
        </Text>

        <Flex stack gap={5}>
          <SectionWrapper>
            <TitleHeader
              title="Company Information"
              navigateTo="/onboarding/company-info"
            />

            <Flex direction="column" gap={4}>
              <Details name="Business name" value={business?.name} />
              <Details
                name="Alias (Optional)"
                value={business?.businessAlias!}
              />
              <Details name="Industry" value={business?.industryType!} />

              <Details
                name="Country of incorporation"
                value={business?.address?.country?.name}
              />
              <Details name="Address" value={business?.address?.line1} />
              <Details name="City" value={business?.address?.city} />
              <Details name="State/Region" value={business?.address?.state} />

              <Details
                name="Phone number"
                value={business?.phoneNumber?.value}
              />
              <Details name="Business email" value={business?.email} />
              <Details
                name="Business description"
                value={business?.description}
              />
            </Flex>
          </SectionWrapper>

          <Separator spacing={4} />

          <SectionWrapper>
            <TitleHeader
              title="Verify Business"
              navigateTo="/onboarding/verify-business"
            />
            <Flex direction="column" gap={4}>
              <Details name="EIN" value={business?.ein} />
              <Details name="Business Type" value={business?.businessType} />
              {business?.businessDocuments.map((doc: BusinessDocument) => {
                let name = doc?.upload?.url.split("/");
                return (
                  <Details
                    key={doc.id}
                    name={(doc.documentType as string).split("_").join(" ")}
                    value={
                      <Flex gap={2}>
                        <HiDocumentText size={16} />
                        <Text color="inherit" size="inherit">
                          {name?.[name.length - 1] as string}
                        </Text>
                      </Flex>
                    }
                  />
                );
              })}
            </Flex>
          </SectionWrapper>

          <Separator spacing={4} />

          <SectionWrapper>
            <TitleHeader
              title="Owner Verification"
              navigateTo="/onboarding/owner-information"
            />

            <Flex stack gap={4}>
              {missingBusinessDirectorInformation && (
                <Sticker variant={"light"} color="tomato">
                  <HiOutlineExclamation size={18} />
                  <Text color="inherit">
                    Missing business ownership information
                  </Text>
                </Sticker>
              )}

              <Flex direction="column" gap={4}>
                {directors?.map((director, index: number) => (
                  <BusinessDirectorForm
                    key={index}
                    director={director as BusinessDirector}
                    data={data}
                  />
                ))}
              </Flex>
            </Flex>
          </SectionWrapper>
        </Flex>

        <Flex gap={2}>
          <CheckBox css={{ marginTop: 5 }}></CheckBox>
          <Text size="sm" lineHeight={"tall"} color="light">
            By continuing , I agree to Float’s{" "}
            <Text
              underline
              color="inherit"
              size={"inherit"}
              as="a"
              href="https://www.float.co/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy &nbsp;
            </Text>
            and
            <Text
              underline
              size={"inherit"}
              as="a"
              href="https://www.float.co/terms"
              target="_blank"
              rel="noopener noreferrer"
              color={"inherit"}
            >
              Terms and Conditions
            </Text>{" "}
            and to receive electronic communication about my accounts and
            services
          </Text>
        </Flex>

        <Flex gap={3} justify={"start"}>
          <Button
            prepend={<HiArrowLeft size={16} />}
            size={"md"}
            appearance="outline"
            disabled={fetching}
            onClick={() => navigate("/onboarding/owner-information")}
          >
            Back
          </Button>
          <Button
            append={<HiChevronRight size={16} />}
            size={"md"}
            type="submit"
            appearance="secondary"
            onClick={submit}
            isLoading={fetching}
            // disabled={!canSubmit}
          >
            Submit application
          </Button>
        </Flex>
      </Flex>
    </>
  );
});

ReviewDetails.displayName = "ReviewDetails";

export default ReviewDetails;
