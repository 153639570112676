import IconButton from "components/icon-button";
import { Flex } from "components/layout";
import Tooltip from "components/tooltip";
import React, { HTMLAttributes } from "react";
import { HiArrowSmDown, HiArrowSmUp, HiX } from "react-icons/hi";
import { styled } from "stitches/stitches.config";

const Header = styled(Flex, {
  // borderBottom: "1px solid $gray2",
  px: 16,
  height: 48,
  width: "100%",
  alignItems: "center !important",

  ".action": {
    color: "$gray10",
    background: "$gray1",
    size: 24,
    borderRadius: 8,
  },
});
const Body = styled(Flex, {});
const Footer = styled(Flex, {});

const ActionButton = React.forwardRef<HTMLButtonElement, any>(
  ({ children, ...props }, ref) => {
    return (
      <IconButton
        {...props}
        css={{ background: "$gray1", color: "$gray10" }}
        ref={ref}
      >
        {children}
      </IconButton>
    );
  }
);

ActionButton.displayName = "ActionButton";

export type TableDetailProps<T = "any"> = React.ComponentProps<typeof Flex> & {
  actions?: React.ReactNode;
  onNext?: () => void;
  onPrev?: () => void;
  data?: T | null;
  onClose?: () => void;
};

const Component = React.forwardRef<HTMLDivElement, TableDetailProps>(
  ({ children, onNext, onPrev, actions, onClose, ...props }, ref) => {
    return (
      <Flex {...props} gap={4} stack>
        <Header justify={"between"}>
          <Flex className="aux-actions" gap={2}>
            {actions}
          </Flex>

          <Flex gap={2}>
            <TableDetail.Action onClick={onClose}>
              <HiX size={18} />
            </TableDetail.Action>
          </Flex>
        </Header>
        <Body>{children}</Body>
      </Flex>
    );
  }
);

const TableDetail = Component as typeof Component & {
  Header: typeof Header;
  Action: typeof ActionButton;
};

Component.displayName = "TableDetail";

TableDetail.Header = Header;
TableDetail.Action = ActionButton;

export default TableDetail;
