import { styled } from "stitches/stitches.config";

/**
 * Just a div, nothing fancy :)
 */
const Box = styled("div", {
  variants: {
    stretchX: { true: { width: "100%" } },
    stretchY: { true: { height: "100%" } },
    position: {
      absolute: { position: "absolute" },
      relative: { position: "relative" },
      fixed: { position: "fixed" },
    },
  },
});

export default Box;
