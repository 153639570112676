import React from "react";
import ActionDropdown from "components/action-dropdown/action-dropdown";
import Avatar from "components/avatar";
import Button from "components/button";
import { Flex } from "components/layout";
import Table, { ITableColumn } from "components/table";
import Tag from "components/tag";
import Text from "components/text";
import {
  useDeletePersonMutation,
  User,
  useTeamPageQuery,
} from "generated/__generated_graphql";
import TeamModal from "pages/teams/components/team-modal";
import {
  HiOutlineStar,
  HiOutlineTrash,
  HiPencil,
  HiPlus,
  HiTrash,
} from "react-icons/hi";
import { extractGraphqlErrors, noop } from "utils/helpers";
import { RowItem, tableStyles } from "../settings.styles";
import { format, formatRelative } from "date-fns";
import ConfirmModal from "components/confirm-modal";
import { ID } from "utils/types";
import { useToast } from "components/toast";

export const TEAM_TABLE_COLUMNS: Array<ITableColumn<User>> = [
  {
    title: "User",
    key: "name",
    dataIndex: "name",
    render: (name, record) => (
      <Flex css={{ py: 6 }} gap={3}>
        <Avatar size={"sm"} variant={"light"} name={name} color="gray"></Avatar>
        <Flex stack gap={1}>
          <Text>{name}</Text>
          <Text size="xs" color={"light"}>
            {record.email}
          </Text>
        </Flex>
      </Flex>
    ),
  },
  {
    title: "Role",
    key: "role",
    dataIndex: "role",
    render: (role, user) => (
      <Flex gap={1}>
        <Tag shape={"pill"} color={"teal"} variant="outline" size={"xs"}>
          {role?.name}
        </Tag>
        {user.isOwner && (
          <Tag
            appendIcon={<HiOutlineStar />}
            size="xs"
            shape="pill"
            variant={"outline"}
            color="orange"
          >
            Owner
          </Tag>
        )}
      </Flex>
    ),
  },

  {
    title: "Last active",
    key: "lastActive",
    render: (_, user) => (
      <Text
        css={{ "&:first-letter": { textTransform: "uppercase" } }}
        color="light"
        size="sm"
      >
        {user.lastActive
          ? formatRelative(new Date(user.lastActive), new Date())
          : "Not signed in yet"}
      </Text>
    ),
  },
];

export default function TeamSettings() {
  const notify = useToast();
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [showTeamModal, setShowTeamModal] = React.useState(false);
  const [user, setUser] = React.useState<User | null>();

  const [{ fetching: isDeleting }, deletePerson] = useDeletePersonMutation();
  const [{ fetching, data }] = useTeamPageQuery({
    variables: {
      pagination: {
        per: 100,
        page: 1,
      },
    },
  });

  function onEdit(user: User) {
    setUser(user);
    setShowTeamModal(true);
  }

  function initiateDelete(user: User) {
    setUser(user);
    setShowConfirmModal(true);
  }
  async function deleteUser(id: ID) {
    try {
      const response = await deletePerson({ id: id as number });
      const error = extractGraphqlErrors(response, "deletePerson");
      if (error || response.data?.deletePerson?.status !== 200) {
        notify({ content: error ?? "Delete failed", status: "error" });
        return;
      }

      setShowConfirmModal(false);
      setUser(null);
      notify({ content: "User deleted successfully", status: "success" });
    } catch (error) {
      notify({ content: "Something went wrong", status: "error" });
    }
  }

  const teamMembers = data?.people.data || [];

  return (
    <>
      {showTeamModal && (
        <TeamModal
          onSuccess={() => setUser(null)}
          teamMember={user}
          onOpenChange={(open) => setShowTeamModal(open)}
          open={showTeamModal}
        />
      )}

      {showConfirmModal && (
        <ConfirmModal
          loading={isDeleting}
          open={showConfirmModal}
          onOpenChange={(open) => setShowConfirmModal(open)}
          onCancel={() => setShowConfirmModal(false)}
          onConfirm={() => deleteUser(user?.id!)}
          title="Remove Team Member"
          description={
            <Flex stack gap={2}>
              <Avatar size="md" variant={"outline"} color="tomato">
                <HiOutlineTrash size={18} />
              </Avatar>
              <Flex stack>
                <Text lineHeight={"tall"}>
                  Are you sure you want to remove{" "}
                  <Text as="span" color="black" weight={"semi"}>
                    {user?.name}
                  </Text>{" "}
                  from your account? This is not reversible.
                </Text>
              </Flex>
            </Flex>
          }
          type="danger"
        />
      )}

      <Flex gap={2} align={"start"} stack>
        <Flex
          stretchX
          css={{ paddingBottom: 16, borderBottom: "1px solid $gray2" }}
        >
          <Button
            onClick={() => {
              setUser(null);
              setShowTeamModal(true);
            }}
            prepend={<HiPlus />}
            size="sm"
            shape="pill"
            appearance={"secondary"}
          >
            Add Team member
          </Button>
        </Flex>
        <Table
          css={{ ".table-card": { boxShadow: "none" } }}
          loading={fetching}
          emptyStateProps={{
            title: "No teammates yet",
            description: "Add and assign roles to your team members here",
            primaryAction: (
              <Button appearance={"secondary"} size="sm">
                Add team mate
              </Button>
            ),
          }}
          columns={TEAM_TABLE_COLUMNS}
          data={teamMembers}
          rowActions={(row) => (
            <ActionDropdown
              actions={[
                {
                  label: "Edit",
                  icon: <HiPencil />,
                  action: () => onEdit(row),
                },
                {
                  label: "Delete",
                  icon: <HiTrash />,
                  action: () => initiateDelete(row),
                },
              ]}
            ></ActionDropdown>
          )}
        />
      </Flex>
    </>
  );
}
