import Button from "components/button";
import { Flex, Grid, GridItem } from "components/layout";
import LinkButton from "components/link-button";
import Text from "components/text";
import { HiArrowSmLeft } from "react-icons/hi";
import { Link, Route, Routes } from "react-router-dom";
import Company from "./screens/company";
import ProfileSettings from "./screens/profile-settings";
import Security from "./screens/security";
import TeamSettings from "./screens/team-settings";
import { Wrapper, Body, Header, Main } from "./settings.styles";

type SettingsPage = {
  title: React.ReactNode;
  path?: string;
  content: any;
};

const pages: Array<SettingsPage> = [
  {
    title: "Profile",
    content: ProfileSettings,
    path: "profile",
  },
  {
    title: "Company",
    content: Company,
    path: "company",
  },
  {
    title: "Team",
    content: TeamSettings,
    path: "team",
  },
  {
    title: "Security",
    content: Security,
    path: "security",
  },
];

export default function Settings() {
  return (
    <Wrapper>
      <Grid>
        <GridItem colSpan={{ "@initial": 12, "@sm": 12, "@xl": 10 }}>
          <Main>
            <Header>
              <LinkButton
                href="/dashboard"
                linkType="link"
                shape={"pill"}
                css={{ color: "$gray11" }}
                appearance={"ghost"}
                prepend={<HiArrowSmLeft size={20} />}
              >
                Go back to dashboard
              </LinkButton>
            </Header>
            <Body>
              <Flex gap={6}>
                {pages.map((page) => {
                  const fullPagePath = `/dashboard/settings/${page.path}`;
                  const isActive = fullPagePath === location.pathname;

                  return (
                    <Link
                      key={page.path}
                      className="nav-link"
                      to={fullPagePath}
                    >
                      <Text color={isActive ? "black" : "lighter"} size={"4xl"}>
                        {page.title}
                      </Text>
                    </Link>
                  );
                })}
              </Flex>

              <Routes>
                {pages.map((page) => {
                  const path = `/${page.path}`;
                  const Component = page.content;

                  return (
                    <Route
                      key={page.path}
                      path={path}
                      element={<Component />}
                    ></Route>
                  );
                })}
              </Routes>
            </Body>
          </Main>
        </GridItem>
        <GridItem colSpan={2}></GridItem>
      </Grid>
    </Wrapper>
  );
}
