import Box from "components/box";
import { styled } from "stitches/stitches.config";

export const TableCard = styled(Box, {
  background: "white",
  // boxShadow: "$subtle",
  borderRadius: 6,
  width: "100%",
});

export const TableElement = styled("table", {
  width: "100%",
  borderCollapse: "collapse",
});
