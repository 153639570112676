import Cookies from "js-cookie";

export const STORAGE_KEY = "float_api_token";

export function getToken(): string | undefined {
  return Cookies.get(STORAGE_KEY);
}

export function setToken(token: string | null) {
  if (token) {
    Cookies.set(STORAGE_KEY, token);
    return true;
  } else {
    Cookies.remove(STORAGE_KEY);
    return false;
  }
}
