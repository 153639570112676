import React from "react";
import { id } from "utils/helpers";
import { ID } from "utils/types";
import { ITable, ITableColumn, TableCell, TableRow } from "./table.types";

export type UseTableProps<T> = ITable<T>;

// row is an array of column objects
//  data = [{id: 1, name: "John", age: 20, location:"accra"}, {id: 2, name: "Jane", age: 20, location:"accra"}]
//  columns = [{dataIndex: "id", title: "ID"}, {dataIndex: "name", title: "Name"}]
// rows = [{column:"id", value: 1}, {column:"name", value: "John"}]
export type UseTableReturn<T> = {
  rows: Array<TableRow<T>>;
  headings: Array<TableCell>;
};

export default function useTable<T>({
  data,
  columns,
  onRow,
}: UseTableProps<T>): UseTableReturn<T> {
  const headings: Array<TableCell> = columns.map((column) => {
    return {
      value: column.title,
      column: column.dataIndex,
      render: column.header ? column.header?.(data) : column.title,
    } as TableCell;
  });

  const rows: Array<TableRow<T>> = React.useMemo(() => {
    return data.map((row) => {
      const cells = columns.map((column) => {
        return {
          value: row[column.dataIndex as keyof T],
          column: column.dataIndex,
          render: column.render
            ? column.render(row[column.dataIndex as keyof T], row)
            : row[column.dataIndex as keyof T],
        } as TableCell;
      });

      return {
        id: (row as any).id || id(),
        cells,
        record: row,
      };
    });
  }, [data, columns]);

  return { rows, headings };
}
