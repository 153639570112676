import { useToast } from "components/toast";
import React from "react";

export default function useCopyToClipboard() {
  const toast = useToast();

  function copyToClipboard(
    text: string,
    message: string = "Text copied successfully",
    showToast: boolean = true
  ) {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text);
      showToast &&
        toast({
          content: message,
          duration: 4000,
          status: "dark",
          position: "top",
          closable: false,
        });
    }
  }

  return copyToClipboard;
}
