import React from "react";
import { Flex } from "components/layout";
import Page from "components/page/page";
import { Card, useGetBusinessCardsQuery } from "generated/__generated_graphql";
import CardsTable from "./components/cards-table";
import CreateCard from "../../components/create-card";
import SpendInsightsBanner from "./components/spend-insights-banner";
import Button from "components/button";
import { HiPlus } from "react-icons/hi";

export default function CardsPage() {
  const [showModal, setShowModal] = React.useState(false);
  const [{ fetching, data }] = useGetBusinessCardsQuery({ pause: showModal });

  const cards = (data?.getBusinessCards as Array<Card>) || [];

  return (
    <>
      <CreateCard
        onOpenChange={(f) => setShowModal(f)}
        open={showModal}
      ></CreateCard>
      <Page isLoading={fetching} pageTitle="Cards">
        <Flex stack gap={12}>
          {/* <SpendInsightsBanner></SpendInsightsBanner> */}
          <Flex gap={4} stack>
            <Flex gap={4}>
              <Button
                onClick={() => setShowModal(true)}
                size="sm"
                appearance={"secondary"}
                shape="pill"
                append={<HiPlus />}
              >
                Add card
              </Button>
            </Flex>
            <CardsTable cards={cards} />
          </Flex>
        </Flex>
      </Page>
    </>
  );
}
