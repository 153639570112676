import { styled } from "stitches/stitches.config";
import Box from "components/box";

const FixedPageContainer = styled(Box, {
  margin: "0 auto",
  height: "100%",
  "@xs": { width: "95%" },
  "@xl": { width: "95%", maxWidth: 1440 },
});

export default FixedPageContainer;
