import React from "react";
import Dashboard from "pages/dashboard/dashboard";
import ExpiredLink from "pages/expired-link/expired-link";
import ForgotPassword from "pages/forgot-password/forgot-password";
import JoinTeam from "pages/join-team/join-team";
import Login from "pages/login/login";
import Onboarding from "pages/onboarding/onboarding";
import Register from "pages/register/register";
import ResetPassword from "pages/reset-password/reset-password";
import { Route, Routes, useSearchParams } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { globalStyles } from "stitches/stitches.config";
import WaitApproval from "pages/wait-approval/wait-approval";
import Verify from "pages/register/screens/verify";
import NotSupportedCountry from "pages/register/screens/not-supported-country";
import DirectorOnboarding from "pages/onboarding/director-onboarding";
import Logout from "pages/logout/logout";
import AppProvider from "providers/app-provider/app-provider";
import { Toaster } from "react-hot-toast";
import { green } from "@radix-ui/colors";
import { HiOutlineCheckCircle } from "react-icons/hi2";

export default function App() {
  const [searchParams] = useSearchParams();
  const { boot, shutdown } = useIntercom();

  React.useEffect(() => {
    if (window.location.pathname === "/dashboard/action-center") {
      shutdown();
      return;
    }
    boot();
  }, [boot, window.location.pathname]);

  globalStyles();

  // allow overide api url with query params
  if (process.env.REACT_APP_ALLOW_API_BASE_OVERRIDE) {
    const baseUrl = searchParams.get("baseUrl");
    baseUrl && sessionStorage.set("baseUrl", baseUrl);
  }

  return (
    <>
      <Toaster
        toastOptions={{
          className: "toast-barr",
          success: {
            icon: <HiOutlineCheckCircle size={20} />,
            iconTheme: {
              primary: green.green10,
              secondary: "white",
            },
            style: {
              fontWeight: "600 !important",
            },
          },
          style: {},
        }}
      ></Toaster>
      <Routes>
        <Route path="/logout" element={<Logout />} />
        <Route path="/flagged" />
        <Route
          path="/dashboard/*"
          element={
            <AppProvider>
              <Dashboard />
            </AppProvider>
          }
        />
        <Route
          path="/onboarding/add-director/:uuid"
          element={<DirectorOnboarding />}
        />
        <Route path="/onboarding/*" element={<Onboarding />} />

        {/* success screen should not be a route #TODO rename screen */}
        <Route path="/waiting-room" element={<WaitApproval />}></Route>
        <Route path="/register/verify" element={<Verify />}></Route>
        <Route
          path="/register/waitlist"
          element={<NotSupportedCountry />}
        ></Route>
        <Route path="/register" element={<Register />}></Route>
        <Route path="/join/:code" element={<JoinTeam />}></Route>
        <Route path="/expired-link" element={<ExpiredLink />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/forgot-password" element={<ForgotPassword />}></Route>
        <Route
          path="/reset-password/:token"
          element={<ResetPassword />}
        ></Route>
        <Route path="/" element={<Login />}></Route>
      </Routes>
    </>
  );
}
