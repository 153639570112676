import { gray, green, lime } from "@radix-ui/colors";
import Box from "components/box";
import Text from "components/text";
import { format } from "date-fns";
import { DailyBalance } from "generated/__generated_graphql";
import React from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { styled } from "stitches/stitches.config";
import { formatMoney } from "utils/helpers";

const TooltipWrapper = styled("div", {
  p: 12,
  borderRadius: 3,
  background: "$whiteA4",

  defaultVariants: {
    variant: "dark",
  },

  variants: {
    variant: {
      light: {
        background: "$blackA12",
        color: "white",
      },
      dark: {
        background: "$whiteA4",
        color: "white",
      },
    },
  },
});

function CustomTooltip({ active, payload, variant }: any) {
  if (active && payload && payload.length) {
    const date = payload[0]?.payload?.date;
    const balance = payload[0]?.payload?.balance;
    return (
      <TooltipWrapper variant={variant} css={{}}>
        <Text color={"inherit"}>{format(new Date(date), "MMM d")}</Text>
        <Text color={"inherit"}>{formatMoney(balance)}</Text>
      </TooltipWrapper>
    );
  }

  return null;
}

type BalanceChartProps = {
  balances?: Array<DailyBalance>;
  wrapperWidth?: number;
  variant?: "light" | "dark";
  height?: number;
};

const BalanceChart = React.forwardRef<HTMLDivElement, BalanceChartProps>(
  (
    { balances = [], wrapperWidth = 100, variant = "dark", height = 260 },
    ref
  ) => {
    return (
      <AreaChart
        margin={{ left: 0, right: 0, top: 0, bottom: 0 }}
        width={wrapperWidth}
        height={height}
        data={balances}
      >
        <defs>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#299764" stopOpacity={0.05} />
            <stop offset="100%" stopColor="#299764" stopOpacity={0.01} />
          </linearGradient>
        </defs>
        <XAxis
          axisLine={{ stroke: "#fff", opacity: 0 }}
          stroke={"transparent"}
          //   tick={<CustomTick />}
          interval={6}
          tickMargin={10}
          tickSize={1}
          dataKey={"createdAt"}
        ></XAxis>
        {/* 
        <CartesianGrid
          horizontal={false}
          opacity={0.2}
          stroke={variant === "dark" ? "#fff" : gray.gray11}
          strokeDasharray="3 3"
        /> */}
        <YAxis
          hide
          type="number"
          domain={["dataMin - 30", "dataMax + 800"]}
          allowDataOverflow={false}
        />
        <Tooltip content={<CustomTooltip variant={variant as any} />} />
        <Area
          connectNulls
          strokeWidth={2}
          type="natural"
          dataKey="value"
          stroke={lime.lime9}
          fillOpacity={1}
          fill="url(#colorPv)"
        />
      </AreaChart>
    );
  }
);

BalanceChart.displayName = "BalanceChart";

export default BalanceChart;
