import ErrorPage from "components/error-page";
import { Flex } from "components/layout";
import Loader from "components/loader";
import {
  ProfileQuery,
  useProfileQuery,
  User,
} from "generated/__generated_graphql";
import React, { useState, Dispatch, SetStateAction } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { runAnalytics } from "utils/analytics";
import { IProviderProps } from "utils/types";
import { wayFinder } from "utils/wayfinder";
import Page from "components/page/page";

enum IdentityVerificationStatus {
  pending = "pending",
  successful = "success",
  failed = "failed",
}

const AppProviderState = React.createContext<{
  user: ProfileQuery["profile"] | undefined;
  fetchProfile: () => void;
  fetching: boolean;

  currency: string;
}>({
  user: undefined,
  fetchProfile: () => {},
  fetching: false,
  currency: "₦",
});

export default function AppProvider({ children }: IProviderProps) {
  const [{ fetching, data, error }, fetchProfile] = useProfileQuery();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [showUnverifiedBVNModal, setShowUnverifiedBVNModal] = useState(false);

  const runAnalyticsCallback = React.useCallback((user: any) => {
    runAnalytics(user);
  }, []);

  async function _fetchProfile() {
    await fetchProfile({ requestPolicy: "network-only" });
  }

  if (fetching) {
    return <Page isLoading={fetching}></Page>;
  }

  if (error) {
    return <ErrorPage onClick={fetchProfile}></ErrorPage>;
  }

  return (
    <AppProviderState.Provider
      value={{
        user: data?.profile,
        fetchProfile: _fetchProfile,
        fetching,
        currency: data?.profile.business?.currency.symbol || "₦",
      }}
    >
      {children}
    </AppProviderState.Provider>
  );
}

export function useAppProvider() {
  return React.useContext(AppProviderState);
}
