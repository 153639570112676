import React from "react";
import useCallbackRef from "./useCallbackRef";

interface IProps<ValueType> {
  value?: ValueType;
  defaultValue?: ValueType;
  update?(value: ValueType): void;
}

//this hook allows us to easily build controlled and uncontrolled components
//the hook will return the value passed in as a prop if it exists - controlled
//the hook will return an internal state value if not value prop exists - uncontrolled
export default function useControllable<ValueType>({
  value: valueProp,
  update,
  defaultValue,
}: IProps<ValueType>) {
  const isControlled = valueProp !== undefined;
  const [value, setValue] = React.useState<ValueType | undefined>(defaultValue);
  const updateFn = useCallbackRef(update);
  const valueToUse = isControlled ? valueProp : value;

  const updateValue = React.useCallback(
    (value: ValueType) => {
      setValue(value);
      updateFn && updateFn(value);
    },
    [updateFn]
  );

  return [valueToUse, updateValue] as [ValueType, (value: ValueType) => void];
}
