import Avatar from "components/avatar";
import Button from "components/button";
import { Flex } from "components/layout";
import Separator from "components/separator";
import TableDetail from "components/table-detail";
import { TableDetailProps } from "components/table-detail/table-detail";
import Tag from "components/tag";
import Text from "components/text";
import {
  Card,
  CardAction,
  JournalEntry,
  useFetchCardDetailsQuery,
  useUpdateCardStatusMutation,
} from "generated/__generated_graphql";
import React from "react";
import {
  HiBan,
  HiChevronRight,
  HiOutlineCreditCard,
  HiOutlineEye,
  HiOutlineEyeOff,
  HiOutlineLockClosed,
  HiOutlineLockOpen,
  HiPencil,
} from "react-icons/hi";
import { styled } from "stitches/stitches.config";
import {
  extractGraphqlErrors,
  formatMoney,
  renderCardNumberWithSpaces,
  safeRelativeDateString,
} from "utils/helpers";
import mastercardLogo from "assets/images/mastercard.svg";
import { useToast } from "components/toast";
import CopyWrapper from "components/copy-wrapper";
import Sticker from "components/sticker";
import ConfirmModal from "components/confirm-modal";
import useModal from "components/modal/use-modal";
import IconButton from "components/icon-button";
import Tooltip from "components/tooltip";

const Wrapper = styled("div", {
  width: 400,
  px: 24,
  pb: 24,

  ".card-detail": {
    letterSpacing: 2.5,
  },

  ".key": {
    width: 150,
    flexShrink: 0,
  },

  ".values": {
    width: 220,
    flexWrap: "wrap",
    gap: "$1",
  },

  ".value": {
    height: 24,
    px: 8,
    background: "white",
    borderRadius: 12,
    border: "1px solid $gray10",
    alignItems: "center",
    color: "$gray12",
    fontSize: 13,
    fontWeight: 500,

    "&:hover": {
      cursor: "pointer",

      "*:not(.add-on)": {
        textDecoration: "underline",
      },
    },

    ".prepend": {
      color: "$gray8",
    },
  },
});

type CardDetailProps = TableDetailProps<Card>;

const CardDetail = React.forwardRef<HTMLDivElement, CardDetailProps>(
  ({ data: card, ...props }, ref) => {
    const [{ fetching: fetchingCardDetails, data, error }, fetchCardDetails] =
      useFetchCardDetailsQuery({
        pause: true,
        variables: { cardId: card?.id! },
      });

    const [operation, setOperation] = React.useState<
      "suspend" | "activate" | "terminate" | null
    >(null);

    const {
      open: showTerminateConfirmModal,
      handleOnOpenChange: toggleConfirmModal,
    } = useModal();

    const [{ fetching: updatingStatus }, updateStatus] =
      useUpdateCardStatusMutation();

    const notify = useToast();

    const cardDetails = data?.fetchCardDetails;
    const address = card?.billingAddress;
    const transactions = card?.journalEntries || [];
    const [showCardDetails, setShowCardDetails] = React.useState(false);

    const isSuspended = card?.status === "suspended";
    const isTerminated = card?.status === "terminated";
    const isActive = card?.status === "active";
    const isInactive = isSuspended || isTerminated;

    async function updateCardStatus(action: CardAction) {
      setOperation(action);
      try {
        const response = await updateStatus({ id: Number(card?.id), action });

        const errors = extractGraphqlErrors(response, "updateCardStatus");

        if (errors) {
          notify({ content: errors, status: "error" });
          return;
        }

        notify({ content: "Card status updated successfully", status: "dark" });

        if (action === CardAction.Terminate) {
          toggleConfirmModal();
        }
      } catch (error) {
        notify({ content: "Something went wrong!", status: "error" });
      }
    }

    async function toggleDetails() {
      if (showCardDetails) {
        setShowCardDetails(false);
        return;
      }

      if (card?.lastFour !== cardDetails?.cardNumber?.slice(-4)) {
        fetchCardDetails({ requestPolicy: "network-only" });
        return;
      }

      setShowCardDetails(true);
    }

    React.useEffect(() => {
      if (!fetchingCardDetails && !error && !!data) {
        setShowCardDetails(true);
      }
    }, [fetchingCardDetails]);

    React.useEffect(() => {
      setShowCardDetails(false);
    }, [card?.id]);

    React.useEffect(() => {
      setShowCardDetails(false);
    }, [card?.status]);

    return (
      <>
        <ConfirmModal
          onOpenChange={toggleConfirmModal}
          onCancel={toggleConfirmModal}
          loading={updatingStatus && operation === "terminate"}
          onConfirm={() => updateCardStatus(CardAction.Terminate)}
          title="Terminate card"
          description={
            <Text size="sm" lineHeight="tall">
              Terminating this card is non reversible and all future
              transactions will be declined. You will still be able to access
              your past transactions.
            </Text>
          }
          open={showTerminateConfirmModal}
          type="danger"
        ></ConfirmModal>
        <TableDetail
          ref={ref}
          // actions={
          //   <Flex>
          //     <TableDetail.Action>
          //       <HiPencil />
          //     </TableDetail.Action>
          //   </Flex>
          // }
          {...props}
        >
          <Wrapper>
            <Flex align="center" stretchX stack gap={5}>
              <Flex
                stack
                justify={"between"}
                gap={4}
                className="card"
                css={{
                  opacity: isInactive ? 0.5 : 1,
                  filter: isInactive ? "grayscale(1)" : "none",
                  pointerEvents: isInactive ? "none" : "auto",
                  background: "$gray12",
                  borderRadius: 16,
                  height: 220,
                  width: "100%",
                  boxShadow: "$subtle",
                  transition:
                    "opacity 0.2s ease-in-out, filter 0.2s ease-in-out",
                }}
              >
                <Flex css={{ px: 24, pt: 24 }} stretchX justify={"end"}>
                  <Tag
                    shape={"pill"}
                    size="2xs"
                    css={{ background: "$whiteA4", color: "white" }}
                  >
                    Virtual debit
                  </Tag>
                </Flex>
                {!isTerminated && (
                  <Flex css={{ px: 24 }} gap={2} stack>
                    <Text color="white">
                      {card?.user.firstName} {card?.user.lastName}
                    </Text>
                    <CopyWrapper value={cardDetails?.cardNumber}>
                      <Text className="card-detail" size="xs" color="white">
                        {showCardDetails
                          ? renderCardNumberWithSpaces(
                              cardDetails?.cardNumber || ""
                            )
                          : `•••• •••• •••• ${card?.lastFour}`}
                      </Text>
                    </CopyWrapper>
                    <Flex gap={8}>
                      <Flex gap={2}>
                        <Text
                          css={{ color: "$whiteA9" }}
                          size="xs"
                          color="dark"
                        >
                          EXP
                        </Text>
                        <CopyWrapper
                          value={`${cardDetails?.expMonth}/${cardDetails?.expYear}`}
                        >
                          <Text className="card-detail" size="xs" color="white">
                            {showCardDetails
                              ? `${cardDetails?.expMonth}/${cardDetails?.expYear}`
                              : "••/••"}
                          </Text>
                        </CopyWrapper>
                      </Flex>
                      <Flex gap={2}>
                        <Text
                          css={{ color: "$whiteA9" }}
                          size="xs"
                          color="dark"
                        >
                          CVV
                        </Text>
                        <CopyWrapper value={cardDetails?.cvv}>
                          <Text className="card-detail" size="xs" color="white">
                            {showCardDetails ? cardDetails?.cvv : "•••"}
                          </Text>
                        </CopyWrapper>
                      </Flex>
                    </Flex>
                  </Flex>
                )}

                <Flex
                  align={"center"}
                  justify={"between"}
                  css={{ background: "$whiteA3", p: 24 }}
                >
                  <Tag
                    shape={"pill"}
                    size="2xs"
                    css={{ background: "$whiteA5", color: "white" }}
                  >
                    Limit {formatMoney(card?.spendLimitAmount ?? 2500)}
                  </Tag>
                  <img src={mastercardLogo} alt="mastercard-logo" width={50} />
                </Flex>
              </Flex>

              {isTerminated && (
                <Sticker color="gray" variant={"outline"}>
                  <Avatar size="sm" color="gray">
                    <HiBan className="icon" size={14} />
                  </Avatar>
                  <Text lineHeight={"tall"} size="xs" color="black">
                    This card was terminated. All transactions will be declined.
                    You will be able to access your past transactions.
                  </Text>
                </Sticker>
              )}

              {isSuspended && (
                <Sticker color="gray" variant={"outline"}>
                  <Avatar size="sm" color="gray">
                    <HiOutlineLockOpen className="icon" size={14} />
                  </Avatar>
                  <Text lineHeight={"tall"} size="xs" color="black">
                    This card is locked. All transactions will be declined.
                    Unlock this card at any time to allow new transactions.
                  </Text>
                </Sticker>
              )}

              <Flex gap={2} className="actions">
                {!isTerminated && (
                  <Tooltip
                    content={
                      showCardDetails ? "Hide details" : "Reveal details"
                    }
                  >
                    <IconButton
                      onClick={toggleDetails}
                      disabled={isInactive}
                      isLoading={fetchingCardDetails}
                      size="md"
                      variant={"outline"}
                      color="gray"
                    >
                      {showCardDetails ? (
                        <HiOutlineEyeOff size={20} />
                      ) : (
                        <HiOutlineEye size={20} />
                      )}
                    </IconButton>
                  </Tooltip>
                )}

                {(isActive || isSuspended) && (
                  <Tooltip content={isActive ? "Lock card" : "Unlock card"}>
                    <IconButton
                      loaderColor={isActive ? "$gray12" : "white"}
                      size="md"
                      variant={isActive ? "outline" : "solid"}
                      color="gray"
                      onClick={() =>
                        updateCardStatus(
                          isActive ? CardAction.Suspend : CardAction.Activate
                        )
                      }
                      isLoading={
                        updatingStatus &&
                        ["suspend", "activate"].includes(operation!)
                      }
                    >
                      {isActive && <HiOutlineLockClosed size={20} />}
                      {isSuspended && <HiOutlineLockOpen size={20} />}
                    </IconButton>
                  </Tooltip>
                )}

                {(isActive || !isTerminated) && (
                  <Tooltip content="Terminate card">
                    <IconButton
                      onClick={toggleConfirmModal}
                      size="md"
                      variant={"outline"}
                      color="gray"
                    >
                      <HiBan size={20} />
                    </IconButton>
                  </Tooltip>
                )}
              </Flex>
            </Flex>

            <Separator spacing={16} />

            <Flex stretchX justify={"between"}>
              <Flex className="key">
                <Text size="xs" color="light">
                  Billing Address
                </Text>
              </Flex>

              <Flex justify={"end"} className="values">
                <CopyWrapper value={address?.line1!}>
                  <Flex className="value">{address?.line1}</Flex>
                </CopyWrapper>

                <CopyWrapper value={address?.city!}>
                  <Flex className="value">{address?.city}</Flex>
                </CopyWrapper>
                <CopyWrapper value={address?.state!}>
                  <Flex className="value">{address?.state}</Flex>
                </CopyWrapper>

                <CopyWrapper value={address?.postalCode!}>
                  <Flex className="value">{address?.postalCode}</Flex>
                </CopyWrapper>
              </Flex>
            </Flex>

            <Separator spacing={16} />

            <Flex align={"center"} stretchX justify={"between"}>
              <Flex className="key">
                <Text size="xs" color="light">
                  Associated account
                </Text>
              </Flex>

              <Flex justify={"end"} className="values">
                <Tag
                  appendIcon={<HiChevronRight size={16} />}
                  className="value"
                >
                  {card?.bankAccount.name} ••{" "}
                  {card?.bankAccount.accountNumber?.slice(-4)}{" "}
                </Tag>
              </Flex>
            </Flex>

            {transactions.length > 0 && (
              <>
                <Separator spacing={16} />
                <Flex gap={4} stack>
                  <Text size="xs" color="light">
                    Recent transactions
                  </Text>

                  <Flex stretchX gap={6} stack>
                    {card?.journalEntries
                      ?.slice(0, 3)
                      ?.map((txn: JournalEntry) => {
                        return (
                          <Flex key={txn.id} stretchX gap={3}>
                            <Avatar variant={"outline"} color="gray" size="sm">
                              <HiOutlineCreditCard size={16} />
                            </Avatar>
                            <Flex stretchX justify={"between"}>
                              <Flex gap={2} stack>
                                <Text color="black">
                                  {txn.destination?.name}
                                </Text>
                                <Text size="xs" color="light">
                                  {safeRelativeDateString(
                                    new Date(txn.createdAt),
                                    new Date()
                                  )}
                                </Text>
                              </Flex>
                            </Flex>
                            <Text weight={"semi"}>
                              {formatMoney(txn.amount)}
                            </Text>
                          </Flex>
                        );
                      })}
                  </Flex>
                </Flex>
              </>
            )}
          </Wrapper>
        </TableDetail>
      </>
    );
  }
);

CardDetail.displayName = "CardDetail";

export default CardDetail;
