import * as Dialog from "@radix-ui/react-dialog";
import IconButton from "components/icon-button";
import { styled } from "stitches/stitches.config";

export const DialogOverlay = styled(Dialog.Overlay, {
  position: "fixed",
  top: 0,
  left: 0,
  height: "100vh",
  width: "100vw",
  background: "$grayA9",
  zIndex: 9999999,
});
export const DialogContent = styled(Dialog.Content, {
  position: "fixed",
  background: "white",
  borderRadius: 6,
  overflow: "hidden",
  zIndex: 9999999,

  defaultVariants: {
    variant: "modal",
  },

  variants: {
    variant: {
      modal: {
        left: "50vw",
        top: "50vh",
        transform: "translate(-50%, -50%)",
      },
      drawer: {
        top: 8,
        right: 8,
        height: "calc(100vh - 16px)",
        minWidth: 400,
      },
    },
  },
});

export const DialogTitle = styled(Dialog.Title, {
  px: 24,
  py: 16,
  borderBottom: "1px solid $gray3",
});

export const DialogDescription = styled(Dialog.Description, {});

export const DialogBody = styled("div", {
  p: 24,

  variants: {
    variant: {
      modal: {},
      drawer: {},
    },
  },
});

export const DialogFooter = styled("div", {
  py: 16,
  px: 24,
  display: "flex",
  justifyContent: "flex-end",
  gap: "$3",
});

export const DialogScreen = styled("section", {});

export const DialogClose = styled(IconButton, {
  position: "absolute",
  top: 9,
  right: 16,
  color: "$gray11",
  "&:hover": {
    background: "$gray3",
  },
});
