import { Flex } from "components/layout";
import PaymentIndicator from "components/payment-indicator";
import Table, { ITableColumn } from "components/table";
import Text from "components/text";
import { format } from "date-fns";
import { JournalEntry } from "generated/__generated_graphql";
import React from "react";
import { formatMoney } from "utils/helpers";

const columns: Array<ITableColumn<JournalEntry>> = [
  {
    dataIndex: "createdAt",
    title: "Date due",
    key: "createdAt",
    render: (_, transaction) => (
      <Text color="light">
        {format(new Date(transaction.moneyTransfer?.effectiveDate), "MMM d")}
      </Text>
    ),
  },

  {
    dataIndex: "destination",
    title: "From / To",
    key: "destination",
    render: (_, transaction) => (
      <Flex css={{ height: 40 }} align={"center"} gap={3}>
        <PaymentIndicator transaction={transaction}></PaymentIndicator>
        <Text color="black">
          {transaction.transactionType === "credit"
            ? transaction.origin.name
            : `${
                transaction.destination.name
              } ••• ${transaction.moneyTransfer?.bankAccount?.accountNumber?.slice(
                -4
              )}`}
        </Text>
      </Flex>
    ),
  },

  {
    dataIndex: "amount",
    title: "Amount",
    key: "amount",
    render: (value) => <Text size="sm">{formatMoney(value)}</Text>,
  },
];

type PendingTransactionsProps = {
  transactions?: Array<JournalEntry>;
};

const PendingTransactions = React.forwardRef<
  HTMLDivElement,
  PendingTransactionsProps
>(({ transactions = [] }, ref) => {
  return (
    <Table title="Pending Transactions" data={transactions} columns={columns} />
  );
});

PendingTransactions.displayName = "PendingTransactions";

export default PendingTransactions;
