import { Flex } from "components/layout";
import Loader from "components/loader";
import Separator from "components/separator";
import Sticker from "components/sticker";
import Text from "components/text";
import { BankInfoQuery, useBankInfoQuery } from "generated/__generated_graphql";
import React from "react";
import {
  HiOutlineExclamationCircle,
  HiOutlineShieldCheck,
  HiOutlineShieldExclamation,
} from "react-icons/hi";

type BankLookUpProps = React.ComponentProps<typeof Sticker> & {
  code?: string;
  type?: string;
  bankName?: string;
  onSuccess?: (data: BankInfoQuery["bankInfo"]) => void;
  onError?: (error: any) => void;
};

const BankLookUp = React.forwardRef<HTMLDivElement, BankLookUpProps>(
  ({ code, children, type, onSuccess, onError, bankName, ...props }, ref) => {
    const [{ fetching, data }] = useBankInfoQuery({
      variables: { code: code ?? "", type: type ?? "" },
      pause: !!!code || !!!type || !!bankName,
    });

    const bankInfo = data?.bankInfo;

    const blockedCountries = ["GH"];
    const blockedCountry = blockedCountries.includes(
      bankInfo?.address?.country?.code!
    );

    const bankIsVerfied = !!data?.bankInfo?.name || !!bankName;

    function getColor() {
      if (fetching) return "transparent";
      if (blockedCountry) return "amber";
      if (bankIsVerfied) return "sky";

      return "orange";
    }

    const color = getColor();

    React.useEffect(() => {
      if (!fetching && data?.bankInfo?.name) {
        onSuccess?.(data?.bankInfo);
      }

      if (!fetching && !data?.bankInfo?.name) {
        onError?.(data?.bankInfo);
      }
    }, [fetching, code, type]);

    return (
      <Sticker variant={"light"} color={color} ref={ref} {...props}>
        <Flex gap={2} stack>
          {fetching && <Loader color="$gray12" size="xs" />}

          {!fetching && bankIsVerfied && (
            <Flex align={"center"} gap={3}>
              <HiOutlineShieldCheck size={16} />
              <Text color="inherit" size="xs">
                {bankName || data?.bankInfo?.name}
              </Text>
            </Flex>
          )}

          {!bankIsVerfied && !fetching && (
            <Flex align="center" gap={3}>
              <HiOutlineShieldExclamation size={16} />
              <Text color="inherit" size="xs">
                Receiving bank not verified! See more
              </Text>
            </Flex>
          )}

          {blockedCountry && !fetching && (
            <>
              <Separator spacing={8} color={`$${color}6`} />
              <Flex gap={2} stack>
                <Flex gap={2}>
                  {/* <HiOutlineExclamationCircle size={24} /> */}
                  <Text color="inherit" size="xs" lineHeight={"tall"}>
                    Direct wires to this country are not supported. You can
                    carry out this transaction through an intermediary bank or
                    by contacting support at help@float.co.
                  </Text>
                </Flex>
                {/* 
          <Textarea
            css={{ ".input-el": { background: "white", minHeight: 40 } }}
            placeholder={`For further credit to ${form.values.accountNumber}`}
          /> */}
              </Flex>
            </>
          )}
        </Flex>
      </Sticker>
    );
  }
);

BankLookUp.displayName = "BankLookUp";

export default BankLookUp;
