import Box from "components/box";
import InputLabel from "components/input-label";
import React from "react";
import ReactSelect, { OptionProps, Props } from "react-select";
import CreatableSelect, { CreatableProps } from "react-select/creatable";
import {
  CustomClearIndicator,
  CustomControl,
  CustomDropdownIndicator,
  CustomMenu,
  CustomMultiValue,
  CustomOption,
  CustomPlaceholder,
  CustomSingleValue,
  StyledControl,
} from "./components/select-overrides";
import InputError from "components/input-error";
import { VariantProps } from "@stitches/react";
import { CSS } from "stitches/stitches.config";
import { Flex } from "components/layout";

export type SelectSingleValue = string | number;
export type SelectMultiValue = Array<SelectSingleValue>;
export type SelectValue = SelectMultiValue | SelectSingleValue;

export type SelectProps = {
  error?: string;
  label?: string;
  append?: React.ReactNode;
  prepend?: React.ReactNode;
} & VariantProps<typeof StyledControl> &
  Omit<Props, "onChange" | "value" | "size"> & {
    css?: CSS;
    onChange?(value: SelectValue, rawValue: Props["value"]): void;
    value?: SelectValue | null;
    defaultValue?: SelectValue | null;
  } & Pick<
    CreatableProps<any, any, any>,
    "createOptionPosition" | "formatCreateLabel" | "getNewOptionData"
  >;

const SelectExperimental = React.forwardRef<HTMLDivElement, SelectProps>(
  (
    {
      label,
      options = [],
      data = [],
      labelKey = "label",
      valueKey = "value",
      css,
      defaultValue,
      defaultOpen,
      onChange,
      value,
      id,
      required,
      filterOutSelected,
      isClearable = false,
      isCreatable,
      isMulti,
      error,
      hideErrorMessage,
      menuSize,
      ...props
    },
    ref
  ) => {
    function _onChange(newValue: any) {
      let value = newValue[valueKey];
      if (isMulti) {
        value = newValue.map((value: any) => value[valueKey] ?? value["value"]);
      }
      // i have found that in very rare occasions, we need the raw option object
      // so we are passing it as a second argument
      onChange?.(value, newValue);
    }

    function findOptionByValue(value: any) {
      return options.find((option: any) => option[valueKey] === value);
    }

    function getValue() {
      if (isMulti) {
        return (value as SelectMultiValue)?.map((value: any) =>
          findOptionByValue(value)
        );
      }
      return findOptionByValue(value);
    }

    function getDefaultValue() {
      if (isMulti) {
        return (defaultValue as SelectMultiValue)?.map((value: any) =>
          findOptionByValue(value)
        );
      }
      return findOptionByValue(defaultValue);
    }

    const _props: Props = {
      hasError: !!error,
      menuPortalTarget: document.body,
      classNamePrefix: "float-select",
      valueKey: valueKey,
      labelKey: labelKey,
      getOptionValue: (option: any) => option[valueKey] ?? option["value"],
      getOptionLabel: (option: any) => option[labelKey] ?? option["label"],
      isMulti,
      options: options,
      defaultValue: getDefaultValue(),
      value: getValue(),
      styles: {
        control: () => ({}),
        option: () => ({}),
        dropdownIndicator: () => ({}),
        indicatorSeparator: () => ({ display: "none" }),
        multiValue: (base) => ({ ...base }),
        menuPortal: (base) => ({ ...base, zIndex: 9999999 }),
        valueContainer: (base) => ({ ...base, padding: "0px 4px" }),
      },
      isClearable: isClearable,
      components: {
        Control: CustomControl,
        DropdownIndicator: CustomDropdownIndicator,
        ClearIndicator: CustomClearIndicator,
        Placeholder: CustomPlaceholder,
        Menu: (props) => <CustomMenu {...{ ...props, menuSize }} />,
        Option: CustomOption,
        SingleValue: CustomSingleValue,
        MultiValue: CustomMultiValue,
      },
      onChange: _onChange,
      menuSize: menuSize,
      ...props,
    };

    return (
      <Flex gap={2} stack stretchX css={css} ref={ref}>
        {label && (
          <InputLabel htmlFor={id} required={required}>
            {label}
          </InputLabel>
        )}

        {isCreatable ? (
          <CreatableSelect {..._props} />
        ) : (
          <ReactSelect aria-label={label} {..._props} />
        )}
        {error && !hideErrorMessage && (
          <InputError css={{ marginTop: 4 }}>{error}</InputError>
        )}
      </Flex>
    );
  }
);

SelectExperimental.displayName = "SelectExperimental";

export default SelectExperimental;
