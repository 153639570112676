export function isNumber(value?: any): boolean {
  return typeof value === "number";
}

export function isArray(value?: any): boolean {
  return value !== null && Array.isArray(value);
}

export function isEmptyArray(value?: any): boolean {
  return isArray(value) && value.length === 0;
}

export function isObject(value?: any): boolean {
  return (
    (typeof value === "object" || typeof value === "function") &&
    !Array.isArray(value) &&
    value !== null
  );
}

export function isEmptyObject(value?: any) {
  return isObject(value) && Object.keys(value).length === 0;
}

export function objectHasEmptyValue(value?: any) {
  return (
    Object.values(value).filter((value) => {
      return value === "" || value === undefined || value === null;
    }).length > 0
  );
}

export function isUndefinedOrNull(value: any) {
  return value == null;
}

export function isUndefined(value: any) {
  return value === undefined;
}