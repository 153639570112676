/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
import AppendPrependWrapper from "components/append-prepend-wrapper";
import { useState } from "react";
import { StyledButtonTag, StyledNativeTag } from "./tag.styles";
import { TagProps } from "./tag.types";

const Tag: React.FC<TagProps> = ({
  content,
  prependIcon,
  appendIcon,
  interactive,
  css,
  ref,
  children,
  ...rest
}) => {
  const [active, setActive] = useState(false);

  const handleOnClick = () => {
    setActive((prevActive) => !prevActive);
  };

  return (
    <>
      {interactive ? (
        <StyledButtonTag
          css={css}
          {...rest}
          onClick={handleOnClick}
          state={active ? "active" : "inactive"}
        >
          <AppendPrependWrapper
            append={appendIcon}
            prepend={prependIcon}
          >
            {children ?? content}
          </AppendPrependWrapper>
        </StyledButtonTag>
      ) : (
        <StyledNativeTag css={css} {...rest}>
          <AppendPrependWrapper append={appendIcon} prepend={prependIcon}>
            {children ?? content}
          </AppendPrependWrapper>
        </StyledNativeTag>
      )}
    </>
  );
};

Tag.displayName = "Tag";

export default Tag;
