import React from "react";
import { RadioGroup as RadixRadioGroup } from "@radix-ui/react-radio-group";
import InputLabel from "components/input-label";
import Box from "components/box";
import { CSS } from "@stitches/react";

type IProps = Omit<React.ComponentProps<typeof RadixRadioGroup>, "onChange"> & {
  onChange?(e: any): void;
  label?: string;
  flexDirection?: "column" | "row" | "rowReverse" | "columnReverse";
  align?: "center" | "stretch" | "end" | "start" | "baseline";
  justify?: "center" | "end" | "start" | "between";
  wrap?: "wrap" | number;
  gap?: number;
};

const RadioGroup = React.forwardRef<HTMLDivElement, IProps & { css?: CSS }>(
  (
    {
      onChange,
      onValueChange,
      name,
      required,
      label,
      children,
      flexDirection = "column",
      align,
      justify,
      wrap,
      gap = 16,
      css,
      ...props
    },
    ref
  ) => {
    function _onChange(value: string) {
      if (onChange) {
        onChange({
          target: {
            name,
            value,
          },
        });
        return;
      }
      if (onValueChange) {
        onValueChange(value);
      }
    }

    return (
      <Box stretchX ref={ref} css={css}>
        {label && (
          <InputLabel required={required} css={{ mb: 8 }}>
            {label}
          </InputLabel>
        )}
        <RadixRadioGroup onValueChange={_onChange} {...props}>
          <Box
            css={{
              display: "flex",
              flexDirection: flexDirection,
              alignItems: align,
              justifyContent: justify,
              flexWrap: wrap,
              gap: gap,
            }}
          >
            {children}
          </Box>
        </RadixRadioGroup>
      </Box>
    );
  }
);

RadioGroup.displayName = "RadioGroup";

export default RadioGroup;
