import React from "react";
import {
  Root,
  Trigger,
  Content,
  Item,
  Portal,
} from "@radix-ui/react-dropdown-menu";
import { styled } from "stitches/stitches.config";
import Box from "components/box";
import { HiDotsVertical } from "react-icons/hi";
import IconButton from "components/icon-button";
import { Flex, Stack } from "components/layout";
import Text from "components/text/text";

interface IDropdownAction {
  label?: React.ReactNode;
  description?: React.ReactNode;
  action?(...args: any): void;
  icon?: React.ReactNode;
  disabled?: boolean;
}

const StyledTrigger = styled(Trigger, {});
const StyledContent = styled(Content, {
  background: "white",
  boxShadow: "$base",
  padding: 4,
  borderRadius: 4,
  minWidth: 140,
});
const StyledItem = styled(Item, {
  display: "flex",
  alignItems: "start",
  gap: 8,
  px: 8,
  py: 8,
  borderRadius: 3,
  fontSize: 15,
  fontFamily: "$medium",
  letterSpacing: 0.3,
  color: "$gray10",
  "&:not(:last-child)": { marginBottom: 8 },
  "&:hover": { background: "$gray2", outline: "none", cursor: "pointer" },
  "&[data-disabled]": {
    pointerEvents: "none",
    color: "$gray9",
  },
});
const StyledDefaultTrigger = styled(HiDotsVertical, {
  color: "$gray10",
  fontSize: 16,
});

type ActionDropdownProps = React.ComponentProps<typeof Box> & {
  actions?: Array<IDropdownAction>;
  trigger?: React.ReactNode;
};

const ActionDropdown = React.forwardRef<HTMLDivElement, ActionDropdownProps>(
  (
    {
      trigger = (
        <IconButton color={"transparent"}>
          <StyledDefaultTrigger />
        </IconButton>
      ),
      actions = [],
      css,
    },
    ref
  ) => {
    return (
      <Box
        css={{ display: "inline-flex" }}
        className="action-dropdown-container"
        ref={ref}
      >
        <Root>
          <StyledTrigger asChild>{trigger}</StyledTrigger>
          <Portal>
            <StyledContent sideOffset={6} align="end">
              {actions.map((action, index) => {
                return (
                  <StyledItem
                    key={index}
                    onSelect={action?.action}
                    disabled={action?.disabled}
                    data-disabled={action?.disabled}
                  >
                    {action.icon}
                    <Flex gap={1} direction={"column"}>
                      <Text
                        weight={"semi"}
                        color={action?.disabled ? "light" : "black"}
                      >
                        {action.label}
                      </Text>
                      {action.description && (
                        <Text weight={"regular"} size={"sm"} color={"light"}>
                          {action.description}
                        </Text>
                      )}
                    </Flex>
                  </StyledItem>
                );
              })}
            </StyledContent>
          </Portal>
        </Root>
      </Box>
    );
  }
);

ActionDropdown.displayName = "ActionDropdown";

export default ActionDropdown;
