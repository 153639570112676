import Box from "components/box";
import { styled } from "stitches/stitches.config";
import PatternBg from "assets/images/pattern.png";
import { Flex } from "components/layout";
import Text from "components/text";
import { useEffect, useState } from "react";
import Input from "components/input";
import { EMAIL_VALIDATION_REGEX } from "utils/constants";
import Button from "components/button";
import Accordion from "components/accordion";
import {
  HiOutlineExclamationCircle,
  HiOutlineShieldCheck,
  HiOutlineShieldExclamation,
} from "react-icons/hi";

import * as Sentry from "@sentry/react";
import {
  LoginMutationVariables,
  LoginPayload,
  useLoginMutation,
  User,
} from "generated/__generated_graphql";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setToken } from "utils/auth";
import { wayFinder } from "utils/wayfinder";
import floatLogo from "assets/images/boundless-logo.svg";
import Sticker from "components/sticker/sticker";
import { extractGraphqlErrors } from "utils/helpers";
import useForm from "hooks/useForm";
import PostLogin from "./post-login";
import Avatar from "components/avatar";
import PinCodeInput from "components/pin-code-input";
import Checkbox from "components/checkbox";

const StyledContainer = styled(Box, {
  background: "$gray2",
  backgroundSize: "50% 50%",
  backgroundRepeat: "repeat-X",
  backgroundPosition: "top",
  width: "100vw",
  minHeight: "100vh",
  position: "relative",
  py: 60,

  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  flexDirection: "column",
});

const ContentWrapper = styled(Flex, {
  width: 380,
  height: "calc(100vh - 120px)",
});

const FormContainer = styled(Flex, {
  background: "white",
  // boxShadow: "$subtle",
  p: 28,
  borderRadius: 6,
});

const StyledAccordion = styled(Box, {
  background: "white",
  // boxShadow: "$subtle",
  p: 18,
  borderRadius: 12,
});

const faqs = [
  {
    title: () => (
      <Flex align="center" gap="2">
        <HiOutlineShieldExclamation size={17} />
        <Text color={"black"} weight="semi">
          Having issues logging in?
        </Text>
      </Flex>
    ),
    description: () => (
      <Text size="sm" color="light" lineHeight="tall" css={{ mt: 8, ml: 23 }}>
        If you're having trouble logging in, get HELP from our support team
        <Text
          as="a"
          href="/"
          size="sm"
          color="primary"
          weight="semi"
          lineHeight="tall"
        >
          &nbsp;here
        </Text>
      </Text>
    ),
  },
];

export default function Login() {
  const navigate = useNavigate();
  const form = useForm({
    fields: { email: "", userPassword: "", totp: "", rememberDevice: false },
    optional: ["totp", "rememberDevice"],
  });

  const devToken = localStorage.getItem("dev_token");

  const { register, setInputValue, errors, values, onSubmit } = form;

  const [{ fetching: loading }, loginUser] = useLoginMutation();
  const [showMfa, setShowMfa] = useState(false);

  const [loginError, setLoginError] = useState<string | null>(null);
  const [_, setShowAccountLockedPrompt] = useState(true);

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const login = async () => {
    const { email, userPassword, totp } = values;

    try {
      const response = await loginUser({
        email,
        password: userPassword,
        totp: totp || null,
        rememberDeviceForMfa: values.rememberDevice,
        deviceToken: devToken,
      });
      const error = extractGraphqlErrors(response, "login");

      if (error) {
        return setLoginError(error);
      }

      const loginData = response?.data?.login;
      const mfaEnabled = loginData?.user?.settings?.mfa?.app;

      if (loginData?.user?.accountLocked) {
        setShowAccountLockedPrompt(true);
        setLoginError("Account locked! Please contact support for assistance");
        return;
      }

      if (mfaEnabled && !loginData.token) {
        setShowMfa(true);
        return;
      }

      if (loginData?.deviceToken) {
        localStorage.setItem("dev_token", loginData?.deviceToken);
      }
      setToken(loginData?.token || null);
      const nextRoute = wayFinder(loginData?.user as User);

      navigate(
        nextRoute === "/register/verify"
          ? `${nextRoute}?email=${email}`
          : nextRoute
      );
    } catch (error) {
      Sentry.captureException(error);
      setLoginError("Something went wrong! Please try again");
    }
  };

  useEffect(() => {
    if (token) {
      setToken(token);
      navigate("/dashboard");
    }
  }, [token]);

  useEffect(() => {
    if (values.totp.length === 6) {
      login();
    }
  }, [values.totp]);

  return (
    <StyledContainer>
      <ContentWrapper justify={"center"} align="center" direction={"column"}>
        <Flex direction="column" align={"center"} stretchX gap={10}>
          <img width={200} src={floatLogo} alt="float-logo" />
          <Flex direction="column" gap={3} stretchX>
            <FormContainer stretchX direction={"column"}>
              {loginError && (
                <Sticker variant={"light"} color="tomato" css={{ mb: 16 }}>
                  <HiOutlineExclamationCircle className="icon" />
                  {
                    <Text size="sm" color="inherit">
                      {loginError}
                    </Text>
                  }
                </Sticker>
              )}
              {showMfa ? (
                <Flex align={"center"} stack gap={10}>
                  <Flex align="center" gap={3}>
                    <Avatar
                      css={{ background: "$gray12" }}
                      variant={"solid"}
                      color="gray"
                      size="md"
                    >
                      <HiOutlineShieldCheck size={16} />
                    </Avatar>
                    <Flex gap={1} stack>
                      <Text size="md" color="black">
                        Two Factor Authentication
                      </Text>
                      <Text size="xs" color="light">
                        Enter the code from your authentication app.
                      </Text>
                    </Flex>
                  </Flex>

                  <Flex align="start" stack gap={3}>
                    <PinCodeInput
                      disabled={loading}
                      value={form.values.totp}
                      onChange={(value) => form.setInputValue("totp", value)}
                      type="text"
                      inputMode="tel"
                      name="2fa"
                      key={"2fa"}
                      fields={6}
                    />
                    <Checkbox
                      onChange={(e) =>
                        setInputValue("rememberDevice", e.target.checked)
                      }
                      label="Trust this browser for 30 days"
                    ></Checkbox>
                  </Flex>
                </Flex>
              ) : (
                <form
                  className="wrapper"
                  noValidate
                  onSubmit={(e) => onSubmit(e, login)}
                >
                  <Flex direction={"column"} align="center" gap={7}>
                    <Flex direction="column" gap={3} stretchX>
                      <Input
                        required
                        label="Email"
                        type="email"
                        placeholder="Email"
                        {...register("email")}
                        error={errors.email}
                      ></Input>
                      <Input
                        required
                        label="Password"
                        type="password"
                        placeholder="password"
                        {...register("userPassword")}
                        error={errors.userPassword}
                      ></Input>
                      <Button
                        appearance={"secondary"}
                        size={"md"}
                        type="submit"
                        isLoading={loading}
                      >
                        Login
                      </Button>
                    </Flex>

                    <Flex direction={"column"} align="center" gap={3}>
                      <Text size="sm" color="light">
                        Forgot password?{" "}
                        <Text color="primary" as="a" href="/forgot-password">
                          Reset password
                        </Text>
                      </Text>
                    </Flex>
                  </Flex>
                </form>
              )}
            </FormContainer>

            <Text align={"center"} size="sm" color="light">
              Don't have an account?{" "}
              <Text color={"primary"} as="a" href="/register">
                Open account
              </Text>
            </Text>
          </Flex>
        </Flex>
      </ContentWrapper>
    </StyledContainer>
  );
}
