import { User } from "generated/__generated_graphql";

export function wayFinder(user: User, currentRoute?: string) {
  if (!user) {
    return currentRoute ?? "/login";
  }

  const awaitApproval =
    user.business && user.business?.state === "pending_review";

  const isApproved = user.business && user.business?.state === "approved";

  const isRejected = user.business?.state === "rejected";

  if (isRejected) {
    return "/flagged";
  }

  if (isApproved && !currentRoute?.includes("/dashboard")) {
    return "/dashboard";
  }

  if (!user?.activated) {
    return "/register/verify";
  }

  if (
    (!user?.business || user.business?.state === "started") &&
    !currentRoute?.includes("/onboarding")
  ) {
    return "/onboarding/company-info";
  }

  if (awaitApproval) {
    return "/waiting-room";
  }

  if (currentRoute === "/waiting-room" && isApproved) {
    return "/dashboard";
  }

  return currentRoute ?? "/dashboard";
}
