import Button from "components/button";
import FileUploader, { Upload } from "components/file-uploader";
import Input from "components/input";
import { Flex, Stack } from "components/layout";
import SelectExperimental from "components/select/select-experimental";
import Separator from "components/separator";
import Text from "components/text";
import { useToast } from "components/toast";
import {
  BusinessDocument,
  useSaveBusinessMutation,
} from "generated/__generated_graphql";
import useUpload from "hooks/use-upload";
import useForm from "hooks/useForm";
import React, { ChangeEvent, FormEvent, useState } from "react";
import { HiArrowLeft, HiArrowRight } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { extractGraphqlErrors } from "utils/helpers";
import { OnboardingComponentProps } from "../onboarding";

const VerifyBusiness = React.forwardRef<
  HTMLDivElement,
  OnboardingComponentProps
>(({ data, countryCode }, ref) => {
  const business = data?.profile.business;

  const savedEIN = business?.businessDocuments.find(
    (d) => d.documentType === "ein"
  );
  const savedIncorporationCert = business?.businessDocuments.find(
    (d) => d.documentType === "incorporation_cert"
  );

  const [
    {
      status: incStatus,
      upload: certificateOfInc,
      removeUpload: removeIncorporationUpload,
    },
    uploadCertificate,
  ] = useUpload({ defaultUpload: savedIncorporationCert?.upload || undefined });

  const [
    { status: einStatus, upload: ein, removeUpload: removeEinUpload },
    uploadEin,
  ] = useUpload({
    defaultUpload: savedEIN?.upload || undefined,
  });

  const { values, register } = useForm({
    fields: { ein: business?.ein || "" },
  });
  const [{ fetching }, saveBusiness] = useSaveBusinessMutation();

  const notify = useToast();
  const navigate = useNavigate();

  const isComplete = certificateOfInc && ein && values.ein;

  async function save() {
    try {
      const response = await saveBusiness({
        businessDetails: {
          businessDocumentsAttributes: [
            {
              documentType: "incorporation_cert",
              uploadId: certificateOfInc?.id as number,
              id: savedIncorporationCert?.id,
            },
            {
              documentType: "ein",
              uploadId: ein?.id as number,
              id: savedEIN?.id,
            },
          ],
          ein: values.ein,
        },
      });

      const error = extractGraphqlErrors(response, "saveBusiness");

      if (error) {
        notify({
          content: error,
          status: "error",
          showIcon: true,
          position: "top",
        });
        return;
      }

      navigate("/onboarding/owner-information");
    } catch (error) {
      notify({
        content: "Something went wrong!",
        status: "error",
        showIcon: true,
        position: "top",
      });
    }
  }

  return (
    <Flex gap={16} direction="column">
      <Flex ref={ref} gap={3} direction="column">
        <Text size="xl" weight={"semi"} color="black">
          Verify Business
        </Text>
        <Text size="sm" color="light">
          Upload buisness documents and verify your EIN
        </Text>
      </Flex>

      <Flex gap={6} direction="column">
        <Flex gap={3} direction="column">
          <Text size="sm" color="light" weight="regular" lineHeight="base">
            Please upload the document you were issued when your company was
            formed in the US.
          </Text>
          <FileUploader
            onRetry={uploadCertificate}
            onRemove={removeIncorporationUpload}
            upload={certificateOfInc}
            status={incStatus}
            onChange={(file) => uploadCertificate(file)}
            id="inc-upload"
            name="inc-upload"
            label="Certificate of incoporation"
          ></FileUploader>
        </Flex>
        <Separator />
        <Flex gap={3} direction={"column"}>
          <Text size="sm" color="light" weight="regular" lineHeight="base">
            We'll accept these IRS documents: Form CP 575, a 147c letter, an
            IRS-returned SS-4, or a screenshot of the IRS website with your EIN
            in it.
          </Text>

          <Input
            placeholder="00–0000000"
            label="Employer Identification Number (EIN)"
            {...register("ein")}
          ></Input>
          <FileUploader
            onRetry={uploadEin}
            onRemove={removeEinUpload}
            upload={ein}
            onChange={(file) => uploadEin(file)}
            status={einStatus}
            id="ein-upload"
            name="ein-upload"
            label="EIN Confirmation Letter"
          ></FileUploader>
        </Flex>
      </Flex>

      <Flex gap={3} justify={"start"}>
        <Button
          onClick={() => navigate("/onboarding/company-info")}
          prepend={<HiArrowLeft size={16} />}
          size={"md"}
          appearance="outline"
          disabled={fetching}
        >
          Back
        </Button>
        <Button
          append={<HiArrowRight size={16} />}
          isLoading={fetching}
          onClick={save}
          disabled={!isComplete}
          appearance={"secondary"}
        >
          Continue
        </Button>
      </Flex>
    </Flex>
  );
});

VerifyBusiness.displayName = "VerifyBusiness";

export default VerifyBusiness;
