import React from "react";
import Flex from "components/layout/flex";
import { cx } from "utils/dom";
import { IProps } from "./input-error.types";
import Text from "components/text";

const InputError = React.forwardRef<HTMLDivElement, IProps>(
  ({ children, className, css }, ref) => {
    return (
      <Flex ref={ref} css={css} className={cx(["input-error", className])}>
        <Text
          css={{ "&:first-letter": { textTransform: "uppercase" } }}
          size="xs"
          color="error"
        >
          {children}
        </Text>
      </Flex>
    );
  }
);

InputError.displayName = "InputError";

export default InputError;
