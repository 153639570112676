import Box from "components/box";
import { styled } from "stitches/stitches.config";
import PatternBg from "assets/images/pattern.png";
import { Flex } from "components/layout";
import Text from "components/text";
import { useEffect } from "react";
import Input from "components/input";
import { EMAIL_VALIDATION_REGEX } from "utils/constants";
import Button from "components/button";
import { RegisterMutationVariables } from "generated/__generated_graphql";
import { useSearchParams } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { useToast } from "components/toast/use-toast";
import { useRegisterMutation } from "generated/__generated_graphql";
import { useNavigate } from "react-router-dom";
import { getToken, setToken } from "../../utils/auth";
import floatLogo from "assets/images/float-logo-final.svg";
import { extractGraphqlErrors } from "utils/helpers";
import useForm from "hooks/useForm";

const StyledContainer = styled(Box, {
  background: "$gray2",
  backgroundImage: `url(${PatternBg})`,
  backgroundSize: "50% 50%",
  backgroundRepeat: "repeat-X",
  backgroundPosition: "top",
  width: "100vw",
  minHeight: "100vh",
  position: "relative",
  py: 60,

  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  flexDirection: "column",
});

const ContentWrapper = styled(Flex, {
  width: 380,
  height: "calc(100vh - 120px)",
});

const FormContainer = styled(Flex, {
  background: "white",
  boxShadow: "$subtle",
  p: 28,
  borderRadius: 6,
});

export default function Register() {
  const [searchParams] = useSearchParams();
  const referralCode = searchParams.get("signup_referral");

  const navigate = useNavigate();
  const toast = useToast();

  function notifyError(error?: string) {
    toast({
      content: error ?? "something went wrong",
      status: "error",
      position: "top",
      showIcon: true,
    });
  }

  const { register, onSubmit, values, setInputValue, errors } =
    useForm<RegisterMutationVariables>({
      fields: {
        firstName: "",
        lastName: "",
        password: "",
        email: "",
      },
    });

  const [{ fetching: loading }, registerUser] = useRegisterMutation();

  const signUp = async () => {
    const { firstName, lastName, email, password } = values;

    try {
      const response = await registerUser({
        firstName,
        lastName,
        email,
        password,
      });

      const error = extractGraphqlErrors(response, "beginOnboarding");

      if (error) {
        Sentry.captureException(error);
        notifyError(error);
        return;
      }

      analytics.track("User Registered", {
        name: `${firstName} ${lastName}`.trim(),
        email: email,
      });
      setToken(response.data?.beginOnboarding?.token!);
      navigate("/register/verify");
    } catch (error) {
      Sentry.captureException(error);
      notifyError();
    }
  };

  return (
    <StyledContainer>
      <ContentWrapper
        justify={"center"}
        gap={10}
        align="center"
        direction={"column"}
      >
        <img width={100} src={floatLogo}></img>
        <FormContainer stretchX direction="column">
          <Flex direction={"column"} gap={9}>
            <form
              className="wrapper"
              noValidate
              onSubmit={(e) => onSubmit(e, signUp)}
            >
              <Flex direction="column" gap={3} className="wrapper">
                <Flex gap={2}>
                  <Input
                    required
                    label="First name"
                    type="text"
                    {...register("firstName")}
                  ></Input>
                  <Input
                    required
                    label="Last name"
                    placeholder="Last name"
                    type="text"
                    {...register("lastName")}
                  ></Input>
                </Flex>

                <Input
                  required
                  label="Email"
                  type="email"
                  placeholder="Enter email address"
                  {...register("email")}
                ></Input>
                <Input
                  required
                  label="Password"
                  type="password"
                  placeholder="Set a password"
                  {...register("password")}
                ></Input>

                <Button
                  size={"md"}
                  disabled={loading}
                  type="submit"
                  isLoading={loading}
                  appearance="secondary"
                >
                  Continue Application
                </Button>
              </Flex>
            </form>

            <Text align={"center"} size="sm" color="light">
              Already have an account?{" "}
              <Text as="a" color="primary" href="/">
                Login
              </Text>
            </Text>
          </Flex>
        </FormContainer>

        <Text size="xs" lineHeight={"tall"} align="center" color="lighter">
          By continuing , I agree to Float’s{" "}
          <Text
            underline
            color="inherit"
            size={"inherit"}
            as="a"
            href="https://www.float.co/terms"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy &nbsp;
          </Text>
          and
          <Text
            underline
            size={"inherit"}
            as="a"
            href="https://www.float.co/terms"
            target="_blank"
            rel="noopener noreferrer"
            color={"inherit"}
          >
            Terms and Conditions
          </Text>{" "}
          and to receive electronic communication about my accounts and services
        </Text>
      </ContentWrapper>
    </StyledContainer>
  );
}
