import Button from "components/button";
import { Flex } from "components/layout";
import RadioCard from "components/radio-card";
import RadioGroup from "components/radio-group";
import Tag from "components/tag";
import Text from "components/text";
import { PaymentMethod } from "generated/__generated_graphql";
import React from "react";
import {
  HiChevronLeft,
  HiChevronRight,
  HiDocument,
  HiDocumentText,
} from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { SendMoneyComponentProps } from "../move-money.types";
import { sectionValidators } from "../move-money.utils";
import { BackButton, NextButton } from "./nav-buttons";

const PaymentType = React.forwardRef<HTMLDivElement, SendMoneyComponentProps>(
  ({ form, data }, ref) => {
    const navigate = useNavigate();
    const input = form.values;
    const paymentType = form.values.methodOfPayment;

    const isDomesticWire = paymentType === "domestic_wire";
    const isInternationalWire = paymentType === "international_wire";
    const isACH = paymentType === "ach";

    const existingRecipient = data?.recentRecipients.find(
      (recipient) => recipient.id === input.recipientId
    );

    const existingDomWireDetails = existingRecipient?.paymentMethods?.find(
      (method) => method.transferMethodType === "DomesticWirePaymentMethod"
    );

    const existingIntWireDetails = existingRecipient?.paymentMethods?.find(
      (method) => method.transferMethodType === "InternationalWirePaymentMethod"
    );

    function getMethodAddress(method: PaymentMethod | null) {
      const address = method?.recipientAddress;
      return {
        recipientCountry: address?.country?.code || "",
        recipientAddress: address?.line1 || "",
        recipientCity: address?.city || "",
        recipientState: address?.state || "",
        recipientZip: address?.postalCode || "",
      };
    }

    function onChange(e: React.ChangeEvent<HTMLInputElement>) {
      form.setInputValue("methodOfPayment", e.target.value);
    }

    function next() {
      const value = form.values.methodOfPayment;

      if (value === "domestic_wire" && existingDomWireDetails) {
        form.bulkUpdate({
          routingNumber: existingDomWireDetails?.method?.routing_number,
          accountNumber: existingDomWireDetails?.method?.account_number,
          accountType: existingDomWireDetails?.method?.account_type,
          paymentMethodId: existingDomWireDetails?.id,
          ...getMethodAddress(existingDomWireDetails),
        });

        navigate("/dashboard/send-money/payment-details");
        return;
      }

      if (value === "international_wire" && existingIntWireDetails) {
        form.bulkUpdate({
          swiftCode: existingIntWireDetails?.method?.swift_code,
          accountNumber: existingIntWireDetails?.method?.account_number,
          recipientType: existingIntWireDetails?.method?.recipient_type,
          paymentMethodId: existingIntWireDetails?.id,
          ...getMethodAddress(existingIntWireDetails),
        });
        navigate("/dashboard/send-money/payment-details");
        return;
      }

      form.bulkUpdate({
        swiftCode: "",
        accountNumber: "",
        recipientType: "",
        paymentMethodId: "",
        accountType: "",
        routingNumber: "",
        ...getMethodAddress(null),
      });

      navigate("/dashboard/send-money/payment-details");
    }

    return (
      <Flex ref={ref} gap={10} direction="column">
        <Text size="xl" color="black">
          How do you want to pay?
        </Text>
        <Flex direction="column">
          <RadioGroup
            name="methodOfPayment"
            onChange={onChange}
            value={input.methodOfPayment}
          >
            <RadioCard
              description="1 business day"
              title="Domestic Wire"
              value="domestic_wire"
            >
              {existingDomWireDetails && (
                <Flex>
                  <Tag
                    variant={"light"}
                    color={isDomesticWire ? "green" : "gray"}
                    prependIcon={<HiDocumentText />}
                    size="2xs"
                  >
                    Existing recipient
                  </Tag>
                </Flex>
              )}
            </RadioCard>
            <RadioCard
              description="1 - 3 business days"
              title="International Wire"
              value="international_wire"
            >
              {existingIntWireDetails && (
                <Flex>
                  <Tag
                    variant={"light"}
                    color={isInternationalWire ? "green" : "gray"}
                    prependIcon={<HiDocumentText />}
                    size="2xs"
                  >
                    Existing recipient
                  </Tag>
                </Flex>
              )}
            </RadioCard>

            <RadioCard
              disabled
              description="Coming soon"
              title="ACH"
              value="ach"
            ></RadioCard>
          </RadioGroup>
        </Flex>
        <Flex gap={3}>
          <BackButton to="/">Back</BackButton>
          <Button
            size="sm"
            appearance={"secondary"}
            disabled={!sectionValidators.paymentType(form.values)}
            onClick={next}
          >
            Continue
          </Button>
        </Flex>
      </Flex>
    );
  }
);

PaymentType.displayName = "PaymentType";

export default PaymentType;
