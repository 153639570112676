import * as Sentry from "@sentry/react";
import Button from "components/button";
import { Flex } from "components/layout";
import Text from "components/text";
import { useNavigate } from "react-router";
import { setToken } from "utils/auth";

interface ErrorPageProps {
  onClick?: (e: any) => void;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ onClick }: ErrorPageProps) => {
  const navigate = useNavigate();

  const logoutUser = (e: any) => {
    e.preventDefault();
    setToken(null);
    Sentry.configureScope((scope) => scope.setUser(null));
    navigate("/login", { replace: true });
  };

  return (
    <Flex align={"center"} justify="center" css={{ height: "80vh" }}>
      <Flex direction="column">
        <Text>Something went wrong</Text>
      </Flex>
    </Flex>
  );
};

export default ErrorPage;
