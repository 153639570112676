import FileUploader from "components/file-uploader";
import IconButton from "components/icon-button";
import { Flex } from "components/layout";
import SelectExperimental from "components/select/select-experimental";
import Text from "components/text";
import Textarea from "components/textarea";
import { Upload } from "generated/__generated_graphql";
import useUpload from "hooks/use-upload";
import React from "react";
import {
  HiOutlineDocument,
  HiOutlineDocumentText,
  HiOutlineTrash,
  HiTrash,
  HiX,
} from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { truncateFileName } from "utils/helpers";
import { SendMoneyComponentProps } from "../move-money.types";
import { sectionValidators } from "../move-money.utils";
import { BackButton, NextButton } from "./nav-buttons";

const MoreInfo = React.forwardRef<HTMLDivElement, SendMoneyComponentProps>(
  ({ form, data }, ref) => {
    const navigate = useNavigate();
    const input = form.values;
    const attachments = input.attachments || [];
    const [{ upload, removeUpload, status }, uploadFile] = useUpload({
      onSuccess: (upload) => {
        form.setInputValue("attachments", [...attachments, upload as Upload]);
        removeUpload?.();
      },
    });

    function deleteAttachment(id: number) {
      const newAttachments = attachments.filter(
        (attachment) => attachment.id !== id
      );
      form.setInputValue("attachments", newAttachments);
    }

    return (
      <Flex ref={ref} gap={10} direction={"column"}>
        <Text size={"xl"} color="black">
          More Info
        </Text>

        <Flex direction={"column"} gap={8}>
          <SelectExperimental
            required
            options={data?.transferPurposeList.map((item) => ({
              label: item,
              value: item,
            }))}
            value={input.purposeOfPayment}
            onChange={(value) =>
              form.setInputValue("purposeOfPayment", value as string)
            }
            label="Purpose of transfer"
          ></SelectExperimental>
          <SelectExperimental
            required
            options={data?.sourceOfFundsList.map((item) => ({
              label: item,
              value: item,
            }))}
            value={input.sourceOfFunds}
            onChange={(value) =>
              form.setInputValue("sourceOfFunds", value as string)
            }
            label="Source of funds"
          ></SelectExperimental>
          <Flex gap={2} direction={"column"}>
            <Textarea
              {...form.register("externalMemo")}
              label="External Memo"
              placeholder="Memo"
            ></Textarea>
            <Text
              {...form.register("internalMemo")}
              lineHeight={"tall"}
              size="xs"
              color="light"
            >
              Add references, For Further Credit information, or other messages
              you want the recipient to see.
            </Text>
          </Flex>

          <Textarea
            {...form.register("internalMemo")}
            label="Internal Memo"
            placeholder="Memo"
          ></Textarea>
          <Flex gap={2} direction={"column"}>
            <FileUploader
              isControlled
              onChange={uploadFile}
              onRemove={removeUpload}
              status={status}
              upload={upload}
              label="Internal Attachments"
            />
            <Text lineHeight={"tall"} size="xs" color="light">
              Invoices, contracts, or other supporting documents you might want
              to reference later, only visible to members of your organization
            </Text>
          </Flex>

          <Flex stack gap={3}>
            {input.attachments?.map((upload, index) => {
              return (
                <Flex
                  css={{
                    border: "1.5px solid $gray3",
                    borderRadius: 8,
                    px: 8,
                    py: 8,
                    color: "$gray11",
                  }}
                  key={index}
                  justify="between"
                  align={"center"}
                >
                  <Flex gap={3}>
                    <HiOutlineDocumentText />
                    <Text>{truncateFileName(upload?.publicId).name}</Text>
                  </Flex>

                  <IconButton
                    onClick={() => deleteAttachment(upload.id)}
                    size="xs"
                    color="tomato"
                    variant={"light"}
                  >
                    <HiTrash />
                  </IconButton>
                </Flex>
              );
            })}
          </Flex>
        </Flex>

        <Flex gap={3}>
          <BackButton to="/amount">Back</BackButton>
          <NextButton
            disabled={!sectionValidators.moreInfo(form.values)}
            to="/schedule"
          >
            Continue
          </NextButton>
        </Flex>
      </Flex>
    );
  }
);

MoreInfo.displayName = "MoreInfo";

export default MoreInfo;
