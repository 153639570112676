import React from "react";
import { useLogoutMutation } from "generated/__generated_graphql";
import { setToken } from "utils/auth";

export default function Logout() {
  const [, logout] = useLogoutMutation();

  React.useEffect(() => {
    async function logoutUser() {
      const response = await logout({});
      setToken(null);
      window.location.pathname = "/login";
    }

    logoutUser();
  }, []);

  return null;
}
