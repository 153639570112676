import React from "react";
import Avatar from "components/avatar";
import { Flex } from "components/layout";
import Loader from "components/loader";
import Logo from "components/logo";
import Text from "components/text";
import {
  BusinessDirector,
  BusinessDirectorAttributeInput,
  useCoownerInviteQuery,
  useSaveBusinessDirectorMutation,
} from "generated/__generated_graphql";
import {
  HiOutlineCheckCircle,
  HiOutlineExclamationCircle,
} from "react-icons/hi";
import { useParams } from "react-router-dom";
import { styled } from "stitches/stitches.config";
import BusinessDirectorForm from "./components/business-director-form";
import PatternBg from "assets/images/pattern.png";
import { ActionStatus } from "utils/types";

const Wrapper = styled("div", {
  display: "flex",
  backgroundColor: "$gray1",
  backgroundImage: `url(${PatternBg})`,
  backgroundSize: "50%",
  backgroundRepeat: "repeat-X",
  backgroundPosition: "bottom",
  flexDirection: "column",
  height: "100vh",
  width: "100vw",
});

const Header = styled("header", {
  display: "flex",
  width: "100vw",
  justifyContent: "space-between",
  alignItems: "center",
  height: 100,
  px: 100,
});

export default function DirectorOnboarding() {
  const params = useParams();

  const [{ data, fetching }] = useCoownerInviteQuery({
    variables: { code: params?.uuid! },
  });

  const [status, setStatus] = React.useState<ActionStatus>("idle");

  const [{ fetching: saving }, saveCoowner] = useSaveBusinessDirectorMutation();

  const director = data?.coownerInvite?.businessDirector;
  const expired = data?.coownerInvite?.expired;

  async function submitDetails(form: BusinessDirectorAttributeInput) {
    try {
    } catch (error) {}
  }

  if ((expired || !director) && !fetching) {
    return (
      <Flex
        gap={12}
        stack
        css={{ height: "90vh", color: "$gray10" }}
        align="center"
        justify={"center"}
      >
        <Flex css={{ width: 400 }} gap={5} align="center" stack>
          <Avatar color="amber">
            <HiOutlineExclamationCircle size={20} />
          </Avatar>

          <Flex align={"center"} stack gap={1}>
            <Text size="md">This link has expired</Text>
            <Text
              align="center"
              weight={"regular"}
              lineHeight="tall"
              color={"light"}
              size="sm"
            >
              Please contact {data?.coownerInvite?.ownerName} to get a new link
              or support if you have any questions.
            </Text>
          </Flex>
        </Flex>
      </Flex>
    );
  }

  if (fetching) {
    return (
      <Flex css={{ height: "90vh" }} align="center" justify="center">
        <Loader size="sm" color="$gray12" />
      </Flex>
    );
  }

  if (status === "success" && !fetching) {
    return (
      <Flex css={{ height: "90vh" }} stack justify={"center"} align="center">
        <Flex gap={5} align={"center"} stack css={{ width: 400 }}>
          <Avatar color="green">
            <HiOutlineCheckCircle size={20} />
          </Avatar>
          <Flex gap={1} align="center" stack>
            <Text size="md">Your profile has been submitted successfully</Text>
            <Text
              align="center"
              weight={"regular"}
              lineHeight="tall"
              color={"light"}
              size="sm"
            >
              Thank you for submitting the form! If you have any further
              questions or concerns, please don't hesitate to reach out to
              support
            </Text>
          </Flex>
        </Flex>
      </Flex>
    );
  }

  return (
    <Wrapper>
      <Header>
        <Logo width={100} />
      </Header>
      <Flex stack align={"center"}>
        <Flex gap={16} stack css={{ width: 400 }}>
          <Flex gap={3} stack>
            <Text color="black" size="xl">
              Hi {director?.firstName} {director?.lastName}
            </Text>
            <Text lineHeight={"tall"} weight={"regular"} size="sm">
              {data?.coownerInvite?.ownerName} is in the process of creating a
              bank account for {data?.coownerInvite?.businessName} and requires
              some information to complete the application process. Please fill
              out this form at your earliest convenience.
            </Text>
          </Flex>
          <BusinessDirectorForm
            onSave={() => setStatus("success")}
            submit={submitDetails}
            submitting={saving}
            shared
            director={data?.coownerInvite?.businessDirector as BusinessDirector}
          />
        </Flex>
      </Flex>
    </Wrapper>
  );
}
