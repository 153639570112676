import Modal, { ModalProps } from "components/modal";
import StepModal from "components/modal/step-modal";
import React from "react";
import CardDetailForm from "./components/card-detail-form";
import SelectCardType from "./components/select-card-type";

type Props = ModalProps;

export default function CreateCard({ open, ...props }: Props) {
  return (
    <StepModal
      open={open}
      defaultScreen={"select-card-type"}
      variant={"drawer"}
      title="Create card"
      {...props}
    >
      <Modal.Screen screenId="select-card-type">
        <SelectCardType />
      </Modal.Screen>
      <Modal.Screen screenId="card-details-form">
        <CardDetailForm />
      </Modal.Screen>
    </StepModal>
  );
}
