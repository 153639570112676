import { TabsContent } from "@radix-ui/react-tabs";
import useControllable from "hooks/useControllable";
import Box from "components/box";
import useControlled from "hooks/useControllable";
import React from "react";
import { cx } from "utils/dom";
import { Tab, TabContent, TabsList, TabsRoot } from "./tabs.styles";
import { ITab, TabsProps } from "./tabs.types";
import { Flex } from "components/layout";
import Tag from "components/tag";

const Tabs = React.forwardRef<HTMLDivElement, TabsProps>(
  (
    { tabs, activeTab, defaultTab, css, className, onTabChange, tabWidth },
    ref
  ) => {
    const [value, setValue] = useControlled({
      value: activeTab,
      defaultValue: defaultTab,
    });

    function handleTabChange(value: string) {
      setValue(value);
      onTabChange && onTabChange(value);
    }

    return (
      <Box className={cx(["tabs", className])} css={css} ref={ref}>
        <TabsRoot defaultValue={value} onValueChange={handleTabChange}>
          <TabsList className="tabs-list">
            {tabs.map((tab: ITab) => {
              if (tab.disabled) return null;
              return (
                <Flex key={tab.key}>
                  <Tab value={tab.key}>
                    {tab.title}
                    {tab.label && (
                      <Tag
                        size={"2xs"}
                        shape="pill"
                        variant="light"
                        color={tab.label.color}
                      >
                        {tab.label.content}
                      </Tag>
                    )}
                  </Tab>
                  <Box css={{ width: tabWidth || "fit-content" }} />
                </Flex>
              );
            })}
          </TabsList>
          <TabsContent value={value}>
            {tabs.map((tab: ITab) => {
              if (tab.disabled) return null;
              return (
                <TabContent key={tab.key} value={tab.key}>
                  {tab.content}
                </TabContent>
              );
            })}
          </TabsContent>
        </TabsRoot>
      </Box>
    );
  }
);

Tabs.displayName = "Tabs";

export default Tabs;
