import { Stack } from "components/layout";
import PageTitle from "components/page-title";
import Tabs from "components/tabs";
import React from "react";

export default function Payments() {
  return (
    <>
      <PageTitle size="xl" title="Payments"></PageTitle>
      <Stack>
        <Tabs
          defaultTab="overview"
          tabs={[
            {
              title: "Outgoing",
              key: "overview",
              content: "",
              label: { content: "3", color: "gray" },
            },
            { title: "Incoming", key: "incoming", content: "" },
            { title: "Vendors", key: "vendors", content: "" },
          ]}
        ></Tabs>
      </Stack>
    </>
  );
}
