import Avatar from "components/avatar";
import Button from "components/button";
import { Flex } from "components/layout";
import FixedPageContainer from "components/layout/fixed-page-container";
import LinkButton from "components/link-button";
import Text from "components/text";
import React from "react";
import { HiReply } from "react-icons/hi";
import { styled } from "stitches/stitches.config";

const Wrapper = styled(Flex, {
  // background: "white",
  height: 60,
  // borderBottom: "1px solid $gray2",
});

type HeaderProps = React.ComponentProps<typeof Wrapper> & {
  pageTitle?: React.ReactNode;
};

const Header = React.forwardRef<HTMLDivElement, HeaderProps>(
  ({ pageTitle, ...props }, ref) => {
    return (
      <Wrapper ref={ref}>
        <FixedPageContainer>
          <Flex stretchY justify="between" align="center">
            <Text size="2xl" color="black" weight={"medium"} as="div">
              {pageTitle}
            </Text>
            <Flex align={"center"} gap={5}>
              <LinkButton
                linkType="link"
                href={"/dashboard/send-money"}
                shape={"sharp"}
                prepend={<HiReply style={{ rotate: "180deg" }} />}
                size="sm"
              >
                Send Money
              </LinkButton>
            </Flex>
          </Flex>
        </FixedPageContainer>
      </Wrapper>
    );
  }
);

Header.displayName = "Header";

export default Header;
