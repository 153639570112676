import Box from "components/box";
import { styled } from "stitches/stitches.config";
import PatternBg from "assets/images/pattern.png";
import { Stack } from "components/layout";
import Text from "components/text";
import { Link } from "react-router-dom";
import Avatar from "components/avatar";
import { AiOutlineExclamationCircle } from "react-icons/ai";

const StyledContainer = styled(Box, {
  background: "$sand2",
  width: "100vw",
  minHeight: "100vh",
  position: "relative",

  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  flexDirection: "column",
  ".bg": {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100vw",

    zIndex: 1,
  },
  ".footer": {
    mt: "auto",
    mb: 30,
  },
});

const StyledBox = styled(Box, {
  mt: 80,
  background: "$sand1",
  boxShadow: "$subtle",
  width: 450,
  p: 48,
  borderRadius: 8,
  zIndex: 2,

  ".wrapper": {
    mt: 48,
  },
});

const StyledLink = styled(Link, {
  textDecoration: "none",
  fontFamily: "$medium",
  color: "$primary",
});

export default function ExpiredLink() {
  return (
    <StyledContainer>
      <img className="bg" src={PatternBg} alt="" />
      <StyledBox>
        <Stack align="center" spacing={30}>
          <Avatar variant="solid" size={"2xl"} css={{ background: "#DE7965" }}>
            <AiOutlineExclamationCircle size={35} color="white" />
          </Avatar>
          <Stack css={{ mt: 5 }} spacing={10}>
            <Text align="center" size="2xl" weight="semi" color="black">
              Link Expired
            </Text>
            <Text
              align="center"
              size="xs"
              lineHeight="taller"
              color="light"
              weight="regular"
            >
              The link you're trying to access has expired.
            </Text>
          </Stack>
          <Text size="xs" color="light" weight="bold">
            <StyledLink to="/login">Return to Login</StyledLink>
          </Text>
        </Stack>
      </StyledBox>

      <Stack className="footer" isInline spacing={10}>
        <Text size="xs" weight="regular" color="light">
          Swipe Technologies, Inc. &nbsp; •
        </Text>
        <Text size="xs" weight="regular" color="light" as={"a"} href="/">
          Privacy Policy &nbsp; •
        </Text>
        <Text size="xs" weight="regular" color="light" as={"a"} href="/">
          Platform Agreement
        </Text>
      </Stack>
    </StyledContainer>
  );
}
