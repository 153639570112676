import { Flex } from "components/layout";
import Text from "components/text";
import { useAppProvider } from "providers/app-provider/app-provider";
import { RowItem } from "../settings.styles";

export default function Company() {
  const { user } = useAppProvider();

  return (
    <Flex gap={7} stack>
      <RowItem>
        <Flex className="key">
          <Text weight={"semi"} color="black">
            Legal name
          </Text>
        </Flex>

        <Text>{user?.business?.name}</Text>
      </RowItem>

      <RowItem>
        <Flex className="key">
          <Text weight={"semi"} color="black">
            Doing business as (DBA)
          </Text>
        </Flex>

        <Text>{user?.business?.businessAlias}</Text>
      </RowItem>

      <RowItem>
        <Flex align="start" gap={2} stack className="key">
          <Text weight={"semi"} color="black">
            Company name
          </Text>
          <Text size="xs" color="light">
            This is the name that appears on Float.
          </Text>
        </Flex>

        <Text>{user?.business?.name}</Text>
      </RowItem>

      <RowItem>
        <Flex align="start" gap={3} stack className="key">
          <Text weight={"semi"} color="black">
            Company address
          </Text>
        </Flex>

        <Text lineHeight={"tall"}>
          {user?.business?.address?.line1} <br />{" "}
          {user?.business?.address?.city}, <br />{" "}
          {user?.business?.address?.state} <br />
          {user?.business?.address?.country?.name}
        </Text>
      </RowItem>

      <RowItem>
        <Flex align="start" gap={3} stack className="key">
          <Text weight={"semi"} color="black">
            Company email
          </Text>
        </Flex>

        <Text lineHeight={"tall"}>{user?.business?.email}</Text>
      </RowItem>

      <RowItem>
        <Flex align="start" gap={3} stack className="key">
          <Text weight={"semi"} color="black">
            Phone number
          </Text>
        </Flex>

        <Text lineHeight={"tall"}>
          {user?.business?.phoneNumber?.countryCode}
          {user?.business?.phoneNumber?.value}
        </Text>
      </RowItem>
    </Flex>
  );
}
