import { Root, List, Content, Trigger } from "@radix-ui/react-tabs";
import { styled } from "stitches/stitches.config";

export const TabsRoot = styled(Root, {
  width: "100%",
});

export const TabsList = styled(List, {
  display: "flex",
  width: "100%",
  borderBottom: "1px solid $gray3",
  justifyContent: "flex-start",
  marginBottom: 24,
});

export const TabContent = styled(Content, {});

export const Tab = styled(Trigger, {
  display: "flex",
  alignItems: "center",
  columnGap: 8,
  marginRight: 40,
  marginBottom: -2,
  letterSpacing: 0.2,
  color: "$gray9",
  cursor: "pointer",
  pb: 16,
  fontFamily: "$medium",
  fontSize: 14,

  "&[data-state='active']": {
    color: "$gray12",
    borderBottom: "2px solid $teal10",

    "&:focus": { outline: "none" },
  },
});
