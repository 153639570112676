import Avatar from "components/avatar";
import BalanceChartCard from "components/balance-chart-card";
import Box from "components/box";

import { Flex, Grid, GridItem, Stack } from "components/layout";
import PageTitle from "components/page-title";
import Page from "components/page/page";
import PaymentsSummaryCard from "components/payments-summary";
import Stat from "components/stat";
import Text from "components/text";
import { useOverviewPageQuery } from "generated/__generated_graphql";
import { useAppProvider } from "providers/app-provider/app-provider";
import { useEffect } from "react";
import { HiOutlineCalendar } from "react-icons/hi";

import { useNavigate } from "react-router-dom";
import { formatMoney } from "utils/helpers";
import SystemUpgrade from "./components/system-upgrade";

const OverviewPage = () => {
  useEffect(() => {
    analytics.page("General", "Overview");
  }, []);

  const [{ data, fetching, error }] = useOverviewPageQuery();

  const navigate = useNavigate();
  const { currency } = useAppProvider();

  if (error) {
    return <div></div>;
  }

  const balances = data?.getBalances;
  const accounts = balances?.bankAccounts;
  const transactions = data?.journalEntries.data;
  const totalMoneyIn = data?.getBalances?.totalMoneyIn;
  const totalMoneyOut = data?.getBalances?.totalMoneyOut;
  const totalPendingPayments =
    data?.getBalances?.totalUpcomingMoneyTransfersAmount;
  const pendingPayments =
    data?.getScheduledMoneyTransfers?.map((t) => t.journalEntry) || [];
  const outgoingPayments = data?.journalEntries.data
    ?.filter((t) => t.transactionType === "debit")
    .slice(0, 5);
  const incomingPayments = data?.journalEntries.data
    ?.filter((t) => t.transactionType === "credit")
    .slice(0, 5);
  const balanceHistory = data?.getLast30DaysBalances;

  return (
    <Page pageTitle="Dashboard" isLoading={fetching}>
      <Flex gap={4} stack>
        <Grid>
          <GridItem colSpan={7}>
            <BalanceChartCard
              totalBalance={balances?.totalBalance}
              balances={balanceHistory}
              transactions={transactions as any}
              bankAccounts={accounts as any}
            ></BalanceChartCard>
          </GridItem>
          <GridItem colSpan={5}>
            <PaymentsSummaryCard
              emptyState={
                <Flex gap={4} align="center" stack>
                  <Avatar size="md" color="gray">
                    <HiOutlineCalendar size={18} />
                  </Avatar>
                  <Flex gap={1} align={"center"} stack>
                    <Text size="sm" weight={"semi"}>
                      No scheduled payments
                    </Text>
                    <Text size="xs" color="light">
                      Your scheduled payments will appear here
                    </Text>
                  </Flex>
                </Flex>
              }
              transactions={pendingPayments as any}
              title="Scheduled payments"
              total={totalPendingPayments}
            />
          </GridItem>
        </Grid>
        <Grid>
          <GridItem colSpan={6}>
            <PaymentsSummaryCard
              transactions={outgoingPayments as any}
              title="Money out last 30 days"
              total={-totalMoneyOut}
            />
          </GridItem>
          <GridItem colSpan={6}>
            <PaymentsSummaryCard
              transactions={incomingPayments as any}
              title="Money in last 30 days"
              total={totalMoneyIn}
            />
          </GridItem>
        </Grid>
      </Flex>
    </Page>
  );
};

export default OverviewPage;
