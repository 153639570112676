import Button from "components/button";
import { useTransferMoneyDataQuery } from "generated/__generated_graphql";
import React from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Box from "components/box";
import { Flex } from "components/layout";
import floatLogo from "assets/images/float-logo-final.svg";
import { HiCheckCircle, HiOutlineCheckCircle, HiX } from "react-icons/hi";
import Text from "components/text";
import { gray, green } from "@radix-ui/colors";
import { TransferMoneyState } from "./move-money.types";
import useForm from "hooks/useForm";
import { defaultValidators } from "components/input";
import { testMoveMoneyData } from "./move-money.data";
import IconButton from "components/icon-button";
import { noop } from "utils/helpers";
import { moveMoneySteps } from "./move-money.utils";
import { useAppProvider } from "providers/app-provider/app-provider";

const MoveMoney = React.forwardRef((props, ref) => {
  const [{ data, fetching }] = useTransferMoneyDataQuery();
  const navigate = useNavigate();
  const currentPath = useLocation().pathname;
  const { user } = useAppProvider();
  const bankAccounts = user?.business?.bankAccounts;

  const form = useForm<TransferMoneyState>({
    fields: {
      amount: 100,
      recipientId: undefined,
      recipientName: "",
      recipientEmail: "",
      recipientPhone: { countryCode: "GH", value: "" },
      recipientAddress: "",
      recipientCity: "",
      recipientState: "",
      recipientZip: "",
      recipientCountry: "US",
      methodOfPayment: "",
      narration: "",
      bankAccountId: bankAccounts?.[0].id || null,
      effectiveDate: null,
      attachmentIds: [],
      attachments: [],
      routingNumber: "",
      accountNumber: "",
      accountType: "",
      bankAddress: {},
      notifyRecipient: false,
      recipientType: "",
      wireInstructions: "",
      internalMemo: "",
      externalMemo: "",
      purposeOfPayment: "",
      sourceOfFunds: "",
      bankName: "",
      swiftCode: "",
      // ...(testMoveMoneyData as any),
    },
    validators: {
      recipientEmail: (value, values) => {
        if (values?.notifyRecipient) {
          return defaultValidators.email(value);
        }
        return "";
      },
    },
    optional: ["internalMemo", "externalMemo"],
  });
  function isCurrentPath(path: string) {
    if (path === "/")
      return (
        currentPath === "/dashboard/send-money" ||
        currentPath === "/dashboard/send-money/"
      );
    return currentPath === `/dashboard/send-money${path}`;
  }

  // React.useEffect(() => {
  //   //rules for page navigation on Load
  //   if (currentPath !== "/dashboard/send-money" && !form.values.recipientName) {
  //     navigate("/dashboard/send-money/");
  //   }
  // }, [currentPath]);

  return (
    <Flex gap={16} direction="column">
      <Flex css={{ height: 60 }}>
        <Flex
          align={"center"}
          justify="between"
          css={{
            height: 60,
            px: 60,
            borderBottom: "1px solid $gray2",
            position: "fixed",
            width: "100vw",
            background: "white",
            color: "$gray10",
          }}
        >
          {/* <img width={100} src={floatLogo} alt="" /> */}
          <Text>
            Send money{" "}
            {form.values.recipientName ? `to ${form.values.recipientName}` : ""}
          </Text>
          <Flex align={"center"} gap={3}>
            <IconButton
              onClick={() => navigate("/dashboard")}
              css={{ color: "$gray11" }}
            >
              <HiX size={20}></HiX>
            </IconButton>
          </Flex>
        </Flex>
      </Flex>
      <Box
        css={{
          width: 400,
          margin: "0 auto",
          marginBottom: 60,
          position: "relative",
        }}
      >
        {currentPath.includes("/success") ? null : (
          <Flex gap={4} css={{ position: "fixed", top: 124, left: 60 }} stack>
            {moveMoneySteps.map((step, index) => {
              const isAvailable = step.isAvailable?.(form.values);
              const isComplete = step.isComplete?.(form.values);

              if (["/success", "/review-transaction"].includes(step.path!))
                return null;

              return (
                <Flex
                  onClick={
                    isAvailable
                      ? () => navigate(`/dashboard/send-money${step.path}`)
                      : noop
                  }
                  gap={3}
                  key={index}
                >
                  {isComplete ? (
                    <HiCheckCircle color={green.green10} />
                  ) : (
                    <HiOutlineCheckCircle
                      color={
                        isCurrentPath(step.path as string)
                          ? green.green10
                          : gray.gray8
                      }
                    />
                  )}
                  <Text
                    color={
                      isCurrentPath(step.path as string) ? "primary" : "light"
                    }
                  >
                    {step.title}
                  </Text>
                </Flex>
              );
            })}
          </Flex>
        )}
        <Routes>
          {moveMoneySteps.map(({ component: Component, path }, index) => {
            return (
              <Route
                key={index}
                element={<Component data={data} form={form} />}
                path={path}
              ></Route>
            );
          })}
        </Routes>
      </Box>
    </Flex>
  );
});

MoveMoney.displayName = "MoveMoney";

export default MoveMoney;
