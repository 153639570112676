import Box from "components/box";
import InputLabel from "components/input-label";
import React, { Ref } from "react";
import { CSS } from "stitches/stitches.config";
import InputError from "components/input-error";
import ReactCodeInput from "react-code-input";
import { styled } from "stitches/stitches.config";
import { autoFillResetStyles, autoFillSelectors } from "components/input";
import { gray } from "@radix-ui/colors";

export const StyledCodeInput = styled(ReactCodeInput, {
  display: "flex !important",
  gap: "$2",
  width: "100%",
  "& input": {
    textAlign: "center",
    fontFamily: "$medium",

    "&:focus": { outline: "none" },

    "&::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },

  defaultVariants: {
    appearance: "flushed",
    size: "sm",
  },

  variants: {
    appearance: {
      outline: {
        "& input": {
          background: "white",
          border: "2px solid $gray5",
          boxShadow: "$subtle",

          "&:focus": {
            boxShadow: "$focusRing",
            borderColor: "$gray4",
          },
        },
      },

      flushed: {
        "& input": {
          background: "$gray2",
          color: "$gray11",
          border: "1.5px solid $gray2",

          "&:focus": {
            boxShadow: "$focusRing",
            border: "1.5px solid $green7",
            backgroundColor: "white",

            [autoFillSelectors]: autoFillResetStyles("white"),
          },

          [autoFillSelectors]: autoFillResetStyles(gray.gray2),
        },
      },
    },

    size: {
      sm: {
        "& input": {
          height: 48,
          width: 44,
          fontSize: 24,
          borderRadius: 8,
        },
      },
      md: {
        "& input": {
          height: 56,
          width: 56,
          fontSize: 48,
          borderRadius: 8,
        },
      },
      lg: {
        "& input": {
          height: 64,
          width: 64,
          fontSize: 48,
          borderRadius: 8,
        },
      },
    },
  },
});

export type PinCodeInputProps = React.ComponentProps<typeof StyledCodeInput> & {
  inputStyle?: CSS;
  inputRef?: Ref<ReactCodeInput> | undefined;
  hideErrorMessage?: boolean;
  label?: string;
  error?: string;
  required?: boolean;
};

const PinCodeInput = React.forwardRef<HTMLDivElement, PinCodeInputProps>(
  (props, ref) => {
    const {
      label,
      error,
      css,
      required,
      disabled,
      hideErrorMessage,
      inputRef,
      name,
      value,
      onChange,
      fields,
      style,
      type = "password",
    } = props;

    return (
      <Box stretchX ref={ref} css={css} style={style}>
        {label && (
          <InputLabel required={required} htmlFor={name}>
            {label}
          </InputLabel>
        )}
        <StyledCodeInput
          className="pin-code-input"
          name={name}
          ref={inputRef}
          fields={fields}
          disabled={disabled}
          type={type}
          inputMode={"tel"}
          value={value}
          onChange={onChange}
        />

        {/* {error && !hideErrorMessage && (
          <InputError css={{ marginTop: 4 }}>{error}</InputError>
        )} */}
      </Box>
    );
  }
);

PinCodeInput.displayName = "PinCodeInput";

export default PinCodeInput;
