import Avatar from "components/avatar";
import CardDetail from "components/card-detail.tsx/card-detail";
import HoverCard from "components/hover-card";
import { Flex } from "components/layout";
import Meter from "components/meter";
import Table, { ITableColumn } from "components/table";
import TableWithDetailCard from "components/table/table-with-detail-card";
import Tag from "components/tag";
import Text from "components/text";
import { format } from "date-fns";
import { Card } from "generated/__generated_graphql";
import React from "react";
import {
  HiBan,
  HiLockClosed,
  HiOutlineCreditCard,
  HiOutlineLockClosed,
} from "react-icons/hi";
import { formatMoney } from "utils/helpers";
import CondensedCardDetails from "./condensed-card-details";

const columns: Array<ITableColumn<Card>> = [
  {
    title: "Card",
    key: "card",
    dataIndex: "id",
    render: (value, card) => (
      <Flex css={{ py: 8 }} gap={4}>
        <Avatar size="sm" color="gray" css={{ background: "white" }}>
          {card.status === "active" && <HiOutlineCreditCard size={16} />}
          {card.status === "terminated" && <HiBan size={16} />}
          {card.status === "suspended" && <HiOutlineLockClosed size={16} />}
        </Avatar>
        <Flex gap={3}>
          <Flex gap={2} stack>
            <Text color={"black"} weight="semi">
              ••{card.lastFour} {card.nickname}
            </Text>
            <Text size="xs" color="light">
              Active since {format(new Date(card.createdAt), "dd MMM yyyy")}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    ),
  },
  {
    title: "Spent",
    key: "spent",
    dataIndex: "amountSpent",
    render: (value, card) => (
      <Flex gap={3} stack>
        <Text color="black">
          {formatMoney(value)}{" "}
          <Text as="span" color={"lighter"}>
            / {formatMoney(card.spendLimitAmount ?? 2500)}
          </Text>
        </Text>
        <Meter
          className={`meter-${card.id}`}
          progress={
            (card.amountSpent / Number(card.spendLimitAmount ?? "2500")) * 100
          }
        />
      </Flex>
    ),
  },
  {
    title: "Card holder",
    key: "card-holder",
    dataIndex: "user",
    render: (value: Card["user"], card) => (
      <Text>
        {value?.firstName} {value?.lastName}
      </Text>
    ),
  },
  {
    title: "Type",
    key: "type",
    dataIndex: "cardType",
    render: (value) => <Text case={"capitalize"}>{value}</Text>,
  },
  {
    title: "Associated account",
    key: "account",
    dataIndex: "bankAccount",
    render: (account: Card["bankAccount"], card) => (
      <Text>
        {account?.name} ••{account?.accountNumber?.slice(-4)}
      </Text>
    ),
  },
];

type CardsTableProps = { cards?: Array<Card> };

const CardsTable = React.forwardRef<HTMLDivElement, CardsTableProps>(
  ({ cards = [] }, ref) => {
    return (
      <TableWithDetailCard detail={CardDetail} data={cards} columns={columns} />
    );
  }
);

CardsTable.displayName = "CardsTable";

export default CardsTable;
