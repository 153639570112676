import AccountSelector from "components/account-selector";
import Avatar from "components/avatar";
import { Flex } from "components/layout";
import Stack from "components/layout/stack";
import MenuItem from "components/menu-item/menu-item";
import Separator from "components/separator";
import Tag from "components/tag";
import Text from "components/text";
import { User } from "generated/__generated_graphql";
import { useAppProvider } from "providers/app-provider/app-provider";
import React from "react";
import {
  HiOutlineLightningBolt,
  HiOutlineReply,
  HiSwitchHorizontal,
  HiSparkles,
} from "react-icons/hi";
import { useLocation, useNavigate } from "react-router";
import { cx } from "utils/dom";
import MenuBarAction from "./menu-bar-action";
import { menuData } from "./menu-bar.data";
import { MenuContainer } from "./menu-bar.styles";
import LOGOMARK from "assets/images/logomark--white.svg";
import MenuTile from "./menu-tile";
import Logo from "components/logo";

type MenuProps = React.ComponentProps<typeof MenuContainer>;

const MenuBar = React.forwardRef<HTMLDivElement, MenuProps>(
  ({ className, css }, ref) => {
    const location = useLocation();
    const pathname = location.pathname;
    const navigate = useNavigate();
    const { user } = useAppProvider();
    const [selectedKey, setSelectedKey] = React.useState<
      string | number | null
    >(null);

    function handleClick(id: string | number) {
      setSelectedKey(id);
    }

    const height = window.innerHeight;

    return (
      <MenuContainer css={css} className={cx(["menu", className])} ref={ref}>
        <Flex
          stack
          className="menu-wrapper-lg"
          justify={"between"}
          gap={10}
          css={{ width: "100%", height: "calc(100vh - 72px)" }}
          align={"center"}
        >
          <Flex stretchY gap={10} stack>
            <Logo width={140} style={{ filter: "invert(1)" }} />

            <Flex stack className="menu-items">
              {menuData.map((menu) => (
                <MenuItem
                  open={selectedKey === menu.id}
                  data={menu}
                  handleOnClick={handleClick}
                  key={menu.id}
                />
              ))}
            </Flex>
          </Flex>

          <AccountSelector size="sm" user={user as User} />
        </Flex>

        <Flex
          className="menu-wrapper-sm"
          justify={"between"}
          gap={10}
          css={{ width: "100%", height: height - 32 }}
          align={"center"}
          stack
        >
          <Flex stretchY stack gap={10}>
            <Avatar css={{ background: "$whiteA4", border: "none" }}>
              <img src={LOGOMARK} alt="logo" />
            </Avatar>

            <Flex gap={3} stack>
              {menuData.map((menu) => (
                <MenuTile
                  active={pathname === menu.path}
                  open={selectedKey === menu.id}
                  data={menu}
                  handleOnClick={handleClick}
                  key={menu.id}
                />
              ))}
            </Flex>
          </Flex>

          <div>
            <AccountSelector
              user={user as User}
              renderSelector={() => (
                <Avatar
                  variant={"light"}
                  // color="gray"
                  size="md"
                  name={user?.business?.name.charAt(0) || "N"}
                />
              )}
            ></AccountSelector>
          </div>
        </Flex>
      </MenuContainer>
    );
  }
);

MenuBar.displayName = "MenuBar";

export default MenuBar;
