import CountryChip from "components/country-chip";
import CurrencyInput from "components/currency-input/currency-input";
import Input from "components/input";
import { Flex } from "components/layout";
import Modal, { ModalProps } from "components/modal";
import SelectExperimental from "components/select";
import Sticker from "components/sticker";
import Text from "components/text";
import React from "react";
import { HiOutlineExclamationCircle } from "react-icons/hi";

export function FundAccount() {
  return (
    <Flex gap={4} stack>
      <CurrencyInput
        size="lg"
        label="Amount"
        append={
          <Text weight="medium" color="light">
            USD
          </Text>
        }
      ></CurrencyInput>
      <SelectExperimental size="xl" label="Transfer from"></SelectExperimental>
      <SelectExperimental size="xl" label="Transfer to"></SelectExperimental>
    </Flex>
  );
}

interface Props extends ModalProps {}
export default function FundYieldAccount({ ...props }: Props) {
  return (
    <Modal {...props} title="Transfer funds between accounts">
      <Modal.Body css={{ width: 600 }}>
        <Flex stack gap={6}>
          <Text color="light" lineHeight={"tall"}>
            Unlock the Full Potential of Your Idle Cash with Float Yield
            Account. With Float's Yield Account, you can effortlessly maximize
            your revenue by moving idle cash into an interest-earning account.
          </Text>
          <Sticker variant={"outline"} color={"lime"}>
            <HiOutlineExclamationCircle />
            <Text color="inherit">
              Transfers to yield can take up to 2-3 business days.
            </Text>
          </Sticker>
          <FundAccount></FundAccount>
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Cancel>Cancel</Modal.Cancel>
        <Modal.Confirm appearance={"primary"}>Fund account</Modal.Confirm>
      </Modal.Footer>
    </Modal>
  );
}
