import Button from "components/button";
import { Flex } from "components/layout";
import Modal from "components/modal";
import { useStepModal } from "components/modal/step-modal";
import Separator from "components/separator";
import Text from "components/text";

export default function AuthTypeSelectStep() {
  const context = useStepModal();
  return (
    <>
      <Modal.Body css={{ width: 540 }}>
        <Flex stack>
          <Flex gap={10} align="start" justify={"between"}>
            <Flex stack gap={2}>
              <Text color="black">Authenticator App</Text>
              <Text lineHeight={"tall"} size="xs" color="light">
                Download and install any authenticator app (E.g. Google
                Authenticator) on your phone.
              </Text>
            </Flex>

            <Button
              onClick={() => context.goToScreen("authenticator-setup")}
              size="sm"
              appearance={"secondary"}
              shape="pill"
            >
              Setup
            </Button>
          </Flex>

          <Separator />

          <Flex align="start" justify={"between"}>
            <Flex stack gap={2}>
              <Text color="black">Text Message (Backup)</Text>
              <Text size="xs" color="light">
                Add a phone number to receive token
              </Text>
            </Flex>

            <Button disabled size="sm" appearance={"flushed"} shape="pill">
              Coming soon
            </Button>
          </Flex>
        </Flex>
      </Modal.Body>
    </>
  );
}
