import Table from "components/table";
import { CSS, styled } from "stitches/stitches.config";

export const Wrapper = styled("div", {
  height: "100vh",
  background: "$gray12",
  p: 16,
});

export const Main = styled("div", {
  width: "100%",
  height: "calc(100vh - 32px)",
  background: "white",
  borderRadius: 16,
  overflow: "scroll",
});

export const Header = styled("div", {
  height: 64,
  width: "100%",
  display: "flex",
  px: 60,
  alignItems: "center",
  borderBottom: "1px solid $gray3",
});

export const Body = styled("div", {
  p: 60,
  display: "flex",
  flexDirection: "column",
  gap: "$16",

  ".nav-link": {
    textDecoration: "none",
  },
});

export const RowItem = styled("div", {
  display: "flex",
  alignItems: "flex-start",
  borderBottom: "1px solid $gray3",
  pb: 18,
  gap: 200,

  ".key": {
    width: 330,
  },

  ".item-wrapper": {
    gap: 200,
  },
});

export const tableStyles: CSS = {
  ".table-card": { boxShadow: "none" },
  ".table-header-row": {
    borderRadius: 8,
  },

  ".table-row": {
    "&:hover": {
      background: "none",
    },
  },
  ".header-cell": {
    border: "none",
    background: "$gray2",
    py: 10,
    fontWeight: 500,

    "&:first-of-type": {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
    },
    "&:last-of-type": {
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
    },
  },
  ".data-cell": {},
};
