import { RadioGroupItem } from "@radix-ui/react-radio-group";
import { styled } from "stitches/stitches.config";
import Flex from "components/layout/flex";
import { sand, gray, green } from "@radix-ui/colors";

export const Indicator = styled(Flex, {
  borderRadius: 8,
  boxShadow: `inset 0 0 0 2px ${gray.gray8}`,
  flexShrink: 0,
  transition: "all .3s ease",

  variants: { circleSize: { sm: { size: 16 } } },

  defaultVariants: { circleSize: "sm" },
});

export const StyledRadio = styled(RadioGroupItem, {
  boxShadow: `0 0 0 2px ${gray.gray3}`,
  border: "none",
  px: 12,
  py: 8,
  background: "white",
  borderRadius: 8,
  transition: "all .3s ease",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  flex: 1,
  color: "$gray11",

  ".title": {
    fontFamily: "$medium",
    fontWeight: 500,
    color: "inherit",
  },
  ".description": {
    lineHeight: 1.5,
  },

  "&[data-state=checked]": {
    boxShadow: `0 0 0 2px ${green.green6}`,
    background: "$green2",
    color: "$green10",
    [`& ${Indicator}`]: { boxShadow: `inset 0 0 0 5px ${green.green10}` },
  },

  variants: {
    disabled: {
      true: {
        background: "$gray1",
        pointerEvents: "none",
        cursor: "not-allowed",
        userSelect: "none",
      },
    },
  },
});
