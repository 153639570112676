import React from "react";
export function AF() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#333"
          d="M2.005 2A2.008 2.008 0 0 0 0 4.009v7.982C0 13.101.894 14 2.005 14H5V2H2.005Z"
        ></path>
        <path
          fill="#24B47E"
          d="M11 2v12h2.995A2.008 2.008 0 0 0 16 11.991V4.01C16 2.899 15.106 2 13.995 2H11Z"
        ></path>
        <path fill="#E25950" d="M5 2h6v12H5z"></path>
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M8 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Z"
        ></path>
      </g>
    </svg>
  );
}
export function AX() {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="#0064AD"
        d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
      ></path>
      <path
        fill="#FFD300"
        d="M16 9.539H8V14H4.923V9.539H0V6.461h4.923V2H8v4.461h8z"
      ></path>
      <path
        fill="#DA0E15"
        d="M16 8.616H7.077V14H5.846V8.616H0V7.384h5.846V2h1.231v5.384H16z"
      ></path>
      <path
        fill="#004F8B"
        d="M15 9.539V6.461h1v3.078ZM4.923 13H8v1H4.923Zm0-11H8v1H4.923ZM0 6.461h1v3.078H0Z"
        opacity="0.1"
      ></path>
    </svg>
  );
}
export function AL() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#E25950"
          d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        ></path>
        <path
          fill="#333"
          d="M5 7h6l-1 2H6zm1.5-3L5 6h3zM8 12l-2-2h4zm1.5-8L8 6h3z"
        ></path>
      </g>
    </svg>
  );
}
export function DZ() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#F6F9FC"
          d="M8 2v12h6.002A2.005 2.005 0 0 0 16 11.991V4.01C16 2.899 15.113 2 14.002 2H8Z"
        ></path>
        <path
          fill="#24B47E"
          d="M1.998 2A2.005 2.005 0 0 0 0 4.009v7.982C0 13.101.887 14 1.998 14H8V2H1.998Z"
        ></path>
        <path
          fill="#E25950"
          d="M10.655 6.602a2.42 2.42 0 0 0-1.922-.935c-1.325 0-2.4 1.044-2.4 2.333 0 1.289 1.075 2.333 2.4 2.333a2.42 2.42 0 0 0 1.922-.935 3 3 0 1 1 0-2.796ZM10 9a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
        ></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H8v1h6a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H8v1h6Z"
        ></path>
      </g>
    </svg>
  );
}
export function AS() {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 4v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2Z"
        fill="#43458B"
      ></path>
      <path
        d="M15.108 13.665 0 8l15.108-5.665c.538.358.892.97.892 1.665v8c0 .695-.354 1.307-.892 1.665Z"
        fill="#E25950"
      ></path>
      <path
        d="M15.708 13.04 2.5 8l13.208-5.04c.185.303.292.659.292 1.04v8c0 .381-.107.737-.292 1.04Z"
        fill="#fff"
      ></path>
      <path
        d="M15.724 2.986C15.9 3.283 16 3.63 16 4v8c0 .381-.107.737-.292 1.04L15 12.776v-9.55l.708-.266.016.027Z"
        fill="#EAEEF3"
      ></path>
      <path
        d="M12.5 8a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
        fill="#AA5323"
      ></path>
      <path
        d="M12.5 11a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
        fill="#FFC221"
      ></path>
    </svg>
  );
}
export function AD() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#43458B"
          d="M2.005 2A2.008 2.008 0 0 0 0 4.009v7.982C0 13.101.894 14 2.005 14H5V2H2.005Z"
        ></path>
        <path
          fill="#E25950"
          d="M11 2v12h2.995A2.008 2.008 0 0 0 16 11.991V4.01C16 2.899 15.106 2 13.995 2H11Z"
        ></path>
        <path fill="#FCD669" d="M5 2h6v12H5z"></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M5 14v-1h6v1H5ZM5 2h6v1H5V2Z"
        ></path>
        <path fill="#E25950" d="M6 6h4v2.5a2 2 0 1 1-4 0V6Z"></path>
        <path fill="#FFF" d="M6 6h2v2H6V6Zm4 2.5a2 2 0 0 1-2 2V8h2v.5Z"></path>
      </g>
    </svg>
  );
}
export function AO() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <path
          fill="#333"
          d="M0 6v4.002C0 11.106.895 12 1.994 12h12.012A1.998 1.998 0 0 0 16 10.002V6H0Z"
        ></path>
        <path
          fill="#E25950"
          d="M1.994 0A1.998 1.998 0 0 0 0 1.998V6h16V1.998A1.997 1.997 0 0 0 14.006 0H1.994Z"
        ></path>
        <path
          fill="#F4B32E"
          d="M5.183 8.572a3.5 3.5 0 1 0 2.37-6.511 4 4 0 1 1-2.37 6.511Z"
        ></path>
        <circle cx="7" cy="4" r="1" fill="#F4B32E"></circle>
      </g>
    </svg>
  );
}
export function AI() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#43458B"
          fillRule="nonzero"
          d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        ></path>
        <path
          fill="#45B2E8"
          fillRule="nonzero"
          d="M10 8h4v.5a2 2 0 1 1-4 0V8Z"
        ></path>
        <path fill="#FFF" fillRule="nonzero" d="M10 6h4v3h-4z"></path>
        <path
          fill="#F4B32E"
          fillRule="nonzero"
          d="M12 8.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
        ></path>
        <path
          fill="#FFF"
          d="M1.346 5.964H0V4.036h3v-.107L.748 2.44c.267-.213.59-.36.943-.415L3 2.89V2h2v2.036h.046L8 2.08v1.04l-1.383.915H8v1.928H5v.083l2.934 1.941h-1.57L5 7.086V8H3V5.964h-.082L0 7.894v-1.04l1.346-.89Z"
        ></path>
        <path
          fill="#E25950"
          d="M3 3.93v.106H1.587L.203 3.12c.13-.265.317-.497.545-.68L3 3.93Zm2-.881L6.586 2H8v.104L5.08 4.036H5v-.987ZM2.92 5.964H3v.987L1.414 8H0v-.104l2.92-1.932Zm3.493 0L8 7.014V8h-.08L5 6.07v-.106h1.413ZM8 5.427H4.444V8h-.888V5.427H0v-.854h3.556V2h.888v2.573H8v.854Z"
        ></path>
      </g>
    </svg>
  );
}
export function AG() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <path
          fill="#333"
          d="M1.994 0A1.992 1.992 0 0 0 0 2v2h16V2c0-1.105-.895-2-1.994-2H1.994Z"
        ></path>
        <circle cx="8" cy="4" r="3" fill="#FCD669"></circle>
        <path fill="#45B2E8" d="M0 4h16v3H0z"></path>
        <path
          fill="#F6F9FC"
          d="M0 7v2a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V7H0Z"
        ></path>
        <path
          fill="#E25950"
          d="M7.253 11.16 0 3v7a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3l-7.253 8.16a1 1 0 0 1-1.494 0Z"
        ></path>
      </g>
    </svg>
  );
}
export function AR() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <path
          fill="#45B2E8"
          d="M1.994 0h12.012C15.106 0 16 .895 16 2v2H0V2C0 .888.893 0 1.994 0ZM0 8h16v2c0 1.112-.893 2-1.994 2H1.994C.894 12 0 11.105 0 10V8Z"
        ></path>
        <path fill="#F6F9FC" d="M0 4h16v4H0z"></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M15 8V4h1v4h-1ZM0 4h1v4H0V4Z"
        ></path>
        <circle cx="8" cy="6" r="1" fill="#F4B32E"></circle>
      </g>
    </svg>
  );
}
export function AM() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#E25950"
          d="M1.994 2A1.992 1.992 0 0 0 0 4v2h16V4c0-1.105-.895-2-1.994-2H1.994Z"
        ></path>
        <path fill="#555ABF" d="M0 6h16v4H0z"></path>
        <path
          fill="#FCD669"
          d="M0 10v2c0 1.105.895 2 1.994 2h12.012A1.992 1.992 0 0 0 16 12v-2H0Z"
        ></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M15 10v2a1 1 0 0 1-1 1H2c-.555 0-1-.447-1-1v-2H0v2c0 1.106.893 2 2 2h12a2 2 0 0 0 2-2v-2h-1Z"
        ></path>
      </g>
    </svg>
  );
}
export function AW() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <path fill="#F4B32E" d="M0 7h16v3H0z"></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M15 9V6h1v3h-1ZM0 6h1v3H0V6Z"
        ></path>
        <path
          fill="#217AB7"
          d="M1.994 0h12.012C15.106 0 16 .896 16 2.001V7H0V2.001A2 2 0 0 1 1.994 0ZM0 8h16v1H0V8Zm0 2h16v1c0 .556-.448 1-1 1H1c-.555 0-1-.448-1-1v-1Z"
        ></path>
        <circle cx="3.5" cy="3.5" r="1.5" fill="#E25950"></circle>
      </g>
    </svg>
  );
}
export function AC() {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 2H2a2 2 0 0 0-2 2v8c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2Z"
        fill="#43458B"
      ></path>
      <path
        d="M1.35 5.96H0V4.04h3v-.11L.75 2.44a2 2 0 0 1 .94-.41L3 2.88V2h2v2.04h.05L8 2.08v1.04l-1.38.92H8v1.92H5v.09l2.93 1.94H6.36L5 7.09V8H3V5.96h-.08L0 7.9V6.85l1.35-.89Z"
        fill="#fff"
      ></path>
      <path
        d="M3 3.93v.1H1.59L.2 3.13c.13-.26.32-.5.55-.68L3 3.93Zm2-.88L6.59 2H8v.1L5.08 4.04H5v-.99ZM2.92 5.96H3v1L1.41 8H0v-.1l2.92-1.94Zm3.5 0L8 7.01V8h-.08L5 6.07v-.1l1.42-.01ZM8 5.43H4.44V8h-.88V5.43H0v-.86h3.56V2h.88v2.57H8v.86Z"
        fill="#E25950"
      ></path>
      <path d="M12 4.97 10.25 8h3.5L12 4.97Z" fill="#159570"></path>
      <path
        d="M10.25 8 8.5 11.03 12 8.9l3.5 2.14L13.75 8h-3.5Z"
        fill="#F79A59"
      ></path>
      <path
        d="m8.5 11.03 1.75-1.07.75 1.07H8.5Zm7 0-1.75-1.07-.75 1.07h2.5Z"
        fill="#159570"
      ></path>
      <path
        d="M10.65 8h2.7v1.69a1.35 1.35 0 0 1-2.7 0V8Z"
        fill="#45B2E8"
      ></path>
      <path d="M10.65 8v1.72L12 8.9l1.35.83V8h-2.7Z" fill="#fff"></path>
    </svg>
  );
}
export function AU() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#43458B" rx="2"></rect>
        <path
          fill="#FFF"
          d="M1.346 3.964H0V2.036h3v-.107L.748.44c.267-.213.59-.36.943-.415L3 .89V0h2v2.036h.046L8 .08v1.04l-1.383.915H8v1.928H5v.083l2.934 1.941h-1.57L5 5.086V6H3V3.964h-.082L0 5.894v-1.04l1.346-.89Z"
        ></path>
        <path
          fill="#E25950"
          d="M3 1.93v.106H1.587L.203 1.12C.333.856.52.624.748.44L3 1.93Zm2-.881L6.586 0H8v.104L5.08 2.036H5v-.987ZM2.92 3.964H3v.987L1.414 6H0v-.104l2.92-1.932Zm3.493 0L8 5.014V6h-.08L5 4.07v-.106h1.413ZM8 3.427H4.444V6h-.888V3.427H0v-.854h3.556V0h.888v2.573H8v.854Z"
        ></path>
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M13.5 5a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm0 3a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm-2-5a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1ZM10 6a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm2.5 3a.5.5 0 1 1 0 1 .5.5 0 0 1 0-1Zm-8.883 1.236-.556.53c-.097.093-.253.015-.244-.12l.055-.78-.749-.118c-.13-.02-.169-.195-.06-.271l.626-.442-.378-.678c-.066-.118.042-.257.168-.217l.725.229.278-.727a.143.143 0 0 1 .27 0l.279.727.724-.23c.126-.04.234.1.169.218l-.378.678.625.442c.11.076.07.25-.06.271l-.749.119.055.779c.01.135-.147.213-.243.12l-.557-.53Z"
        ></path>
      </g>
    </svg>
  );
}
export function AT() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#E25950"
          d="M1.994 2h12.012C15.106 2 16 2.895 16 4v2H0V4c0-1.112.893-2 1.994-2ZM0 10h16v2c0 1.112-.893 2-1.994 2H1.994C.894 14 0 13.105 0 12v-2Z"
        ></path>
        <path fill="#F6F9FC" d="M0 6h16v4H0z"></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M15 10V6h1v4h-1ZM0 6h1v4H0V6Z"
        ></path>
      </g>
    </svg>
  );
}
export function AZ() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#217AB7"
          d="M1.994 2A1.992 1.992 0 0 0 0 4v2h16V4c0-1.105-.895-2-1.994-2H1.994Z"
        ></path>
        <path fill="#E25950" d="M0 6h16v4H0z"></path>
        <path
          fill="#24B47E"
          d="M0 10v2c0 1.105.895 2 1.994 2h12.012A1.992 1.992 0 0 0 16 12v-2H0Z"
        ></path>
        <path
          fill="#FFF"
          d="M8 6.268a1.75 1.75 0 1 0 0 3.464 2 2 0 1 1 0-3.464ZM9 9a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
        ></path>
      </g>
    </svg>
  );
}
export function BS() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#FCD669" rx="2"></rect>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M15 8V4h1v4z"
        ></path>
        <path
          fill="#45B2E8"
          d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v2H0V2Zm0 6h16v2c0 1.112-.893 2-1.994 2H1.994C.894 12 0 11.105 0 10V8Z"
        ></path>
        <path
          fill="#333"
          d="M.7 11.52A2.01 2.01 0 0 1 0 9.99V2.01C0 1.395.275.844.707.475a.504.504 0 0 1 .07.039L9 5.996.777 11.478a.5.5 0 0 1-.076.042Z"
        ></path>
      </g>
    </svg>
  );
}
export function BH() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#F6F9FC"
          fillRule="nonzero"
          d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        ></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M2 3a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4v1H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h4v1H2Z"
        ></path>
        <path
          fill="#E25950"
          d="M14.003 14H5l1-.5-2-1 2-1-2-1 2-1-2-1 2-1-2-1 2-1-2-1 2-1-2-1L5 2h9.003C15.104 2 16 2.9 16 4.009v7.982A2.005 2.005 0 0 1 14.003 14Z"
        ></path>
      </g>
    </svg>
  );
}
export function BD() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#159570" rx="2"></rect>
        <path
          fill="#E25950"
          fillRule="nonzero"
          d="M7 3a3 3 0 1 1 0 6 3 3 0 0 1 0-6Z"
        ></path>
      </g>
    </svg>
  );
}
export function BB() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#43458B"
          d="M2.005 2H5v12H2.005C.894 14 0 13.1 0 11.991V4.01A2.01 2.01 0 0 1 2.005 2ZM11 2h2.995C15.106 2 16 2.9 16 4.009v7.982A2.008 2.008 0 0 1 13.995 14H11V2Z"
        ></path>
        <path fill="#FCD669" d="M5 2h6v12H5z"></path>
        <path fill="#333" d="M8 10 5.5 6h5z"></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M5 14v-1h6v1H5ZM5 2h6v1H5V2Z"
        ></path>
      </g>
    </svg>
  );
}
export function BY() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <g>
          <path
            fill="#F6F9FC"
            d="M1.998 2A2.005 2.005 0 0 0 0 4.009v7.982C0 13.101.898 14 1.998 14H6V2H1.998Z"
          ></path>
          <path
            fill="#EAEEF3"
            fillRule="nonzero"
            d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h4v-1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V2H2Z"
          ></path>
          <path
            fill="#E25950"
            d="M14.14 2C15.167 2 16 3.02 16 4.276V8H6V2h8.14Z"
          ></path>
          <path
            fill="#24B47E"
            d="M16 12.257c0 .961-.832 1.743-1.86 1.743H6V8h10v4.257Z"
          ></path>
        </g>
        <path
          fill="#E25950"
          fillRule="nonzero"
          d="M1.776 4.447a.5.5 0 0 1 0-.894l2-1a.5.5 0 0 1 .448.894L3.118 4l1.106.553a.5.5 0 0 1 0 .894L3.118 6l1.106.553a.5.5 0 0 1 0 .894L3.118 8l1.106.553a.5.5 0 0 1 0 .894L3.118 10l1.106.553a.5.5 0 0 1 0 .894L3.118 12l1.106.553a.5.5 0 1 1-.448.894l-2-1a.5.5 0 0 1 0-.894L2.882 11l-1.106-.553a.5.5 0 0 1 0-.894L2.882 9l-1.106-.553a.5.5 0 0 1 0-.894L2.882 7l-1.106-.553a.5.5 0 0 1 0-.894L2.882 5l-1.106-.553Z"
        ></path>
      </g>
    </svg>
  );
}
export function BE() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#333"
          d="M2.005 2A2.008 2.008 0 0 0 0 4.009v7.982C0 13.101.894 14 2.005 14H5V2H2.005Z"
        ></path>
        <path
          fill="#E25950"
          d="M11 2v12h2.995A2.008 2.008 0 0 0 16 11.991V4.01C16 2.899 15.106 2 13.995 2H11Z"
        ></path>
        <path fill="#FCD669" d="M5 2h6v12H5z"></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M5 14v-1h6v1H5ZM5 2h6v1H5V2Z"
        ></path>
      </g>
    </svg>
  );
}
export function BZ() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#E25950"
          d="M1 2h14c.555 0 1 .448 1 1v1H0V3c0-.556.448-1 1-1ZM0 12h16v1c0 .556-.448 1-1 1H1c-.555 0-1-.448-1-1v-1Z"
        ></path>
        <path fill="#43458B" d="M0 4h16v8H0z"></path>
        <path fill="#FFF" d="M8 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"></path>
        <path fill="#24B47E" d="M8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"></path>
      </g>
    </svg>
  );
}
export function BJ() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#24B47E"
          d="M1.998 2A2.005 2.005 0 0 0 0 4.009v7.982C0 13.101.898 14 1.998 14H6V2H1.998Z"
        ></path>
        <path
          fill="#FCD669"
          d="M14.14 2C15.167 2 16 3.02 16 4.276V8H6V2h8.14Z"
        ></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M15 8V4a1 1 0 0 0-1-1H6V2h8a2 2 0 0 1 2 2v4h-1Z"
        ></path>
        <path
          fill="#E25950"
          d="M16 12.257c0 .961-.832 1.743-1.86 1.743H6V8h10v4.257Z"
        ></path>
      </g>
    </svg>
  );
}
export function BM() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#E25950"
          fillRule="nonzero"
          d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        ></path>
        <path fill="#43458B" d="M2 2h6v6H0V4a2 2 0 0 1 2-2Z"></path>
        <path
          fill="#24B47E"
          fillRule="nonzero"
          d="M10 8h4v.5a2 2 0 1 1-4 0V8Z"
        ></path>
        <path fill="#FFF" fillRule="nonzero" d="M10 6h4v3h-4z"></path>
        <path fill="#45B2E8" d="M11 7h2v2h-2z"></path>
        <path fill="#B1372F" d="M11.5 6h1v1h-1z"></path>
        <path
          fill="#FFF"
          d="M1.346 5.964H0V4.036h3v-.107L.748 2.44c.267-.213.59-.36.943-.415L3 2.89V2h2v2.036h.046L8 2.08v1.04l-1.383.915H8v1.928H5v.083l2.934 1.941h-1.57L5 7.086V8H3V5.964h-.082L0 7.894v-1.04l1.346-.89Z"
        ></path>
        <path
          fill="#E25950"
          d="M3 3.93v.106H1.587L.203 3.12c.13-.265.317-.497.545-.68L3 3.93Zm2-.881L6.586 2H8v.104L5.08 4.036H5v-.987ZM2.92 5.964H3v.987L1.414 8H0v-.104l2.92-1.932Zm3.493 0L8 7.014V8h-.08L5 6.07v-.106h1.413ZM8 5.427H4.444V8h-.888V5.427H0v-.854h3.556V2h.888v2.573H8v.854Z"
        ></path>
      </g>
    </svg>
  );
}
export function BT() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <g>
          <path
            fill="#FCD669"
            d="M14 14H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Z"
          ></path>
          <path
            fill="#A68527"
            fillOpacity="0.1"
            fillRule="nonzero"
            d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2ZM1 12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8Z"
          ></path>
          <path
            fill="#E25950"
            d="M2 14c-.51 0-.975-.19-1.328-.504L15.328 2.504c.412.367.672.9.672 1.496v8a2 2 0 0 1-2 2H2Z"
          ></path>
        </g>
        <path
          stroke="#FFF"
          strokeLinecap="square"
          strokeWidth="2"
          d="M5 10h2V8h2V6h2"
        ></path>
      </g>
    </svg>
  );
}
export function BO() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#E25950"
          d="M1.994 2A1.992 1.992 0 0 0 0 4v2h16V4c0-1.105-.895-2-1.994-2H1.994Z"
        ></path>
        <path fill="#FCD669" d="M0 6h16v4H0z"></path>
        <path
          fill="#B1372F"
          fillRule="nonzero"
          d="M8 6.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z"
        ></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M15 10V6h1v4h-1ZM0 6h1v4H0V6Z"
        ></path>
        <path
          fill="#24B47E"
          d="M0 10v2c0 1.105.895 2 1.994 2h12.012A1.992 1.992 0 0 0 16 12v-2H0Z"
        ></path>
      </g>
    </svg>
  );
}
export function BA() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#43458B" rx="2"></rect>
        <path fill="#FCD669" d="M2 0h12v12z"></path>
        <path
          fill="#FFF"
          d="M2 1.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm1 1a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm1 1a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm1 1a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm1 1a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm1 1a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm1 1a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm1 1a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm1 1a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm1 1a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Z"
        ></path>
        <path
          fill="#FFF"
          d="M12 11.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0ZM1 .5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Z"
          opacity="0.5"
        ></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M13 12v-1h1v1h-1ZM2 0h12v1H2V0Z"
        ></path>
      </g>
    </svg>
  );
}
export function BW() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path fill="#F6F9FC" d="M0 5h16v6H0z"></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M15 10V6h1v4h-1ZM0 6h1v4H0V6Z"
        ></path>
        <path
          fill="#45B2E8"
          d="M1.994 2h12.012C15.106 2 16 2.895 16 4v2H0V4c0-1.112.893-2 1.994-2ZM0 10h16v2c0 1.112-.893 2-1.994 2H1.994C.894 14 0 13.105 0 12v-2Z"
        ></path>
        <path fill="#333" d="M0 7h16v2H0z"></path>
      </g>
    </svg>
  );
}
export function BR() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#24B47E" rx="2"></rect>
        <path
          fill="#FCD669"
          fillRule="nonzero"
          d="M7.999 1 15 6l-7.001 5L1 5.92z"
        ></path>
        <path
          fill="#43458B"
          fillRule="nonzero"
          d="M8 3a3 3 0 1 1 0 6 3 3 0 0 1 0-6Z"
        ></path>
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M10.848 6.945a2.99 2.99 0 0 1-.561.997c-1.28-1.4-3.17-2.297-5.238-2.485a2.99 2.99 0 0 1 .384-1.01c2.076.247 4.024 1.158 5.415 2.498Z"
        ></path>
      </g>
    </svg>
  );
}
export function IO() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <g>
          <path
            fill="#43458B"
            fillRule="nonzero"
            d="M14 14H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Z"
          ></path>
          <path
            fill="#F6F9FC"
            fillRule="nonzero"
            d="M0 11h16v1H0v-1Zm14 3H2a2 2 0 0 1-1.732-1h15.464A2 2 0 0 1 14 14ZM0 9h16v1H0V9Zm0-2h16v1H0V7Zm5-2h11v1H5V5Zm0-2h10.723c.171.295.277.634.277 1H5V3Z"
          ></path>
          <path fill="#43458B" d="M2 2h6v6H0V4a2 2 0 0 1 2-2Z"></path>
          <path
            fill="#FFF"
            d="M1.346 5.964H0V4.036h3v-.107L.748 2.44c.267-.213.59-.36.943-.415L3 2.89V2h2v2.036h.046L8 2.08v1.04l-1.383.915H8v1.928H5v.083l2.934 1.941h-1.57L5 7.086V8H3V5.964h-.082L0 7.894v-1.04l1.346-.89Z"
          ></path>
          <path
            fill="#E25950"
            d="M3 3.93v.106H1.587L.203 3.12c.13-.265.317-.497.545-.68L3 3.93Zm2-.881L6.586 2H8v.104L5.08 4.036H5v-.987ZM2.92 5.964H3v.987L1.414 8H0v-.104l2.92-1.932Zm3.493 0L8 7.014V8h-.08L5 6.07v-.106h1.413ZM8 5.427H4.444V8h-.888V5.427H0v-.854h3.556V2h.888v2.573H8v.854Z"
          ></path>
          <path
            fill="#43458B"
            fillOpacity="0.1"
            fillRule="nonzero"
            d="M14 14H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Zm1-10a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4Z"
          ></path>
        </g>
        <circle cx="12.5" cy="8.5" r="1.5" fill="#159570"></circle>
      </g>
    </svg>
  );
}
export function VG() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#43458B"
          fillRule="nonzero"
          d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        ></path>
        <path
          fill="#159570"
          fillRule="nonzero"
          d="M10 6h4v2.5a2 2 0 1 1-4 0V6Z"
        ></path>
        <path
          fill="#FCD669"
          fillRule="nonzero"
          d="M10.882 10.75H9.708a2.5 2.5 0 0 0 4.583 0h-1.173a1.5 1.5 0 0 1-2.236 0Z"
        ></path>
        <path
          fill="#FFF"
          d="M1.346 5.964H0V4.036h3v-.107L.748 2.44c.267-.213.59-.36.943-.415L3 2.89V2h2v2.036h.046L8 2.08v1.04l-1.383.915H8v1.928H5v.083l2.934 1.941h-1.57L5 7.086V8H3V5.964h-.082L0 7.894v-1.04l1.346-.89Z"
        ></path>
        <path
          fill="#E25950"
          d="M3 3.93v.106H1.587L.203 3.12c.13-.265.317-.497.545-.68L3 3.93Zm2-.881L6.586 2H8v.104L5.08 4.036H5v-.987ZM2.92 5.964H3v.987L1.414 8H0v-.104l2.92-1.932Zm3.493 0L8 7.014V8h-.08L5 6.07v-.106h1.413ZM8 5.427H4.444V8h-.888V5.427H0v-.854h3.556V2h.888v2.573H8v.854Z"
        ></path>
      </g>
    </svg>
  );
}
export function BN() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FCD669"
          fillRule="nonzero"
          d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        ></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M15 7V4a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1H0a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3h-1zM1 9v3a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1h1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V9h1z"
        ></path>
        <path fill="#F6F9FC" d="m0 3.5 16 3v3l-16-3z"></path>
        <path
          fill="#EAEEF3"
          d="m15 6.313 1 .187v3l-1-.188v-3ZM1 3.688v3L0 6.5v-3l1 .188Z"
        ></path>
        <path fill="#333" d="m0 6.5 16 3v3l-16-3z"></path>
        <path
          fill="#E25950"
          fillRule="nonzero"
          d="M8 6a2 2 0 1 1 0 4 2 2 0 0 1 0-4Z"
        ></path>
      </g>
    </svg>
  );
}
export function BG() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#F6F9FC"
          d="M1.994 2A1.997 1.997 0 0 0 0 4.005V7h16V4.005A2 2 0 0 0 14.006 2H1.994Z"
        ></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M15 6V4.005A1 1 0 0 0 14.006 3H1.994A.997.997 0 0 0 1 4.005V6H0V4.005C0 2.894.893 2 1.994 2h12.012A2 2 0 0 1 16 4.005V6h-1Z"
        ></path>
        <path fill="#24B47E" d="M0 6h16v4H0z"></path>
        <path
          fill="#E25950"
          d="M0 10v2c0 1.105.895 2 1.994 2h12.012A1.992 1.992 0 0 0 16 12v-2H0Z"
        ></path>
      </g>
    </svg>
  );
}
export function BF() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#E25950"
          d="M1.994 2A1.998 1.998 0 0 0 0 3.998V8h16V3.998A1.997 1.997 0 0 0 14.006 2H1.994Z"
        ></path>
        <path
          fill="#24B47E"
          d="M0 8v4.002C0 13.106.895 14 1.994 14h12.012A1.998 1.998 0 0 0 16 12.002V8H0Z"
        ></path>
        <path
          fill="#FCD669"
          d="M7.78 9.785 5.444 11.19l.623-2.601L4 6.658l2.612-.115L7.78 4l1.168 2.543 2.612.115-2.067 1.931.623 2.601z"
        ></path>
      </g>
    </svg>
  );
}
export function BI() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#F6F9FC" rx="2"></rect>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2v-1a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1V0Zm12 0a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1V0Z"
        ></path>
        <path
          fill="#E25950"
          d="M1.994 0h12.012c.557 0 1.061.23 1.423.602L8 4.5.868.496A1.713 1.713 0 0 1 .762.43C1.1.16 1.529 0 1.994 0Zm12.012 12H1.994c-.465 0-.893-.16-1.233-.43.032-.022.068-.044.107-.066L8.001 7.5l7.13 4.004c.04.022.075.044.107.066-.339.269-.767.43-1.232.43Z"
        ></path>
        <path fill="#24B47E" d="m0 2 7 4-7 4V2Zm16 0v8L9 6l7-4Z"></path>
        <circle cx="8" cy="6" r="3" fill="#F6F9FC"></circle>
        <path
          fill="#B1372F"
          d="M8 5.5a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1ZM7 7a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm2 0a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Z"
        ></path>
      </g>
    </svg>
  );
}
export function KH() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none">
        <path fill="#E25950" d="M0 5h16v6H0z"></path>
        <path
          fill="#43458B"
          d="M0 5V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1H0Zm14 9H2a2 2 0 0 1-2-2v-1h16v1a2 2 0 0 1-2 2Z"
        ></path>
        <path
          fill="#FFF"
          d="M4 8h8v2H4V8Zm0-2h2v1H4V6Zm3 0h2v1H7V6Zm3 0h2v1h-2V6Z"
        ></path>
      </g>
    </svg>
  );
}
export function CM() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#159570"
          d="M2.005 2A2.008 2.008 0 0 0 0 4.009v7.982C0 13.101.894 14 2.005 14H5V2H2.005Z"
        ></path>
        <path
          fill="#FCD669"
          d="M11 2v12h2.995A2.008 2.008 0 0 0 16 11.991V4.01C16 2.899 15.106 2 13.995 2H11Z"
        ></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M11 3V2h3a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-3v-1h3a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1h-3Z"
        ></path>
        <path fill="#E25950" d="M5 2h6v12H5z"></path>
        <path
          fill="#FCD669"
          fillRule="nonzero"
          d="m10.296 7.167-1.458.003a.202.202 0 0 1-.193-.14l-.452-1.388a.202.202 0 0 0-.386 0l-.45 1.387a.202.202 0 0 1-.193.14h-1.46a.202.202 0 0 0-.118.368l1.18.857a.202.202 0 0 1 .073.227l-.45 1.388a.202.202 0 0 0 .312.227l1.18-.792a.202.202 0 0 1 .239 0l1.18.792a.202.202 0 0 0 .313-.227L9.161 8.62a.202.202 0 0 1 .074-.227l1.18-.857a.205.205 0 0 0-.12-.37Z"
        ></path>
      </g>
    </svg>
  );
}
export function CA() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path fill="#F6F9FC" fillRule="nonzero" d="M5 2h6v12H5z"></path>
        <path
          fill="#E25950"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M5 14v-1h6v1H5ZM5 2h6v1H5V2Z"
        ></path>
        <path
          fill="#E25950"
          fillRule="nonzero"
          d="M5 14H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h3v12Zm9 0h-3V2h3a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Z"
        ></path>
        <path
          fill="#E25950"
          d="m7.673 9.283-.999-.006.126-.585-1.3-.198.462-.855-.414-1.132 1.138.453.336-1.299.499.254.477-.916.543.903.443-.204.214 1.255 1.266-.398-.389 1.05.425.882-1.257.205.115.579-1.03-.01.006 1.418-.659.014z"
        ></path>
      </g>
    </svg>
  );
}
export function CV() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path fill="#F6F9FC" d="M0 8h16v3H0z"></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M15 11V8h1v3h-1ZM0 8h1v3H0V8Z"
        ></path>
        <path
          fill="#43458B"
          d="M1.994 2h12.012C15.106 2 16 2.896 16 4.001V8H0V4.001A2 2 0 0 1 1.994 2ZM0 11h16v1.5a1.5 1.5 0 0 1-1.505 1.5H1.505A1.498 1.498 0 0 1 0 12.5V11Z"
        ></path>
        <path fill="#E25950" d="M0 9h16v1H0z"></path>
        <path
          fill="#FCD669"
          fillRule="nonzero"
          d="M6.5 11a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm0-2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm-1 3a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm-1-4.5a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm0 5a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm-1-.5a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm2-4a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm-2 0a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm-1 1a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm0 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Z"
        ></path>
      </g>
    </svg>
  );
}
export function BQ() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
      <path fill="#F6F9FC" d="M0 6h16v4H0z"></path>
      <path fill="#EAEEF3" d="M15 10V6h1v4h-1ZM0 6h1v4H0V6Z"></path>
      <path
        fill="#E25950"
        d="M16 4v2H0V4c0-1.1.9-2 2-2h12a2 2 0 0 1 2 2Z"
      ></path>
      <path
        fill="#43458B"
        d="M0 12v-2h16v2a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2Z"
      ></path>
    </svg>
  );
}
export function KY() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#43458B"
          fillRule="nonzero"
          d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        ></path>
        <path fill="#43458B" d="M2 2h6v6H0V4a2 2 0 0 1 2-2Z"></path>
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M10 6h4v2.5a2 2 0 1 1-4 0V6Z"
        ></path>
        <path
          fill="#FCD669"
          fillRule="nonzero"
          d="M10.882 10.75H9.708a2.5 2.5 0 0 0 4.583 0h-1.173a1.5 1.5 0 0 1-2.236 0Z"
        ></path>
        <path fill="#E25950" d="M10 6h4v2h-4z"></path>
        <path
          fill="#45B2E8"
          fillRule="nonzero"
          d="M13.937 9a2 2 0 0 1-.614 1h-2.646a2 2 0 0 1-.614-1h3.874Z"
        ></path>
        <path
          fill="#FFF"
          d="M1.346 5.964H0V4.036h3v-.107L.748 2.44c.267-.213.59-.36.943-.415L3 2.89V2h2v2.036h.046L8 2.08v1.04l-1.383.915H8v1.928H5v.083l2.934 1.941h-1.57L5 7.086V8H3V5.964h-.082L0 7.894v-1.04l1.346-.89Z"
        ></path>
        <path
          fill="#E25950"
          d="M3 3.93v.106H1.587L.203 3.12c.13-.265.317-.497.545-.68L3 3.93Zm2-.881L6.586 2H8v.104L5.08 4.036H5v-.987ZM2.92 5.964H3v.987L1.414 8H0v-.104l2.92-1.932Zm3.493 0L8 7.014V8h-.08L5 6.07v-.106h1.413ZM8 5.427H4.444V8h-.888V5.427H0v-.854h3.556V2h.888v2.573H8v.854Z"
        ></path>
      </g>
    </svg>
  );
}
export function CF() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <path
          fill="#43458B"
          d="M1.994 0h12.012C15.106 0 16 .895 16 2v1H0V2C0 .888.893 0 1.994 0Z"
        ></path>
        <rect width="1" height="1" x="2" y="1" fill="#FCD669" rx="0.5"></rect>
        <path fill="#F6F9FC" d="M0 3h16v3H0z"></path>
        <path fill="#24B47E" d="M0 6h16v3H0z"></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M15 6V3h1v3h-1ZM0 3h1v3H0V3Z"
        ></path>
        <path
          fill="#FCD669"
          d="M0 9h16v1c0 1.112-.893 2-1.994 2H1.994C.894 12 0 11.105 0 10V9Z"
        ></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M15 9v1a1 1 0 0 1-1 1H2c-.555 0-1-.447-1-1V9H0v1c0 1.106.893 2 2 2h12a2 2 0 0 0 2-2V9h-1Z"
        ></path>
        <path fill="#E25950" d="M6 0h4v12H6z"></path>
      </g>
    </svg>
  );
}
export function TD() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#43458B"
          d="M2.005 2A2.008 2.008 0 0 0 0 4.009v7.982C0 13.101.894 14 2.005 14H5V2H2.005Z"
        ></path>
        <path
          fill="#E25950"
          d="M11 2v12h2.995A2.008 2.008 0 0 0 16 11.991V4.01C16 2.899 15.106 2 13.995 2H11Z"
        ></path>
        <path fill="#FCD669" d="M5 2h6v12H5z"></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M5 14v-1h6v1H5ZM5 2h6v1H5V2Z"
        ></path>
      </g>
    </svg>
  );
}
export function CL() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#F6F9FC"
          d="M8 2v6h8V3.998A1.993 1.993 0 0 0 14.002 2H8Z"
        ></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M16 8h-1V4c-.09-.598-.517-1.002-1-1H8V2h6c1.059 0 1.948.894 2 2-.052-.295.065 4.245 0 4Z"
        ></path>
        <path
          fill="#E25950"
          d="M0 8v4.002C0 13.106.895 14 1.994 14h12.012A1.998 1.998 0 0 0 16 12.002V8H0Z"
        ></path>
        <path fill="#43458B" d="M1.998 2A2 2 0 0 0 0 3.998V8h8V2H1.998Z"></path>
        <path
          fill="#FFF"
          d="m3.98 6.157-1.334.377.464-1.307L2 4.38l1.393-.19L3.98 3l.587 1.19 1.393.19-1.11.847.464 1.307z"
        ></path>
      </g>
    </svg>
  );
}
export function CN() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#E25950" rx="2"></rect>
        <path
          fill="#FCD669"
          d="M7 1.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm1 2a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm0 2a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm-1 2a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0ZM3.471 5.963 1.715 6.971l.435-1.969L.971 3.525l1.772-.098L3.47 1.9l.73 1.527 1.77.098-1.179 1.477.492 1.97-1.813-1.01Z"
        ></path>
      </g>
    </svg>
  );
}
export function CX() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#24B47E"
          d="M2 14h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2Z"
        ></path>
        <path
          fill="#FCD669"
          d="M14.245 3.155a3 3 0 0 1-3.892 4.428h.047c2.154 0 3.9-1.697 3.9-3.791 0-.217-.019-.43-.055-.637ZM6 8a2 2 0 1 1 4 0 2 2 0 0 1-4 0Z"
        ></path>
        <path
          fill="#217AB7"
          d="M14 14c.51 0 .975-.19 1.328-.504L.673 2.504C.26 2.871 0 3.404 0 4v8a2 2 0 0 0 2 2h12Z"
        ></path>
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M1.5 10a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm1 3a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm1-2a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm1-3a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Z"
        ></path>
      </g>
    </svg>
  );
}
export function CC() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#24B47E" rx="2"></rect>
        <path
          fill="#FCD669"
          d="M10 3.357a2.333 2.333 0 1 0 0 4.619 2.666 2.666 0 1 1 0-4.619ZM10.5 6a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm3-3a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm1 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm-2 5a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm-10-6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Z"
        ></path>
      </g>
    </svg>
  );
}
export function CO() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FCD669"
          d="M1.994 2A1.998 1.998 0 0 0 0 3.998V8h16V3.998A1.997 1.997 0 0 0 14.006 2H1.994Z"
        ></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M15 8V4a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v4H0V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v4h-1Z"
        ></path>
        <path fill="#43458B" d="M0 8h16v3H0z"></path>
        <path
          fill="#E25950"
          d="M0 11v1.5c0 .828.667 1.5 1.505 1.5h12.99A1.5 1.5 0 0 0 16 12.5V11H0Z"
        ></path>
      </g>
    </svg>
  );
}
export function KM() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#FCD669" rx="2"></rect>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M15 5V2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1H0a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3h-1Z"
        ></path>
        <path
          fill="#217AB7"
          d="M0 9h16v1c0 1.112-.893 2-1.994 2H1.994C.894 12 0 11.105 0 10V9Z"
        ></path>
        <path fill="#F6F9FC" d="M0 3h16v3H0z"></path>
        <path fill="#E25950" d="M0 6h16v3H0z"></path>
        <path fill="#EAEEF3" fillRule="nonzero" d="M15 6V3h1v3z"></path>
        <path
          fill="#24B47E"
          d="M.649 11.473A2.01 2.01 0 0 1 0 9.991V2.01C0 1.419.253.889.656.52A.5.5 0 0 1 .8.596l7.2 5.4-7.2 5.4a.5.5 0 0 1-.151.077Z"
        ></path>
        <path
          fill="#FFF"
          d="M4 4.268a1.75 1.75 0 1 0 0 3.464 2 2 0 1 1 0-3.464Z"
        ></path>
      </g>
    </svg>
  );
}
export function CG() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#FCD669" rx="2"></rect>
        <path
          fill="#24B47E"
          d="M.183 10.832A2.011 2.011 0 0 1 0 9.992V2a2 2 0 0 1 2-2h10L.183 10.832Z"
        ></path>
        <path
          fill="#E25950"
          d="M15.817 1.168c.117.255.183.54.183.84V10a2 2 0 0 1-2 2H4L15.817 1.168Z"
        ></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M16 2.009c0-.3-.066-.586-.183-.841l-.82.752A1 1 0 0 0 14 1h-3.09L12 0h2a2 2 0 0 1 2 2v.009ZM0 10c0 .3.066.585.183.84l.82-.751a1 1 0 0 0 .997.92h3.09l-1.09 1H2a2 2 0 0 1-2-2V10Z"
        ></path>
      </g>
    </svg>
  );
}
export function CD() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#FCD669" rx="2"></rect>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          d="M10 0h4v1h-4V0ZM3 11h4v1H3v-1ZM0 5h1v4H0V5Zm15-2h1v3h-1V3Z"
        ></path>
        <path
          fill="#E25950"
          d="M16 3 3 12H2a2 2 0 0 1-2-2V9l13.5-9h.5a2 2 0 0 1 2 2v1Z"
        ></path>
        <path
          fill="#45B2E8"
          d="M2.003 0H11.5L0 7.5V2.01A2 2 0 0 1 2.003 0ZM16 9.99c0 1.11-.9 2.01-1.992 2.01H5l11-7.5v5.49Z"
        ></path>
        <rect width="2" height="2" x="2" y="1" fill="#FCD669" rx="1"></rect>
      </g>
    </svg>
  );
}
export function CK() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#43458B" rx="2"></rect>
        <path
          fill="#FFF"
          d="M1.346 3.964H0V2.036h3v-.107L.748.44c.267-.213.59-.36.943-.415L3 .89V0h2v2.036h.046L8 .08v1.04l-1.383.915H8v1.928H5v.083l2.934 1.941h-1.57L5 5.086V6H3V3.964h-.082L0 5.894v-1.04l1.346-.89Z"
        ></path>
        <path
          fill="#E25950"
          d="M3 1.93v.106H1.587L.203 1.12C.333.856.52.624.748.44L3 1.93Zm2-.881L6.586 0H8v.104L5.08 2.036H5v-.987ZM2.92 3.964H3v.987L1.414 6H0v-.104l2.92-1.932Zm3.493 0L8 5.014V6h-.08L5 4.07v-.106h1.413ZM8 3.427H4.444V6h-.888V3.427H0v-.854h3.556V0h.888v2.573H8v.854Z"
        ></path>
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M12 9.5a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5Zm0-1a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
        ></path>
      </g>
    </svg>
  );
}
export function CR() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path fill="#F6F9FC" d="M0 5h16v6H0z"></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M15 11V5h1v6h-1ZM0 5h1v6H0V5Z"
        ></path>
        <path
          fill="#43458B"
          d="M1.505 2h12.99C15.333 2 16 2.672 16 3.5V5H0V3.5A1.5 1.5 0 0 1 1.505 2ZM0 11h16v1.5a1.5 1.5 0 0 1-1.505 1.5H1.505A1.498 1.498 0 0 1 0 12.5V11Z"
        ></path>
        <path fill="#E25950" d="M0 6h16v4H0z"></path>
      </g>
    </svg>
  );
}
export function CI() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none">
        <path fill="#F6F9FC" d="M5 2h6v12H5z"></path>
        <path fill="#EAEEF3" d="M5 14v-1h6v1H5ZM5 2h6v1H5V2Z"></path>
        <path
          fill="#F79A59"
          d="M2 2h3v12H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        ></path>
        <path
          fill="#24B47E"
          d="M14 14h-3V2h3a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Z"
        ></path>
      </g>
    </svg>
  );
}
export function HR() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#E25950"
          d="M1.994 2A1.992 1.992 0 0 0 0 4v2h16V4c0-1.105-.895-2-1.994-2H1.994Z"
        ></path>
        <path
          fill="#43458B"
          d="M0 10v2c0 1.105.895 2 1.994 2h12.012A1.992 1.992 0 0 0 16 12v-2H0Z"
        ></path>
        <path fill="#F6F9FC" d="M0 6h16v4H0z"></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M6 6h4v2.5a2 2 0 1 1-4 0V6Z"
        ></path>
        <path
          fill="#E25950"
          fillRule="nonzero"
          d="M7 7H6V6h1v1Zm2 0H8V6h1v1ZM8 8H7V7h1v1Zm2 0H9V7h1v1ZM7 9H6V8h1v1Zm2 0H8V8h1v1Zm-1 1H7V9h1v1Zm-1 .232A2.006 2.006 0 0 1 6.677 10H7v.232Zm2 0a1.99 1.99 0 0 1-1 .268V10h1v.232ZM9.937 9a2 2 0 0 1-.614 1H9V9h.937Z"
        ></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M15 10V6h1v4h-1ZM0 6h1v4H0V6Z"
        ></path>
      </g>
    </svg>
  );
}
export function CU() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#43458B" rx="2"></rect>
        <path
          fill="#43458B"
          d="M0 9h16v1c0 1.112-.893 2-1.994 2H1.994C.894 12 0 11.105 0 10V9Z"
        ></path>
        <path fill="#F6F9FC" d="M0 3h16v2H0z"></path>
        <path fill="#EAEEF3" fillRule="nonzero" d="M15 5V3h1v2z"></path>
        <path fill="#F6F9FC" d="M0 7h16v2H0z"></path>
        <path fill="#EAEEF3" fillRule="nonzero" d="M15 9V7h1v2z"></path>
        <path
          fill="#E25950"
          d="M.649 11.473A2.01 2.01 0 0 1 0 9.991V2.01C0 1.419.253.889.656.52A.5.5 0 0 1 .8.596l7.2 5.4-7.2 5.4a.5.5 0 0 1-.151.077Z"
        ></path>
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="m4.747 5.302-1.14.003a.158.158 0 0 1-.15-.11l-.353-1.084a.158.158 0 0 0-.302 0l-.351 1.084a.158.158 0 0 1-.151.11H1.16a.158.158 0 0 0-.093.286l.922.67c.055.04.079.112.057.178l-.352 1.084a.158.158 0 0 0 .245.177l.921-.618a.16.16 0 0 1 .187 0l.922.618a.158.158 0 0 0 .245-.177L3.86 6.439a.158.158 0 0 1 .057-.178l.922-.67a.16.16 0 0 0-.093-.29Z"
        ></path>
      </g>
    </svg>
  );
}
export function CW() {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="#43458B"
        d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
      ></path>
      <path fill="#FCD669" d="M0 9.5h16V11H0z"></path>
      <path
        fill="#F6F9FC"
        d="M4.92 5.51h-.74a.1.1 0 0 1-.1-.07l-.22-.7a.1.1 0 0 0-.2 0l-.22.7a.1.1 0 0 1-.1.07H2.6a.1.1 0 0 0-.06.19l.6.43a.1.1 0 0 1 .03.11l-.22.7a.1.1 0 0 0 .16.12l.59-.4a.1.1 0 0 1 .12 0l.6.4a.1.1 0 0 0 .15-.12l-.22-.7a.1.1 0 0 1 .03-.11l.6-.43a.1.1 0 0 0-.06-.19ZM2.8 3.63h-.54a.08.08 0 0 1-.07-.05L2 3.05a.08.08 0 0 0-.14 0l-.17.53a.08.08 0 0 1-.07.05h-.55a.08.08 0 0 0-.05.14l.45.32c.02.02.03.05.02.09l-.17.52a.08.08 0 0 0 .12.08l.45-.3a.08.08 0 0 1 .09 0l.44.3a.08.08 0 0 0 .12-.08l-.17-.52a.08.08 0 0 1 .03-.09l.44-.32a.08.08 0 0 0-.04-.14Z"
      ></path>
    </svg>
  );
}
export function CY() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#F6F9FC"
          fillRule="nonzero"
          d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        ></path>
        <path
          fill="#24B47E"
          fillRule="nonzero"
          d="M6.5 9a1.5 1.5 0 0 0 3 0h1A2.498 2.498 0 0 1 8 11.5 2.5 2.5 0 0 1 5.5 9h1Z"
        ></path>
        <path fill="#F79A59" d="m6 6 4-1v2L6 8z"></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M14 14H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Zm1-10a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4Z"
        ></path>
      </g>
    </svg>
  );
}
export function CZ() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#F6F9FC" rx="2"></rect>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M1.51 1.128.8.596A.5.5 0 0 0 .656.52 2.01 2.01 0 0 0 0 2.009V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v4h-1V2a1 1 0 0 0-1-1H2a.995.995 0 0 0-.49.128Z"
        ></path>
        <path
          fill="#E25950"
          d="M0 6v4.002C0 11.106.895 12 1.994 12h12.012A1.998 1.998 0 0 0 16 10.002V6H0Z"
        ></path>
        <path
          fill="#43458B"
          d="M.649 11.473A2.01 2.01 0 0 1 0 9.991V2.01C0 1.419.253.889.656.52A.5.5 0 0 1 .8.596l7.2 5.4-7.2 5.4a.5.5 0 0 1-.151.077Z"
        ></path>
      </g>
    </svg>
  );
}
export function DK() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none">
        <path
          fill="#E25950"
          d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        ></path>
        <path fill="#FFF" d="M16 9H7v5H4V9H0V6h4V2h3v4h9z"></path>
        <path
          fill="#E25950"
          d="M15 9V6h1v3h-1ZM4 13h3v1H4v-1ZM4 2h3v1H4V2ZM0 6h1v3H0V6Z"
          opacity="0.1"
        ></path>
      </g>
    </svg>
  );
}
export function DJ() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#45B2E8" rx="2"></rect>
        <path
          fill="#24B47E"
          d="M0 6v4.002C0 11.106.895 12 1.994 12h12.012A1.998 1.998 0 0 0 16 10.002V6H0Z"
        ></path>
        <path
          fill="#F6F9FC"
          d="M.7 11.52A2.01 2.01 0 0 1 0 9.99V2.01C0 1.395.275.844.707.475a.504.504 0 0 1 .07.039L9 5.996.777 11.478a.5.5 0 0 1-.076.042Z"
        ></path>
        <path
          fill="#EAEEF3"
          d="M1.619 1.075A1 1 0 0 0 1 2v8a1 1 0 0 0 .611.922l-.834.556a.5.5 0 0 1-.076.042A2.01 2.01 0 0 1 0 9.99V2.01C0 1.395.275.844.707.475a.504.504 0 0 1 .07.039l.842.561Z"
        ></path>
        <circle cx="4" cy="6" r="1" fill="#E25950"></circle>
      </g>
    </svg>
  );
}
export function DM() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#159570" rx="2"></rect>
        <path fill="#FCD669" d="M6 0h1v12H6z"></path>
        <path fill="#FCD669" d="M0 4h16v1H0z"></path>
        <path fill="#F6F9FC" d="M0 7h16v1H0z"></path>
        <path fill="#333" d="M0 5h16v2H0z"></path>
        <path fill="#333" d="M7 0h2v12H7z"></path>
        <path fill="#F6F9FC" d="M9 0h1v12H9z"></path>
        <path
          fill="#E25950"
          fillRule="nonzero"
          d="M8 3a3 3 0 1 1 0 6 3 3 0 0 1 0-6Z"
        ></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          d="M6 0h1v1H6V0Zm0 11h1v1H6v-1ZM0 4h1v1H0V4Zm15 0h1v1h-1V4Z"
        ></path>
        <path
          fill="#EAEEF3"
          d="M9 0h1v1H9V0Zm0 11h1v1H9v-1ZM0 7h1v1H0V7Zm15 0h1v1h-1V7Z"
        ></path>
      </g>
    </svg>
  );
}
export function DO() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
          <path
            fill="#E25950"
            d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
          ></path>
          <path
            fill="#43458B"
            d="M2 2h5v5H0V4a2 2 0 0 1 2-2Zm12 12H9V9h7v3a2 2 0 0 1-2 2Z"
          ></path>
          <path fill="#FFF" d="M16 9H9v5H7V9H0V7h7V2h2v5h7z"></path>
          <path
            fill="#EAEEF3"
            d="M15 9V7h1v2h-1Zm-8 4h2v1H7v-1ZM7 2h2v1H7V2ZM0 7h1v2H0V7Z"
          ></path>
        </g>
        <circle cx="8" cy="8" r="1" fill="#24B47E"></circle>
      </g>
    </svg>
  );
}
export function EC() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <path
          fill="#FCD669"
          d="M1.994 0A1.998 1.998 0 0 0 0 1.998V6h16V1.998A1.997 1.997 0 0 0 14.006 0H1.994Z"
        ></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M15 6V2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v4H0V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v4h-1Z"
        ></path>
        <path fill="#43458B" d="M0 6h16v3H0z"></path>
        <path
          fill="#E25950"
          d="M0 9v1.5c0 .828.667 1.5 1.505 1.5h12.99A1.5 1.5 0 0 0 16 10.5V9H0Z"
        ></path>
        <path
          fill="#B1372F"
          fillRule="nonzero"
          d="M6.882 4.25H5.708a2.5 2.5 0 0 1 4.583 0H9.118a1.5 1.5 0 0 0-2.236 0Z"
        ></path>
        <circle cx="8" cy="6" r="1.5" fill="#FFF"></circle>
      </g>
    </svg>
  );
}
export function EG() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#E25950"
          d="M1.994 2A1.992 1.992 0 0 0 0 4v2h16V4c0-1.105-.895-2-1.994-2H1.994Z"
        ></path>
        <path fill="#F6F9FC" d="M0 6h16v4H0z"></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M15 10V6h1v4h-1ZM0 6h1v4H0V6Z"
        ></path>
        <path
          fill="#333"
          d="M0 10v2c0 1.105.895 2 1.994 2h12.012A1.992 1.992 0 0 0 16 12v-2H0Z"
        ></path>
        <path
          fill="#FCD669"
          d="m4 6.5 3 1-3 2v-3Zm8 0v3l-3-2 3-1ZM7 8h2v1H7V8Z"
        ></path>
      </g>
    </svg>
  );
}
export function SV() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#43458B"
          d="M1.994 2h12.012C15.106 2 16 2.895 16 4v2H0V4c0-1.112.893-2 1.994-2ZM0 10h16v2c0 1.112-.893 2-1.994 2H1.994C.894 14 0 13.105 0 12v-2Z"
        ></path>
        <path fill="#F6F9FC" d="M0 6h16v4H0z"></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M15 10V6h1v4h-1ZM0 6h1v4H0V6Z"
        ></path>
        <path
          fill="#FCD669"
          fillRule="nonzero"
          d="M8 9a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
        ></path>
      </g>
    </svg>
  );
}
export function GQ() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#F6F9FC" rx="2"></rect>
        <circle cx="8" cy="6" r="1" fill="#CFD7DF" opacity="0.9"></circle>
        <path fill="#EAEEF3" fillRule="nonzero" d="M15 8V4h1v4z"></path>
        <path
          fill="#24B47E"
          d="M0 2v2h16V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2Z"
        ></path>
        <path
          fill="#E25950"
          d="M0 8v2c0 1.105.895 2 1.994 2h12.012A1.992 1.992 0 0 0 16 10V8H0Z"
        ></path>
        <path
          fill="#45B2E8"
          d="M.7 11.52A2.01 2.01 0 0 1 0 9.99V2.01C0 1.395.275.844.707.475a.504.504 0 0 1 .07.039L5 5.996.777 11.478a.5.5 0 0 1-.076.042Z"
        ></path>
      </g>
    </svg>
  );
}
export function ER() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#F6F9FC" rx="2"></rect>
        <path
          fill="#24B47E"
          d="M0 2v4h16V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2Z"
        ></path>
        <path
          fill="#45B2E8"
          d="M0 8v2c0 1.105.895 2 1.994 2h12.012A1.992 1.992 0 0 0 16 10V6H0v2Z"
        ></path>
        <path
          fill="#E25950"
          d="M.7 11.52A2.01 2.01 0 0 1 0 9.99V2.01C0 1.395.275.844.707.475a.504.504 0 0 1 .07.039L16 5.996.777 11.478a.5.5 0 0 1-.076.042Z"
        ></path>
        <path
          fill="#FCD669"
          fillRule="nonzero"
          d="M4 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 1a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z"
        ></path>
        <path fill="#FCD669" d="M3.5 4h1v4h-1z"></path>
      </g>
    </svg>
  );
}
export function EE() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#45B2E8"
          d="M1.994 2A1.992 1.992 0 0 0 0 4v2h16V4c0-1.105-.895-2-1.994-2H1.994Z"
        ></path>
        <path
          fill="#F6F9FC"
          d="M0 10v2c0 1.105.895 2 1.994 2h12.012A1.992 1.992 0 0 0 16 12v-2H0Z"
        ></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M14 14H2a2 2 0 0 1-2-2v-2h1v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2h1v2a2 2 0 0 1-2 2Z"
        ></path>
        <path fill="#333" d="M0 6h16v4H0z"></path>
      </g>
    </svg>
  );
}
export function SZ() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#555ABF"
          d="M1 2h14c.555 0 1 .448 1 1v1H0V3c0-.556.448-1 1-1ZM0 12h16v1c0 .556-.448 1-1 1H1c-.555 0-1-.448-1-1v-1Z"
        ></path>
        <path fill="#FCD669" d="M0 4h16v8H0z"></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M15 12V4h1v8h-1ZM0 4h1v8H0V4Z"
        ></path>
        <path fill="#B1372F" d="M0 5h16v6H0z"></path>
        <path fill="#333" d="M8 10V6c-2.08 0-4 .895-4 2s1.79 2 4 2Z"></path>
        <path fill="#F6F9FC" d="M8 10V6c2.08 0 4 .895 4 2s-1.79 2-4 2Z"></path>
      </g>
    </svg>
  );
}
export function ET() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#24B47E"
          d="M1.994 2A1.992 1.992 0 0 0 0 4v2h16V4c0-1.105-.895-2-1.994-2H1.994Z"
        ></path>
        <path fill="#FCD669" d="M0 6h16v4H0z"></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M15 10V6h1v4h-1ZM0 6h1v4H0V6Z"
        ></path>
        <path
          fill="#E25950"
          d="M0 10v2c0 1.105.895 2 1.994 2h12.012A1.992 1.992 0 0 0 16 12v-2H0Z"
        ></path>
        <path fill="#45B2E8" d="M8 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"></path>
        <path
          fill="#FCD669"
          fillRule="nonzero"
          d="m9.747 7.302-1.14.003a.158.158 0 0 1-.15-.11l-.353-1.084a.158.158 0 0 0-.302 0l-.351 1.084a.158.158 0 0 1-.151.11H6.16a.158.158 0 0 0-.093.286l.922.67c.055.04.079.112.057.178l-.352 1.084a.158.158 0 0 0 .245.177l.921-.618a.16.16 0 0 1 .187 0l.922.618a.158.158 0 0 0 .245-.177L8.86 8.439a.158.158 0 0 1 .057-.178l.922-.67a.16.16 0 0 0-.093-.29Z"
        ></path>
      </g>
    </svg>
  );
}
export function FK() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#43458B"
          fillRule="nonzero"
          d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        ></path>
        <path fill="#43458B" d="M2 2h6v6H0V4a2 2 0 0 1 2-2Z"></path>
        <path
          fill="#45B2E8"
          fillRule="nonzero"
          d="M10 6h4v2.5a2 2 0 1 1-4 0V6Z"
        ></path>
        <path
          fill="#FDBC72"
          fillRule="nonzero"
          d="M10.882 10.75H9.708a2.5 2.5 0 0 0 4.583 0h-1.173a1.5 1.5 0 0 1-2.236 0Z"
        ></path>
        <path
          fill="#FFF"
          d="M11 7h2v2h-2zM1.346 5.964H0V4.036h3v-.107L.748 2.44c.267-.213.59-.36.943-.415L3 2.89V2h2v2.036h.046L8 2.08v1.04l-1.383.915H8v1.928H5v.083l2.934 1.941h-1.57L5 7.086V8H3V5.964h-.082L0 7.894v-1.04l1.346-.89Z"
        ></path>
        <path
          fill="#E25950"
          d="M3 3.93v.106H1.587L.203 3.12c.13-.265.317-.497.545-.68L3 3.93Zm2-.881L6.586 2H8v.104L5.08 4.036H5v-.987ZM2.92 5.964H3v.987L1.414 8H0v-.104l2.92-1.932Zm3.493 0L8 7.014V8h-.08L5 6.07v-.106h1.413ZM8 5.427H4.444V8h-.888V5.427H0v-.854h3.556V2h.888v2.573H8v.854Z"
        ></path>
      </g>
    </svg>
  );
}
export function FO() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none">
        <path
          fill="#F6F9FC"
          d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        ></path>
        <path
          fill="#EAEEF3"
          d="M14 14H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Zm1-10a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4Z"
        ></path>
        <path fill="#43458B" d="M16 10H8v4H4v-4H0V6h4V2h4v4h8z"></path>
        <path fill="#E25950" d="M16 9H7v5H5V9H0V7h5V2h2v5h9z"></path>
      </g>
    </svg>
  );
}
export function FJ() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#45B2E8"
          fillRule="nonzero"
          d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        ></path>
        <path fill="#43458B" d="M2 2h6v6H0V4a2 2 0 0 1 2-2Z"></path>
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M10 7h4v1.5a2 2 0 1 1-4 0V7Z"
        ></path>
        <path
          fill="#E25950"
          fillRule="nonzero"
          d="M11.5 8V7H10V6h4v1h-1.5v1H14v1h-1.5v1.437a2.004 2.004 0 0 1-1 0V9H10V8h1.5Z"
        ></path>
        <path
          fill="#FFF"
          d="M1.346 5.964H0V4.036h3v-.107L.748 2.44c.267-.213.59-.36.943-.415L3 2.89V2h2v2.036h.046L8 2.08v1.04l-1.383.915H8v1.928H5v.083l2.934 1.941h-1.57L5 7.086V8H3V5.964h-.082L0 7.894v-1.04l1.346-.89Z"
        ></path>
        <path
          fill="#E25950"
          d="M3 3.93v.106H1.587L.203 3.12c.13-.265.317-.497.545-.68L3 3.93Zm2-.881L6.586 2H8v.104L5.08 4.036H5v-.987ZM2.92 5.964H3v.987L1.414 8H0v-.104l2.92-1.932Zm3.493 0L8 7.014V8h-.08L5 6.07v-.106h1.413ZM8 5.427H4.444V8h-.888V5.427H0v-.854h3.556V2h.888v2.573H8v.854Z"
        ></path>
      </g>
    </svg>
  );
}
export function FI() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none">
        <path
          fill="#F6F9FC"
          d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        ></path>
        <path
          fill="#EAEEF3"
          d="M14 14H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Zm1-10a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4Z"
        ></path>
        <path fill="#43458B" d="M16 9H7v5H4V9H0V6h4V2h3v4h9z"></path>
      </g>
    </svg>
  );
}
export function FR() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#43458B"
          d="M2.005 2A2.008 2.008 0 0 0 0 4.009v7.982C0 13.101.894 14 2.005 14H5V2H2.005Z"
        ></path>
        <path
          fill="#E25950"
          d="M11 2v12h2.995A2.008 2.008 0 0 0 16 11.991V4.01C16 2.899 15.106 2 13.995 2H11Z"
        ></path>
        <path fill="#F6F9FC" d="M5 2h6v12H5z"></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M5 14v-1h6v1H5ZM5 2h6v1H5V2Z"
        ></path>
      </g>
    </svg>
  );
}
export function GF() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FCD669"
          d="M14 2H2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2Z"
        ></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M2 14h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2ZM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4Z"
        ></path>
        <path
          fill="#24B47E"
          d="M2 2c-.51 0-.975.19-1.328.504l14.656 10.992c.412-.366.672-.9.672-1.496V4a2 2 0 0 0-2-2H2Z"
        ></path>
        <path
          fill="#E25950"
          fillRule="nonzero"
          d="m10.755 7-1.75.004a.243.243 0 0 1-.231-.168L8.232 5.17a.243.243 0 0 0-.464 0l-.54 1.664a.243.243 0 0 1-.231.168H5.246a.243.243 0 0 0-.143.441l1.415 1.03a.243.243 0 0 1 .089.272l-.54 1.665a.243.243 0 0 0 .375.272l1.415-.95a.243.243 0 0 1 .287 0l1.416.95a.243.243 0 0 0 .376-.272l-.542-1.665a.243.243 0 0 1 .088-.273l1.416-1.029A.246.246 0 0 0 10.755 7Z"
        ></path>
      </g>
    </svg>
  );
}
export function PF() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path fill="#F6F9FC" fillRule="nonzero" d="M0 5h16v6H0z"></path>
        <path
          fill="#217AB7"
          fillRule="nonzero"
          d="M8 10a2 2 0 0 1-2-2h4a2 2 0 0 1-2 2Z"
        ></path>
        <path
          fill="#FCD669"
          fillRule="nonzero"
          d="M8 6a2 2 0 0 0-2 2h4a2 2 0 0 0-2-2Z"
        ></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M15 11V5h1v6h-1ZM0 5h1v6H0V5Z"
        ></path>
        <path
          fill="#E25950"
          d="M16 4v1H0V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2ZM0 12v-1h16v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2Z"
        ></path>
      </g>
    </svg>
  );
}
export function GA() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#24B47E"
          d="M1.994 2A1.992 1.992 0 0 0 0 4v2h16V4c0-1.105-.895-2-1.994-2H1.994Z"
        ></path>
        <path fill="#FCD669" d="M0 6h16v4H0z"></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M15 10V6h1v4h-1ZM0 6h1v4H0V6Z"
        ></path>
        <path
          fill="#217AB7"
          d="M0 10v2c0 1.105.895 2 1.994 2h12.012A1.992 1.992 0 0 0 16 12v-2H0Z"
        ></path>
      </g>
    </svg>
  );
}
export function GM() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path fill="#F6F9FC" d="M0 5h16v6H0z"></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M15 11V5h1v6h-1ZM0 5h1v6H0V5Z"
        ></path>
        <path
          fill="#E25950"
          d="M1.505 2A1.5 1.5 0 0 0 0 3.5V5h16V3.5c0-.828-.667-1.5-1.505-1.5H1.505Z"
        ></path>
        <path fill="#43458B" d="M0 6h16v4H0z"></path>
        <path
          fill="#24B47E"
          d="M0 11v1.5c0 .828.667 1.5 1.505 1.5h12.99A1.5 1.5 0 0 0 16 12.5V11H0Z"
        ></path>
      </g>
    </svg>
  );
}
export function GE() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none">
        <path
          fill="#EAEEF3"
          d="M14 14H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Zm1-10a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4Z"
        ></path>
        <path fill="#E25950" d="M16 9H9v5H7V9H0V7h7V2h2v5h7z"></path>
        <path
          fill="#E25950"
          d="M3 4V3h1v1h1v1H4v1H3V5H2V4h1Zm9 0V3h1v1h1v1h-1v1h-1V5h-1V4h1Zm0 7v-1h1v1h1v1h-1v1h-1v-1h-1v-1h1Zm-9 0v-1h1v1h1v1H4v1H3v-1H2v-1h1Z"
        ></path>
      </g>
    </svg>
  );
}
export function DE() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none">
        <path fill="#E25950" d="M0 6h16v4H0z"></path>
        <path
          fill="#FCD669"
          d="M0 12.4V10h16v2.4c0 .884-.682 1.6-1.524 1.6H1.524C.682 14 0 13.284 0 12.4Z"
        ></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          d="M15 10v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-2H0v2c0 1.392.476 2 2 2h12c1.579 0 2-.733 2-2v-2h-1Z"
        ></path>
        <path
          fill="#333"
          d="M16 3.6V6H0V3.6C0 2.716.682 2 1.524 2h12.952C15.318 2 16 2.716 16 3.6Z"
        ></path>
      </g>
    </svg>
  );
}
export function GH() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#E25950"
          d="M1.994 2A1.992 1.992 0 0 0 0 4v2h16V4c0-1.105-.895-2-1.994-2H1.994Z"
        ></path>
        <path fill="#FCD669" d="M0 6h16v4H0z"></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M15 10V6h1v4h-1ZM0 6h1v4H0V6Z"
        ></path>
        <path
          fill="#24B47E"
          d="M0 10v2c0 1.105.895 2 1.994 2h12.012A1.992 1.992 0 0 0 16 12v-2H0Z"
        ></path>
        <path
          fill="#333"
          fillRule="nonzero"
          d="m10.296 7.167-1.458.003a.202.202 0 0 1-.193-.14l-.452-1.388a.202.202 0 0 0-.386 0l-.45 1.387a.202.202 0 0 1-.193.14h-1.46a.202.202 0 0 0-.118.368l1.18.857a.202.202 0 0 1 .073.227l-.45 1.388a.202.202 0 0 0 .312.227l1.18-.792a.202.202 0 0 1 .239 0l1.18.792a.202.202 0 0 0 .313-.227L9.161 8.62a.202.202 0 0 1 .074-.227l1.18-.857a.205.205 0 0 0-.12-.37Z"
        ></path>
      </g>
    </svg>
  );
}
export function GI() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#F6F9FC"
          d="M1.994 2A1.991 1.991 0 0 0 0 3.998V10h16V3.998A1.997 1.997 0 0 0 14.006 2H1.994Z"
        ></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M14 2a2 2 0 0 1 2 2v6h-1V4a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v6H0V4a2 2 0 0 1 2-2h12Z"
        ></path>
        <path
          fill="#E25950"
          d="M0 10v2c0 1.105.895 2 1.994 2h12.012A1.992 1.992 0 0 0 16 12v-2H0zm10-4V5h2v3H4V5h2v1h1V5h2v1h1z"
        ></path>
        <path
          fill="#F4B32E"
          d="M8.5 10.914V13h-1v-2.086L6.586 10 8 8.586 9.414 10l-.914.914Z"
        ></path>
      </g>
    </svg>
  );
}
export function GR() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#43458B"
          fillRule="nonzero"
          d="M14 14H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Z"
        ></path>
        <path
          fill="#F6F9FC"
          fillRule="nonzero"
          d="M0 11h16v1H0v-1Zm14 3H2a2 2 0 0 1-1.732-1h15.464A2 2 0 0 1 14 14ZM0 9h16v1H0V9Zm0-2h16v1H0V7Zm5-2h11v1H5V5Zm0-2h10.723c.171.295.277.634.277 1H5V3Z"
        ></path>
        <path fill="#FFF" d="M2 4V2h1v2h2v1H3v2H2V5H0V4z"></path>
        <path
          fill="#43458B"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M14 14H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Zm1-10a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4Z"
        ></path>
      </g>
    </svg>
  );
}
export function GL() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#F6F9FC"
          d="M1.994 2A1.998 1.998 0 0 0 0 3.998V8h16V3.998A1.997 1.997 0 0 0 14.006 2H1.994Z"
        ></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M14 2a2 2 0 0 1 2 2v4h-1V4a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v4H0V4a2 2 0 0 1 2-2h12Z"
        ></path>
        <path
          fill="#E25950"
          d="M3 8a2 2 0 1 0 4 0h9v4.002c0 1.1-.893 1.998-1.994 1.998H1.994C.894 14 0 13.106 0 12.002V8h3Z"
          opacity="0.9"
        ></path>
        <path
          fill="#E25950"
          fillRule="nonzero"
          d="M7 8H3a2 2 0 1 1 4 0Z"
        ></path>
      </g>
    </svg>
  );
}
export function GD() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#E25950" rx="2"></rect>
        <path fill="#FCD669" d="M2 2h12v8H2z"></path>
        <path
          fill="#159570"
          d="M14 2v8L8 6l6-4ZM2 2l6 4-6 4V2Zm2 3a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
        ></path>
        <path
          fill="#E25950"
          fillRule="nonzero"
          d="M8 4a2 2 0 1 1 0 4 2 2 0 0 1 0-4Z"
        ></path>
        <path
          fill="#FCD669"
          d="M8 5a1 1 0 1 1 0 2 1 1 0 0 1 0-2zM3.5 1a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0zm4 0a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0zm4 0a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0zm-8 10a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0zm4 0a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0zm4 0a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0z"
        ></path>
      </g>
    </svg>
  );
}
export function GP() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#555ABF"
          d="M2.005 2A2.008 2.008 0 0 0 0 4.009v7.982C0 13.101.894 14 2.005 14H5V2H2.005Z"
        ></path>
        <path
          fill="#E25950"
          d="M11 2v12h2.995A2.008 2.008 0 0 0 16 11.991V4.01C16 2.899 15.106 2 13.995 2H11Z"
        ></path>
        <path fill="#F6F9FC" d="M5 2h6v12H5z"></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M5 14v-1h6v1H5ZM5 2h6v1H5V2Z"
        ></path>
      </g>
    </svg>
  );
}
export function GU() {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        fill="#E25950"
      ></path>
      <path
        d="M2.5 2.5H14A1.5 1.5 0 0 1 15.5 4v8a1.5 1.5 0 0 1-1.5 1.5H2A1.5 1.5 0 0 1 .5 12V4A1.5 1.5 0 0 1 2 2.5h.5Z"
        fill="#43458B"
      ></path>
      <path d="M8 3.5c-4 3-4 6 0 9 4-3 4-6 0-9Z" fill="#E25950"></path>
      <path
        d="M6.61 10.5c.37.42.83.83 1.39 1.25.5-.37.97-.79 1.39-1.25H6.6Z"
        fill="#FCD669"
      ></path>
      <path
        d="M5.78 9.25c.18.42.46.83.83 1.25H9.4c.37-.42.65-.83.83-1.25H5.78Z"
        fill="#217AB7"
      ></path>
      <path
        d="M6.12 6.12c-.4.62-.61 1.23-.62 1.84v.08c0 .4.1.8.28 1.21h4.44c.18-.4.27-.8.28-1.21v-.08c0-.61-.22-1.22-.63-1.83H6.13Z"
        fill="#45B2E8"
      ></path>
      <path
        d="M8 4.25a7.94 7.94 0 0 0-1.88 1.87h3.76A7.94 7.94 0 0 0 8 4.25Z"
        fill="#24B47E"
      ></path>
      <path d="M7.5 6.12h1v3.13h-1V6.12Z" fill="#E25950"></path>
    </svg>
  );
}
export function GT() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#45B2E8"
          d="M2.005 2H5v12H2.005C.894 14 0 13.1 0 11.991V4.01A2.01 2.01 0 0 1 2.005 2ZM11 2h2.995C15.106 2 16 2.9 16 4.009v7.982A2.008 2.008 0 0 1 13.995 14H11V2Z"
        ></path>
        <path fill="#F6F9FC" d="M5 2h6v12H5z"></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M5 14v-1h6v1H5ZM5 2h6v1H5V2Z"
        ></path>
        <path
          fill="#24B47E"
          fillRule="nonzero"
          d="M8 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Z"
        ></path>
      </g>
    </svg>
  );
}
export function GG() {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="#F6F9FC"
        d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
      ></path>
      <path fill="#E25950" d="M0 6.5h6.5V2h3v4.5H16v3H9.5V14h-3V9.5H0z"></path>
      <path fill="#FFD300" d="M4 7.5h3.5V4h1v3.5H12v1H8.5V12h-1V8.5H4z"></path>
      <path
        fill="#EAEEF3"
        d="M14 2a2 2 0 0 1 2 2v2.5h-1V4a1 1 0 0 0-1-1H9.5V2zM2 2a2 2 0 0 0-2 2v2.5h1V4a1 1 0 0 1 1-1h4.5V2zm12 12a2 2 0 0 0 2-2V9.5h-1V12a1 1 0 0 1-1 1H9.5v1zM2 14a2 2 0 0 1-2-2V9.5h1V12a1 1 0 0 0 1 1h4.5v1z"
      ></path>
    </svg>
  );
}
export function GN() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#E25950"
          d="M2.005 2H5v12H2.005C.894 14 0 13.1 0 11.991V4.01A2.01 2.01 0 0 1 2.005 2Z"
        ></path>
        <path
          fill="#24B47E"
          d="M11 2h2.995C15.106 2 16 2.9 16 4.009v7.982A2.008 2.008 0 0 1 13.995 14H11V2Z"
        ></path>
        <path fill="#FCD669" d="M5 2h6v12H5z"></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M5 14v-1h6v1H5ZM5 2h6v1H5V2Z"
        ></path>
      </g>
    </svg>
  );
}
export function GW() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#E25950"
          d="M1.998 2A2.005 2.005 0 0 0 0 4.009v7.982C0 13.101.898 14 1.998 14H6V2H1.998Z"
        ></path>
        <path
          fill="#333"
          fillRule="nonzero"
          d="m5.296 7.167-1.458.003a.202.202 0 0 1-.193-.14l-.452-1.388a.202.202 0 0 0-.386 0l-.45 1.387a.202.202 0 0 1-.193.14H.704a.202.202 0 0 0-.118.368l1.18.857a.202.202 0 0 1 .073.227l-.45 1.388a.202.202 0 0 0 .312.227l1.18-.792a.202.202 0 0 1 .239 0l1.18.792a.202.202 0 0 0 .313-.227L4.161 8.62a.202.202 0 0 1 .074-.227l1.18-.857a.205.205 0 0 0-.12-.37Z"
        ></path>
        <path
          fill="#FCD669"
          d="M14.14 2C15.167 2 16 3.02 16 4.276V8H6V2h8.14Z"
        ></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M15 8V4a1 1 0 0 0-1-1H6V2h8a2 2 0 0 1 2 2v4h-1Z"
        ></path>
        <path
          fill="#24B47E"
          d="M16 12.257c0 .961-.832 1.743-1.86 1.743H6V8h10v4.257Z"
        ></path>
      </g>
    </svg>
  );
}
export function GY() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(-1 2)">
        <rect width="16" height="12" x="1" fill="#24B47E" rx="2"></rect>
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M0 9.991V2.01l.001-.077C.039.717 1.152-.013 2.27.417L17.988 6l-2.653.942-13.041 4.632C1.152 12.013.04 11.294 0 10.06v-.069Z"
        ></path>
        <path
          fill="#FCD669"
          d="M1 9.991V1.964c.017-.52.429-.8.935-.604L15 6 1.935 10.64c-.506.195-.918-.074-.934-.602L1 9.991Z"
        ></path>
        <path
          fill="#333"
          d="M1.664 11.488A2.01 2.01 0 0 1 1 9.99V2.01c0-.594.257-1.128.665-1.497.043.023.086.052.131.085L9 6l-7.204 5.403a1.03 1.03 0 0 1-.132.085Z"
        ></path>
        <path
          fill="#E25950"
          d="M1.16 10.781a2.012 2.012 0 0 1-.16-.79V2.01c0-.338.083-.656.23-.936L7.5 6c-3.233 2.584-5.347 4.178-6.34 4.781Z"
        ></path>
      </g>
    </svg>
  );
}
export function HT() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#43458B"
          d="M1.994 2A1.998 1.998 0 0 0 0 3.998V8h16V3.998A1.997 1.997 0 0 0 14.006 2H1.994Z"
        ></path>
        <path
          fill="#E25950"
          d="M0 8v4.002C0 13.106.895 14 1.994 14h12.012A1.998 1.998 0 0 0 16 12.002V8H0Z"
        ></path>
        <path fill="#FFF" d="M4 6h8v4H4z"></path>
        <path
          fill="#24B47E"
          d="M11.438 10H4.362c.86-.607 2.19-1 3.538-1 1.348 0 2.678.393 3.538 1ZM7 7h2v1l-.983 1L7 8V7Z"
        ></path>
        <path
          fill="#FCD669"
          d="M6 8h1v1H6V8Zm3 0h1v1H9V8ZM5 7h1v1H5V7Zm5 0h1v1h-1V7Z"
        ></path>
      </g>
    </svg>
  );
}
export function HN() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#45B2E8"
          d="M1.994 2h12.012C15.106 2 16 2.895 16 4v2H0V4c0-1.112.893-2 1.994-2ZM0 10h16v2c0 1.112-.893 2-1.994 2H1.994C.894 14 0 13.105 0 12v-2Z"
        ></path>
        <path fill="#F6F9FC" d="M0 6h16v4H0z"></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M15 10V6h1v4h-1ZM0 6h1v4H0V6Z"
        ></path>
        <path
          fill="#45B2E8"
          d="M7.5 8a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0ZM5 7a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm0 2a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm5 0a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm0-2a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Z"
        ></path>
      </g>
    </svg>
  );
}
export function HK() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#E25950"
          d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        ></path>
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M7.836 7.915c.22-.749.34-1.212 1.575-1.693 1.235-.482 2.43.792 2.589 1.496-.048.04-.13-.02-.24-.083-.135-.078-.31-.156-.499-.028-.341.23-.414.423-.815.562-.401.138-.754.006-1.11-.293-.355-.299-.943-.46-1.5.04m-.241.392c-.617.478-.987.781-2.27.445-1.282-.337-1.5-2.07-1.214-2.733.142-.012.121.51.532.524.144.005.26-.005.365-.016.197-.02.361-.04.625.041.406.124.615.438.726.888.111.451.492.928 1.236.85m.321.082c.25.738.418 1.187-.318 2.29s-2.446.745-2.979.26c.035-.14.52.05.668-.333.148-.385.097-.585.347-.929.249-.342.613-.437 1.076-.396.462.041 1.037-.163 1.206-.892m.102-.277c.78-.024 1.257-.052 2.097.974.84 1.026-.002 2.556-.623 2.925-.123-.074.201-.484-.123-.737-.324-.254-.53-.264-.786-.603-.256-.338-.24-.714-.065-1.145.174-.43.148-1.04-.5-1.414m-.482-.106c-.638-.45-1.036-.714-1.089-2.04-.053-1.325 1.54-2.04 2.258-1.96.053.133-.452.264-.346.662.106.398.266.53.266.954 0 .424-.239.716-.638.954-.398.239-.744.741-.45 1.43"
        ></path>
      </g>
    </svg>
  );
}
export function HU() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#E25950"
          d="M1.994 2A1.992 1.992 0 0 0 0 4v2h16V4c0-1.105-.895-2-1.994-2H1.994Z"
        ></path>
        <path fill="#F6F9FC" d="M0 6h16v4H0z"></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M15 10V6h1v4h-1ZM0 6h1v4H0V6Z"
        ></path>
        <path
          fill="#24B47E"
          d="M0 10v2c0 1.105.895 2 1.994 2h12.012A1.992 1.992 0 0 0 16 12v-2H0Z"
        ></path>
      </g>
    </svg>
  );
}
export function IS() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none">
        <path
          fill="#43458B"
          d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        ></path>
        <path fill="#F6F9FC" d="M16 9H7v5H4V9H0V6h4V2h3v4h9z"></path>
        <path fill="#E25950" d="M16 8H6v6H5V8H0V7h5V2h1v5h10z"></path>
        <path
          fill="#555ABF"
          d="M15 9V6h1v3h-1ZM4 13h3v1H4v-1ZM4 2h3v1H4V2ZM0 6h1v3H0V6Z"
          opacity="0.1"
        ></path>
      </g>
    </svg>
  );
}
export function IN() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#F79A59"
          d="M1.994 2A1.992 1.992 0 0 0 0 4v2h16V4c0-1.105-.895-2-1.994-2H1.994Z"
        ></path>
        <path fill="#F6F9FC" d="M0 6h16v4H0z"></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M15 10V6h1v4h-1ZM0 6h1v4H0V6Z"
        ></path>
        <path
          fill="#24B47E"
          d="M0 10v2c0 1.105.895 2 1.994 2h12.012A1.992 1.992 0 0 0 16 12v-2H0Z"
        ></path>
        <path
          fill="#555ABF"
          fillRule="nonzero"
          d="M8 9a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
        ></path>
      </g>
    </svg>
  );
}
export function ID() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#F6F9FC"
          d="M0 8v4.002C0 13.106.895 14 1.994 14h12.012A1.998 1.998 0 0 0 16 12.002V8H0Z"
        ></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M14 14H2a2 2 0 0 1-2-2V8h1v4a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V8h1v4a2 2 0 0 1-2 2Z"
        ></path>
        <path
          fill="#E25950"
          d="M1.994 2A1.998 1.998 0 0 0 0 3.998V8h16V3.998A1.997 1.997 0 0 0 14.006 2H1.994Z"
        ></path>
      </g>
    </svg>
  );
}
export function IR() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#24B47E"
          d="M1.994 2A1.992 1.992 0 0 0 0 4v2h16V4c0-1.105-.895-2-1.994-2H1.994Z"
        ></path>
        <path fill="#F6F9FC" d="M0 6h16v4H0z"></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M15 10V6h1v4h-1ZM0 6h1v4H0V6Z"
        ></path>
        <path
          fill="#E25950"
          d="M0 10v2c0 1.105.895 2 1.994 2h12.012A1.992 1.992 0 0 0 16 12v-2H0Z"
        ></path>
        <path
          fill="#E25950"
          fillRule="nonzero"
          d="M8 9a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
        ></path>
        <path
          fill="#FFF"
          d="M2 4.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm2 0a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm2 0a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm2 0a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm2 0a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm2 0a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm2 0a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm-13 7a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm2 0a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm2 0a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm2 0a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm2 0a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm2 0a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm2 0a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Z"
          opacity="0.7"
        ></path>
      </g>
    </svg>
  );
}
export function IQ() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#E25950"
          d="M1.994 2A1.992 1.992 0 0 0 0 4v2h16V4c0-1.105-.895-2-1.994-2H1.994Z"
        ></path>
        <path fill="#F6F9FC" d="M0 6h16v4H0z"></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M15 10V6h1v4h-1ZM0 6h1v4H0V6Z"
        ></path>
        <path
          fill="#333"
          d="M0 10v2c0 1.105.895 2 1.994 2h12.012A1.992 1.992 0 0 0 16 12v-2H0Z"
        ></path>
        <path
          fill="#24B47E"
          d="M5 7h1v2H5V7Zm2 0h2v2H7V7Zm3 0h1v2h-1V7Z"
        ></path>
      </g>
    </svg>
  );
}
export function IE() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none">
        <path fill="#F6F9FC" d="M5 2h6v12H5z"></path>
        <path fill="#EAEEF3" d="M5 14v-1h6v1H5ZM5 2h6v1H5V2Z"></path>
        <path
          fill="#24B47E"
          d="M2 2h3v12H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        ></path>
        <path
          fill="#F79A59"
          d="M14 14h-3V2h3a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Z"
        ></path>
      </g>
    </svg>
  );
}
export function IM() {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        fill="#E25950"
      ></path>
      <path
        d="M7.98 7.5 6.2 5.05 4 7m3.98.5L6.42 11l2.58.5m-1.02-4 3.52.5V5.06"
        stroke="#fff"
      ></path>
      <path
        d="m10.22 10.35-.37.02h-.03l-.02-.03-.14-.31-.03-.02a.06.06 0 0 0-.06 0l-.01.03-.1.33v.02l-.04.01-.37.03-.03.01-.02.02v.03l.02.03.32.17c.02.02.03.04.02.06l-.08.33v.03l.02.02a.06.06 0 0 0 .06 0l.29-.21.03-.01h.03l.31.17a.06.06 0 0 0 .06 0l.02-.03v-.03l-.14-.31v-.03l.01-.03.29-.22.01-.02v-.03c0-.01 0-.02-.02-.03h-.03Zm-4.5-3-.37.02h-.03l-.02-.03-.14-.31-.03-.02a.06.06 0 0 0-.06 0l-.01.03-.1.33v.02l-.04.01-.37.03-.03.01-.02.02v.03l.02.03.32.17c.02.02.03.04.02.06l-.08.33v.03l.02.02a.06.06 0 0 0 .06 0l.29-.21.03-.01h.03l.31.17a.06.06 0 0 0 .06 0l.02-.03v-.03l-.14-.31v-.03l.01-.03.29-.22.01-.02v-.03c0-.01 0-.02-.02-.03h-.03Zm5-2.25-.37.02h-.03l-.02-.03-.14-.31-.03-.02a.06.06 0 0 0-.06 0l-.01.03-.1.33v.02l-.04.01-.37.03-.03.01-.02.02v.03l.02.03.32.17c.02.02.03.04.02.06l-.08.33v.03l.02.02a.06.06 0 0 0 .06 0l.29-.21.03-.01h.03l.31.17a.06.06 0 0 0 .06 0l.02-.03v-.03l-.14-.31v-.03l.01-.03.29-.22.01-.02v-.03c0-.01 0-.02-.02-.03h-.03Z"
        fill="#FCD669"
      ></path>
    </svg>
  );
}
export function IL() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#43458B"
          d="M1.505 2h12.99C15.333 2 16 2.672 16 3.5V5H0V3.5A1.5 1.5 0 0 1 1.505 2ZM0 11h16v1.5a1.5 1.5 0 0 1-1.505 1.5H1.505A1.498 1.498 0 0 1 0 12.5V11Z"
        ></path>
        <path fill="#F6F9FC" d="M0 5h16v6H0z"></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M15 11V5h1v6h-1ZM0 5h1v6H0V5Z"
        ></path>
        <path
          fill="#43458B"
          d="m8.929 7.986-.643-.857h-.572l-.643.857.643.857h.572l.643-.857Zm.428 0 .643.857H8.857L8 10.27l-.857-1.428H6l.643-.857L6 7.129h1.143L8 5.7l.857 1.429H10l-.643.857Z"
        ></path>
      </g>
    </svg>
  );
}
export function IT() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none">
        <path fill="#F6F9FC" d="M5 2h6v12H5z"></path>
        <path fill="#EAEEF3" d="M5 14v-1h6v1H5ZM5 2h6v1H5V2Z"></path>
        <path
          fill="#24B47E"
          d="M2 2h3v12H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        ></path>
        <path
          fill="#E25950"
          d="M14 14h-3V2h3a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Z"
        ></path>
      </g>
    </svg>
  );
}
export function JM() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#FCD669" rx="2"></rect>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2v-1a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1V0Zm12 0a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1V0Z"
        ></path>
        <path
          fill="#24B47E"
          d="M1.994 0h12.012c.557 0 1.061.23 1.423.602L8 4.5.868.496A1.713 1.713 0 0 1 .762.43C1.1.16 1.529 0 1.994 0Zm12.012 12H1.994c-.465 0-.893-.16-1.233-.43.032-.022.068-.044.107-.066L8.001 7.5l7.13 4.004c.04.022.075.044.107.066-.339.269-.767.43-1.232.43Z"
        ></path>
        <path fill="#333" d="m0 3 6 3-6 3V3Zm16 0v6l-6-3 6-3Z"></path>
      </g>
    </svg>
  );
}
export function JP() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none">
        <path
          fill="#F6F9FC"
          d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        ></path>
        <path
          fill="#EAEEF3"
          d="M14 14H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Zm1-10a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4Z"
        ></path>
        <path fill="#E25950" d="M8 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Z"></path>
      </g>
    </svg>
  );
}
export function JE() {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.21 2.16A2 2 0 0 1 2 2h12a2 2 0 0 1 .79.16L13.67 3H2.33l-1.12-.84Zm0 11.68A2 2 0 0 0 2 14h12a2 2 0 0 0 .79-.16L13.67 13H2.33l-1.12.84Zm14.5-10.87A2 2 0 0 1 16 4v8a2 2 0 0 1-.29 1.03L15 12.5v-9l.71-.53ZM.3 2.97A2 2 0 0 0 0 4v8c0 .36.1.72.29 1.03L1 12.5v-9l-.71-.53Z"
        fill="#EAEEF3"
      ></path>
      <path
        d="M2.33 3h11.34L8 7.25 2.33 3Zm0 10h11.34L8 8.75 2.33 13ZM15 3.5v9L9 8l6-4.5Zm-14 0v9L7 8 1 3.5Z"
        fill="#F6F9FC"
      ></path>
      <path
        d="M.29 2.97a2 2 0 0 1 .92-.8L8 7.24l6.79-5.09a2 2 0 0 1 .92.8L9 8l6.71 5.03a2 2 0 0 1-.92.8L8 8.76l-6.79 5.09a2 2 0 0 1-.92-.8L7 8 .29 2.97Z"
        fill="#E25950"
      ></path>
      <path
        d="M9.96 4H6.04c.13 1.22.6 1.43 1.96 2 1.36-.57 1.83-.78 1.96-2Z"
        fill="#E25950"
      ></path>
      <path
        d="M10 2.5H6V3c0 .4.01.72.04 1h3.92c.03-.28.04-.6.04-1v-.5Z"
        fill="#FCD669"
      ></path>
    </svg>
  );
}
export function JO() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#333" rx="2"></rect>
        <path
          fill="#24B47E"
          d="M0 8v2c0 1.105.895 2 1.994 2h12.012A1.992 1.992 0 0 0 16 10V8H0Z"
        ></path>
        <path fill="#F6F9FC" d="M0 4h16v4H0z"></path>
        <path fill="#EAEEF3" fillRule="nonzero" d="M15 8V4h1v4z"></path>
        <path
          fill="#E25950"
          d="M.649 11.473A2.01 2.01 0 0 1 0 9.991V2.01C0 1.419.253.889.656.52A.5.5 0 0 1 .8.596l7.2 5.4-7.2 5.4a.5.5 0 0 1-.151.077Z"
        ></path>
        <circle cx="3" cy="6" r="1" fill="#F6F9FC"></circle>
      </g>
    </svg>
  );
}
export function KZ() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#45B2E8" rx="2"></rect>
        <path
          fill="#047A9D"
          fillOpacity="0.199"
          opacity="0.7"
          d="M1 1h4v9H1z"
        ></path>
        <path
          fill="#FCD669"
          d="M8.5 8a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5ZM2 2h2v1H2V2Zm0 2h2v1H2V4Zm0 2h2v1H2V6Zm0 2h2v1H2V8Z"
        ></path>
      </g>
    </svg>
  );
}
export function KE() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path fill="#F6F9FC" d="M0 5h16v6H0z"></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M15 11V5h1v6h-1ZM0 5h1v6H0V5Z"
        ></path>
        <path
          fill="#333"
          d="M1.505 2A1.5 1.5 0 0 0 0 3.5V5h16V3.5c0-.828-.667-1.5-1.505-1.5H1.505Z"
        ></path>
        <path fill="#E25950" d="M0 6h16v4H0z"></path>
        <path
          fill="#159570"
          d="M0 11v1.5c0 .828.667 1.5 1.505 1.5h12.99A1.5 1.5 0 0 0 16 12.5V11H0Z"
        ></path>
        <path
          fill="#E25950"
          fillRule="nonzero"
          d="M8 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Z"
        ></path>
        <path
          fill="#333"
          fillRule="nonzero"
          d="M10 5.764c.614.55 1 1.348 1 2.236 0 .888-.386 1.687-1 2.236C9.386 9.686 9 8.888 9 8c0-.888.386-1.687 1-2.236Zm-4 0c.614.55 1 1.348 1 2.236 0 .888-.386 1.687-1 2.236C5.386 9.686 5 8.888 5 8c0-.888.386-1.687 1-2.236Z"
        ></path>
      </g>
    </svg>
  );
}
export function KI() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none">
        <path
          fill="#43458B"
          d="M14 14H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Z"
        ></path>
        <path
          fill="#E25950"
          d="M0 8V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v4H0Z"
        ></path>
        <path fill="#FCD669" d="M10 8H6a2 2 0 1 1 4 0ZM6 4h4v1H6V4Z"></path>
        <path
          fill="#F6F9FC"
          d="M0 10h16v1H0v-1Zm0-2h16v1H0V8Zm.268 5A1.99 1.99 0 0 1 0 12h16c0 .364-.097.706-.268 1H.268Z"
        ></path>
        <path
          fill="#43458B"
          fillOpacity="0.1"
          d="M14 14H2a2 2 0 0 1-2-2V8h1v4a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V8h1v4a2 2 0 0 1-2 2Z"
        ></path>
      </g>
    </svg>
  );
}
export function XK() {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        fill="#43458B"
      ></path>
      <path
        d="M7.3 6.38a1 1 0 0 1 1.4 0l1.92 1.9a1 1 0 0 1 0 1.42L8.7 11.61a1 1 0 0 1-1.42 0l-1.9-1.9a1 1 0 0 1 0-1.42l1.9-1.91Z"
        fill="#FCD669"
      ></path>
      <path
        d="M4.25 5.7a.6.6 0 1 0 0-1.2.6.6 0 0 0 0 1.2Zm1.5-.45a.6.6 0 1 0 0-1.2.6.6 0 0 0 0 1.2Zm1.5-.22a.6.6 0 1 0 0-1.2.6.6 0 0 0 0 1.2Zm1.5 0a.6.6 0 1 0 0-1.2.6.6 0 0 0 0 1.2Zm1.5.22a.6.6 0 1 0 0-1.2.6.6 0 0 0 0 1.2Zm1.5.45a.6.6 0 1 0 0-1.2.6.6 0 0 0 0 1.2Z"
        fill="#F6F9FC"
      ></path>
    </svg>
  );
}
export function KW() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path fill="#24B47E" d="M2 2v4h14V4c0-1.105-.897-2-2.006-2H2Z"></path>
        <path fill="#F6F9FC" d="M7 6h9v4H7z"></path>
        <path fill="#EAEEF3" fillRule="nonzero" d="M15 10V6h1v4z"></path>
        <path
          fill="#E25950"
          d="M2 10v4h11.994A1.998 1.998 0 0 0 16 12v-2H2Z"
        ></path>
        <path
          fill="#333"
          d="M7 6v4l-4.995 4A1.996 1.996 0 0 1 0 12.008V3.992A2 2 0 0 1 2.005 2L7 6Z"
        ></path>
      </g>
    </svg>
  );
}
export function KG() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#E25950" rx="2"></rect>
        <path
          fill="#FCD669"
          d="M10.5 9a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm-5-5a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm5 0a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm-5 5a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1ZM8 3a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1ZM4.5 6.5a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1ZM8 10a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm3.5-3.5a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1ZM8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4Z"
        ></path>
      </g>
    </svg>
  );
}
export function LA() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <path
          fill="#E25950"
          d="M1.994 0h12.012C15.106 0 16 .895 16 2v2H0V2C0 .888.893 0 1.994 0ZM0 8h16v2c0 1.112-.893 2-1.994 2H1.994C.894 12 0 11.105 0 10V8Z"
        ></path>
        <path fill="#43458B" d="M0 3h16v6H0z"></path>
        <circle cx="8" cy="6" r="2" fill="#FFF"></circle>
      </g>
    </svg>
  );
}
export function LV() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#B1372F"
          d="M1.994 2h12.012A2 2 0 0 1 16 4.005V7H0V4.005C0 2.894.893 2 1.994 2ZM0 9h16v2.995A1.997 1.997 0 0 1 14.006 14H1.994A2 2 0 0 1 0 11.995V9Z"
        ></path>
        <path fill="#F6F9FC" d="M0 7h16v2H0z"></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M14.933 9V7H16v2h-1.067ZM0 7h1.067v2H0V7Z"
        ></path>
      </g>
    </svg>
  );
}
export function LB() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#E25950"
          d="M1.505 2h12.99C15.333 2 16 2.672 16 3.5V5H0V3.5A1.5 1.5 0 0 1 1.505 2ZM0 11h16v1.5a1.5 1.5 0 0 1-1.505 1.5H1.505A1.498 1.498 0 0 1 0 12.5V11Z"
        ></path>
        <path fill="#F6F9FC" d="M0 5h16v6H0z"></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M15 11V5h1v6h-1ZM0 5h1v6H0V5Z"
        ></path>
        <path fill="#24B47E" d="m8 6-2.5 4h5z"></path>
      </g>
    </svg>
  );
}
export function LS() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#217AB7"
          d="M1.994 2A1.992 1.992 0 0 0 0 4v2h16V4c0-1.105-.895-2-1.994-2H1.994Z"
        ></path>
        <path fill="#F6F9FC" d="M0 6h16v4H0z"></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M15 10V6h1v4h-1ZM0 6h1v4H0V6Z"
        ></path>
        <path
          fill="#24B47E"
          d="M0 10v2c0 1.105.895 2 1.994 2h12.012A1.992 1.992 0 0 0 16 12v-2H0Z"
        ></path>
        <path fill="#333" d="M8.047 7 6 9h4z"></path>
      </g>
    </svg>
  );
}
export function LR() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none">
        <path
          fill="#E25950"
          d="M14 14H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Z"
        ></path>
        <path
          fill="#F6F9FC"
          d="M0 11h16v1H0v-1Zm14 3H2a2 2 0 0 1-1.732-1h15.464A2 2 0 0 1 14 14ZM0 9h16v1H0V9Zm6-2h10v1H6V7Zm0-2h10v1H6V5Zm0-2h9.723c.171.295.277.634.277 1H6V3Z"
        ></path>
        <path
          fill="#E25950"
          d="M14 14H2a2 2 0 0 1-2-2V8h1v4a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H8V2h6a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Z"
          opacity="0.1"
        ></path>
        <path fill="#43458B" d="M0 8V3.714C0 2.768.796 2 1.778 2H6v6H0Z"></path>
        <path
          fill="#FFF"
          d="m4.747 4.302-1.14.003a.158.158 0 0 1-.15-.11l-.353-1.084a.158.158 0 0 0-.302 0l-.351 1.084a.158.158 0 0 1-.151.11H1.16a.158.158 0 0 0-.093.286l.922.67c.055.04.079.112.057.178l-.352 1.084a.158.158 0 0 0 .245.177l.921-.618a.16.16 0 0 1 .187 0l.922.618a.158.158 0 0 0 .245-.177L3.86 5.439a.158.158 0 0 1 .057-.178l.922-.67a.16.16 0 0 0-.093-.29Z"
        ></path>
      </g>
    </svg>
  );
}
export function LY() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path fill="#333" fillRule="nonzero" d="M0 5h16v6H0z"></path>
        <path
          fill="#FFF"
          d="M8 6.268a1.75 1.75 0 1 0 0 3.464 2 2 0 1 1 0-3.464ZM9 9a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
        ></path>
        <path
          fill="#E25950"
          fillRule="nonzero"
          d="M0 5V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1H0Z"
        ></path>
        <path
          fill="#24B47E"
          d="M14 14H2a2 2 0 0 1-2-2v-1h16v1a2 2 0 0 1-2 2Z"
        ></path>
      </g>
    </svg>
  );
}
export function LI() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#43458B"
          d="M1.994 2A1.998 1.998 0 0 0 0 3.998V8h16V3.998A1.997 1.997 0 0 0 14.006 2H1.994Z"
        ></path>
        <path
          fill="#FCD669"
          fillRule="nonzero"
          d="M6 6v1H2V6a2 2 0 1 1 4 0Z"
        ></path>
        <path
          fill="#E25950"
          d="M0 8v4.002C0 13.106.895 14 1.994 14h12.012A1.998 1.998 0 0 0 16 12.002V8H0Z"
        ></path>
      </g>
    </svg>
  );
}
export function LT() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FCD669"
          d="M1.994 2A1.992 1.992 0 0 0 0 4v2h16V4c0-1.105-.895-2-1.994-2H1.994Z"
        ></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M15 6V4a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2H0V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v2h-1Z"
        ></path>
        <path fill="#159570" d="M0 6h16v4H0z"></path>
        <path
          fill="#E25950"
          d="M0 10v2c0 1.105.895 2 1.994 2h12.012A1.992 1.992 0 0 0 16 12v-2H0Z"
        ></path>
      </g>
    </svg>
  );
}
export function LU() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none">
        <path fill="#F6F9FC" d="M0 6h16v4H0z"></path>
        <path
          fill="#EAEEF3"
          d="M14.933 10V6H16v4h-1.067ZM0 6h1.067v4H0V6Z"
        ></path>
        <path
          fill="#45B2E8"
          d="M0 12.4V10h16v2.4c0 .884-.682 1.6-1.524 1.6H1.524C.682 14 0 13.284 0 12.4Z"
        ></path>
        <path
          fill="#E25950"
          d="M16 3.6V6H0V3.6C0 2.716.682 2 1.524 2h12.952C15.318 2 16 2.716 16 3.6Z"
        ></path>
      </g>
    </svg>
  );
}
export function MO() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#159570" rx="2"></rect>
        <path
          fill="#FFF"
          d="M7.403 7.153A1.748 1.748 0 0 1 8 5a1.748 1.748 0 0 1 .623 2.093 1.748 1.748 0 0 1 2 .657 1.748 1.748 0 0 1-2.687.225A1.748 1.748 0 0 1 5.25 7.75a1.748 1.748 0 0 1 2.153-.597Z"
        ></path>
        <path
          fill="#FCD669"
          d="M5 5.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm1-1a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0ZM7.5 4a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm1.5.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm1 1a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Z"
        ></path>
      </g>
    </svg>
  );
}
export function MG() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#F6F9FC"
          d="M1.998 2A2.005 2.005 0 0 0 0 4.009v7.982C0 13.101.898 14 1.998 14H6V2H1.998Z"
        ></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M2 3a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4v1H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h4v1H2Z"
        ></path>
        <path
          fill="#E25950"
          d="M14.14 2C15.167 2 16 3.02 16 4.276V8H6V2h8.14Z"
        ></path>
        <path
          fill="#24B47E"
          d="M16 12.257c0 .961-.832 1.743-1.86 1.743H6V8h10v4.257Z"
        ></path>
      </g>
    </svg>
  );
}
export function MW() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none">
        <path fill="#E25950" d="M0 6h16v4H0z"></path>
        <path
          fill="#24B47E"
          d="M0 12.4V10h16v2.4c0 .884-.682 1.6-1.524 1.6H1.524C.682 14 0 13.284 0 12.4Z"
        ></path>
        <path
          fill="#333"
          d="M16 3.6V6H0V3.6C0 2.716.682 2 1.524 2h12.952C15.318 2 16 2.716 16 3.6Z"
        ></path>
        <path fill="#E25950" d="M5 5c0-1.105.987-2 3-2s3 .895 3 2H5Z"></path>
      </g>
    </svg>
  );
}
export function MY() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#E25950"
          fillRule="nonzero"
          d="M14 14H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Z"
        ></path>
        <path
          fill="#F6F9FC"
          fillRule="nonzero"
          d="M0 11h16v1H0v-1Zm14 3H2a2 2 0 0 1-1.732-1h15.464A2 2 0 0 1 14 14ZM0 9h16v1H0V9Zm8-2h8v1H8V7Zm0-2h8v1H8V5Zm0-2h7.723c.171.295.277.634.277 1H8V3Z"
        ></path>
        <path
          fill="#E25950"
          fillRule="nonzero"
          d="M14 14H2a2 2 0 0 1-2-2V8h1v4a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H8V2h6a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Z"
          opacity="0.1"
        ></path>
        <path
          fill="#43458B"
          fillRule="nonzero"
          d="M0 8V3.714C0 2.768.796 2 1.778 2H8v6H0Z"
        ></path>
        <path
          fill="#FCD669"
          d="M4 3.268a1.75 1.75 0 1 0 0 3.464 2 2 0 1 1 0-3.464ZM6 6a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
        ></path>
      </g>
    </svg>
  );
}
export function MV() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#B1372F" rx="2"></rect>
        <path fill="#159570" d="M3 3h10v6H3z"></path>
        <path
          fill="#FFF"
          d="M9.5 4.268a1.75 1.75 0 1 0 0 3.464 2 2 0 1 1 0-3.464Z"
        ></path>
      </g>
    </svg>
  );
}
export function ML() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#24B47E"
          d="M2.005 2H5v12H2.005C.894 14 0 13.1 0 11.991V4.01A2.01 2.01 0 0 1 2.005 2Z"
        ></path>
        <path
          fill="#E25950"
          d="M11 2h2.995C15.106 2 16 2.9 16 4.009v7.982A2.008 2.008 0 0 1 13.995 14H11V2Z"
        ></path>
        <path fill="#FCD669" d="M5 2h6v12H5z"></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M5 14v-1h6v1H5ZM5 2h6v1H5V2Z"
        ></path>
      </g>
    </svg>
  );
}
export function MT() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#F6F9FC"
          d="M1.998 2A2.005 2.005 0 0 0 0 4.009v7.982C0 13.101.887 14 1.998 14H8V2H1.998Z"
        ></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M2 3a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h6v1H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h6v1H2Z"
        ></path>
        <path
          fill="#E25950"
          d="M8 2v12h6.002A2.005 2.005 0 0 0 16 11.991V4.01C16 2.899 15.113 2 14.002 2H8Z"
        ></path>
        <path fill="#CFD7DF" d="M5 5h2v2H5v2H3V7H1V5h2V3h2z"></path>
      </g>
    </svg>
  );
}
export function MH() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#43458B"
          fillRule="nonzero"
          d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        ></path>
        <path fill="#F79A59" d="M0 11.5 16 4v3L0 12z"></path>
        <path
          fill="#F6F9FC"
          fillRule="nonzero"
          d="M16 7v3L.06 12.49A2.004 2.004 0 0 1 0 12l16-5Z"
        ></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M16 7v3l-1 .156V7.312L16 7ZM1 12.344l-.94.147A2.004 2.004 0 0 1 0 12l1-.313v.657Z"
        ></path>
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M3.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z"
        ></path>
      </g>
    </svg>
  );
}
export function MQ() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#43458B"
          d="M2.005 2A2.008 2.008 0 0 0 0 4.009v7.982C0 13.101.894 14 2.005 14H5V2H2.005Z"
        ></path>
        <path
          fill="#E25950"
          d="M11 2v12h2.995A2.008 2.008 0 0 0 16 11.991V4.01C16 2.899 15.106 2 13.995 2H11Z"
        ></path>
        <path fill="#F6F9FC" d="M5 2h6v12H5z"></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M5 14v-1h6v1H5ZM5 2h6v1H5V2Z"
        ></path>
      </g>
    </svg>
  );
}
export function MR() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#159570" rx="2"></rect>
        <path
          fill="#F4B32E"
          d="M11.464 4a4 4 0 1 1-6.929 0 3.5 3.5 0 1 0 6.929 0ZM7 3a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
        ></path>
      </g>
    </svg>
  );
}
export function MU() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#E25950"
          d="M1.994 2h12.012C15.106 2 16 2.896 16 4.001V5H0v-.999A2 2 0 0 1 1.994 2Z"
        ></path>
        <path fill="#FCD669" d="M0 8h16v3H0z"></path>
        <path fill="#43458B" d="M0 5h16v3H0z"></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M15 11V8h1v3h-1ZM0 8h1v3H0V8Z"
        ></path>
        <path
          fill="#24B47E"
          d="M0 11h16v1c0 1.112-.893 2-1.994 2H1.994C.894 14 0 13.105 0 12v-1Z"
        ></path>
      </g>
    </svg>
  );
}
export function YT() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#43458B"
          d="M2.005 2A2.008 2.008 0 0 0 0 4.009v7.982C0 13.101.894 14 2.005 14H5V2H2.005Z"
        ></path>
        <path
          fill="#E25950"
          d="M11 2v12h2.995A2.008 2.008 0 0 0 16 11.991V4.01C16 2.899 15.106 2 13.995 2H11Z"
        ></path>
        <path fill="#F6F9FC" d="M5 2h6v12H5z"></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M5 14v-1h6v1H5ZM5 2h6v1H5V2Z"
        ></path>
      </g>
    </svg>
  );
}
export function MX() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none">
        <path fill="#F6F9FC" d="M5 2h6v12H5z"></path>
        <path fill="#EAEEF3" d="M5 14v-1h6v1H5ZM5 2h6v1H5V2Z"></path>
        <path
          fill="#24B47E"
          d="M6.5 8a1.5 1.5 0 0 0 3 0h1A2.498 2.498 0 0 1 8 10.5 2.5 2.5 0 0 1 5.5 8h1ZM2 2h3v12H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        ></path>
        <path
          fill="#E25950"
          d="M14 14h-3V2h3a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Z"
        ></path>
        <path fill="#B5562B" d="M8 7a1 1 0 1 1 0 2 1 1 0 0 1 0-2Z"></path>
      </g>
    </svg>
  );
}
export function FM() {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        fill="#45B2E8"
      ></path>
      <path
        d="M8 5.08a.8.8 0 1 0 0-1.6.8.8 0 0 0 0 1.6Zm0 7.44a.8.8 0 1 0 0-1.6.8.8 0 0 0 0 1.6ZM4.5 8.8a.8.8 0 1 0 0-1.6.8.8 0 0 0 0 1.6Zm7 0a.8.8 0 1 0 0-1.6.8.8 0 0 0 0 1.6Z"
        fill="#F6F9FC"
      ></path>
    </svg>
  );
}
export function MD() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#43458B"
          d="M2.005 2A2.008 2.008 0 0 0 0 4.009v7.982C0 13.101.894 14 2.005 14H5V2H2.005Z"
        ></path>
        <path
          fill="#E25950"
          d="M11 2v12h2.995A2.008 2.008 0 0 0 16 11.991V4.01C16 2.899 15.106 2 13.995 2H11Z"
        ></path>
        <path fill="#FCD669" d="M5 2h6v12H5z"></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M5 14v-1h6v1H5ZM5 2h6v1H5V2Z"
        ></path>
        <path fill="#E25950" d="M6 6h4v2.5a2 2 0 1 1-4 0V6Z"></path>
        <path
          fill="#45B2E8"
          d="M8 10.5a2 2 0 0 1-2-2V8h4v.5a2 2 0 0 1-2 2Z"
        ></path>
        <path fill="#FCD669" d="M7 7h2L8 9z"></path>
      </g>
    </svg>
  );
}
export function MC() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#F6F9FC"
          d="M0 8v4.002C0 13.106.895 14 1.994 14h12.012A1.998 1.998 0 0 0 16 12.002V8H0Z"
        ></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M14 14H2a2 2 0 0 1-2-2V8h1v4a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V8h1v4a2 2 0 0 1-2 2Z"
        ></path>
        <path
          fill="#E25950"
          d="M1.994 2A1.998 1.998 0 0 0 0 3.998V8h16V3.998A1.997 1.997 0 0 0 14.006 2H1.994Z"
        ></path>
      </g>
    </svg>
  );
}
export function MN() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#E25950"
          d="M2.005 2H5v12H2.005C.894 14 0 13.1 0 11.991V4.01A2.01 2.01 0 0 1 2.005 2ZM11 2h2.995C15.106 2 16 2.9 16 4.009v7.982A2.008 2.008 0 0 1 13.995 14H11V2Z"
        ></path>
        <path
          fill="#FCD669"
          d="M1 8h1v4H1V8Zm1.5-1a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3ZM3 8h1v4H3V8Z"
        ></path>
        <path fill="#45B2E8" d="M5 2h6v12H5z"></path>
      </g>
    </svg>
  );
}
export function ME() {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        fill="#FCD669"
      ></path>
      <path
        d="M2.5 2.5H14A1.5 1.5 0 0 1 15.5 4v8a1.5 1.5 0 0 1-1.5 1.5H2A1.5 1.5 0 0 1 .5 12V4A1.5 1.5 0 0 1 2 2.5h.5Z"
        fill="#E25950"
      ></path>
      <path
        d="M4.8 5c0 1.95 1.6 2.9 3.2 2.9 1.6-.02 3.2-1 3.2-2.9 2.03 2.57-.06 5.79-3.2 5.81-3.17.02-5.23-3.23-3.2-5.81Z"
        fill="#FCD669"
      ></path>
      <circle cx="8" cy="5" r="1" fill="#FCD669"></circle>
      <circle cx="8" cy="11" r="1" fill="#FCD669"></circle>
      <circle cx="8" cy="9" r="1" fill="#24B47E"></circle>
      <circle cx="8" cy="8" r="1" fill="#43458B"></circle>
    </svg>
  );
}
export function MS() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#43458B"
          fillRule="nonzero"
          d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        ></path>
        <path fill="#43458B" d="M2 2h6v6H0V4a2 2 0 0 1 2-2Z"></path>
        <path
          fill="#E37C4C"
          fillRule="nonzero"
          d="M10 6h4v2.5a2 2 0 1 1-4 0V6Z"
        ></path>
        <path fill="#45B2E8" d="M10 6h4v2h-4z"></path>
        <path
          fill="#FFF"
          d="M1.346 5.964H0V4.036h3v-.107L.748 2.44c.267-.213.59-.36.943-.415L3 2.89V2h2v2.036h.046L8 2.08v1.04l-1.383.915H8v1.928H5v.083l2.934 1.941h-1.57L5 7.086V8H3V5.964h-.082L0 7.894v-1.04l1.346-.89Z"
        ></path>
        <path
          fill="#E25950"
          d="M3 3.93v.106H1.587L.203 3.12c.13-.265.317-.497.545-.68L3 3.93Zm2-.881L6.586 2H8v.104L5.08 4.036H5v-.987ZM2.92 5.964H3v.987L1.414 8H0v-.104l2.92-1.932Zm3.493 0L8 7.014V8h-.08L5 6.07v-.106h1.413ZM8 5.427H4.444V8h-.888V5.427H0v-.854h3.556V2h.888v2.573H8v.854Z"
        ></path>
      </g>
    </svg>
  );
}
export function MA() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#B1372F" rx="2"></rect>
        <path
          fill="#159570"
          fillRule="nonzero"
          d="m10.755 5-1.75.004a.243.243 0 0 1-.231-.168L8.232 3.17a.243.243 0 0 0-.464 0l-.54 1.664a.243.243 0 0 1-.231.168H5.246a.243.243 0 0 0-.143.441l1.415 1.03a.243.243 0 0 1 .089.272l-.54 1.665a.243.243 0 0 0 .375.272l1.415-.95a.243.243 0 0 1 .287 0l1.416.95a.243.243 0 0 0 .376-.272l-.542-1.665a.243.243 0 0 1 .088-.273l1.416-1.029A.246.246 0 0 0 10.755 5Z"
        ></path>
      </g>
    </svg>
  );
}
export function MZ() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#159570" rx="2"></rect>
        <path
          fill="#FCD669"
          d="M0 9h16v1c0 1.112-.5 2-1.994 2H1.994C.894 12 0 11.105 0 10V9Z"
        ></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M15 9v1a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V8H0v2c0 .5.5 2 2 2h12c1.579 0 2-.733 2-2V9h-1Z"
        ></path>
        <path fill="#F6F9FC" d="M0 3h16v1H0V3Zm0 5h16v1H0V8Z"></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M15 4V3h1v1h-1Zm0 5V8h1v1h-1Z"
        ></path>
        <path fill="#333" d="M0 4h16v4H0z"></path>
        <path
          fill="#E25950"
          d="M.649 11.473A2.01 2.01 0 0 1 0 9.991V2.01C0 1.419.253.889.656.52A.5.5 0 0 1 .8.596l7.2 5.4-7.2 5.4a.5.5 0 0 1-.151.077Z"
        ></path>
        <path
          fill="#FCD669"
          fillRule="nonzero"
          d="m4.747 5.302-1.14.003a.158.158 0 0 1-.15-.11l-.353-1.084a.158.158 0 0 0-.302 0l-.351 1.084a.158.158 0 0 1-.151.11H1.16a.158.158 0 0 0-.093.286l.922.67c.055.04.079.112.057.178l-.352 1.084a.158.158 0 0 0 .245.177l.921-.618a.16.16 0 0 1 .187 0l.922.618a.158.158 0 0 0 .245-.177L3.86 6.439a.158.158 0 0 1 .057-.178l.922-.67a.16.16 0 0 0-.093-.29Z"
        ></path>
      </g>
    </svg>
  );
}
export function MM() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FCD669"
          d="M1.994 2A1.992 1.992 0 0 0 0 4v2h16V4c0-1.105-.895-2-1.994-2H1.994Z"
        ></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M15 6V4a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2H0V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v2h-1Z"
        ></path>
        <path fill="#24B47E" d="M0 6h16v4H0z"></path>
        <path
          fill="#E25950"
          d="M0 10v2c0 1.105.895 2 1.994 2h12.012A1.992 1.992 0 0 0 16 12v-2H0Z"
        ></path>
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="m10.755 7-1.75.004a.243.243 0 0 1-.231-.168L8.232 5.17a.243.243 0 0 0-.464 0l-.54 1.664a.243.243 0 0 1-.231.168H5.246a.243.243 0 0 0-.143.441l1.415 1.03a.243.243 0 0 1 .089.272l-.54 1.665a.243.243 0 0 0 .375.272l1.415-.95a.243.243 0 0 1 .287 0l1.416.95a.243.243 0 0 0 .376-.272l-.542-1.665a.243.243 0 0 1 .088-.273l1.416-1.029A.246.246 0 0 0 10.755 7Z"
        ></path>
      </g>
    </svg>
  );
}
export function NA() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#F6F9FC" rx="2"></rect>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M0 6h1v4a1 1 0 0 0 1 1h5v1H2a2 2 0 0 1-2-2V6Zm16 0h-1V2a1 1 0 0 0-1-1H9V0h5a2 2 0 0 1 2 2v4Z"
        ></path>
        <path
          fill="#E25950"
          d="M16 3 3 12H2a2 2 0 0 1-2-2V9l13.5-9h.5a2 2 0 0 1 2 2v1Z"
        ></path>
        <path
          fill="#43458B"
          d="M2.003 0A2 2 0 0 0 0 2.01V7.5L11.5 0H2.003Z"
        ></path>
        <path
          fill="#24B47E"
          d="M16 9.99c0 1.11-.9 2.01-1.992 2.01H5l11-7.5v5.49Z"
        ></path>
        <path fill="#FCD669" d="M2 2h2v2H2z"></path>
      </g>
    </svg>
  );
}
export function NR() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <path
          fill="#43458B"
          d="M1.994 0h12.012A2 2 0 0 1 16 2.005V5H0V2.005C0 .894.893 0 1.994 0ZM0 7h16v2.995A1.997 1.997 0 0 1 14.006 12H1.994A2 2 0 0 1 0 9.995V7Z"
        ></path>
        <circle cx="4.5" cy="9.5" r="1.5" fill="#FFF"></circle>
        <path fill="#FCD669" d="M0 5h16v2H0z"></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M14.933 7V5H16v2h-1.067ZM0 5h1.067v2H0V5Z"
        ></path>
      </g>
    </svg>
  );
}
export function NP() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 16 16"
    >
      <defs>
        <path
          id="fi_FlagIcon-NP__a"
          d="M2 12a2 2 0 0 1-2-2V2A2 2 0 0 1 1.514.06L9.22 5.454A.3.3 0 0 1 9.048 6H4.545l4.999 5.498a.3.3 0 0 1-.222.502H2Z"
        ></path>
      </defs>
      <g fill="none" transform="translate(0 2)">
        <path
          fill="#F6F9FC"
          d="M2 0h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2Z"
        ></path>
        <path
          fill="#EAEEF3"
          d="M14 12H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Zm1-10a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2Z"
        ></path>
        <use
          fill="#E25950"
          fillRule="evenodd"
          xlinkHref="#fi_FlagIcon-NP__a"
        ></use>
        <path
          stroke="#43458B"
          d="m8.87 11.5-5.455-6h4.999L1.43.612C.878.838.5 1.382.5 2v8A1.5 1.5 0 0 0 2 11.5h6.87Z"
        ></path>
        <path
          fill="#FFF"
          d="M3 8a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm-.5-5a.5.5 0 1 1 0 1 .5.5 0 0 1 0-1z"
        ></path>
      </g>
    </svg>
  );
}
export function NL() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path fill="#F6F9FC" fillRule="nonzero" d="M0 6h16v4H0z"></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M15 10V6h1v4h-1ZM0 6h1v4H0V6Z"
        ></path>
        <path
          fill="#E25950"
          d="M16 4v2H0V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2Z"
        ></path>
        <path
          fill="#43458B"
          fillRule="nonzero"
          d="M0 12v-2h16v2a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2Z"
        ></path>
      </g>
    </svg>
  );
}
export function NC() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#217AB7"
          d="M1.994 2A1.992 1.992 0 0 0 0 4v2h16V4c0-1.105-.895-2-1.994-2H1.994Z"
        ></path>
        <path fill="#E25950" d="M0 6h16v4H0z"></path>
        <path
          fill="#24B47E"
          d="M0 10v2c0 1.105.895 2 1.994 2h12.012A1.992 1.992 0 0 0 16 12v-2H0Z"
        ></path>
        <path
          fill="#FCD669"
          fillRule="nonzero"
          d="M5 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Z"
        ></path>
        <path
          fill="#333"
          fillRule="nonzero"
          d="M5 6a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0-1a3 3 0 1 1 0 6 3 3 0 0 1 0-6Z"
        ></path>
      </g>
    </svg>
  );
}
export function NZ() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#43458B"
          fillRule="nonzero"
          d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        ></path>
        <path
          fill="#FFF"
          d="M1.346 5.964H0V4.036h3v-.107L.748 2.44c.267-.213.59-.36.943-.415L3 2.89V2h2v2.036h.046L8 2.08v1.04l-1.383.915H8v1.928H5v.083l2.934 1.941h-1.57L5 7.086V8H3V5.964h-.082L0 7.894v-1.04l1.346-.89Z"
        ></path>
        <path
          fill="#E25950"
          d="M3 3.93v.106H1.587L.203 3.12c.13-.265.317-.497.545-.68L3 3.93Zm2-.881L6.586 2H8v.104L5.08 4.036H5v-.987ZM2.92 5.964H3v.987L1.414 8H0v-.104l2.92-1.932Zm3.493 0L8 7.014V8h-.08L5 6.07v-.106h1.413ZM8 5.427H4.444V8h-.888V5.427H0v-.854h3.556V2h.888v2.573H8v.854Z"
        ></path>
        <path
          fill="#E25950"
          fillRule="nonzero"
          d="M14 6.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm-2 5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm.067-6.75a.5.5 0 1 1 .866-.5.5.5 0 0 1-.866.5ZM11 6.5c0-.276.22-.5.49-.5a.489.489 0 0 1 .441.245.508.508 0 0 1 0 .51.489.489 0 0 1-.44.245.495.495 0 0 1-.491-.5Z"
        ></path>
      </g>
    </svg>
  );
}
export function NI() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#217AB7"
          d="M1.994 2h12.012C15.106 2 16 2.895 16 4v2H0V4c0-1.112.893-2 1.994-2ZM0 10h16v2c0 1.112-.893 2-1.994 2H1.994C.894 14 0 13.105 0 12v-2Z"
        ></path>
        <path fill="#F6F9FC" d="M0 6h16v4H0z"></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M15 10V6h1v4h-1ZM0 6h1v4H0V6Z"
        ></path>
        <path
          fill="#FCD669"
          fillRule="nonzero"
          d="M8 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Z"
        ></path>
      </g>
    </svg>
  );
}
export function NE() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <path
          fill="#E37C4C"
          d="M1.994 0h12.012C15.106 0 16 .895 16 2v2H0V2C0 .888.893 0 1.994 0Z"
        ></path>
        <path fill="#F6F9FC" d="M0 4h16v4H0z"></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M15 8V4h1v4h-1ZM0 4h1v4H0V4Z"
        ></path>
        <path
          fill="#24B47E"
          d="M0 8h16v2c0 1.112-.893 2-1.994 2H1.994C.894 12 0 11.105 0 10V8Z"
        ></path>
        <circle cx="8" cy="6" r="1" fill="#E37C4C"></circle>
      </g>
    </svg>
  );
}
export function NG() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#24B47E"
          d="M2.005 2H5v12H2.005C.894 14 0 13.1 0 11.991V4.01A2.01 2.01 0 0 1 2.005 2ZM11 2h2.995C15.106 2 16 2.9 16 4.009v7.982A2.008 2.008 0 0 1 13.995 14H11V2Z"
        ></path>
        <path fill="#F6F9FC" d="M5 2h6v12H5z"></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M5 14v-1h6v1H5ZM5 2h6v1H5V2Z"
        ></path>
      </g>
    </svg>
  );
}
export function NU() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FCD669"
          fillRule="nonzero"
          d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        ></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M14 14H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Zm1-10a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4Z"
        ></path>
        <path fill="#43458B" d="M2 2h6v6H0V4a2 2 0 0 1 2-2Z"></path>
        <path
          fill="#FFF"
          d="M1.346 5.964H0V4.036h3v-.107L.748 2.44c.267-.213.59-.36.943-.415L3 2.89V2h2v2.036h.046L8 2.08v1.04l-1.383.915H8v1.928H5v.083l2.934 1.941h-1.57L5 7.086V8H3V5.964h-.082L0 7.894v-1.04l1.346-.89Z"
        ></path>
        <path
          fill="#E25950"
          d="M3 3.93v.106H1.587L.203 3.12c.13-.265.317-.497.545-.68L3 3.93Zm2-.881L6.586 2H8v.104L5.08 4.036H5v-.987ZM2.92 5.964H3v.987L1.414 8H0v-.104l2.92-1.932Zm3.493 0L8 7.014V8h-.08L5 6.07v-.106h1.413ZM8 5.427H4.444V8h-.888V5.427H0v-.854h3.556V2h.888v2.573H8v.854Z"
        ></path>
      </g>
    </svg>
  );
}
export function NF() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#24B47E"
          d="M2.005 2H5v12H2.005C.894 14 0 13.1 0 11.991V4.01A2.01 2.01 0 0 1 2.005 2ZM11 2h2.995C15.106 2 16 2.9 16 4.009v7.982A2.008 2.008 0 0 1 13.995 14H11V2Z"
        ></path>
        <path fill="#F6F9FC" d="M5 2h6v12H5z"></path>
        <path fill="#159570" d="M9 10v1H7v-1H5.5L8 4l2.5 6H9Z"></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M5 14v-1h6v1H5ZM5 2h6v1H5V2Z"
        ></path>
      </g>
    </svg>
  );
}
export function KP() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#43458B"
          d="M1 2h14c.555 0 1 .448 1 1v1H0V3c0-.556.448-1 1-1ZM0 12h16v1c0 .556-.448 1-1 1H1c-.555 0-1-.448-1-1v-1Z"
        ></path>
        <path fill="#F6F9FC" d="M0 4h16v8H0z"></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M15 12V4h1v8h-1ZM0 4h1v8H0V4Z"
        ></path>
        <path fill="#E25950" d="M0 5h16v6H0z"></path>
        <path fill="#FFF" d="M5 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"></path>
      </g>
    </svg>
  );
}
export function MK() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 16 16"
    >
      <defs>
        <path
          id="fi_FlagIcon-MK__a"
          d="M1.24.15A1.95 1.95 0 0 1 1.994 0H6.75l.75 3h1l.75-3h4.756c.267 0 .523.053.756.15L10 3.139l.47.883L16 2v2.5l-5 1v1l5 1v2.49l-5.53-1.873L10 9l4.755 2.853a1.972 1.972 0 0 1-.75.147H9.5l-1-3h-1l-1 3H1.994a1.96 1.96 0 0 1-.755-.15l4.797-2.986-.47-.883L0 9.99V7.5l5-1v-1l-5-1V2l5.567 2.022.469-.883L1.239.149ZM8 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
        ></path>
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#FCD669" rx="2"></rect>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          d="M1.994 0h12.012C15.106 0 16 .9 16 2.009V9.99A2.003 2.003 0 0 1 14.006 12H1.994C.894 12 0 11.1 0 9.991V2.01C0 .902.893 0 1.994 0Zm.013 1C1.45 1 1 1.456 1 1.995v8.01A1 1 0 0 0 2.007 11h11.986c.556 0 1.007-.456 1.007-.995v-8.01A1 1 0 0 0 13.993 1H2.007Z"
        ></path>
        <use fill="#E25950" xlinkHref="#fi_FlagIcon-MK__a"></use>
      </g>
    </svg>
  );
}
export function MP() {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        fill="#43458B"
      ></path>
      <path
        d="M3 8a5 5 0 1 0 10 0A5 5 0 0 0 3 8Zm1 0a4 4 0 1 1 8 0 4 4 0 0 1-8 0Z"
        fill="#FCD669"
      ></path>
      <path
        fillRule="evenodd"
        d="M9.5 5c0 .5 0 1-.5 1.5l.75 5.5c0 .55-.75 1-1.75 1s-1.75-.45-1.75-1L7 6.5c-.5-.5-.5-1-.5-1.5h3Z"
        fill="#6D71A4"
      ></path>
      <path
        d="M7.91 6.44a.1.1 0 0 1 .18 0l.56 1.71a.1.1 0 0 0 .08.06h1.8a.1.1 0 0 1 .06.17L9.13 9.44a.1.1 0 0 0-.03.1l.56 1.72a.1.1 0 0 1-.15.1l-1.46-1.05a.1.1 0 0 0-.1 0l-1.46 1.06a.1.1 0 0 1-.15-.1l.56-1.72a.1.1 0 0 0-.03-.1L5.4 8.37a.1.1 0 0 1 .05-.17h1.8a.1.1 0 0 0 .1-.06l.55-1.71Z"
        fill="#F6F9FC"
      ></path>
      <path
        fillRule="evenodd"
        d="M7.35 8.15a.1.1 0 0 1-.08.06h-1.8a.1.1 0 0 0-.06.17l1.46 1.06a.1.1 0 0 1 .03.1l-.56 1.72a.1.1 0 0 0 .15.1l1.46-1.05a.1.1 0 0 1 .1 0l1.46 1.06a.1.1 0 0 0 .15-.1L9.1 9.54a.1.1 0 0 1 .03-.1l1.46-1.07a.1.1 0 0 0-.05-.17h-1.8a.1.1 0 0 1-.1-.06l-.54-1.7a.1.1 0 0 0-.18 0l-.56 1.71Zm1.59-.3-.5-1.53a.46.46 0 0 0-.88 0l-.5 1.52h-1.6a.46.46 0 0 0-.27.84l1.3.94-.5 1.53c-.14.43.35.78.71.52l1.3-.95 1.3.95c.36.26.85-.1.71-.52l-.5-1.53 1.3-.94a.46.46 0 0 0-.27-.84h-1.6Z"
        fill="#43458B"
      ></path>
    </svg>
  );
}
export function NO() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none">
        <path
          fill="#E25950"
          d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        ></path>
        <path fill="#F6F9FC" d="M16 9H7v5H4V9H0V6h4V2h3v4h9z"></path>
        <path fill="#43458B" d="M16 8H6v6H5V8H0V7h5V2h1v5h10z"></path>
        <path
          fill="#555ABF"
          d="M15 9V6h1v3h-1ZM4 13h3v1H4v-1ZM4 2h3v1H4V2ZM0 6h1v3H0V6Z"
          opacity="0.1"
        ></path>
      </g>
    </svg>
  );
}
export function OM() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#F6F9FC"
          d="M4 2h9.991C15.098 2 16 2.895 16 4v2H4V2Z"
        ></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M15 6V4a1 1 0 0 0-1-1H4V2h10a2 2 0 0 1 2 2v2h-1Z"
        ></path>
        <path
          fill="#E25950"
          d="M2 2h2v12H2a2 2 0 0 1-2-2.009V4.01C0 2.902.895 2 2 2Z"
        ></path>
        <path fill="#B1372F" fillOpacity="0.2" d="M3 2h1v12H3z"></path>
        <path fill="#E25950" d="M4 6h12v4H4z"></path>
        <path fill="#24B47E" d="M4 10h12v2a2 2 0 0 1-2.009 2H4v-4Z"></path>
        <path fill="#FFF" d="M1 4a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"></path>
      </g>
    </svg>
  );
}
export function PK() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#F6F9FC"
          fillRule="nonzero"
          d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        ></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M2 3a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h3v1H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h3v1H2Z"
        ></path>
        <path
          fill="#159570"
          d="M5 2v12h9.008c1.1 0 1.992-.902 1.992-2.009V4.01C16 2.899 15.1 2 14.008 2H5Z"
        ></path>
        <path
          fill="#FFF"
          d="M8.678 4.743a4.5 4.5 0 0 0 5.58 5.58 4 4 0 1 1-5.58-5.58ZM13 7a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
        ></path>
      </g>
    </svg>
  );
}
export function PW() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#45B2E8" rx="2"></rect>
        <circle cx="6" cy="6" r="3" fill="#FCD669"></circle>
      </g>
    </svg>
  );
}
export function PS() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#333" rx="2"></rect>
        <path
          fill="#24B47E"
          d="M0 8v2c0 1.105.895 2 1.994 2h12.012A1.992 1.992 0 0 0 16 10V8H0Z"
        ></path>
        <path fill="#F6F9FC" d="M0 4h16v4H0z"></path>
        <path fill="#EAEEF3" fillRule="nonzero" d="M15 8V4h1v4z"></path>
        <path
          fill="#E25950"
          d="M.649 11.473A2.01 2.01 0 0 1 0 9.991V2.01C0 1.419.253.889.656.52A.5.5 0 0 1 .8.596l7.2 5.4-7.2 5.4a.5.5 0 0 1-.151.077Z"
        ></path>
      </g>
    </svg>
  );
}
export function PA() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#F6F9FC"
          d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Z"
        ></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M2 3a1 1 0 0 0-1 1v4H0V4a2 2 0 0 1 2-2h6v1H2zm12 10a1 1 0 0 0 1-1V8h1v4a2 2 0 0 1-2 2H8v-1h6z"
        ></path>
        <path
          fill="#E25950"
          fillRule="nonzero"
          d="m13.292 10.068-1.153.002a.16.16 0 0 1-.152-.11l-.358-1.098a.16.16 0 0 0-.305 0l-.356 1.097a.16.16 0 0 1-.152.11H9.662a.16.16 0 0 0-.094.291l.932.678a.16.16 0 0 1 .059.18l-.356 1.097a.16.16 0 0 0 .247.18l.933-.626a.16.16 0 0 1 .188 0l.934.626a.16.16 0 0 0 .247-.18l-.357-1.097a.16.16 0 0 1 .058-.18l.933-.678a.162.162 0 0 0-.094-.293Z"
        ></path>
        <path
          fill="#217AB7"
          fillRule="nonzero"
          d="m6.247 4.802-1.14.003a.158.158 0 0 1-.15-.11l-.353-1.084a.158.158 0 0 0-.302 0l-.351 1.084a.158.158 0 0 1-.151.11H2.66a.158.158 0 0 0-.093.286l.922.67c.055.04.079.112.057.178l-.352 1.084a.158.158 0 0 0 .245.177l.921-.618a.16.16 0 0 1 .187 0l.922.618a.158.158 0 0 0 .245-.177L5.36 5.939a.158.158 0 0 1 .057-.178l.922-.67a.16.16 0 0 0-.093-.29Z"
        ></path>
        <path
          fill="#217AB7"
          d="M0 8h8v6H1.998A1.993 1.993 0 0 1 0 12.002V8Z"
        ></path>
        <path
          fill="#E25950"
          d="M8 2h6.002C15.113 2 16 2.894 16 3.998V8H8V2Z"
        ></path>
      </g>
    </svg>
  );
}
export function PG() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#E25950"
          d="M14.005 2A2 2 0 0 1 16 3.992v8.016A1.994 1.994 0 0 1 14.005 14H2a2 2 0 0 1-2-2V3.992C0 2.893.893 2 1.995 2h12.01Z"
        ></path>
        <path
          fill="#333"
          d="M15.328 13.496A1.992 1.992 0 0 1 14 14H1.994C.894 14 0 13.1 0 11.991V4c0-.595.26-1.13.672-1.496l14.656 10.992Z"
        ></path>
        <path
          fill="#FFF"
          d="M3 12.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm2-4a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm-3 1a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm1-3a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Z"
        ></path>
        <path
          fill="#F4B32E"
          d="M11 5V3.708c0-.557.353-.75.805-.418l2.89 2.12c.445.326.357.59-.194.59H12v1.506c0 .536-.35.721-.784.384L8.284 5.61c-.43-.335-.337-.61.215-.61H11Z"
        ></path>
      </g>
    </svg>
  );
}
export function PY() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#E25950"
          d="M1.994 2h12.012C15.106 2 16 2.895 16 4v2H0V4c0-1.112.893-2 1.994-2Z"
        ></path>
        <path fill="#F6F9FC" d="M0 6h16v4H0z"></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M15 10V6h1v4h-1ZM0 6h1v4H0V6Z"
        ></path>
        <path
          fill="#43458B"
          d="M0 10h16v2c0 1.112-.893 2-1.994 2H1.994C.894 14 0 13.105 0 12v-2Z"
        ></path>
        <path
          fill="#E25950"
          fillRule="nonzero"
          d="M8 9a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
        ></path>
      </g>
    </svg>
  );
}
export function PE() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#E25950"
          d="M2.005 2H5v12H2.005C.894 14 0 13.1 0 11.991V4.01A2.01 2.01 0 0 1 2.005 2ZM11 2h2.995C15.106 2 16 2.9 16 4.009v7.982A2.008 2.008 0 0 1 13.995 14H11V2Z"
        ></path>
        <path fill="#F6F9FC" d="M5 2h6v12H5z"></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M5 14v-1h6v1H5ZM5 2h6v1H5V2Z"
        ></path>
      </g>
    </svg>
  );
}
export function PH() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#555ABF" rx="2"></rect>
        <path
          fill="#E25950"
          d="M0 6v4.002C0 11.106.895 12 1.994 12h12.012A1.998 1.998 0 0 0 16 10.002V6H0Z"
        ></path>
        <path
          fill="#F6F9FC"
          d="M.7 11.52A2.01 2.01 0 0 1 0 9.99V2.01C0 1.395.275.844.707.475a.504.504 0 0 1 .07.039L9 5.996.777 11.478a.5.5 0 0 1-.076.042Z"
        ></path>
        <path
          fill="#EAEEF3"
          d="M0 9.991V2.01C0 1.395.275.844.707.475a.504.504 0 0 1 .07.039l.842.561A1 1 0 0 0 1 2v8a1 1 0 0 0 .611.922l-.834.556a.5.5 0 0 1-.076.042A2.01 2.01 0 0 1 0 9.99Z"
        ></path>
        <circle cx="4" cy="6" r="1" fill="#F4B32E"></circle>
      </g>
    </svg>
  );
}
export function PL() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#F6F9FC"
          d="M1.994 2A1.998 1.998 0 0 0 0 3.998V8h16V3.998A1.997 1.997 0 0 0 14.006 2H1.994Z"
        ></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M14 2a2 2 0 0 1 2 2v4h-1V4a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v4H0V4a2 2 0 0 1 2-2h12Z"
        ></path>
        <path
          fill="#E25950"
          d="M0 8v4.002C0 13.106.895 14 1.994 14h12.012A1.998 1.998 0 0 0 16 12.002V8H0Z"
        ></path>
      </g>
    </svg>
  );
}
export function PT() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none">
        <path
          fill="#24B47E"
          d="M6 14H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h4v12Z"
        ></path>
        <path
          fill="#E25950"
          d="M14 14H6V2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Z"
        ></path>
        <path
          fill="#FCD669"
          d="M5.975 5C7.645 5 9 6.343 9 7.999c0 1.656-1.355 2.999-3.025 2.999C4.324 10.97 3 9.636 3 7.999c0-1.636 1.324-2.97 2.975-2.998V5Z"
        ></path>
        <path
          fill="#E25950"
          d="M5.94 6a2 2 0 1 1 .12 4 2 2 0 0 1-.12-4Z"
        ></path>
        <path fill="#FFF" d="M5 7h2v1.5a1 1 0 1 1-2 0V7Z"></path>
      </g>
    </svg>
  );
}
export function PR() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#E25950" rx="2"></rect>
        <path
          fill="#E25950"
          d="M0 9h16v1c0 1.112-.893 2-1.994 2H1.994C.894 12 0 11.105 0 10V9z"
        ></path>
        <path fill="#F6F9FC" d="M0 3h16v2H0z"></path>
        <path fill="#EAEEF3" fillRule="nonzero" d="M15 5V3h1v2z"></path>
        <path fill="#F6F9FC" d="M0 7h16v2H0z"></path>
        <path fill="#EAEEF3" fillRule="nonzero" d="M15 9V7h1v2z"></path>
        <path
          fill="#43458B"
          d="M.649 11.473A2.01 2.01 0 0 1 0 9.991V2.01C0 1.419.253.889.656.52A.5.5 0 0 1 .8.596l7.2 5.4-7.2 5.4a.5.5 0 0 1-.151.077z"
        ></path>
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="m4.747 5.302-1.14.003a.158.158 0 0 1-.15-.11l-.353-1.084a.158.158 0 0 0-.302 0l-.351 1.084a.158.158 0 0 1-.151.11H1.16a.158.158 0 0 0-.093.286l.922.67c.055.04.079.112.057.178l-.352 1.084a.158.158 0 0 0 .245.177l.921-.618a.16.16 0 0 1 .187 0l.922.618a.158.158 0 0 0 .245-.177L3.86 6.439a.158.158 0 0 1 .057-.178l.922-.67a.16.16 0 0 0-.093-.29z"
        ></path>
      </g>
    </svg>
  );
}
export function QA() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#F6F9FC"
          fillRule="nonzero"
          d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        ></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M2 3a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4v1H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h4v1H2Z"
        ></path>
        <path
          fill="#B1372F"
          d="M14.003 14H5l1-.5-2-1 2-1-2-1 2-1-2-1 2-1-2-1 2-1-2-1 2-1-2-1L5 2h9.003C15.104 2 16 2.9 16 4.009v7.982A2.005 2.005 0 0 1 14.003 14Z"
        ></path>
      </g>
    </svg>
  );
}
export function RE() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#43458B"
          d="M2.005 2A2.008 2.008 0 0 0 0 4.009v7.982C0 13.101.894 14 2.005 14H5V2H2.005Z"
        ></path>
        <path
          fill="#E25950"
          d="M11 2v12h2.995A2.008 2.008 0 0 0 16 11.991V4.01C16 2.899 15.106 2 13.995 2H11Z"
        ></path>
        <path fill="#F6F9FC" d="M5 2h6v12H5z"></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M5 14v-1h6v1H5ZM5 2h6v1H5V2Z"
        ></path>
      </g>
    </svg>
  );
}
export function RO() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#43458B"
          d="M2.005 2A2.008 2.008 0 0 0 0 4.009v7.982C0 13.101.894 14 2.005 14H5V2H2.005Z"
        ></path>
        <path
          fill="#E25950"
          d="M11 2v12h2.995A2.008 2.008 0 0 0 16 11.991V4.01C16 2.899 15.106 2 13.995 2H11Z"
        ></path>
        <path fill="#FCD669" d="M5 2h6v12H5z"></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M5 14v-1h6v1H5ZM5 2h6v1H5V2Z"
        ></path>
      </g>
    </svg>
  );
}
export function RU() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#F6F9FC"
          d="M1.994 2A1.997 1.997 0 0 0 0 4.005V7h16V4.005A2 2 0 0 0 14.006 2H1.994Z"
        ></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M15 6V4.005A1 1 0 0 0 14.006 3H1.994A.997.997 0 0 0 1 4.005V6H0V4.005C0 2.894.893 2 1.994 2h12.012A2 2 0 0 1 16 4.005V6h-1Z"
        ></path>
        <path fill="#43458B" d="M0 6h16v4H0z"></path>
        <path
          fill="#E25950"
          d="M0 10v2c0 1.105.895 2 1.994 2h12.012A1.992 1.992 0 0 0 16 12v-2H0Z"
        ></path>
      </g>
    </svg>
  );
}
export function RW() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <path
          fill="#45B2E8"
          d="M1.994 0A1.998 1.998 0 0 0 0 1.998V6h16V1.998A1.997 1.997 0 0 0 14.006 0H1.994Z"
        ></path>
        <path fill="#FCD669" d="M0 6h16v3H0z"></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M15 9V6h1v3h-1ZM0 6h1v3H0V6Z"
        ></path>
        <path
          fill="#24B47E"
          d="M0 9v1.5c0 .828.667 1.5 1.505 1.5h12.99A1.5 1.5 0 0 0 16 10.5V9H0Z"
        ></path>
        <circle cx="13" cy="3" r="1" fill="#FCD669"></circle>
      </g>
    </svg>
  );
}
export function WS() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#E25950"
          fillRule="nonzero"
          d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        ></path>
        <path fill="#43458B" d="M2 2h7v7H0V4a2 2 0 0 1 2-2Z"></path>
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M5 5.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm-1 2a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm-.933-3.75a.5.5 0 1 1 .866-.5.5.5 0 0 1-.866.5ZM2 5.5c0-.276.22-.5.49-.5a.489.489 0 0 1 .441.245.508.508 0 0 1 0 .51.489.489 0 0 1-.44.245A.495.495 0 0 1 2 5.5Z"
        ></path>
      </g>
    </svg>
  );
}
export function SM() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <path
          fill="#F6F9FC"
          d="M1.994 0A1.998 1.998 0 0 0 0 1.998V6h16V1.998A1.997 1.997 0 0 0 14.006 0H1.994Z"
        ></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M14 0a2 2 0 0 1 2 2v4h-1V2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v4H0V2a2 2 0 0 1 2-2h12Z"
        ></path>
        <path
          fill="#217AB7"
          d="M0 6v4.002C0 11.106.895 12 1.994 12h12.012A1.998 1.998 0 0 0 16 10.002V6H0Z"
        ></path>
        <circle cx="8" cy="6" r="2" fill="#F4B32E"></circle>
      </g>
    </svg>
  );
}
export function ST() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#FCD669" rx="2"></rect>
        <path
          fill="#333"
          d="M8 7a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm4 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
          opacity="0.9"
        ></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M15 8V4h1v4z"
        ></path>
        <path
          fill="#24B47E"
          d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v2H0V2Zm0 6h16v2c0 1.112-.893 2-1.994 2H1.994C.894 12 0 11.105 0 10V8Z"
        ></path>
        <path
          fill="#E25950"
          d="M.7 11.52A2.01 2.01 0 0 1 0 9.99V2.01C0 1.395.275.844.707.475a.504.504 0 0 1 .07.039L5 5.996.777 11.478a.5.5 0 0 1-.076.042Z"
        ></path>
      </g>
    </svg>
  );
}
export function SA() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#24B47E" rx="2"></rect>
        <path
          fill="#FFF"
          d="M12 8h1l-.5 1H12v1l-1-.5V9H5.492L3 8h8v-.5l1-.5v1Zm-1-5 1.657.146L13 4v2h-1l-1.492-.528L10 6V3h1Zm-3 .375L9 3v3l-1-.488L7 6l.172-1.858L7 3l1 .375ZM5 3l1 1v2l-.764-.343L4 6l-.994-.006.183-2.917L5 3Z"
        ></path>
      </g>
    </svg>
  );
}
export function SN() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#24B47E"
          d="M2.005 2H5v12H2.005C.894 14 0 13.1 0 11.991V4.01A2.01 2.01 0 0 1 2.005 2Z"
        ></path>
        <path
          fill="#E25950"
          d="M11 2h2.995C15.106 2 16 2.9 16 4.009v7.982A2.008 2.008 0 0 1 13.995 14H11V2Z"
        ></path>
        <path fill="#FCD669" d="M5 2h6v12H5z"></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M5 14v-1h6v1H5ZM5 2h6v1H5V2Z"
        ></path>
        <path
          fill="#24B47E"
          fillRule="nonzero"
          d="m10.296 7.167-1.458.003a.202.202 0 0 1-.193-.14l-.452-1.388a.202.202 0 0 0-.386 0l-.45 1.387a.202.202 0 0 1-.193.14h-1.46a.202.202 0 0 0-.118.368l1.18.857a.202.202 0 0 1 .073.227l-.45 1.388a.202.202 0 0 0 .312.227l1.18-.792a.202.202 0 0 1 .239 0l1.18.792a.202.202 0 0 0 .313-.227L9.161 8.62a.202.202 0 0 1 .074-.227l1.18-.857a.205.205 0 0 0-.12-.37Z"
        ></path>
      </g>
    </svg>
  );
}
export function RS() {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 2a2 2 0 0 0-2 2v2h16V4a2 2 0 0 0-2-2H2Z"
        fill="#B84241"
      ></path>
      <path d="M0 6v4h16V6H0Z" fill="#1B4172"></path>
      <path d="M0 10v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-2H0Z" fill="#fff"></path>
      <path d="M3 6h4v3a2 2 0 1 1-4 0V6Z" fill="#B84241"></path>
      <path
        d="M1 10v1.995A1 1 0 0 0 1.994 13h12.012c.55 0 .994-.447.994-1.005V10h1v1.995A1.997 1.997 0 0 1 14.006 14H1.994A2 2 0 0 1 0 11.995V10h1Z"
        fill="#EAEEF3"
      ></path>
      <path
        d="M6 6H4l-.686-1.029c-.167-.25-.074-.586.21-.686C3.906 4.149 4.453 4 5 4c.547 0 1.093.15 1.477.285.283.1.376.436.209.686L6 6Z"
        fill="#FCD669"
      ></path>
      <path
        d="M3.5 6 5 7l1.5-1L7 9l-1.429-.714L6 10H4l.429-1.714L3 9l.5-3Z"
        fill="#fff"
      ></path>
    </svg>
  );
}
export function SC() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#E25950"
          fillRule="nonzero"
          d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        ></path>
        <path
          fill="#43458B"
          fillRule="nonzero"
          d="M2 2h3L.358 13.142A1.99 1.99 0 0 1 0 12V4a2 2 0 0 1 2-2Z"
        ></path>
        <path
          fill="#FCD669"
          d="M5 2h6L.56 13.389a2.01 2.01 0 0 1-.202-.247L5 2Z"
        ></path>
        <path
          fill="#FCD669"
          d="M5 2h6L.56 13.389a2.01 2.01 0 0 1-.202-.247L5 2Z"
        ></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="m11 2-.917 1h-5.5L5 2z"
        ></path>
        <path
          fill="#F6F9FC"
          fillRule="nonzero"
          d="M16 6v4L.555 13.382a2.005 2.005 0 0 1-.339-.477L16 6Z"
        ></path>
        <path
          fill="#159570"
          fillRule="nonzero"
          d="M16 12a2 2 0 0 1-2 2H2a2 2 0 0 1-1.758-1.045L16 10v2Z"
        ></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M16 6v4l-1 .156V6.438z"
        ></path>
      </g>
    </svg>
  );
}
export function SL() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#24B47E"
          d="M1.994 2h12.012C15.106 2 16 2.895 16 4v2H0V4c0-1.112.893-2 1.994-2Z"
        ></path>
        <path fill="#F6F9FC" fillRule="nonzero" d="M0 6h16v4H0z"></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M15 10V6h1v4h-1ZM0 6h1v4H0V6Z"
        ></path>
        <path
          fill="#217AB7"
          d="M0 10h16v2c0 1.112-.893 2-1.994 2H1.994C.894 14 0 13.105 0 12v-2Z"
        ></path>
      </g>
    </svg>
  );
}
export function SG() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none">
        <path
          fill="#F6F9FC"
          d="M16 8v4a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8h16Z"
        ></path>
        <path
          fill="#EAEEF3"
          d="M14 14H2a2 2 0 0 1-2-2V8h1v4a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V8h1v4a2 2 0 0 1-2 2Z"
        ></path>
        <path
          fill="#E25950"
          d="M0 8V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v4H0Z"
        ></path>
        <path
          fill="#FFF"
          d="M3.364 5.205c0 .75.606 1.36 1.356 1.363a1.691 1.691 0 0 1-1.015.342 1.705 1.705 0 1 1 0-3.41c.382 0 .73.13 1.015.342a1.363 1.363 0 0 0-1.356 1.363ZM9.02 5a.5.5 0 1 1-.04-1 .5.5 0 0 1 .04 1ZM7.5 4.5a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1ZM7 6a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM6 5a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm2.5.5a.5.5 0 1 1 0 1 .5.5 0 0 1 0-1Z"
        ></path>
      </g>
    </svg>
  );
}
export function SX() {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M.64 2.54A2 2 0 0 0 0 4v8a2 2 0 0 0 .64 1.46l.36-.3V2.84l-.36-.3Z"
        fill="#EAEEF3"
      ></path>
      <path d="M1 2.84v10.32L7.11 8 1 2.84Z" fill="#F6F9FC"></path>
      <path
        d="M.64 2.54A2 2 0 0 1 2 2h12a2 2 0 0 1 2 2v4H7.11L.64 2.54Z"
        fill="#E25950"
      ></path>
      <path
        d="M.64 13.46A2 2 0 0 0 2 14h12a2 2 0 0 0 2-2V8H7.11L.64 13.46Z"
        fill="#43458B"
      ></path>
      <path
        d="M2.75 9.75a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5Z"
        fill="#45B2E8"
      ></path>
    </svg>
  );
}
export function SK() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#F6F9FC"
          d="M1.994 2A1.997 1.997 0 0 0 0 4.005V7h16V4.005A2 2 0 0 0 14.006 2H1.994Z"
        ></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M15 6V4.005A1 1 0 0 0 14.006 3H1.994A.997.997 0 0 0 1 4.005V6H0V4.005C0 2.894.893 2 1.994 2h12.012A2 2 0 0 1 16 4.005V6h-1Z"
        ></path>
        <path fill="#217AB7" d="M0 6h16v4H0z"></path>
        <path
          fill="#E25950"
          d="M0 10v2c0 1.105.895 2 1.994 2h12.012A1.992 1.992 0 0 0 16 12v-2H0Z"
        ></path>
        <path
          fill="#43458B"
          fillRule="nonzero"
          d="M3 6h4v2.5a2 2 0 1 1-4 0V6Z"
        ></path>
        <path fill="#E25950" d="M3 6h4v3H3z"></path>
      </g>
    </svg>
  );
}
export function SI() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#F6F9FC"
          d="M1.994 2A1.997 1.997 0 0 0 0 4.005V7h16V4.005A2 2 0 0 0 14.006 2H1.994Z"
        ></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M15 6V4.005A1 1 0 0 0 14.006 3H1.994A.997.997 0 0 0 1 4.005V6H0V4.005C0 2.894.893 2 1.994 2h12.012A2 2 0 0 1 16 4.005V6h-1Z"
        ></path>
        <path fill="#217AB7" d="M0 6h16v4H0z"></path>
        <path
          fill="#E25950"
          d="M0 10v2c0 1.105.895 2 1.994 2h12.012A1.992 1.992 0 0 0 16 12v-2H0Z"
        ></path>
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M3 4h4v2.5a2 2 0 1 1-4 0V4Z"
        ></path>
        <path fill="#217AB7" d="M3 4h4v2H3z"></path>
      </g>
    </svg>
  );
}
export function SB() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#FCD669" rx="2"></rect>
        <path
          fill="#217AB7"
          d="M0 9.991V2a2 2 0 0 1 2-2h12L.183 10.832A2.011 2.011 0 0 1 0 9.992Z"
        ></path>
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M4 2.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm-2 2a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm2 0a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0ZM2.067 2.75a.5.5 0 1 1 .866-.5.5.5 0 0 1-.866.5ZM3 3.5c0-.276.22-.5.49-.5a.489.489 0 0 1 .441.245.508.508 0 0 1 0 .51.489.489 0 0 1-.44.245A.495.495 0 0 1 3 3.5Z"
        ></path>
        <path
          fill="#159570"
          d="M15.817 1.168c.117.255.183.54.183.84V10a2 2 0 0 1-2 2H2L15.817 1.168Z"
        ></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M16 2.009c0-.3-.066-.586-.183-.841l-.82.752A1 1 0 0 0 14 1h-3.09L12 0h2a2 2 0 0 1 2 2v.009ZM0 10c0 .3.066.585.183.84l.82-.751a1 1 0 0 0 .997.92h3.09l-1.09 1H2a2 2 0 0 1-2-2V10Z"
        ></path>
      </g>
    </svg>
  );
}
export function SO() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#217AB7"
          d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Z"
        ></path>
        <path
          fill="#FFF"
          d="m8.08 9.785-1.723 1.036a.3.3 0 0 1-.447-.327l.457-1.905-1.545-1.443a.3.3 0 0 1 .192-.52l1.898-.083.895-1.95a.3.3 0 0 1 .546 0l.895 1.95 1.898.084a.3.3 0 0 1 .192.519L9.793 8.589l.457 1.905a.3.3 0 0 1-.447.327L8.08 9.785Z"
        ></path>
      </g>
    </svg>
  );
}
export function ZA() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#E25950" rx="2"></rect>
        <path
          fill="#43458B"
          d="M0 6v4.002C0 11.106.895 12 1.994 12h12.012A1.998 1.998 0 0 0 16 10.002V6H0Z"
        ></path>
        <path
          fill="#159570"
          d="M6.672 5H16v2H6.661L.8 11.396a.5.5 0 0 1-.151.077A2.01 2.01 0 0 1 0 9.991V2.01C0 1.419.253.889.656.52A.5.5 0 0 1 .8.596L6.672 5Z"
        ></path>
        <path fill="#333" d="M0 9V3l4 3z"></path>
        <path
          fill="#FCD669"
          d="m0 9 4-3-4-3v-.991c0-.084.005-.167.015-.248L5.667 6 .015 10.239A2.04 2.04 0 0 1 0 9.99V9Z"
        ></path>
        <path
          fill="#F6F9FC"
          d="M16 4v1H6.506L.602.571a1.99 1.99 0 0 1 .966-.526L6.838 4H16Zm0 3.02v1H6.828l-5.249 3.936a1.987 1.987 0 0 1-.971-.521L6.494 7.02H16Z"
        ></path>
        <path
          fill="#EAEEF3"
          d="M15 4h1v1h-1V4ZM2.84 1h-.833c-.218 0-.42.07-.584.187L.602.572a1.99 1.99 0 0 1 .966-.526L2.84 1ZM15 7.02h1v1h-1v-1Zm-13.572 3.8c.164.113.363.18.579.18h.847l-1.275.956a1.987 1.987 0 0 1-.971-.521l.82-.615Z"
        ></path>
      </g>
    </svg>
  );
}
export function KR() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#F6F9FC"
          fillRule="nonzero"
          d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        ></path>
        <path
          fill="#EAEEF3"
          d="M1.994 2h12.012C15.106 2 16 2.9 16 4.009v7.982A2.003 2.003 0 0 1 14.006 14H1.994C.894 14 0 13.1 0 11.991V4.01C0 2.902.893 2 1.994 2Zm.013 1C1.45 3 1 3.456 1 3.995v8.01A1 1 0 0 0 2.007 13h11.986c.556 0 1.007-.456 1.007-.995v-8.01A1 1 0 0 0 13.993 3H2.007Z"
        ></path>
        <path
          fill="#333"
          d="M11 4.207V4h1.207L13.5 5.793V6h-1.207L11 4.207ZM2.5 5.793 3.793 4H5v.207L3.707 6H2.5v-.207Zm8.5 6L12.293 10H13.5v.207L12.207 12H11v-.207Zm-8.5-1.586V10h1.207L5 11.793V12H3.793L2.5 10.207Z"
        ></path>
        <path
          fill="#E25950"
          d="M5 8a3 3 0 1 1 5.711 1.286H5.29A2.988 2.988 0 0 1 5 8Z"
        ></path>
        <path
          fill="#43458B"
          d="M9.436 7.346c.987 0 1.395.677 1.52 1.173a3.001 3.001 0 0 1-2.896 2.48c-1.18-.021-2.2-.663-2.711-1.594A2.986 2.986 0 0 1 5 8.025a2.81 2.81 0 0 1 .336-1.31c.002.04.003.084.003.131 0 .578-.002 1.787 1.45 1.787 1.454 0 1.411-1.287 2.647-1.287Z"
        ></path>
      </g>
    </svg>
  );
}
export function SS() {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="#43458B"
        d="M.67 2.5A2 2 0 0 0 0 4v8a2 2 0 0 0 .67 1.5L8 8z"
      ></path>
      <path
        fill="#333"
        d="M.67 2.5A2 2 0 0 1 2 2h12a2 2 0 0 1 2 2v1.58H4.77z"
      ></path>
      <path fill="#E25950" d="M5.61 6.21H16v3.58H5.61L8 8z"></path>
      <path
        fill="#24B47E"
        d="M.67 13.5A2 2 0 0 0 2 14h12a2 2 0 0 0 2-2v-1.58H4.77z"
      ></path>
      <path
        fill="#F6F9FC"
        d="M4.77 5.58H15v.63H5.61zm.84 4.21H15v.63H4.77z"
      ></path>
      <path fill="#EAEEF3" d="M15 5.58h1v.63h-1zm0 4.21h1v.63h-1z"></path>
      <path
        fill="#FCD669"
        d="M2.33 6.16v1.17c0 .07-.04.13-.1.15l-1.12.36a.16.16 0 0 0 0 .31l1.11.36a.16.16 0 0 1 .12.16v1.17a.16.16 0 0 0 .29.1l.69-.95a.16.16 0 0 1 .18-.06l1.1.36a.16.16 0 0 0 .19-.25l-.63-.94a.16.16 0 0 1 0-.2l.63-.94a.16.16 0 0 0-.18-.25l-1.11.36a.16.16 0 0 1-.19-.06l-.68-.94a.16.16 0 0 0-.3.1Z"
      ></path>
    </svg>
  );
}
export function ES() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none">
        <path fill="#FCD669" d="M0 5h16v6H0z"></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          d="M15 11V5h1v6h-1ZM0 5h1v6H0V5Z"
        ></path>
        <path
          fill="#E25950"
          d="M0 5V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1H0Zm14 9H2a2 2 0 0 1-2-2v-1h16v1a2 2 0 0 1-2 2Z"
        ></path>
        <path fill="#FFF" d="M4 6h2v2H4z"></path>
        <path fill="#F79A59" d="M4 10.5a2 2 0 0 1-2-2V8h2v2.5Z"></path>
        <path
          fill="#E25950"
          d="M4 10.5V8h2v.5a2 2 0 0 1-2 2ZM2 6h2v2H2V6Z"
        ></path>
      </g>
    </svg>
  );
}
export function LK() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <g>
          <path
            fill="#F4B32E"
            fillRule="nonzero"
            d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
          ></path>
          <path fill="#E37C4C" d="M3 3h2v10H3z"></path>
          <path
            fill="#B1372F"
            d="M6 3h8a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H6V3Zm1 1v1h1V4H7Zm6 0v1h1V4h-1Zm-6 7v1h1v-1H7Zm6 0v1h1v-1h-1ZM8 7v2h5V7H8Z"
          ></path>
        </g>
        <path
          fill="#159570"
          d="M2 3h1v10H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1Z"
        ></path>
      </g>
    </svg>
  );
}
export function BL() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
      <path fill="#43458B" d="M2 2a2 2 0 0 0-2 2v8c0 1.1.9 2 2 2h3V2H2Z"></path>
      <path
        fill="#E25950"
        d="M11 2v12h3a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2h-3Z"
      ></path>
      <path fill="#F6F9FC" d="M5 2h6v12H5z"></path>
      <path fill="#EAEEF3" d="M5 14v-1h6v1H5ZM5 2h6v1H5V2Z"></path>
    </svg>
  );
}
export function SH() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#43458B"
          fillRule="nonzero"
          d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        ></path>
        <path fill="#43458B" d="M2 2h6v6H0V4a2 2 0 0 1 2-2Z"></path>
        <path
          fill="#45B2E8"
          fillRule="nonzero"
          d="M10 6h4v2.5a2 2 0 1 1-4 0V6Z"
        ></path>
        <path fill="#FCD669" d="M10 6h4v2h-4z"></path>
        <path
          fill="#FFF"
          d="M1.346 5.964H0V4.036h3v-.107L.748 2.44c.267-.213.59-.36.943-.415L3 2.89V2h2v2.036h.046L8 2.08v1.04l-1.383.915H8v1.928H5v.083l2.934 1.941h-1.57L5 7.086V8H3V5.964h-.082L0 7.894v-1.04l1.346-.89Z"
        ></path>
        <path
          fill="#E25950"
          d="M3 3.93v.106H1.587L.203 3.12c.13-.265.317-.497.545-.68L3 3.93Zm2-.881L6.586 2H8v.104L5.08 4.036H5v-.987ZM2.92 5.964H3v.987L1.414 8H0v-.104l2.92-1.932Zm3.493 0L8 7.014V8h-.08L5 6.07v-.106h1.413ZM8 5.427H4.444V8h-.888V5.427H0v-.854h3.556V2h.888v2.573H8v.854Z"
        ></path>
      </g>
    </svg>
  );
}
export function KN() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 16 16"
    >
      <defs>
        <rect id="fi_FlagIcon-KN__a" width="16" height="12" rx="2"></rect>
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <use fill="#FCD669" xlinkHref="#fi_FlagIcon-KN__a"></use>
        <rect
          width="15"
          height="11"
          x="0.5"
          y="0.5"
          stroke="#A68527"
          strokeOpacity="0.1"
          rx="2"
        ></rect>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M15 6V2a1 1 0 0 0-1-1H9V0h5a2 2 0 0 1 2 2v4h-1ZM1 6v4a1 1 0 0 0 1 1h5v1H2a2 2 0 0 1-2-2V6h1Z"
        ></path>
        <path
          fill="#333"
          d="M16 3 3 12H2a2 2 0 0 1-2-2V9l13.5-9h.5a2 2 0 0 1 2 2v1Z"
        ></path>
        <path
          fill="#24B47E"
          d="M2.003 0A2 2 0 0 0 0 2.01V7.5L11.5 0H2.003Z"
        ></path>
        <path
          fill="#E25950"
          d="M16 9.99c0 1.11-.9 2.01-1.992 2.01H5l11-7.5v5.49Z"
        ></path>
        <path
          fill="#FFF"
          d="M5 9a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm6-4a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
        ></path>
      </g>
    </svg>
  );
}
export function LC() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#45B2E8" rx="2"></rect>
        <path fill="#FFF" d="m8 2-5 8h10z"></path>
        <path fill="#333" d="m8 4-4 6h8z"></path>
        <path fill="#FCD669" d="m8 7-5 3h10z"></path>
      </g>
    </svg>
  );
}
export function MF() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
      <path fill="#43458B" d="M2 2a2 2 0 0 0-2 2v8c0 1.1.9 2 2 2h3V2H2Z"></path>
      <path
        fill="#E25950"
        d="M11 2v12h3a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2h-3Z"
      ></path>
      <path fill="#F6F9FC" d="M5 2h6v12H5z"></path>
      <path fill="#EAEEF3" d="M5 14v-1h6v1H5ZM5 2h6v1H5V2Z"></path>
    </svg>
  );
}
export function PM() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#43458B"
          d="M2.005 2A2.008 2.008 0 0 0 0 4.009v7.982C0 13.101.894 14 2.005 14H5V2H2.005Z"
        ></path>
        <path
          fill="#E25950"
          d="M11 2v12h2.995A2.008 2.008 0 0 0 16 11.991V4.01C16 2.899 15.106 2 13.995 2H11Z"
        ></path>
        <path fill="#F6F9FC" d="M5 2h6v12H5z"></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M5 14v-1h6v1H5ZM5 2h6v1H5V2Z"
        ></path>
      </g>
    </svg>
  );
}
export function VC() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#217AB7"
          d="M2.005 2H4v12H2.005C.894 14 0 13.1 0 11.991V4.01A2.01 2.01 0 0 1 2.005 2Z"
        ></path>
        <path
          fill="#24B47E"
          d="M12 2h1.995C15.106 2 16 2.9 16 4.009v7.982A2.008 2.008 0 0 1 13.995 14H12V2Z"
        ></path>
        <path fill="#FCD669" d="M4 2h8v12H4z"></path>
        <path
          fill="#24B47E"
          d="M8 8.586 9.414 10 8 11.414 6.586 10 8 8.586Zm2-2L11.414 8 10 9.414 8.586 8 10 6.586Zm-4 0L7.414 8 6 9.414 4.586 8 6 6.586Z"
        ></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M4 14v-1h8v1H4ZM4 2h8v1H4V2Z"
        ></path>
      </g>
    </svg>
  );
}
export function SD() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#E25950" rx="2"></rect>
        <path
          fill="#333"
          d="M0 8v2c0 1.105.895 2 1.994 2h12.012A1.992 1.992 0 0 0 16 10V8H0Z"
        ></path>
        <path fill="#F6F9FC" d="M0 4h16v4H0z"></path>
        <path fill="#EAEEF3" fillRule="nonzero" d="M15 8V4h1v4z"></path>
        <path
          fill="#159570"
          d="M.649 11.473A2.01 2.01 0 0 1 0 9.991V2.01C0 1.419.253.889.656.52A.5.5 0 0 1 .8.596l7.2 5.4-7.2 5.4a.5.5 0 0 1-.151.077Z"
        ></path>
      </g>
    </svg>
  );
}
export function SR() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path fill="#F6F9FC" d="M0 5h16v6H0z"></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M15 11V5h1v6h-1ZM0 5h1v6H0V5Z"
        ></path>
        <path
          fill="#159570"
          d="M1.505 2h12.99C15.333 2 16 2.672 16 3.5V5H0V3.5A1.5 1.5 0 0 1 1.505 2ZM0 11h16v1.5a1.5 1.5 0 0 1-1.505 1.5H1.505A1.498 1.498 0 0 1 0 12.5V11Z"
        ></path>
        <path fill="#E25950" d="M0 6h16v4H0z"></path>
        <path
          fill="#FCD669"
          fillRule="nonzero"
          d="m9.747 7.302-1.14.003a.158.158 0 0 1-.15-.11l-.353-1.084a.158.158 0 0 0-.302 0l-.351 1.084a.158.158 0 0 1-.151.11H6.16a.158.158 0 0 0-.093.286l.922.67c.055.04.079.112.057.178l-.352 1.084a.158.158 0 0 0 .245.177l.921-.618a.16.16 0 0 1 .187 0l.922.618a.158.158 0 0 0 .245-.177L8.86 8.439a.158.158 0 0 1 .057-.178l.922-.67a.16.16 0 0 0-.093-.29Z"
        ></path>
      </g>
    </svg>
  );
}
export function SJ() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none">
        <path
          fill="#E25950"
          d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        ></path>
        <path fill="#F6F9FC" d="M16 9H7v5H4V9H0V6h4V2h3v4h9z"></path>
        <path fill="#43458B" d="M16 8H6v6H5V8H0V7h5V2h1v5h10z"></path>
        <path
          fill="#555ABF"
          d="M15 9V6h1v3h-1ZM4 13h3v1H4v-1ZM4 2h3v1H4V2ZM0 6h1v3H0V6Z"
          opacity="0.1"
        ></path>
      </g>
    </svg>
  );
}
export function SE() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none">
        <path
          fill="#217AB7"
          d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        ></path>
        <path fill="#FCD669" d="M16 9H7v5H4V9H0V6h4V2h3v4h9z"></path>
        <path
          fill="#A68527"
          d="M15 9V6h1v3h-1ZM4 13h3v1H4v-1ZM4 2h3v1H4V2ZM0 6h1v3H0V6Z"
          opacity="0.1"
        ></path>
      </g>
    </svg>
  );
}
export function CH() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#E25950"
          d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        ></path>
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M12.5 9.5h-3v3h-3v-3h-3v-3h3v-3h3v3h3z"
        ></path>
      </g>
    </svg>
  );
}
export function SY() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path fill="#F6F9FC" fillRule="nonzero" d="M0 6h16v4H0z"></path>
        <path
          fill="#24B47E"
          d="M6 9a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm4 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
          opacity="0.9"
        ></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M15 10V6h1v4h-1ZM0 6h1v4H0V6Z"
        ></path>
        <path
          fill="#E25950"
          d="M16 4v2H0V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2Z"
        ></path>
        <path
          fill="#333"
          fillRule="nonzero"
          d="M0 12v-2h16v2a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2Z"
        ></path>
      </g>
    </svg>
  );
}
export function TW() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <path
          fill="#E25950"
          fillRule="nonzero"
          d="M2 0h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2Z"
        ></path>
        <path fill="#43458B" d="M2 0h7v7H0V2a2 2 0 0 1 2-2Z"></path>
        <circle cx="4.5" cy="3.5" r="1.5" fill="#FFF"></circle>
      </g>
    </svg>
  );
}
export function TJ() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#E25950"
          d="M1.994 2A1.992 1.992 0 0 0 0 4v2h16V4c0-1.105-.895-2-1.994-2H1.994Z"
        ></path>
        <path fill="#F6F9FC" fillRule="nonzero" d="M0 6h16v4H0z"></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M15 10V6h1v4h-1ZM0 6h1v4H0V6Z"
        ></path>
        <path
          fill="#24B47E"
          d="M0 10v2c0 1.105.895 2 1.994 2h12.012A1.992 1.992 0 0 0 16 12v-2H0Z"
        ></path>
        <path
          fill="#FCD669"
          d="M5 8.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm1-1a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0ZM7.5 7a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm1.5.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm1 1a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm-2.5 0a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Z"
        ></path>
      </g>
    </svg>
  );
}
export function TZ() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#FCD669" rx="2"></rect>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M0 6h1v4a1 1 0 0 0 1 1h5v1H2a2 2 0 0 1-2-2V6Zm16 0h-1V2a1 1 0 0 0-1-1H9V0h5a2 2 0 0 1 2 2v4Z"
        ></path>
        <path
          fill="#333"
          d="M16 3 3 12H2a2 2 0 0 1-2-2V9l13.5-9h.5a2 2 0 0 1 2 2v1Z"
        ></path>
        <path
          fill="#24B47E"
          d="M2.003 0A2 2 0 0 0 0 2.01V7.5L11.5 0H2.003Z"
        ></path>
        <path
          fill="#45B2E8"
          d="M16 9.99c0 1.11-.9 2.01-1.992 2.01H5l11-7.5v5.49Z"
        ></path>
      </g>
    </svg>
  );
}
export function TH() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#E25950"
          d="M1 2h14c.555 0 1 .448 1 1v1H0V3c0-.556.448-1 1-1ZM0 12h16v1c0 .556-.448 1-1 1H1c-.555 0-1-.448-1-1v-1Z"
        ></path>
        <path fill="#F6F9FC" d="M0 4h16v8H0z"></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M15 12V4h1v8h-1ZM0 4h1v8H0V4Z"
        ></path>
        <path fill="#43458B" d="M0 5h16v6H0z"></path>
      </g>
    </svg>
  );
}
export function TL() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#E25950" rx="2"></rect>
        <path
          fill="#FCD669"
          d="M0 10.038V1.964c.017-.52.429-.8.935-.604L13 6 .935 10.64c-.506.195-.918-.074-.934-.602Z"
        ></path>
        <path
          fill="#333"
          d="M.664 11.488A2.01 2.01 0 0 1 0 9.99V2.01C0 1.415.257.88.665.512.708.535.75.564.796.597L8 6 .796 11.403a1.03 1.03 0 0 1-.132.085Z"
        ></path>
        <circle cx="3" cy="6" r="1" fill="#F7FAFC"></circle>
      </g>
    </svg>
  );
}
export function TG() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path fill="#FCD669" d="M0 5h16v6H0z"></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M15 11V5h1v6h-1ZM0 5h1v6H0V5Z"
        ></path>
        <path
          fill="#159570"
          d="M1.994 2h12.012C15.106 2 16 2.895 16 4v1H0V4c0-1.112.893-2 1.994-2ZM0 11h16v1.5a1.5 1.5 0 0 1-1.505 1.5H1.505A1.498 1.498 0 0 1 0 12.5V11Zm0-4h16v2H0V7Z"
        ></path>
        <path fill="#E25950" d="M2 2h5v7H0V4a2 2 0 0 1 2-2Z"></path>
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="m5.71 4.637-1.432.003a.199.199 0 0 1-.19-.138L3.645 3.14a.199.199 0 0 0-.38 0l-.442 1.362a.199.199 0 0 1-.19.137H1.202A.199.199 0 0 0 1.084 5l1.159.842c.07.05.099.141.072.223l-.442 1.363a.199.199 0 0 0 .307.223l1.158-.778a.199.199 0 0 1 .235 0l1.159.778a.199.199 0 0 0 .307-.223l-.443-1.363a.199.199 0 0 1 .072-.223L5.827 5a.202.202 0 0 0-.117-.363Z"
        ></path>
      </g>
    </svg>
  );
}
export function TK() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#43458B"
          fillRule="nonzero"
          d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        ></path>
        <path fill="#FCD669" d="M14 6.5V12H4z"></path>
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M5 5.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm-1 4a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm-.933-5.75a.5.5 0 1 1 .866-.5.5.5 0 0 1-.866.5ZM2 5.5c0-.276.22-.5.49-.5a.489.489 0 0 1 .441.245.508.508 0 0 1 0 .51.489.489 0 0 1-.44.245A.495.495 0 0 1 2 5.5Z"
        ></path>
      </g>
    </svg>
  );
}
export function TO() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#B1372F" rx="2"></rect>
        <path fill="#F6F9FC" d="M2 0h5v7H0V2a2 2 0 0 1 2-2Z"></path>
        <path
          fill="#B1372F"
          fillRule="nonzero"
          d="M3 3V2h1v1h1v1H4v1H3V4H2V3z"
        ></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M7 0v1H2a1 1 0 0 0-1 1v5H0V2a2 2 0 0 1 2-2h5Z"
        ></path>
      </g>
    </svg>
  );
}
export function TT() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#F6F9FC"
          d="M16 4v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2Z"
        ></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M16 8v4a2 2 0 0 1-2 2H9v-1h5a1 1 0 0 0 1-1V8h1ZM0 8V4a2 2 0 0 1 2-2h5v1H2a1 1 0 0 0-1 1v4H0Z"
        ></path>
        <path
          fill="#333"
          d="M0 5V4a2 2 0 0 1 2-2h.5L16 11v1a2 2 0 0 1-2 2h-1L0 5Z"
        ></path>
        <path
          fill="#E25950"
          d="M13.997 2A2 2 0 0 1 16 4.01V9.5L4.5 2h9.497ZM0 11.99V6.5L11 14H1.992C.9 14 0 13.1 0 11.99Z"
        ></path>
      </g>
    </svg>
  );
}
export function TA() {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 2H2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2Z"
        fill="#43458B"
      ></path>
      <path
        d="M1.35 5.96H0V4.04h3v-.11L.75 2.44a2 2 0 0 1 .94-.41L3 2.88V2h2v2.04h.05L8 2.08v1.04l-1.38.92H8v1.92H5v.09l2.93 1.94H6.36L5 7.09V8H3V5.96h-.08L0 7.9V6.85l1.35-.89Z"
        fill="#fff"
      ></path>
      <path
        d="M3 3.93v.1H1.59L.2 3.13c.13-.26.32-.5.55-.68L3 3.93Zm2-.88L6.59 2H8v.1L5.08 4.04H5v-.99ZM2.92 5.96H3v1L1.41 8H0v-.1l2.92-1.94Zm3.5 0L8 7.01V8h-.08L5 6.07v-.1l1.42-.01ZM8 5.43H4.44V8h-.88V5.43H0v-.86h3.56V2h.88v2.57H8v.86Z"
        fill="#E25950"
      ></path>
      <circle cx="10.5" cy="8.5" r="1.5" fill="#F79A59"></circle>
      <circle cx="13.5" cy="8.5" r="1.5" fill="#F79A59"></circle>
      <path
        d="M14 8.78V8h-4v.78c0 .59.21 1.15.59 1.57.37.42.88.65 1.41.65a1.9 1.9 0 0 0 1.41-.65c.38-.42.59-.98.59-1.57Z"
        fill="#fff"
      ></path>
      <path d="M10 6h4v2h-4V6Z" fill="#45B2E8"></path>
    </svg>
  );
}
export function TN() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#E25950" rx="2"></rect>
        <path
          fill="#FFF"
          d="M8 9a3 3 0 1 1 0-6 3 3 0 0 1 0 6Zm1-4.732a2 2 0 1 0 0 3.464 1.75 1.75 0 1 1 0-3.464ZM9 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
        ></path>
      </g>
    </svg>
  );
}
export function TR() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#E25950" rx="2"></rect>
        <path
          fill="#FFF"
          d="M8 2.536a3.5 3.5 0 1 0 0 6.929 4 4 0 1 1 0-6.929Z"
        ></path>
        <circle cx="9" cy="6" r="1" fill="#FFF"></circle>
      </g>
    </svg>
  );
}
export function TM() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#159570" rx="2"></rect>
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M10.636 3.205c0 .75-.606 1.36-1.356 1.363.292.221.649.341 1.015.342a1.705 1.705 0 1 0 0-3.41c-.382 0-.73.13-1.015.342.75.004 1.356.613 1.356 1.363ZM7 4a.5.5 0 1 0 1 0 .5.5 0 0 0-1 0Zm1-1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Z"
        ></path>
        <path
          fill="#E25950"
          d="M2 0h4v12H2V0Zm2 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
        ></path>
      </g>
    </svg>
  );
}
export function TC() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#43458B"
          fillRule="nonzero"
          d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        ></path>
        <path fill="#43458B" d="M2 2h6v6H0V4a2 2 0 0 1 2-2Z"></path>
        <path
          fill="#FCD669"
          fillRule="nonzero"
          d="M10 6h4v2.5a2 2 0 1 1-4 0V6Z"
        ></path>
        <path
          fill="#FFF"
          d="M1.346 5.964H0V4.036h3v-.107L.748 2.44c.267-.213.59-.36.943-.415L3 2.89V2h2v2.036h.046L8 2.08v1.04l-1.383.915H8v1.928H5v.083l2.934 1.941h-1.57L5 7.086V8H3V5.964h-.082L0 7.894v-1.04l1.346-.89Z"
        ></path>
        <path
          fill="#E25950"
          d="M3 3.93v.106H1.587L.203 3.12c.13-.265.317-.497.545-.68L3 3.93Zm2-.881L6.586 2H8v.104L5.08 4.036H5v-.987ZM2.92 5.964H3v.987L1.414 8H0v-.104l2.92-1.932Zm3.493 0L8 7.014V8h-.08L5 6.07v-.106h1.413ZM8 5.427H4.444V8h-.888V5.427H0v-.854h3.556V2h.888v2.573H8v.854Z"
        ></path>
      </g>
    </svg>
  );
}
export function TV() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#45B2E8"
          fillRule="nonzero"
          d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        ></path>
        <path
          fill="#FCD669"
          d="M9 11.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm1-1a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm1-1a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm1-1a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm-1-2a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm1-1a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm1-1a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Z"
        ></path>
        <path fill="#43458B" d="M2 2h6v6H0V4a2 2 0 0 1 2-2Z"></path>
        <path
          fill="#FFF"
          d="M1.346 5.964H0V4.036h3v-.107L.748 2.44c.267-.213.59-.36.943-.415L3 2.89V2h2v2.036h.046L8 2.08v1.04l-1.383.915H8v1.928H5v.083l2.934 1.941h-1.57L5 7.086V8H3V5.964h-.082L0 7.894v-1.04l1.346-.89Z"
        ></path>
        <path
          fill="#E25950"
          d="M3 3.93v.106H1.587L.203 3.12c.13-.265.317-.497.545-.68L3 3.93Zm2-.881L6.586 2H8v.104L5.08 4.036H5v-.987ZM2.92 5.964H3v.987L1.414 8H0v-.104l2.92-1.932Zm3.493 0L8 7.014V8h-.08L5 6.07v-.106h1.413ZM8 5.427H4.444V8h-.888V5.427H0v-.854h3.556V2h.888v2.573H8v.854Z"
        ></path>
      </g>
    </svg>
  );
}
export function VI() {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        fill="#EAEEF3"
      ></path>
      <path
        d="M2 3h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1Z"
        fill="#F6F9FC"
      ></path>
      <path
        d="M13.4 3.93 9.93 5.09a.26.26 0 0 0-.18.24v2.32c0 .23.28.35.45.19l3.47-3.48c.2-.2 0-.52-.27-.43Zm-10.8 0 3.47 1.16c.1.03.18.13.18.24v2.32c0 .23-.28.35-.45.19L2.33 4.36c-.2-.2 0-.52.27-.43Z"
        fill="#FCD669"
      ></path>
      <circle cx="8" cy="5" r="1" fill="#FCD669"></circle>
      <path
        d="M10.16 11.59 8.19 9.23a.25.25 0 0 0-.38 0l-1.97 2.36a.25.25 0 0 0 .2.41h3.93c.2 0 .32-.25.19-.41Z"
        fill="#FCD669"
      ></path>
      <path d="M6 6a3 3 0 0 0 2 0 3 3 0 0 0 2 0v1.5H6V6Z" fill="#43458B"></path>
      <path
        d="M6 7.5c0 1.5 1.5 2.8 2 3 .5-.2 2-1.5 2-3H6Z"
        fill="#E25950"
      ></path>
      <path d="M2 6h2v4H2V6Zm10 0h2v4h-2V6Z" fill="#45B2E8"></path>
    </svg>
  );
}
export function UG() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <path
          fill="#333"
          d="M1.994 0h12.012C15.106 0 16 .896 16 2.001H0A2 2 0 0 1 1.994 0ZM0 6h16v2H0V6Z"
        ></path>
        <path fill="#FCD669" d="M0 2h16v2H0V2Zm0 6h16v2H0V8Z"></path>
        <path
          fill="#E25950"
          d="M0 4h16v2H0V4Zm16 6c0 1.112-.893 2-1.994 2H1.994C.894 12 0 11.105 0 10h16Z"
        ></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M15 4V2h1v2h-1ZM0 2h1v2H0V2Zm15 8V8h1v2h-1ZM0 8h1v2H0V8Z"
        ></path>
        <circle cx="8" cy="6" r="1" fill="#F7FAFC"></circle>
      </g>
    </svg>
  );
}
export function UA() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#45B2E8"
          d="M1.994 2h12.012C15.106 2 16 2.894 16 3.998V8H0V3.998C0 2.898.893 2 1.994 2Z"
        ></path>
        <path
          fill="#FCD669"
          d="M0 8h16v4.002c0 1.1-.893 1.998-1.994 1.998H1.994C.894 14 0 13.106 0 12.002V8Z"
        ></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M15 8h1v4a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8h1v4a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V8Z"
        ></path>
      </g>
    </svg>
  );
}
export function AE() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 16 16"
    >
      <defs>
        <filter
          id="fi_FlagIcon-AE__b"
          width="108.3%"
          height="125%"
          x="-4.2%"
          y="-12.5%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dx="-1"
            in="SourceAlpha"
            result="shadowOffsetInner1"
          ></feOffset>
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner1"
          ></feComposite>
          <feColorMatrix
            in="shadowInnerInner1"
            values="0 0 0 0 0.917647059 0 0 0 0 0.933333333 0 0 0 0 0.952941176 0 0 0 1 0"
          ></feColorMatrix>
        </filter>
        <path id="fi_FlagIcon-AE__a" d="M4 4h12v4H4z"></path>
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <path
          fill="#E25950"
          d="M2 0C.895 0 0 .902 0 2.009V9.99A2 2 0 0 0 2 12h2V0H2Z"
        ></path>
        <path fill="#24B47E" d="M4 0v4h12V2c0-1.105-.902-2-2.009-2H4Z"></path>
        <use fill="#F6F9FC" xlinkHref="#fi_FlagIcon-AE__a"></use>
        <use
          fill="#000"
          filter="url(#fi_FlagIcon-AE__b)"
          xlinkHref="#fi_FlagIcon-AE__a"
        ></use>
        <path fill="#333" d="M4 8v4h9.991A2 2 0 0 0 16 10V8H4Z"></path>
      </g>
    </svg>
  );
}
export function GB() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#43458B"
          fillRule="nonzero"
          d="M14 14H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Z"
        ></path>
        <path
          fill="#F6F9FC"
          fillRule="nonzero"
          d="M13.5 2h.5a2 2 0 0 1 2 2l-2.5 2H16v4h-2.5l2.5 1.5v.5a2 2 0 0 1-2 2h-1l-3-2.5V14H6v-2.5L2.5 14H2a2 2 0 0 1-2-2l2.5-2H0V6h2.5L0 4a2 2 0 0 1 2-2h.5L6 4.5V2h4v2.5L13.5 2Z"
        ></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M13.764 2H14a2 2 0 0 1 2 2l-1 .8V4a1 1 0 0 0-1-1h-1.9l1.4-1h.264zM15 6h1v1h-1V6zM0 7V6h1v1H0zm1-2.2L0 4a2 2 0 0 1 2-2h.5l1.4 1H2a1 1 0 0 0-1 1v.8zM6 3V2h4v1H6zm9 7V9h1v1h-1zm0 .9 1 .6v.5a2 2 0 0 1-2 2h-1l-1.2-1H14a1 1 0 0 0 1-1v-1.1zM10 13v1H6v-1h4zm-6.1 0-1.4 1H2a2 2 0 0 1-2-2l1-.8v.8a1 1 0 0 0 1 1h1.9zM1 10H0V9h1v1z"
        ></path>
        <path
          fill="#E25950"
          d="M9 9v5H7V9H0V7h7V2h2v5h7v2H9Zm5.794-6.836c.399.173.73.471.944.846L12 6h-2l4.794-3.836ZM.262 3.01a2.01 2.01 0 0 1 .943-.846L6 6H4L.262 3.01Zm.943 10.826a2.008 2.008 0 0 1-.943-.846L4 10h2l-4.795 3.836Zm14.533-.846a2.008 2.008 0 0 1-.943.846L10 10h2l3.738 2.99Z"
        ></path>
      </g>
    </svg>
  );
}
export function US() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#E25950"
          fillRule="nonzero"
          d="M14 14H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Z"
        ></path>
        <path
          fill="#F6F9FC"
          fillRule="nonzero"
          d="M0 11h16v1H0v-1Zm14 3H2a2 2 0 0 1-1.732-1h15.464A2 2 0 0 1 14 14ZM0 9h16v1H0V9Zm8-2h8v1H8V7Zm0-2h8v1H8V5Zm0-2h7.723c.171.295.277.634.277 1H8V3Z"
        ></path>
        <path
          fill="#E25950"
          fillRule="nonzero"
          d="M14 14H2a2 2 0 0 1-2-2V8h1v4a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H8V2h6a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Z"
          opacity="0.1"
        ></path>
        <path
          fill="#43458B"
          fillRule="nonzero"
          d="M0 8V3.714C0 2.768.796 2 1.778 2H8v6H0Z"
        ></path>
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M7.026 5.501a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM5.518 5a.5.5 0 1 1-.038-1 .5.5 0 0 1 .038 1Zm-.492.501a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM3.518 5a.5.5 0 1 1-.038-1 .5.5 0 0 1 .038 1Zm-.492.501a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm4-2a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm-2 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm-2 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm-1.507 1.5a.5.5 0 1 1-.038-.999.5.5 0 0 1 .038 1Zm.014 1A.5.5 0 1 1 1.57 7a.5.5 0 0 1-.037-1Zm2 0A.5.5 0 1 1 3.57 7a.5.5 0 0 1-.037-1Zm2 0A.5.5 0 1 1 5.57 7a.5.5 0 0 1-.037-1Z"
        ></path>
        <path
          fill="#FFF"
          d="M6.533 7a.5.5 0 1 1 .037 1 .5.5 0 0 1-.037-1Zm-2 0a.5.5 0 1 1 .037 1 .5.5 0 0 1-.037-1Zm-2 0a.5.5 0 1 1 .037 1 .5.5 0 0 1-.037-1ZM.482 7a.5.5 0 1 1 .036 1 .5.5 0 0 1-.036-1Zm0-2a.5.5 0 1 1 .036 1 .5.5 0 0 1-.036-1Zm0-2a.5.5 0 1 1 .036 1 .5.5 0 0 1-.036-1Zm1-1a.5.5 0 1 1 .036 1 .5.5 0 0 1-.036-1Zm2 0a.5.5 0 1 1 .036 1 .5.5 0 0 1-.036-1Zm2 0a.5.5 0 1 1 .036 1 .5.5 0 0 1-.036-1Zm2 0a.5.5 0 1 1 .036 1 .5.5 0 0 1-.036-1Zm0 2a.5.5 0 1 1 .036 1 .5.5 0 0 1-.036-1Zm0 2a.5.5 0 1 1 .036 1 .5.5 0 0 1-.036-1Z"
          opacity="0.5"
        ></path>
      </g>
    </svg>
  );
}
export function UY() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#F6F9FC"
          fillRule="nonzero"
          d="M14 14H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Z"
        ></path>
        <path
          fill="#F4B32E"
          d="M3.5 7a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
        ></path>
        <path
          fill="#43458B"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M14 14H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Zm1-10a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4Z"
        ></path>
        <path
          fill="#43458B"
          fillRule="nonzero"
          d="M14 14H2a2 2 0 0 1-2-2h16a2 2 0 0 1-2 2ZM0 9h16v2H0V9Zm7-3h9v2H7V6Zm9-2v1H7V3h8.732c.17.294.268.636.268 1Z"
        ></path>
      </g>
    </svg>
  );
}
export function UZ() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <g transform="translate(0 2)">
          <path
            fill="#217AB7"
            d="M1.994 0h12.012C15.106 0 16 .895 16 2v2H0V2C0 .888.893 0 1.994 0Z"
          ></path>
          <circle cx="3" cy="2" r="1" fill="#FFF"></circle>
          <path fill="#F6F9FC" fillRule="nonzero" d="M0 4h16v4H0z"></path>
          <path
            fill="#EAEEF3"
            fillRule="nonzero"
            d="M15 8V4h1v4h-1ZM0 4h1v4H0V4Z"
          ></path>
          <path
            fill="#24B47E"
            d="M0 8h16v2c0 1.112-.893 2-1.994 2H1.994C.894 12 0 11.105 0 10V8Z"
          ></path>
        </g>
        <path fill="#E25950" d="M0 6h16v1H0zm0 3h16v1H0z"></path>
      </g>
    </svg>
  );
}
export function VU() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#E25950" rx="2"></rect>
        <path
          fill="#24B47E"
          d="M0 6v4.002C0 11.106.895 12 1.994 12h12.012A1.998 1.998 0 0 0 16 10.002V6H0Z"
        ></path>
        <path
          fill="#333"
          d="M6.672 5H16v2H6.661L.8 11.396a.5.5 0 0 1-.151.077A2.01 2.01 0 0 1 0 9.991V2.01C0 1.419.253.889.656.52A.5.5 0 0 1 .8.596L6.672 5Z"
        ></path>
        <path
          fill="#FCD669"
          d="M16 5.5v1H6.363L.308 11.063A2.007 2.007 0 0 1 0 10.043l5.396-4.067L.002 1.91C.02 1.54.138 1.196.33.904L6.427 5.5H16Z"
        ></path>
        <circle cx="2" cy="6" r="1" fill="#FCD669"></circle>
      </g>
    </svg>
  );
}
export function VA() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#F6F9FC"
          d="M8 2v12h6.002A2.005 2.005 0 0 0 16 11.991V4.01C16 2.899 15.113 2 14.002 2H8Z"
        ></path>
        <path
          fill="#FCD669"
          d="M1.998 2A2.005 2.005 0 0 0 0 4.009v7.982C0 13.101.887 14 1.998 14H8V2H1.998Z"
        ></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M14 3H8V2h6a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H8v-1h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1Z"
        ></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M2 3a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h6v1H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h6v1H2Z"
        ></path>
        <path
          fill="#CFD7DF"
          fillRule="nonzero"
          d="M13.5 10.165A.5.5 0 1 0 13 9.3a.5.5 0 0 0 .5.866ZM10.933 4.72l2.043 3.538a1.5 1.5 0 1 1-.866.5l-1.543-2.672-.866.5-.5-.866 1.732-1Z"
        ></path>
        <path
          fill="#FCD669"
          fillRule="nonzero"
          d="M10 10.165a.5.5 0 1 0 .5-.866.5.5 0 0 0-.5.866Zm2.567-5.446 1.732 1-.5.866-.866-.5-1.543 2.672a1.5 1.5 0 1 1-.866-.5l2.043-3.538Z"
        ></path>
      </g>
    </svg>
  );
}
export function VE() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FCD669"
          d="M1.994 2A1.992 1.992 0 0 0 0 4v2h16V4c0-1.105-.895-2-1.994-2H1.994Z"
        ></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M15 6V4a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2H0V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v2h-1Z"
        ></path>
        <path
          fill="#E25950"
          d="M0 10v2c0 1.105.895 2 1.994 2h12.012A1.992 1.992 0 0 0 16 12v-2H0Z"
        ></path>
        <path fill="#43458B" d="M0 6h16v4H0z"></path>
        <path
          fill="#FFF"
          d="M4.5 8.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0ZM6 8a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm1.5-.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0ZM9 8a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm1.5.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Z"
        ></path>
      </g>
    </svg>
  );
}
export function VN() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#E25950" rx="2"></rect>
        <path
          fill="#FCD669"
          d="M8.08 7.785 6.357 8.821a.3.3 0 0 1-.447-.327l.457-1.905-1.545-1.443a.3.3 0 0 1 .192-.52l1.898-.083.895-1.95a.3.3 0 0 1 .546 0l.895 1.95 1.898.084a.3.3 0 0 1 .192.519L9.793 6.589l.457 1.905a.3.3 0 0 1-.447.327L8.08 7.785Z"
        ></path>
      </g>
    </svg>
  );
}
export function WF() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#E25950"
          fillRule="nonzero"
          d="M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"
        ></path>
        <path
          fill="#F6F9FC"
          d="M11 7.5 12.5 9 11 10.5v-3Zm3.5-.5L13 8.5 11.5 7h3Zm0 4h-3L13 9.5l1.5 1.5Zm.5-3.5v3L13.5 9 15 7.5Z"
        ></path>
        <path fill="#43458B" d="M2 2h1v6H0V4a2 2 0 0 1 2-2Z"></path>
        <path fill="#F6F9FC" d="M3 2h3v6H3z"></path>
        <path fill="#EAEEF3" d="M3 2h3v1H3z"></path>
        <path fill="#E25950" d="M6 2h3v6H6z"></path>
        <path fill="#FFF" d="M9 8V2h.5v6.5H0V8z"></path>
      </g>
    </svg>
  );
}
export function EH() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#F6F9FC" rx="2"></rect>
        <path
          fill="#E25950"
          d="M11 4.268a1.75 1.75 0 1 0 0 3.464 2 2 0 1 1 0-3.464ZM11 7a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
        ></path>
        <path fill="#EAEEF3" fillRule="nonzero" d="M15 8V4h1v4z"></path>
        <path fill="#333" d="M0 2v2h16V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2Z"></path>
        <path
          fill="#24B47E"
          d="M0 8v2c0 1.105.895 2 1.994 2h12.012A1.992 1.992 0 0 0 16 10V8H0Z"
        ></path>
        <path
          fill="#E25950"
          d="M.7 11.52A2.01 2.01 0 0 1 0 9.99V2.01C0 1.395.275.844.707.475a.504.504 0 0 1 .07.039L7 5.996.777 11.478a.5.5 0 0 1-.076.042Z"
        ></path>
      </g>
    </svg>
  );
}
export function YE() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#E25950"
          d="M1.994 2A1.992 1.992 0 0 0 0 4v2h16V4c0-1.105-.895-2-1.994-2H1.994Z"
        ></path>
        <path fill="#F6F9FC" d="M0 6h16v4H0z"></path>
        <path
          fill="#EAEEF3"
          fillRule="nonzero"
          d="M15 10V6h1v4h-1ZM0 6h1v4H0V6Z"
        ></path>
        <path
          fill="#333"
          d="M0 10v2c0 1.105.895 2 1.994 2h12.012A1.992 1.992 0 0 0 16 12v-2H0Z"
        ></path>
      </g>
    </svg>
  );
}
export function ZM() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <rect width="16" height="12" fill="#159570" rx="2"></rect>
        <path fill="#E25950" d="M10 5h2v7h-2z"></path>
        <path fill="#333" d="M12 5h2v7h-2z"></path>
        <path
          fill="#F79A59"
          d="M16 5v4.991A2.003 2.003 0 0 1 14.006 12L14 5h2Zm-5-3h4v1h-4V2Z"
        ></path>
      </g>
    </svg>
  );
}
export function ZW() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" transform="translate(0 2)">
        <path
          fill="#24B47E"
          d="M1.994 0h12.012C15.106 0 16 .896 16 2.001H0A2 2 0 0 1 1.994 0ZM16 10c0 1.112-.893 2-1.994 2H1.994C.894 12 0 11.105 0 10h16Z"
        ></path>
        <path fill="#FCD669" d="M0 2h16v2H0V2Zm0 6h16v2H0V8Z"></path>
        <path fill="#E25950" d="M0 4h16v4H0z"></path>
        <path fill="#333" d="M0 5h16v2H0z"></path>
        <path
          fill="#A68527"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M15 4V2h1v2h-1ZM0 2h1v2H0V2Zm15 8V8h1v2h-1ZM0 8h1v2H0V8Z"
        ></path>
        <path
          fill="#F6F9FC"
          d="M.7 11.52A2.01 2.01 0 0 1 0 9.99V2.01C0 1.395.275.844.707.475a.504.504 0 0 1 .07.039L9 5.996.777 11.478a.5.5 0 0 1-.076.042Z"
        ></path>
        <path
          fill="#EAEEF3"
          d="M1.619 1.075A1 1 0 0 0 1 2v8a1 1 0 0 0 .611.922l-.834.556a.5.5 0 0 1-.076.042A2.01 2.01 0 0 1 0 9.99V2.01C0 1.395.275.844.707.475a.504.504 0 0 1 .07.039l.842.561Z"
        ></path>
        <circle cx="3" cy="6" r="1" fill="#E25950"></circle>
      </g>
    </svg>
  );
}
