import { useRect } from "@reach/rect";
import Box from "components/box";
import { Flex } from "components/layout";
import Tag from "components/tag";
import Text from "components/text";
import { format, subDays, addDays, isBefore } from "date-fns";
import {
  BankAccount,
  DailyBalance,
  JournalEntry,
} from "generated/__generated_graphql";
import React, { useRef } from "react";
import {
  HiLibrary,
  HiOutlineCalendar,
  HiOutlineExclamationCircle,
  HiOutlineLibrary,
} from "react-icons/hi";

import { styled } from "stitches/stitches.config";
import { mergeRefs } from "utils/dom";
import { formatMoney } from "utils/helpers";
import BalanceChart from "./balance-chart";

const Wrapper = styled("div", {
  display: "flex",
  flexDirection: "column",

  background: "$gray12",
  minHeight: 400,
  width: "100%",
  borderRadius: 6,
});

type BalanceChartProps = {
  bankAccounts?: Array<BankAccount>;
  transactions?: Array<JournalEntry>;
  balances?: Array<DailyBalance>;
  totalBalance?: number;
};

const BalanceChartCard = React.forwardRef<HTMLDivElement, BalanceChartProps>(
  ({ bankAccounts, transactions = [], balances = [], totalBalance }, ref) => {
    const wrapperRef = useRef<HTMLDivElement>(null);

    const wrapperWidth = useRect(wrapperRef)?.width || 100;

    return (
      <Wrapper ref={mergeRefs(ref, wrapperRef)}>
        <Flex css={{ px: 24, pt: 24 }}>
          <Flex gap={3} stack>
            <Text size="sm">Available Float Balance</Text>
            <Text
              weight={"regular"}
              css={{ letterSpacing: 1 }}
              fontFamily={"haffer"}
              color="white"
              size="3xl"
            >
              {formatMoney(totalBalance)}
            </Text>
          </Flex>
        </Flex>

        <Box>
          <BalanceChart balances={balances} wrapperWidth={wrapperWidth} />
        </Box>

        <Flex gap={2} css={{ px: 24, pb: 24 }}>
          <Tag shape="pill" css={{ background: "$whiteA5", color: "White" }}>
            All accounts
          </Tag>
          <Tag shape="pill" css={{ background: "$whiteA5", color: "White" }}>
            Last 30 days
          </Tag>
        </Flex>
      </Wrapper>
    );
  }
);

BalanceChartCard.displayName = "BalanceChart";

export default BalanceChartCard;
