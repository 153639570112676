import { styled } from "stitches/stitches.config";

export const StyledLabel = styled("label", {
  display: "block",
  color: "$gray10",
  fontFamily: "$medium",
  fontWeight: 500,
  fontSize: 13,
  // letterSpacing: 0.3,
  textAlign: "left",

  span: {
    marginLeft: 2,
    color: "$red10",
  },
});
