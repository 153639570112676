import Box from "components/box";
import ReactDOM from "react-dom";
import ToastAlert from "./toast-alert";
import {
  ToastCreateOptions,
  ToastPosition,
  ToastProps,
  ToastState,
} from "./toast.types";
import { AnimatePresence } from "framer-motion";

const toastElementId = "float-toast-portal";

export class ToastManager {
  private toastElementContainer: HTMLElement;
  private toastPositionMap: ToastState = {
    top: [],
    bottom: [],
    "top-right": [],
    "top-left": [],
    "bottom-right": [],
    "bottom-left": [],
  };

  constructor() {
    let toastElement: HTMLElement;
    const existingToastContainer = document.getElementById(toastElementId);

    if (existingToastContainer) {
      toastElement = existingToastContainer;
    } else {
      const div = document.createElement("div");
      div.id = toastElementId;
      document.body?.appendChild(div);
      toastElement = div;
    }
    this.toastElementContainer = toastElement;
  }

  private findToastPosition(id: string | number) {
    return Object.values(this.toastPositionMap)
      .flat()
      .find((toast) => toast.id === id)?.position;
  }

  getStyle = (position: ToastPosition): React.CSSProperties => {
    const isTopOrBottom = position === "top" || position === "bottom";
    const margin = isTopOrBottom ? "0 auto" : undefined;

    const top = position.includes("top") ? " 20px" : undefined;
    const bottom = position.includes("bottom") ? "20px" : undefined;
    const right = !position.includes("left") ? "20px" : undefined;
    const left = !position.includes("right") ? "20px" : undefined;

    return {
      position: "fixed",
      zIndex: 5500,
      display: "flex",
      flexDirection: "column",
      alignItems: isTopOrBottom ? "center" : "end",
      minWidth: 300,
      margin,
      top,
      bottom,
      right,
      left,
    };
  };

  public show(options: ToastCreateOptions): void {
    const toastId = Math.random().toString(36).substr(2, 9);
    const toastPosition = options.position ?? "top";
    const toast: ToastProps = {
      ...options,
      position: toastPosition,
      id: toastId,
      destroy: () => this.destroy(options.id ?? toastId),
    };

    const isTop = toastPosition.includes("top");
    const toasts = isTop
      ? [toast, ...this.toastPositionMap[toastPosition]]
      : [...this.toastPositionMap[toastPosition], toast];

    this.toastPositionMap = {
      ...this.toastPositionMap,
      [toastPosition]: toasts,
    };

    this.render();
  }

  public destroy(id: string | number): void {
    const toastPosition = this.findToastPosition(id);
    if (!toastPosition) return;

    const filteredToasts = this.toastPositionMap[toastPosition].filter(
      (toast: ToastProps) => toast.id !== id
    );
    this.toastPositionMap = {
      ...this.toastPositionMap,
      [toastPosition]: filteredToasts,
    };

    this.render();
  }

  private render() {
    const toastElements = Object.keys(this.toastPositionMap).map((position) => {
      const toasts = this.toastPositionMap[position as ToastPosition];
      return (
        <Box
          key={position}
          style={this.getStyle(position as ToastPosition)}
          id={`float-toast-manager-${position}`}
        >
          <AnimatePresence initial>
            {toasts.map((toast) => (
              <ToastAlert key={toast.id} {...toast} />
            ))}
          </AnimatePresence>
        </Box>
      );
    });

    ReactDOM.render(toastElements, this.toastElementContainer);
  }
}

export const toast = new ToastManager();
