import Avatar from "components/avatar";
import Box from "components/box";
import { REVERSAL_NARRATION } from "components/move-money/move-money.utils";
import Text from "components/text";
import { isAfter } from "date-fns";
import { JournalEntry } from "generated/__generated_graphql";
import React from "react";
import {
  HiArrowSmLeft,
  HiArrowSmRight,
  HiCreditCard,
  HiExclamationCircle,
  HiGlobe,
  HiGlobeAlt,
  HiLibrary,
  HiOutlineCalendar,
  HiOutlineCreditCard,
  HiOutlineExclamationCircle,
  HiRefresh,
  HiReply,
  HiX,
} from "react-icons/hi";
import { styled, VariantProps } from "stitches/stitches.config";

const Indicator = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  size: 24,
  borderRadius: "50%",
  border: "3px solid white !important",

  position: "absolute",
  bottom: 0,
  left: -15,

  variants: {
    variant: {
      debit: {
        background: "$red3",
        color: "$red11",
        border: "2px solid $red4",
      },
      credit: {
        background: "$green3",
        color: "$green11",
        border: "2px solid $green4",
      },
      pending: {
        background: "$gray3",
        color: "$gray11",
        border: "2px solid $gray4",
      },
      none: {
        display: "none",
      },
    },
  },
});

type PaymentIndicatorProps = React.ComponentProps<typeof Avatar> & {
  transaction?: JournalEntry;
  useIcon?: boolean;
};

const PaymentIndicator = React.forwardRef<
  HTMLDivElement,
  PaymentIndicatorProps
>(({ size, transaction, useIcon, ...props }, ref) => {
  const type =
    (transaction?.transactionType as VariantProps<
      typeof Indicator
    >["variant"]) || "none";

  const isPending: boolean = transaction?.state === "pending";
  const isCompleted: boolean = transaction?.state === "completed";
  const isFailed: boolean = ["cancelled", "returned"].includes(
    transaction?.state!
  );

  const merchantLogo = transaction?.merchant?.iconUrl;

  const borderStyle = transaction?.state === "pending" ? "dashed" : "solid";
  const isScheduled =
    isAfter(new Date(transaction?.moneyTransfer?.effectiveDate), new Date()) &&
    transaction?.transactionType === "debit" &&
    isPending;

  const isReversed = !!transaction?.originalEntryId;

  const name =
    transaction?.transactionType === "credit"
      ? transaction.origin.name
      : transaction?.destination.name;

  function getIndicator() {
    if (type === "credit" && isCompleted) {
      return <HiArrowSmLeft style={{ rotate: "-45deg" }} />;
    }
    if (type === "debit" && isCompleted) {
      return <HiArrowSmRight style={{ rotate: "-45deg" }} />;
    }
    if (type === "debit" && isFailed) {
      return <HiOutlineExclamationCircle size={16} />;
    }
    if (type === "debit" && isReversed) {
      return <HiReply />;
    }

    if (type === "debit" && isScheduled) {
      return <HiOutlineCalendar />;
    }

    if (isPending) {
      return <HiRefresh />;
    }
  }

  function getIcon() {
    if (transaction?.paymentMethod === "international_wire") {
      return <HiGlobeAlt />;
    }

    if (transaction?.paymentMethod === "card") {
      return <HiCreditCard />;
    }

    return <HiLibrary />;
  }

  return (
    <Avatar
      color={!!merchantLogo ? "gray" : "gray"}
      variant={"outline"}
      name={name.split(" ")[0]}
      size={size}
      src={merchantLogo ?? ""}
    ></Avatar>
  );
});

PaymentIndicator.displayName = "PaymentIndicator";

export default PaymentIndicator;
