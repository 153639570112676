import Input, { InputProps } from "components/input";
import React from "react";
import { mergeRefs } from "utils/dom";
import { formatMoney } from "utils/helpers";

type NumberInputProps = InputProps & {
  onValueChange?(value: number): void;
  preventLeadingZeros?: boolean;
  currency?: string;
  max?: number;
  min?: number;
};

const NumberInput = React.forwardRef<HTMLDivElement, NumberInputProps>(
  (
    {
      onChange,
      value,
      onValueChange,
      onKeyDown,
      onFocus,
      onBlur,
      preventLeadingZeros = false,
      currency = "₦",
      max,
      min,
      ...props
    },
    ref
  ) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    function handleFocus() {}
    function handleBlur() {}

    function positionCursor(start: number, end: number) {
      inputRef.current?.setSelectionRange?.(start, end);
    }

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
      const { selectionStart: cursorPosition, value } = e.target;
      const number = parseFloat(value.replace(/[^0-9.]/g, ""));
      const formattedValue = formatMoney(number, currency);

      if (!value) {
        onValueChange?.(0);
        return;
      }

      if (formattedValue !== value) {
        e.target.value = formattedValue;
        positionCursor(cursorPosition!, cursorPosition!);
      }

      if (number > max! || number < min!) return;
      onValueChange?.(number);
    }
    function handleKeyDown() {}

    return (
      <Input
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        value={value}
        onKeyDown={handleKeyDown}
        ref={mergeRefs(ref)}
        inputRef={inputRef}
        {...props}
      ></Input>
    );
  }
);

NumberInput.displayName = "NumberInput";
export default NumberInput;
