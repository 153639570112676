/* eslint-disable react/prop-types */
import React, { isValidElement } from "react";
import { CSS, styled } from "stitches/stitches.config";
import { cx } from "utils/dom";
import { ResponsiveProps } from "utils/types";
import Flex from "./flex";

const StyledFlex = styled(Flex, { width: "auto" });

type IProps = React.ComponentProps<typeof StyledFlex> & {
  spacing?: ResponsiveProps<number>;
  isInline?: boolean;
};

const Stack = React.forwardRef<HTMLDivElement, IProps>(
  ({ spacing = 16, isInline, className, children, css, ...props }, ref) => {
    const childrenCount = React.Children.count(children);
    return (
      <StyledFlex
        ref={ref}
        direction={isInline ? "row" : "column"}
        className={cx(["stack", className])}
        css={css}
        {...props}
      >
        {React.Children.map(children, (child, index) => {
          const isLastChild = index === childrenCount - 1;

          if (!child) {
            return;
          }

          if (!isValidElement(child)) {
            return null;
          }

          return React.cloneElement(child as React.ReactElement, {
            css: {
              ...child.props.css,
              marginBottom: !isInline && !isLastChild ? spacing : 0,
              marginRight: isInline && !isLastChild ? spacing : 0,
            } as CSS,
          });
        })}
      </StyledFlex>
    );
  }
);

Stack.displayName = "Stack";

export default Stack;
