import Avatar from "components/avatar";
import { Flex } from "components/layout";
import Table, { ITableColumn } from "components/table";
import Tag from "components/tag";
import Text from "components/text";
import { formatRelative } from "date-fns";
import { Business } from "generated/__generated_graphql";
import { useAppProvider } from "providers/app-provider/app-provider";
import { HiOutlineExclamationCircle, HiOutlineStar } from "react-icons/hi";
import { RowItem, tableStyles } from "../settings.styles";

export default function ProfileSettings() {
  const { user } = useAppProvider();

  const userBusinessColumns: Array<ITableColumn<Business>> = [
    {
      title: "Business Name",
      key: "name",
      dataIndex: "name",
      render: (_, business) => (
        <Flex css={{ py: 8 }} gap={4} align="start">
          <Avatar
            size="sm"
            variant={"outline"}
            name={business?.name?.split(" ")[0]}
          ></Avatar>
          <Flex stack gap={1}>
            <Text>{business.name}</Text>
            <Text size="xs" color="light">
              {user?.id === business.owner.id ? "Owner" : "Invited"}
            </Text>
          </Flex>
        </Flex>
      ),
    },
  ];

  return (
    <Flex stack gap={7}>
      <RowItem>
        <Flex>
          <Text color="black" className="key">
            Name
          </Text>
          <Text>{user?.name}</Text>
        </Flex>
      </RowItem>
      <RowItem>
        <Flex>
          <Text color="black" className="key">
            Email address
          </Text>
          <Text>{user?.email}</Text>
        </Flex>
      </RowItem>
      <RowItem>
        <Flex>
          <Text color="black" className="key">
            Role
          </Text>
          <Flex gap={2}>
            <Tag size="xs" shape={"pill"} variant={"outline"} color="teal">
              {user?.role?.name}
            </Tag>

            {user?.isOwner && (
              <Tag
                appendIcon={<HiOutlineStar />}
                size="xs"
                shape={"pill"}
                variant={"outline"}
                color="orange"
              >
                Owner
              </Tag>
            )}
          </Flex>
        </Flex>
      </RowItem>
      <RowItem>
        <Flex>
          <Text color="black" className="key">
            Date created
          </Text>
          <Text>{formatRelative(new Date(user?.createdAt), new Date())}</Text>
        </Flex>
      </RowItem>

      <RowItem>
        <Flex stretchX stack gap={6}>
          <Flex className="item-wrapper">
            <Flex align="start" gap={2} stack className="key">
              <Text weight={"semi"} color="black">
                Linked businesses
              </Text>
              <Text lineHeight={"tall"} size="xs" color="light">
                This shows a list of all businesses you have access to
              </Text>
            </Flex>
            {/* 
              <Button size="sm" appearance={"secondary"} shape="pill">
                Logout of all sessions
              </Button> */}
          </Flex>

          <Table
            css={tableStyles}
            columns={userBusinessColumns}
            data={user?.businesses || []}
          ></Table>
        </Flex>
      </RowItem>

      <Flex css={{ width: 500, color: "$gray8" }} align="start" gap={2}>
        {/* <HiOutlineExclamationCircle style={{ flexShrink: 0 }} /> */}
        <Text
          lineHeight={"base"}
          weight="regular"
          css={{ fontSize: 11, letterSpacing: 0 }}
          color="inherit"
          fontFamily={"monospace"}
        >
          By continuing to use your Float account You agree to Float's Terms of
          Use, Privacy Policy and to receive electronic communication about my
          accounts.
        </Text>
      </Flex>
    </Flex>
  );
}
