import { gray, green } from "@radix-ui/colors";
import Avatar from "components/avatar";
import Button from "components/button";
import { Flex } from "components/layout";
import Loader from "components/loader";
import Separator from "components/separator";
import Sticker from "components/sticker";
import Text from "components/text";
import { useToast } from "components/toast";
import {
  RecipientInput,
  TransferMoneyMutationVariables,
  useTransferMoneyMutation,
} from "generated/__generated_graphql";
import { useAppProvider } from "providers/app-provider/app-provider";
import React from "react";
import {
  HiGlobe,
  HiGlobeAlt,
  HiLibrary,
  HiOutlineCheckCircle,
  HiOutlineGlobeAlt,
  HiOutlineShieldCheck,
  HiOutlineShieldExclamation,
  HiReply,
  HiShieldCheck,
} from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { styled } from "stitches/stitches.config";
import { extractGraphqlErrors, formatMoney } from "utils/helpers";
import { ActionStatus } from "utils/types";
import { SendMoneyComponentProps } from "../move-money.types";
import BankLookUp from "./bank-lookup";
import { BackButton, NextButton } from "./nav-buttons";
import TransactionTimeline from "./transaction-timeline";

const Wrapper = styled(Flex, {
  width: "100%",
  ".rounded-container": {
    border: "1.5px solid $gray3",
    borderRadius: 8,
    wdith: "100%",

    ".item": {
      px: 12,
      py: 12,
      "&:not(:last-child)": { borderBottom: "1.5px solid $gray3" },
    },
  },

  ".key": {
    color: "$gray9",
  },
  ".value": {},
});

const ReviewTransaction = React.forwardRef<
  HTMLDivElement,
  SendMoneyComponentProps
>(({ form }, ref) => {
  const [status, setStatus] = React.useState<ActionStatus>("success");
  const navigate = useNavigate();
  const bankAccounts = useAppProvider().user?.business?.bankAccounts || [];
  const notify = useToast();

  const input = form.values;

  const isIntWire = input.methodOfPayment === "international_wire";
  const isDomWire = input.methodOfPayment === "domestic_wire";
  const isACH = input.methodOfPayment === "ach";
  const fee = input.fee || 0;
  const receivingBankIsVerified = !!input.bankName;
  const [{ fetching: sending }, transferMoney] = useTransferMoneyMutation();

  function buildPayload() {
    const basePayload: Partial<TransferMoneyMutationVariables> = {
      amount: input.amount,
      narration: input.externalMemo,
      bankAccountId: input.bankAccountId as number,
      effectiveDate: input.effectiveDate,
      attachmentIds: input.attachments?.map((a) => a.id as any) || [],
      internalMemo: input.internalMemo,
      externalMemo: input.externalMemo,
    };

    const address = {
      line1: input.recipientAddress,
      city: input.recipientCity,
      state: input.recipientState,
      postalCode: input.recipientZip,
      countryCode: input.recipientCountry,
    };

    const baseRecipientPayload: Partial<RecipientInput> = {
      name: input.recipientName,
      methodOfPayment:
        input.methodOfPayment as RecipientInput["methodOfPayment"],
      countryCode: input.recipientCountry,
      email: input.recipientEmail,
    };

    if (!String(input.recipientId).includes("TEMP_")) {
      baseRecipientPayload["id"] = input.recipientId as any;
    }

    if (isIntWire) {
      return {
        ...basePayload,
        recipient: {
          ...baseRecipientPayload,
          internationalWireInput: {
            id: input?.paymentMethodId,
            swiftCode: input.swiftCode,
            accountNumber: input.accountNumber,
            recipientType: input.recipientType,
            purposeOfTransfer: input.purposeOfPayment,
            sourceOfFunds: input.sourceOfFunds,
            recipientAddressAttributes: address,
            bankAddressAttributes: input.bankAddress,
          },
        },
      };
    }

    if (isDomWire || isACH) {
      return {
        ...basePayload,
        recipient: {
          ...baseRecipientPayload,
          [isDomWire ? "domesticWireInput" : "achInput"]: {
            id: input?.paymentMethodId,
            routingNumber: input.routingNumber,
            accountNumber: input.accountNumber,
            accountType: input.accountType,
            recipientAddressAttributes: address,
          },
        },
      };
    }
  }

  async function onTransferMoney() {
    const payload = buildPayload();
    try {
      const response = await transferMoney(
        payload as TransferMoneyMutationVariables
      );
      const error = extractGraphqlErrors(response, "transferMoney");
      if (error) {
        notify({
          content: error,
          status: "error",
          position: "top",
          showIcon: true,
        });
        return;
      }
      navigate("/dashboard/send-money/success");
    } catch (error) {
      console.error(error);
      notify({
        content: "Something went wrong! Please try again",
        status: "error",
        position: "top",
        showIcon: true,
      });
    }
  }

  return (
    <Wrapper ref={ref} stack gap={10}>
      <Flex stretchX stack>
        <Flex stretchX align={"center"} stack gap={6}>
          <Flex align="center" gap={2}>
            <Avatar name="P" size="xl"></Avatar>
            <HiReply
              color={gray.gray8}
              style={{ rotate: "180deg" }}
              size={20}
            />
            <Avatar size={"xl"} color="gray" variant={"light"}>
              {isIntWire ? (
                <HiOutlineGlobeAlt size={30} />
              ) : (
                <HiLibrary size={20} />
              )}
            </Avatar>
          </Flex>
          <Flex gap={2} align="center" stack>
            <Text rounded color={"black"} size="4xl">
              {formatMoney(input.amount)}
            </Text>
            <Text color={"light"}>To {input.recipientName}</Text>
          </Flex>
        </Flex>

        {input.externalMemo ? (
          <>
            <Separator spacing={30}></Separator>
            <Text align={"center"}>{input.externalMemo}</Text>

            <Separator spacing={30}></Separator>
          </>
        ) : null}
        <Flex css={{ marginTop: input.externalMemo ? 0 : 30 }} gap={4} stack>
          <Flex className="rounded-container">
            <Flex className="item" stretchX align="center" justify={"between"}>
              <Text className="key">From</Text>
              <Text className="value">
                {
                  bankAccounts.find(
                    (account) => account.id === input.bankAccountId
                  )?.accountName
                }
              </Text>
            </Flex>
          </Flex>

          <Flex stack className="rounded-container">
            <Flex className="item" stretchX align="center" justify={"between"}>
              <Text className="key">To</Text>
              <Text className="value">{input.recipientName}</Text>
            </Flex>
            <Flex gap={2} stack className="item">
              <Flex stretchX align="center" justify={"between"}>
                <Text className="key">
                  {" "}
                  {isIntWire ? "SWIFT code" : "Routing number"}
                </Text>
                <Text className="value">
                  {isIntWire ? input.swiftCode : input.routingNumber}
                </Text>
              </Flex>
              {receivingBankIsVerified && (
                <BankLookUp bankName={input.bankName} />
              )}
            </Flex>
            <Flex className="item" stretchX align="center" justify={"between"}>
              <Text className="key">Account number</Text>
              <Text className="value">{input.accountNumber}</Text>
            </Flex>

            {/* <Flex className="item" stretchX align="center" justify={"between"}>
              <Text className="key">Bank location</Text>
              <Text className="value">Toronto, CA</Text>
            </Flex> */}
          </Flex>
        </Flex>

        <Separator spacing={30}></Separator>

        <Flex stack>
          <Flex className="item" stretchX align="center" justify={"between"}>
            <Text className="key">Amount</Text>
            <Text className="value">{formatMoney(input.amount)}</Text>
          </Flex>

          <Separator spacing={16}></Separator>

          {!!fee && (
            <>
              <Flex
                className="item"
                stretchX
                align="center"
                justify={"between"}
              >
                <Text className="key">Fee</Text>
                <Text className="value">{formatMoney(fee)}</Text>
              </Flex>

              <Separator spacing={16}></Separator>
            </>
          )}

          <Flex className="item" stretchX align="center" justify={"between"}>
            <Text size="lg" className="key">
              Total
            </Text>
            <Text color="black" size="lg" className="value">
              {formatMoney(input.amount + fee)}
            </Text>
          </Flex>
          <Separator spacing={16}></Separator>
        </Flex>
      </Flex>

      <Flex justify={"center"} gap={3}>
        <BackButton to="/schedule">Back</BackButton>
        <Button
          isLoading={sending}
          appearance={"secondary"}
          size="sm"
          onClick={onTransferMoney}
        >
          Send Payment
        </Button>
      </Flex>
    </Wrapper>
  );
});

ReviewTransaction.displayName = "ReviewTransaction";

export default ReviewTransaction;
