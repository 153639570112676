import React from "react";
import { getToken, setToken } from "utils/auth";
import {
  createClient,
  dedupExchange,
  cacheExchange,
  fetchExchange,
  Provider,
  mapExchange,
} from "urql";
import { devtoolsExchange } from "@urql/devtools";
import * as Sentry from "@sentry/react";
import { baseServerUrl } from "utils/helpers";

const client = createClient({
  url: baseServerUrl() as string,
  fetchOptions: () => {
    const token = getToken();
    if (token) {
      return { headers: { Authorization: `Bearer ${token}` } };
    }

    return {};
  },

  exchanges: [
    devtoolsExchange,
    dedupExchange,
    cacheExchange,
    mapExchange({
      onError: (error) => {
        const isAuthError = error.graphQLErrors.some(
          (e) => e.message === "Unauthenticated"
        );
        process.env.NODE_ENV === "development" && console.error(error);

        if (isAuthError) {
          Sentry.configureScope((scope) => scope.setUser(null));
          setToken(null);
          window.location.href = "/login";
        }
      },
    }),
    fetchExchange,
  ],
});

export default function UrqlProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return <Provider value={client}>{children}</Provider>;
}
