import React from "react";
import Button from "components/button";
import Card from "components/card";
import { HiChevronRight, HiOutlineArrowRight } from "react-icons/hi";
import { Flex } from "components/layout";
import Text from "components/text";
import PaymentItem from "./payment-summary-item";
import { formatMoney } from "utils/helpers";
import { JournalEntry } from "generated/__generated_graphql";
import LinkButton from "components/link-button";

type PaymentsSummaryCardProps = {
  total?: number;
  title?: React.ReactNode;
  transactions?: Array<JournalEntry>;
  emptyState?: React.ReactNode;
};

function groupTransactionsByDate(transactions: Array<JournalEntry>) {
  const groupedTransactions = transactions.reduce((acc, transaction) => {
    const date = new Date(
      transaction.moneyTransfer?.effectiveDate || transaction.createdAt
    ).toDateString();
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(transaction);
    return acc;
  }, {} as Record<string, Array<JournalEntry>>);

  return groupedTransactions;
}

const PaymentsSummaryCard = React.forwardRef<
  HTMLDivElement,
  PaymentsSummaryCardProps
>(({ total = 0, title, transactions = [], emptyState = <Text color="light">
      No transactions yet
    </Text> }, ref) => {
  const transactionsByDate = groupTransactionsByDate(transactions || []);

  // console.log(title, groupTransactionsByDate(transactions || []));

  return (
    <Card>
      <Flex gap={8} className="wrapper" stack>
        <Flex className="head" align="center" justify={"between"}>
          <Flex stack gap={3}>
            <Text as="div" color="light">
              {title}
            </Text>
            <Text
              fontFamily={"haffer"}
              weight="regular"
              size="2xl"
              color="black"
            >
              {formatMoney(total)}
            </Text>
          </Flex>

          {transactions.length > 0 && (
            <LinkButton
              linkType="link"
              href="/dashboard/transactions"
              size="sm"
              appearance={"ghost"}
              css={{ fontWeight: 600 }}
              append={<HiChevronRight size={18} />}
            >
              View all
            </LinkButton>
          )}
        </Flex>

        {transactions?.length === 0 && (
          <Flex justify={"center"} align="center" css={{ height: 200 }}>
            {emptyState}
          </Flex>
        )}

        <Flex gap={6} stack>
          {transactions.map((transaction) => {
            return (
              <PaymentItem transaction={transaction} key={transaction.id} />
            );
          })}
        </Flex>

        {/* {Object.keys(transactionsByDate).map((date, index) => {
          return (
            <Flex key={index} gap={4} stack className="group-by-date">
              <Text size="sm" color="lighter">
                {date}
              </Text>

              <Flex gap={6} stack>
                {transactionsByDate[date].map((transaction) => {
                  return (
                    <PaymentItem
                      transaction={transaction}
                      key={transaction.id}
                    />
                  );
                })}
              </Flex>
            </Flex>
          );
        })} */}
      </Flex>
    </Card>
  );
});

PaymentsSummaryCard.displayName = "PendingPaymentsCard";

export default PaymentsSummaryCard;
