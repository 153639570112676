import CurrencyInput from "components/currency-input/currency-input";
import Input from "components/input";
import InputLabel from "components/input-label";
import { Flex } from "components/layout";
import Modal from "components/modal";
import SelectExperimental from "components/select/select-experimental";
import Text from "components/text";
import {
  BankAccount,
  useCreateCardDataQuery,
  useCreateCardMutation,
} from "generated/__generated_graphql";
import Loader from "components/loader";
import { extractGraphqlErrors, formatMoney } from "utils/helpers";
import useForm from "hooks/useForm";
import { split } from "utils/object";
import { useToast } from "components/toast";
import { useStepModal } from "components/modal/step-modal";

export default function CardDetailForm() {
  const [{ fetching, data }] = useCreateCardDataQuery();
  const [{ fetching: creatingCard }, createCard] = useCreateCardMutation();
  const notify = useToast();
  const modal = useStepModal();

  const accounts = data?.getBankAccounts || [];
  const people = data?.people.data || [];

  const { values, onChange, setInputValue, errors, formIsComplete } = useForm({
    fields: {
      userId: people[0]?.id,
      bankAccountId: accounts[0]?.id,
      nickname: "",
      spendLimitAmount: 0,
      spendLimitInterval: "daily",
    },
    optional: ["spendLimitInterval"],
    refreshToken: String(fetching),
    validators: {
      spendLimitAmount: (value) =>
        value <= 0 ? "Spend limit is required" : "",
    },
  });

  async function onCreate() {
    const [required, limitFields] = split(values, [
      "userId",
      "bankAccountId",
      "nickname",
    ]);
    function getPayload() {
      if (values.spendLimitAmount > 0) {
        return { ...values, cardType: "virtual" };
      }
      return { ...required, cardType: "virtual" };
    }
    try {
      const response = await createCard(getPayload() as any);
      const error = extractGraphqlErrors(response, "createCard");

      if (error) {
        notify({ content: error!, position: "top", status: "error" });
        return;
      }

      notify({ content: "Card created successfully", position: "top" });
      modal.onOpenChange?.(false);
    } catch (error) {
      notify({
        content: "Something went wrong",
        position: "top",
        status: "error",
      });
    }
  }

  function renderAccountOption(option: BankAccount) {
    return (
      <Flex gap={3}>
        <Flex gap={2}>
          <Text color="black">
            {option?.name} ••{option.accountNumber?.slice(-4)}
          </Text>
          <Text color="light" size="sm">
            {formatMoney(option?.balance)}
          </Text>
        </Flex>
      </Flex>
    );
  }

  if (fetching)
    return (
      <Modal.Body css={{ width: 480 }}>
        <Flex justify={"center"} align="center" css={{ height: "70vh" }}>
          <Loader color="$gray12" size="xs"></Loader>
        </Flex>
      </Modal.Body>
    );

  return (
    <>
      <Modal.Body css={{ width: 480 }}>
        <Flex gap={6} stack>
          <SelectExperimental
            value={values.userId}
            onChange={(value) => setInputValue("userId", value as number)}
            placeholder="Cardholder"
            valueKey="id"
            labelKey="firstName"
            renderValue={(option) => (
              <Flex gap={1}>
                <Text color="black">
                  {option?.firstName} {option?.lastName}
                </Text>
                <Text color="light">{option?.email}</Text>
              </Flex>
            )}
            renderOption={(option) => (
              <Flex gap={1}>
                <Text color="black">
                  {option?.firstName} {option?.lastName}
                </Text>
                <Text color="light">{option?.email}</Text>
              </Flex>
            )}
            options={people}
            size="md"
            label="Who is this card for?"
          ></SelectExperimental>
          <Input
            name="nickname"
            error={errors.nickname}
            value={values.nickname}
            onChange={onChange}
            size="md"
            placeholder="Ex. Marketing"
            label="Card alias (Required)"
            description="
            This will be the card’s name in the Float app (e.g. Marketing)"
          ></Input>

          <SelectExperimental
            value={values.bankAccountId}
            onChange={(value) =>
              setInputValue("bankAccountId", value as number)
            }
            renderValue={renderAccountOption}
            renderOption={renderAccountOption}
            labelKey="name"
            valueKey="id"
            options={accounts}
            size="md"
            label="Associated account"
          ></SelectExperimental>

          <Flex stack gap={2}>
            <Flex gap={2} stack>
              <InputLabel>Spend limit (Required)</InputLabel>
              <Flex gap={1}>
                <CurrencyInput
                  value={values.spendLimitAmount}
                  onValueChange={(value) =>
                    setInputValue("spendLimitAmount", value)
                  }
                  prepend={<Text color="light">$</Text>}
                  size="md"
                />
                <SelectExperimental
                  value={values.spendLimitInterval}
                  onChange={(value) =>
                    setInputValue("spendLimitInterval", value as string)
                  }
                  renderOption={(option) => (
                    <Flex stack gap={2}>
                      <Text color="black">{option?.label}</Text>
                      <Text weight="regular" size="xs" color="light">
                        {option?.description}
                      </Text>
                    </Flex>
                  )}
                  options={[
                    {
                      label: "Daily",
                      value: "daily",
                      description: "Resets every day",
                    },
                    {
                      label: "Weekly",
                      value: "weekly",
                      description: "Resets every monday",
                    },
                    {
                      label: "Monthly",
                      value: "monthly",
                      description: "Resets every 1st of the month",
                    },
                  ]}
                  size="md"
                ></SelectExperimental>
              </Flex>
            </Flex>
            <Text lineHeight={"tall"} weight="regular" size="xs" color="light">
              Any transactions greater than this amount will be declined. Limit
              will reset at the end of the selected period.
            </Text>
          </Flex>
        </Flex>

        <Flex gap={6} stack>
          {/* <Text weight="semi" color="light" case="uppercase">
          Card Rules
        </Text> */}
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Next appearance={"flushed"} to="select-card-type">
          Back
        </Modal.Next>
        <Modal.Confirm
          onClick={onCreate}
          disabled={!formIsComplete}
          isLoading={creatingCard}
        >
          Create Card
        </Modal.Confirm>
      </Modal.Footer>
    </>
  );
}
