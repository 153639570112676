import React from "react";
import Box from "components/box";
import { styled } from "stitches/stitches.config";
import { SharedColorStyles } from "components/_settings/styles/SharedColorStyles";
import { Flex } from "components/layout";

const StyledBox = styled(Flex, SharedColorStyles, {
  py: 8,
  px: 8,
  borderRadius: 2,
  ".icon": { flexShrink: 0 },
});

type StickerProps = React.ComponentProps<typeof StyledBox>;

const Sticker = React.forwardRef<HTMLDivElement, StickerProps>(
  ({ children, gap = "2", ...props }, ref) => {
    return (
      <StyledBox gap={gap} {...props} ref={ref}>
        {children}
      </StyledBox>
    );
  }
);

Sticker.displayName = "Sticker";

export default Sticker;
