import React from "react";
import Input from "components/input";
import { Flex } from "components/layout";
import Modal from "components/modal";
import Sticker from "components/sticker";
import Text from "components/text";
import { useLoginMutation } from "generated/__generated_graphql";
import { useAppProvider } from "providers/app-provider/app-provider";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { extractGraphqlErrors } from "utils/helpers";
import { useToast } from "components/toast";

export default function ConfirmPassword() {
  const context = Modal.useStepModal();
  const { user } = useAppProvider();
  const notify = useToast();

  const [password, setPassword] = React.useState("");

  const [{ fetching }, cofirmPassword] = useLoginMutation();

  async function onConfirm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    try {
      const response = await cofirmPassword({
        email: user?.email!,
        password: password,
      });

      const error = extractGraphqlErrors(response, "login");
      if (error) {
        notify({
          content: "Invalid password",
          status: "error",
          position: "top",
        });
        return;
      }

      context.goToScreen("auth-type");
    } catch (error) {
      notify({
        content: "Something went wrong",
        status: "error",
        position: "top",
      });
    }
  }

  return (
    <>
      <form onSubmit={onConfirm}>
        <Modal.Body css={{ width: 540 }}>
          <Flex gap={8} stack>
            <Flex gap={4} stack>
              <Text size="xs" lineHeight={"tall"}>
                Two-factor authentication (2FA) is an extra layer of security
                for your Float account. In addition to using your username and
                password to log in, you'll enter a security code generated by an
                authenticator app or sent to you as a text message.
              </Text>

              <Sticker variant="light" color="amber">
                <HiOutlineExclamationCircle
                  size={16}
                  style={{ rotate: "180deg" }}
                />
                <Text size="xs" color="inherit" lineHeight={"tall"}>
                  Once enabled, you will be logged out of all other devices
                  except this one.
                </Text>
              </Sticker>
            </Flex>

            <Input
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              type="password"
              size="md"
              label="Enter your password to begin"
              placeholder="Enter your password to begin"
            ></Input>
          </Flex>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Cancel>Cancel</Modal.Cancel>
          <Modal.Confirm
            disabled={!password}
            isLoading={fetching}
            type="submit"
          >
            Confirm password
          </Modal.Confirm>
        </Modal.Footer>
      </form>
    </>
  );
}
