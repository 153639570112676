import { styled } from "stitches/stitches.config";
import * as RadixAccordion from "@radix-ui/react-accordion";

export const Root = styled(RadixAccordion.Root, {});

export const Item = styled(RadixAccordion.Item, {
  // "&:not(:last-child)": { ".header": { borderBottom: "1px solid $gray2" } },
  variants: {
    spacing: {
      sm: {
        marginBottom: 15,
      },
      md: {
        marginBottom: 30,
      },
      lg: {
        marginBottom: 50,
      },
      none: {
        marginBottom: 0,
      },
    },
  },
});

export const Trigger = styled(RadixAccordion.Trigger, {
  width: "100%",
  padding: 0,
  backgroundColor: "White",
  border: "none",
  textAlign: "left",
  cursor: "pointer",
  display: "flex",
  alignItems: "start",
  justifyContent: "space-between",
  flexDirection: "row",
  color: "$gray10",
  transition: "all 0.5s",
  gap: "$4",

  ".icon": { transition: "all 0.5s" },
  "&[data-state='closed']": { ".icon": {} },
  "&[data-state='open']": { ".icon": { rotate: "180deg" } },
});

export const NoStyleTrigger = styled(RadixAccordion.Trigger, {
  width: "100%",
  padding: 0,
  backgroundColor: "transparent",
  border: "none",
  textAlign: "left",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  transition: "all 0.5s",
});

export const Content = styled(RadixAccordion.Content, {
  width: "100%",
  // background: "$gray1",
  // px: 8,
  // py: 8,
});
