import IconButton from "components/icon-button/icon-button";
import InputLabel from "components/input-label";
import Flex from "components/layout/flex";
import Stack from "components/layout/stack";
import Loader from "components/loader/loader";
import React from "react";
import { theme } from "stitches/stitches.config";
import { truncateFileName } from "utils/helpers";
import Box from "components/box";
import { FileUploaderProps, Upload } from "./file-uploader.types";
import { Input, Label } from "./file-uploader.styles";
import InputError from "components/input-error";
import {
  HiDocumentAdd,
  HiExclamation,
  HiOutlineCheckCircle,
  HiOutlineExclamationCircle,
  HiReply,
  HiX,
} from "react-icons/hi";
import Text from "components/text";
import { ActionStatus } from "utils/types";
import { green } from "@radix-ui/colors";

const FileUploader = React.forwardRef<HTMLDivElement, FileUploaderProps>(
  (
    {
      label,
      id = "file-uploader",
      upload,
      disabled,
      status: uploadStatus = "idle",
      css,
      error,
      multiple = false,
      onChange,
      children,
      onRemove,
      onRetry,
      isControlled,
    },
    ref
  ) => {
    const [file, setFile] = React.useState<File | undefined>(undefined);
    const inputRef = React.useRef<HTMLInputElement>(null);

    function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
      const file = e.target.files?.[0];
      // temporary fix for controlled file uploader
      // derive this condition from props
      // eg. isControlled if onChange or onRemove is passed
      !isControlled && setFile(file);
      onChange?.(file);
    }

    function handleRemove() {
      setFile(undefined);
      onRemove?.();
      inputRef.current?.value && (inputRef.current.value = "");
    }

    function getStatus(): ActionStatus {
      if (uploadStatus === "error") return "error";
      if (uploadStatus === "loading") return "loading";
      if (
        uploadStatus === "success" ||
        ((file || upload) && uploadStatus === "idle")
      )
        return "success";
      return "idle";
    }

    const showError = getStatus() === "error";
    const showSuccess = getStatus() === "success";
    const showIdle = getStatus() === "idle";

    return (
      <Flex stack gap={2} stretchX css={css} ref={ref}>
        <Input
          style={{ zIndex: "-1" }}
          ref={inputRef}
          onChange={handleOnChange}
          disabled={disabled}
          type={["success", "fail"].includes(getStatus()) ? "hidden" : "file"}
          accept="image/*, .pdf"
          id={id}
          multiple={multiple}
        ></Input>
        {label && <InputLabel>{label}</InputLabel>}

        {children ? (
          <Label ghost htmlFor={id} data-disabled={disabled}>
            {children}
          </Label>
        ) : (
          <Label state={getStatus()} disabled={disabled} htmlFor={id}>
            <Flex className="uploader-container" justify="between">
              <Stack className="main-section" align="center" isInline>
                {showError && (
                  <HiOutlineExclamationCircle className="uploader-icon" />
                )}

                {showSuccess && (
                  <HiOutlineCheckCircle
                    style={{ color: green.green10 }}
                    className="uploader-icon"
                  />
                )}

                {uploadStatus === "loading" && (
                  <Loader size="xs" color={theme.colors.green9.toString()} />
                )}

                {showIdle && (
                  <HiDocumentAdd className="uploader-icon"></HiDocumentAdd>
                )}

                <div className="uploader-text">
                  {showIdle && (
                    <Text size="sm">
                      Drag and drop here or click to upload files
                    </Text>
                  )}

                  {/* {uploadStatus === "idle" && file && (
                    <Text size="sm" color={"inherit"}>
                      {truncateFileName(file?.name as string).truncated}.{" "}
                      <Text as="span">change file</Text>
                    </Text>
                  )} */}

                  {uploadStatus === "loading" && (
                    <Text size="sm" color={"inherit"}>
                      Uploading file
                    </Text>
                  )}

                  {showError && (
                    <Text size="sm" color="inherit">
                      File was not uploaded! Try again
                    </Text>
                  )}

                  {showSuccess && (
                    <Text size="sm" color="inherit">
                      {file?.name
                        ? truncateFileName(file?.name as string).truncated
                        : truncateFileName(upload?.publicId as string)
                            .truncated}
                    </Text>
                  )}
                </div>
              </Stack>

              <Stack className="actions" isInline>
                {uploadStatus === "error" && (
                  <IconButton
                    onClick={() => onRetry?.(file)}
                    color="sand"
                    size="xs"
                    type="button"
                  >
                    <HiReply />
                  </IconButton>
                )}
                {(["fail", "success"].includes(uploadStatus!) ||
                  upload ||
                  file) && (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemove();
                    }}
                    css={{ color: "inherit" }}
                    size="xs"
                    type="button"
                  >
                    <HiX color="inherit" size={16} />
                  </IconButton>
                )}
              </Stack>
            </Flex>
          </Label>
        )}
        {error && <InputError css={{ marginTop: 8 }}>{error}</InputError>}
      </Flex>
    );
  }
);

FileUploader.displayName = "FileUploader";

export default FileUploader;
