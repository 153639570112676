import Box from "components/box";
import { styled } from "stitches/stitches.config";
import PatternBg from "assets/images/pattern.png";
import { Flex, Stack } from "components/layout";
import Text from "components/text";
import { useEffect, useState, useRef } from "react";
import Button from "components/button";
import {
  useRequestEmailAndSmsOtpMutation,
  useValidateEmailAndSmsOtpMutation,
} from "generated/__generated_graphql";
import * as Sentry from "@sentry/react";
import { useLocation, useNavigate } from "react-router-dom";
import { setToken } from "utils/auth";
import { useToast } from "components/toast/use-toast";
import PinCodeInput from "components/pin-code-input";
import { HiAtSymbol } from "react-icons/hi";
import Avatar from "components/avatar";
import { extractGraphqlErrors } from "utils/helpers";
import { secondsToMinutes } from "date-fns";
import floatLogo from "assets/images/float-logo-final.svg";
import useQueryParams from "hooks/use-query-params";

const StyledContainer = styled(Box, {
  background: "$gray2",
  backgroundImage: `url(${PatternBg})`,
  backgroundSize: "50% 50%",
  backgroundRepeat: "repeat-X",
  backgroundPosition: "top",
  width: "100vw",
  minHeight: "100vh",
  position: "relative",
  py: 60,

  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  flexDirection: "column",
});

const ContentWrapper = styled(Flex, {
  width: 380,
  height: "calc(100vh - 120px)",
});

const FormContainer = styled(Flex, {
  background: "white",
  boxShadow: "$subtle",
  p: 28,
  borderRadius: 18,
});

const StyledLabel = styled("label", {
  display: "flex",
  alignItems: "center",
  color: "$gray11",
  marginBottom: 6,
  fontFamily: "$bold",
  fontSize: 13,
  gap: 5,
  letterSpacing: 0.3,

  span: {
    color: "$gray9",
    fontFamily: "$bold",
  },
});

export default function Verify() {
  const navigate = useNavigate();
  const toast = useToast();
  const [timeLeft, setTimeLeft] = useState(0);
  const [otp, setOtp] = useState("");
  const TIMER = 60;
  const intervalRef = useRef<null | NodeJS.Timer>(null);

  const search = useLocation().search;
  const email = useQueryParams().get("email");

  const [{ fetching: resending }, requestEmailAndSmsOtp] =
    useRequestEmailAndSmsOtpMutation();
  const [{ fetching: validating }, validateEmailAndSmsOtp] =
    useValidateEmailAndSmsOtpMutation();

  const verifyOtp = async () => {
    try {
      const response = await validateEmailAndSmsOtp({
        emailOtp: otp,
      });

      const error = extractGraphqlErrors(response, "validateEmailAndSmsOtp");

      if (error) {
        toast({
          content: error,
          duration: 4000,
          status: "error",
          showIcon: true,
          position: "top",
        });
      }
      const data = response.data?.validateEmailAndSmsOtp!;

      if (data.token) {
        setToken(data.token);
        navigate("/onboarding/company-info");
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const resendOtp = async (e: any) => {
    try {
      const response = await requestEmailAndSmsOtp({});

      const error = extractGraphqlErrors(response, "requestEmailAndSmsOtp");

      if (error) {
        toast({
          content: error,
          duration: 4000,
          status: "error",
          position: "top",
          showIcon: true,
        });
        return;
      }

      setTimeLeft(TIMER);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    const interval = intervalRef.current;

    if (interval && timeLeft === 0) {
      clearInterval(interval);
      intervalRef.current = null;
      return;
    }

    function tick() {
      setTimeLeft((t) => t - 1);
    }

    if (!interval && timeLeft === TIMER) {
      intervalRef.current = setInterval(tick, 1000);
    }
  }, [timeLeft]);

  return (
    <StyledContainer>
      <ContentWrapper
        gap={9}
        justify={"center"}
        align="center"
        direction={"column"}
      >
        <img width={100} src={floatLogo} alt="float-logo" />
        <FormContainer gap={9} direction="column">
          <Flex direction="column" align="center" gap={2}>
            <Avatar size="lg" color={"gray"}>
              <HiAtSymbol size={18} />
            </Avatar>
            <Text size="lg" color="black">
              Verify Email Address
            </Text>
            <Text size="sm" align="center" color="light" lineHeight="base">
              Please enter the code we've sent to{" "}
              {email ?? "your email address"}
            </Text>
          </Flex>

          <Stack stretchX align="start" spacing={16}>
            <PinCodeInput
              onChange={(value) => setOtp(value)}
              error="Code is wrong"
              fields={6}
              name=""
              inputMode="tel"
              type="number"
            />

            <Flex direction="column" gap={2} stretchX>
              <Button
                onClick={verifyOtp}
                appearance="primary"
                isLoading={validating}
              >
                Validate
              </Button>

              {timeLeft > 0 ? (
                <Text
                  css={{
                    height: 40,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  align={"center"}
                  color="light"
                  size="sm"
                >
                  Resend again in{" "}
                  {secondsToMinutes(timeLeft).toString().padStart(2, "0")}:
                  {timeLeft < 60 ? timeLeft.toString().padStart(2, "0") : "00"}
                </Text>
              ) : (
                <Button
                  type="button"
                  onClick={resendOtp}
                  css={{ color: "$primary" }}
                  appearance="ghost"
                  isLoading={resending}
                >
                  Resend code
                </Button>
              )}
            </Flex>
          </Stack>
        </FormContainer>
        <Text align={"center"} size="sm" className="login" color="light">
          Already have an account?{" "}
          <Text as="a" color={"primary"} href="/login">
            Login
          </Text>
        </Text>
      </ContentWrapper>
    </StyledContainer>
  );
}
