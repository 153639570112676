import { IMenuItem } from "components/menu-item/menu-item.type";
import {
  HiChartPie,
  HiCheckCircle,
  HiCreditCard,
  HiDocumentText,
  HiLibrary,
  HiLightningBolt,
  HiOutlineCash,
  HiOutlineChartPie,
  HiOutlineCreditCard,
  HiOutlineDocumentText,
  HiOutlineScale,
  HiOutlineSwitchVertical,
  HiUserCircle,
} from "react-icons/hi";

export const menuData: IMenuItem[] = [
  {
    id: "Dashboard",
    icon: HiOutlineChartPie,
    label: "Dashboard",
    path: "/dashboard",
  },
  // {
  //   id: "treasury",
  //   icon: HiOutlineScale,
  //   label: "Treasury",
  //   path: "/dashboard/yield",
  // },
  {
    id: "Accounts",
    icon: HiOutlineCash,
    label: "Accounts",
    path: "/dashboard/cash",
  },
  {
    id: "transactions",
    label: "Transactions",
    path: "/dashboard/transactions",
    icon: HiOutlineSwitchVertical,
  },
  {
    id: "statements",
    label: "Statements",
    path: "/dashboard/statements",
    icon: HiOutlineDocumentText,
  },
  {
    id: "cards",
    label: "Cards",
    path: "/dashboard/cards",
    icon: HiOutlineCreditCard,
  },

  // {
  //   id: "Invoices",
  //   icon: HiDocumentText,
  //   label: "Invoices",
  //   open: false,
  //   subMenu: [
  //     {
  //       id: "invoices",
  //       label: "Overview",
  //       path: "/dashboard/invoices/overview",
  //     },
  //     {
  //       id: "customers",
  //       label: "Customers",
  //       path: "/dashboard/customers",
  //     },
  //   ],
  // },
  // {
  //   id: "Team",
  //   icon: HiUserCircle,
  //   label: "Team",
  //   path: "/dashboard/teams",
  // },
];
