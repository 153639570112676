import Flex from "components/layout/flex";
import { Link } from "react-router-dom";
import { styled } from "stitches/stitches.config";

export const StyledLink = styled(Link, { textDecoration: "none" });

export const MenuItemWrapper = styled(Flex, {
  cursor: "pointer",
  position: "relative",
  color: "$whiteA9",
  px: 8,
  height: 36,
  alignItems: "center",
  borderRadius: 6,

  ".icon-wrapper": { width: 20, height: 20, marginRight: 6 },

  ".icon": { size: 20, strokeWidth: 4 },
  ".chevron": { color: "$gray8", fontSize: 20 },

  variants: {
    active: {
      true: {
        // background: "$gray3",
        cursor: "pointer",
        color: "$whiteA11",

        "&::before": {
          content: "",
          height: "100%",
          top: 0,
          left: -16,
          borderTopRightRadius: 4,
          borderBottomRightRadius: 4,
          position: "absolute",
          width: 5,
          background: "$gray0",
        },
      },
    },
    hasSubMenu: {
      true: {
        background: "none",
        "&::before": { display: "none" },
      },
    },
    isSubItem: { true: { height: 30, "&::before": { display: "none" } } },
  },
});
