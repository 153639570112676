import { gray } from "@radix-ui/colors";
import Button from "components/button";
import Checkbox from "components/checkbox";
import Input from "components/input";
import { Flex, Stack } from "components/layout";
import SelectExperimental from "components/select/select-experimental";
import Text from "components/text";
import React from "react";
import { HiAtSymbol, HiOutlinePlusCircle } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { id } from "utils/helpers";
import { SendMoneyComponentProps } from "../move-money.types";
import { sectionValidators } from "../move-money.utils";
import { NextButton } from "./nav-buttons";

const Recipient = React.forwardRef<HTMLDivElement, SendMoneyComponentProps>(
  ({ form, data }, ref) => {
    const input = form.values;

    const recentRecipients = (data?.recentRecipients as any[]) || [];

    return (
      <Flex
        direction="column"
        ref={ref}
        className="ex-content"
        align={"start"}
        gap={10}
      >
        <Text color="black" size="xl">
          Who are you paying?
        </Text>
        <Flex stretchX direction={"column"} gap={4}>
          <SelectExperimental
            label="Recipient Name"
            createOptionPosition="first"
            formatCreateLabel={(inputValue: string) => (
              <Flex gap={1} align="center">
                <HiOutlinePlusCircle />
                <Text as="div">Add new vensdddor: {inputValue} </Text>
              </Flex>
            )}
            value={input?.recipientId}
            getNewOptionData={(inputValue: string) => ({
              name: inputValue,
              id: `TEMP_${id()}`,
            })}
            onChange={(_: any, value: any) => {
              form.bulkUpdate({
                recipientName: value.name,
                recipientId: value.id ?? "",
                recipientEmail: value.email ?? "",
              });
            }}
            labelKey="name"
            valueKey="id"
            isCreatable
            size="md"
            options={recentRecipients.concat(
              String(input.recipientId)?.includes?.("TEMP_")
                ? [{ name: input.recipientName, id: input.recipientId }]
                : []
            )}
            placeholder="Enter Recipients Legal Name"
          />

          <Checkbox
            checked={input?.notifyRecipient}
            onChange={(e) => {
              form.setInputValue("notifyRecipient", e.target.checked);

              form.setError("recipientEmail", "");
            }}
            label={
              <Text color="light" size="xs">
                Notify them when this payment is sent
              </Text>
            }
          ></Checkbox>

          {form.values.notifyRecipient && (
            <Input
              onBlur={form.validateField}
              error={form.errors.recipientEmail}
              onChange={form.onChange}
              placeholder="Enter Recipients Email"
              value={input?.recipientEmail}
              name="recipientEmail"
              append={<HiAtSymbol color={gray.gray9} />}
              label="Email"
            ></Input>
          )}
        </Flex>

        <NextButton
          disabled={!sectionValidators.recipient(form.values)}
          to="/payment-type"
        >
          Continue
        </NextButton>
      </Flex>
    );
  }
);

Recipient.displayName = "Recipient";

export default Recipient;
