import { ButtonProps } from "components/button";
import Modal from "components/modal/modal";
import Text from "components/text";
import { StyledConfirmText } from "./confirm-modal.styles";
import { ConfirmModalProps } from "./confirm-modal.types";

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  open,
  onOpenChange,
  type,
  description,
  onCancel,
  onConfirm,
  loading,
  ...rest
}: ConfirmModalProps) => {
  const isSuccess = type === "success";

  return (
    <Modal open={open} onOpenChange={onOpenChange} {...rest}>
      <Modal.Body css={{ width: 400 }}>
        <Text as="div" color="black">
          {description}
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Cancel onClick={onCancel}>Cancel</Modal.Cancel>
        <Modal.Confirm
          onClick={onConfirm}
          isLoading={loading}
          appearance={isSuccess ? "secondary" : "danger"}
        >
          Confirm
        </Modal.Confirm>
      </Modal.Footer>
    </Modal>
  );
};

ConfirmModal.displayName = "ConfirmModal";

export default ConfirmModal;
