import React from "react";
import * as Sentry from "@sentry/react";
import Logo from "components/logo";
import Box from "components/box";
import errorImg from "./error.svg";
import Text from "components/text";
import Button from "components/button";
import { Flex } from "components/layout";

class ErrorBoundary extends React.Component<{ children?: React.ReactNode }> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  handleOnRefresh = () => {
    location.reload();
  };

  componentDidCatch(error: any, errorInfo: any) {
    console.error(error, errorInfo);
    Sentry.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Flex css={{ height: "80vh" }} justify="center" align={"center"} stack>
          <Flex gap={4} align="center" css={{ width: 400 }} stack>
            <img width={100} src={errorImg} />

            <Flex align="center" stack gap={2}>
              <Text weight={"semi"}>Oops! An Error Occurred</Text>
              <Text align="center" lineHeight="tall" color="light">
                We could not load the page you were looking for. The link may be
                broken, or the page is not available. Please try refreshing your
                browser.
              </Text>
            </Flex>
            <Button css={{ mt: 30 }} size="md" onClick={this.handleOnRefresh}>
              Refresh Page
            </Button>
          </Flex>
        </Flex>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
