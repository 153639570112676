import {
  Content,
  Item,
  Root,
  Trigger,
  SubTrigger,
} from "@radix-ui/react-dropdown-menu";
import { Flex } from "components/layout";
import { css, styled } from "stitches/stitches.config";

export const StyledDropdown = styled(Root, {});
export const StyledDropdownContent = styled(Content, {
  background: "white",
  width: 260,
  overflow: "hidden",
  borderRadius: 6,
  boxShadow: "$base",
  border: "1px solid $gray2",
  a: { textDecoration: "none" },
  ".selector-header": {
    padding: 12,
  },
  ".selector-body": { padding: 6 },
  ".selector-businesses": { px: 6, py: 12 },
});

const itemStyles = css({
  all: "unset",
  borderRadius: 3,
  cursor: "pointer",
  px: 6,
  py: 6,
  display: "flex",
  alignItems: "center",
  position: "relative",

  "&:hover": { background: "$gray2" },

  "&.dropdown-action": {
    background: "$teal3",
    justifyContent: "space-between",
    ".item-label": { color: "$teal11" },
    ".action-icon": {
      color: "$teal11",
      fontSize: 18,
    },
  },
  ".item-icon": {
    marginRight: 8,
    width: 20,
    color: "$gray9",
  },
  ".item-label": {
    fontFamily: "$medium",
    color: "$gray11",
  },
});

export const StyledItem = styled(Item, itemStyles);

export const StyledDropdownTriggerItem = styled(SubTrigger, itemStyles);

export const StyledDropdownTrigger = styled(Trigger, {});

export const Wrapper = styled(Flex, {
  width: "100%",
  cursor: "pointer",
  borderRadius: 3,
  px: 12,
  py: 10,

  "&:hover": {
    background: "$whiteA4",
  },

  ".business-name": {
    width: 150,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },

  ".chevron": { color: "$gray9" },
});
