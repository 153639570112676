import ActionDropdown from "components/action-dropdown/action-dropdown";
import Avatar from "components/avatar";
import TheBuoy from "components/buoy/the-buoy";
import Button from "components/button";
import Card from "components/card";
import { Flex } from "components/layout";
import Page from "components/page/page";
import Table, { ITableColumn } from "components/table";
import { DataCell, HeaderCell } from "components/table/components/table-cell";
import TableRow from "components/table/components/table-row";
import useTable from "components/table/use-table";
import Tag from "components/tag";
import Text from "components/text";
import {
  BankAccount,
  useAccountsPageQuery,
  useGetBankAccountsQuery,
  useLinkExternalBankAccountMutation,
  useYieldAgreementsQuery,
} from "generated/__generated_graphql";
import {
  HiArrowRight,
  HiChevronDown,
  HiOutlineLightningBolt,
} from "react-icons/hi";
import { Route, Routes, useNavigate } from "react-router-dom";
import { styled } from "stitches/stitches.config";
import { formatMoney } from "utils/helpers";
import YieldSetup from "./components/yield-setup";
import { usePlaidLink } from "react-plaid-link";
import useModal from "components/modal/use-modal";
import { useAppProvider } from "providers/app-provider/app-provider";
import YieldBanner from "assets/images/yield-banner.jpg";
import { HiOutlineSparkles } from "react-icons/hi2";

const StatCard = styled("div", {
  display: "flex",
  flexDirection: "column",
  background: "white",
  borderRadius: 6,

  ".bottom": {
    px: 24,
    py: 16,
  },

  ".top": {
    borderBottom: "1px solid $gray3",
    px: 24,
  },

  ".stat": {
    py: 28,

    "&:not(:last-child)": {
      borderRight: "1px solid $gray3",
      pr: 60,
    },
  },
});

const TableWrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  background: "white",
  borderRadius: 6,

  table: {
    borderCollapse: "collapse",
  },
});

const COLUMNS: Array<ITableColumn<BankAccount>> = [
  {
    title: "Account",
    key: "account",
    dataIndex: "name",
    render: (_, account: BankAccount) => {
      return (
        <Flex gap={3}>
          <Avatar name={account.accountName} size="md">
            <TheBuoy />
          </Avatar>
          <Flex stack gap={2}>
            <Text color="black" size="sm">
              {account.name}
            </Text>
            <Text color="light" size="sm">
              {account.bankName} •• {account.accountNumber?.slice(-4)}
            </Text>
          </Flex>
        </Flex>
      );
    },
  },
  {
    title: "Balance",
    key: "balance",
    dataIndex: "balance",
    render: (balance) => (
      <Text size={"md"} color="black">
        {formatMoney(balance)}
      </Text>
    ),
  },
];

export default function Accounts() {
  const [{ data, fetching }] = useAccountsPageQuery();
  const bankAccounts = (data?.getBankAccounts as Array<BankAccount>) || [];
  const totalBalance = data?.getBalances?.totalBalance;
  const { user } = useAppProvider();
  const yieldStatus = user?.business?.yieldAccountStatus;
  const hasYieldAccount = yieldStatus === "approved";
  const plaidLinkToken = data?.getPlaidLinkToken.token;
  const navigate = useNavigate();
  const [{}, linkAccount] = useLinkExternalBankAccountMutation();
  const { open: showYieldSetup, setOpen: setShowYieldSetup } = useModal();

  async function linkExternalAccount(publicKey: string) {
    const data = await linkAccount({
      enrollmentToken: publicKey,
      visibility: "public",
      provider: "plaid" as any,
      adminIds: [],
      memberIds: [],
    });
  }

  const { open } = usePlaidLink({
    onSuccess: (payload) => {
      linkExternalAccount(payload);
    },
    token: plaidLinkToken!,
  });

  const { rows, headings } = useTable({
    columns: COLUMNS,
    data: [...bankAccounts],
  });

  function goToAccount(account: BankAccount) {
    navigate(`/dashboard/cash/account/${account.uuid}`);
  }

  return (
    <>
      {showYieldSetup && (
        <YieldSetup onOpenChange={setShowYieldSetup} open={showYieldSetup} />
      )}
      <Page pageTitle="Accounts" isLoading={fetching}>
        {yieldStatus !== "processed" && (
          <Flex
            css={{
              background: "#FDF8F1",
              border: "4px solid white",
              mb: 20,
              borderRadius: 2,
            }}
          >
            <Flex align={"start"} css={{ p: 24 }} stack gap={2}>
              <Flex gap={3} align={"center"}>
                <Text weight={"medium"} size={"md"} color="black">
                  Earn up to 4.7% on your idle cash
                </Text>
                {/* <Tag
                size="sm"
                shape={"flat"}
                color="lime"
                variant={"outline"}
                appendIcon={<HiOutlineSparkles size={18} />}
              >
                <Text color="inherit" size="sm">
                  New
                </Text>
              </Tag> */}
              </Flex>
              <Text size="xs" lineHeight={"tall"}>
                Maximize the potential of their idle cash.Earn up to 4% interest
                on your balance while enjoying unparalleled security and
                reliability.{" "}
              </Text>

              <Button
                onClick={() => setShowYieldSetup(true)}
                size="sm"
                append={<HiArrowRight />}
              >
                Learn more
              </Button>
            </Flex>
            <Flex stretchX css={{ overflow: "hidden" }}>
              <img
                style={{
                  objectFit: "cover",
                  width: "100%",
                  objectPosition: "bottom",
                }}
                src={YieldBanner}
                alt="yield banner"
              />
            </Flex>
          </Flex>
        )}
        <Flex stack gap={4}>
          {/* <Text color="black" size="2xl">
            Account Summary
          </Text> */}

          <Flex align={"center"} justify={"between"}>
            {/* <Flex gap={2}>
              <Tag variant={"solid"} color="gray" shape={"pill"} size="md">
                All accounts
              </Tag>
              <Tag variant={"light"} color="gray" shape={"pill"} size="md">
                Float
              </Tag>
              <Tag variant={"light"} color="gray" shape={"pill"} size="md">
                Linked Accounts
              </Tag>
            </Flex> */}
            {/* 
            <Text color="black" size="2xl">
              Bank Accounts
            </Text> */}

            <ActionDropdown
              actions={[
                {
                  label: "Link account",
                  action: () => open(),
                  description: "See all your bank balances in one place",
                },
                {
                  label: (
                    <Flex align="center" gap={2}>
                      <Text weight={"semi"}>Float Account</Text>
                      <Tag variant={"outline"} color="lime" size="xs">
                        Coming soon
                      </Tag>
                    </Flex>
                  ),
                  action: () => {},
                  description: "Create a new float bank account",
                  disabled: true,
                },
              ]}
              trigger={
                <Button
                  size="sm"
                  appearance="secondary"
                  append={<HiChevronDown />}
                >
                  Add account
                </Button>
              }
            ></ActionDropdown>
          </Flex>

          <StatCard>
            <Flex gap={10} stretchX className="top">
              <Flex className="stat" gap={2} stack>
                <Text color="light">Float balance</Text>
                <Text
                  size="2xl"
                  weight={"regular"}
                  color="black"
                  fontFamily={"haffer"}
                >
                  {formatMoney(totalBalance)}
                </Text>
              </Flex>

              <Flex className="stat" gap={2} stack>
                <Text color="light">Last month’s return</Text>
                <Text
                  size="2xl"
                  weight={"regular"}
                  color="black"
                  fontFamily={"haffer"}
                >
                  $0.00
                </Text>
              </Flex>
            </Flex>
            <Flex className="bottom">
              <Text color={"light"} size={"xs"}>
                Your Float accounts are FDIC-insured up to $1M through our
                regulated partner banks and use of sweep networks.
              </Text>
            </Flex>
          </StatCard>

          <TableWrapper>
            <table>
              <thead>
                <TableRow isHeader>
                  {headings.map((heading) => (
                    <HeaderCell key={heading.value}>
                      {heading.render}
                    </HeaderCell>
                  ))}
                </TableRow>
              </thead>
              <tbody>
                {yieldStatus !== "processed" && (
                  <TableRow
                    onClick={
                      yieldStatus === "approved"
                        ? () => navigate("/dashboard/yield")
                        : undefined
                    }
                    id="dummy-yield-row"
                  >
                    <DataCell>
                      <Flex gap={4}>
                        <Avatar size="md" color="lime" variant={"light"}>
                          <HiOutlineLightningBolt size={20} />
                        </Avatar>

                        <Flex gap={2} stack>
                          <Text size="sm" color="black">
                            Float Yield
                          </Text>
                          <Text color="light">Earning 4% APY</Text>
                        </Flex>
                      </Flex>
                    </DataCell>
                    <DataCell css={{ py: 12 }}>
                      <Flex gap={4}>
                        {!yieldStatus && (
                          <Button
                            size="sm"
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowYieldSetup(true);
                            }}
                          >
                            Enable
                          </Button>
                        )}
                        {["pending", "processing"].includes(
                          yieldStatus ?? ""
                        ) && (
                          <Flex>
                            <Text css={{ fontStyle: "italic" }}>
                              Pending approval
                            </Text>
                          </Flex>
                        )}
                        {["denied", "failed"].includes(yieldStatus ?? "") && (
                          <Flex>
                            <Text>
                              Yield is not available for your business at this
                              time
                            </Text>
                          </Flex>
                        )}
                      </Flex>
                    </DataCell>
                  </TableRow>
                )}
                {rows.map((row, index) => (
                  <TableRow
                    onClick={
                      row.record.accountType === "external"
                        ? () => {}
                        : () => goToAccount(row.record)
                    }
                    key={index}
                  >
                    {row.cells.map((cell) => {
                      const isCellInLastRow: boolean =
                        index === rows.length - 1;
                      return (
                        <DataCell
                          css={{ py: 12 }}
                          isCellInLastRow={isCellInLastRow}
                          key={cell.column}
                        >
                          {cell.render}
                        </DataCell>
                      );
                    })}
                  </TableRow>
                ))}
              </tbody>
            </table>
          </TableWrapper>
        </Flex>
      </Page>
    </>
  );
}
