export const colors = [
  "sand",
  "green",
  "blue",
  "pink",
  "orange",
  "purple",
  "violet",
  "cyan",
  "tomato",
  "amber",
  "indigo",
];
