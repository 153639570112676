import { styled } from "stitches/stitches.config";

const SVG = styled("svg", {});

export default function TheBuoy() {
  return (
    <SVG
      width="23"
      height="19"
      viewBox="0 0 23 19"
      fill="inherit"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5006 0.0474105C15.5233 0.0474105 18.7844 3.42023 18.7844 7.58081H4.21677C4.21677 3.42023 7.47783 0.0474105 11.5006 0.0474105Z"
        fill="inherit"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5 7.57568C22.5 13.859 17.5751 18.9526 11.5 18.9526C5.42487 18.9526 0.5 13.859 0.5 7.57568H22.5ZM18.7844 7.58179C18.7844 11.7424 15.5233 15.1152 11.5006 15.1152C7.47784 15.1152 4.21678 11.7424 4.21678 7.58179H18.7844Z"
        fill="inherit"
      />
    </SVG>
  );
}
