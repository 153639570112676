import Box from "components/box";
import { SharedInputStyles } from "components/input";
import { styled } from "stitches/stitches.config";

export const StyledContainer = styled(Box, {
  height: "100%",
  width: "100%",
});

export const StyledTextArea = styled("textarea", SharedInputStyles, {
  minHeight: 100,
  fontFamily: "$medium",
  fontWeight: 500,
  px: 8,
  py: 8,
  lineHeight: 1.5,
  fontSize: "$3",

  "&:focus": {
    outline: "none",
  },

  "&::placeholder": {
    color: "$blackA8",
    fontSize: "$3",
  },
  variants: {
    size: {
      xl: { minHeight: 400 },
      lg: { minHeight: 300 },
      md: { minHeight: 200 },
      sm: { minHeight: 100 },
      xs: { minHeight: 68 },
    },
  },
  defaultVariants: { size: "sm" },
});

export const Count = styled("p", {
  fontSize: 14,
  color: "$gray9",
});
