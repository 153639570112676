import CountryChip from "components/country-chip";
import Input, { InputProps } from "components/input";
import InputError from "components/input-error";
import InputLabel from "components/input-label";
import { Flex } from "components/layout";
import SelectExperimental from "components/select/select-experimental";
import Text from "components/text";
import { CountriesQuery } from "generated/__generated_graphql";

import React from "react";
import { styled } from "stitches/stitches.config";
import { countries as defaultCountries, Country } from "../../utils/countries";
import useControllable from "hooks/useControllable";

export const StyledInput = styled(Input, {
  width: "100%",
  height: "100%",
  "& input": {
    "&::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

interface PhoneNumberValue {
  countryCode?: string;
  value?: string;
}

type PhoneNumberInputProps = Omit<
  InputProps,
  "onChange" | "value" | "defaultValue"
> & {
  onChange?(value: PhoneNumberValue): void;
  countries?: Partial<Country>[];
  value?: PhoneNumberValue | null;
  defaultValue?: PhoneNumberValue;
};

function findPhoneCodeByCountryCode(countries: Country[], countryCode: string) {
  const country = countries.find((c) => c.code === countryCode);
  return country?.phoneCode;
}

export const PhoneNumberInput = React.forwardRef<
  HTMLDivElement,
  PhoneNumberInputProps
>(
  (
    {
      label,
      required,
      name,
      disabled,
      error,
      hideErrorMessage,
      onChange,
      size,
      value,
      defaultValue = { countryCode: "US", value: "" },
      countries = defaultCountries,
      ...props
    },
    ref
  ) => {
    const valueIsValid = value?.countryCode && value?.value;

    const [phoneNumber, updatePhoneNumber] = useControllable({
      value: valueIsValid ? value : undefined,
      update: onChange,
      defaultValue,
    });

    const onSelectChange = (countryCode: string) => {
      updatePhoneNumber({ ...phoneNumber, countryCode: countryCode });
    };

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      updatePhoneNumber({ ...phoneNumber, value: value });
    };

    return (
      <Flex stack gap={2} stretchX>
        {label && (
          <InputLabel required={required} htmlFor={name}>
            {label}
          </InputLabel>
        )}
        <Flex gap="1" stretchX>
          <SelectExperimental
            showCheckmark={false}
            className="phone-number-input-select-el"
            css={{
              width: "calc(20% + 20px)",
              ".float-select__value-container": {
                paddingRight: 0,
              },
            }}
            placeholder="+xxxx"
            size={size}
            menuSize={400}
            required={required}
            name={`${name}-select`}
            defaultValue={defaultValue?.countryCode}
            value={phoneNumber?.countryCode}
            onChange={onSelectChange}
            options={countries}
            renderOption={(option, meta: any) => {
              return (
                <Flex stretchX justify={"between"} align="center">
                  <Flex css={{ svg: { width: 16 } }} align={"center"} gap={"2"}>
                    <CountryChip country={option?.code} />

                    <Text size="sm" weight={"regular"} color="dark">
                      {option.name}
                    </Text>
                  </Flex>
                  <Text color={"dark"} weight={"regular"} size="sm">
                    +{option.phoneCode}
                  </Text>
                </Flex>
              );
            }}
            labelKey="name"
            valueKey="code"
            renderValue={(value: Country) => {
              return (
                <Flex align={"center"} gap={2}>
                  <CountryChip country={value?.code as any} size={20} />
                </Flex>
              );
            }}
          />

          <StyledInput
            prepend={
              <Text size="sm">
                +
                {findPhoneCodeByCountryCode(
                  countries,
                  phoneNumber?.countryCode!
                )}
              </Text>
            }
            disabled={disabled}
            className="phone-number-input-input-el"
            placeholder="Enter phone number"
            id={name}
            name={name}
            ref={ref}
            required={required}
            size={size}
            value={phoneNumber?.value?.replace(
              `+${findPhoneCodeByCountryCode(
                countries,
                phoneNumber?.countryCode!
              )}`,
              ``
            )}
            onChange={onInputChange}
            {...props}
          />
        </Flex>
        {error && !hideErrorMessage && (
          <InputError css={{ marginTop: 4 }}>{error}</InputError>
        )}
      </Flex>
    );
  }
);

PhoneNumberInput.displayName = "PhoneNumberInput";

export default PhoneNumberInput;
