import Button from "components/button";
import Input from "components/input";
import { Flex } from "components/layout";
import PhoneNumberInput from "components/phone-number-input";
import RadioCard from "components/radio-card";
import RadioGroup from "components/radio-group";
import SelectExperimental from "components/select/select-experimental";
import Sticker from "components/sticker";
import Text from "components/text";
import Textarea from "components/textarea";
import {
  AddressAttributeInput,
  Country,
  PhoneNumber,
} from "generated/__generated_graphql";
import { CustomCountryOption } from "pages/onboarding/components/custom-country-option";
import React from "react";
import {
  HiChevronLeft,
  HiChevronRight,
  HiOutlineExclamationCircle,
} from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { countries } from "utils/countries";
import { omit } from "utils/object";
import { americanStates } from "utils/states";
import { SendMoneyComponentProps } from "../move-money.types";
import { sectionValidators } from "../move-money.utils";
import BankLookUp from "./bank-lookup";
import { BackButton, NextButton } from "./nav-buttons";

const PaymentFields = React.forwardRef<HTMLDivElement, SendMoneyComponentProps>(
  ({ form, data }, ref) => {
    const input = form.values;
    const navigate = useNavigate();

    const IBANCountries = data.ibanCountries as Array<String>;
    const isIBAN = IBANCountries.includes(
      form.values.bankAddress?.countryCode || ""
    );
    const isIntWire = input.methodOfPayment === "international_wire";
    const isDomWire = input.methodOfPayment === "domestic_wire";
    const isACH = input.methodOfPayment === "ach";
    const showBankLookup = isIntWire
      ? !!input.swiftCode
      : !!input.routingNumber;

    const pageTitleMap: any = {
      international_wire: "International Wire Details",
      domestic_wire: "Domestic Wire Details",
      ach: "ACH Details",
    };

    // React.useEffect(() => {
    //   if (!input.methodOfPayment) {
    //     navigate("/dashboard/send-money");
    //   }
    // }, [input.methodOfPayment]);

    return (
      <Flex direction={"column"} gap={10}>
        <Flex direction={"column"} gap={2}>
          <Text size="xl" color="black">
            {pageTitleMap[input.methodOfPayment]}
          </Text>
          <Text color="light" size="sm">
            Delivery within 3 business days.
          </Text>
        </Flex>
        <Flex stretchX gap={4} direction="column">
          <Text size="xs" weight={"semi"} color="light" case={"uppercase"}>
            Bank Details
          </Text>
          {isIntWire && (
            <Flex stretchX>
              <RadioGroup
                name="recipientType"
                onChange={form.onChange}
                value={input.recipientType}
                label="Recipient type"
                flexDirection="row"
              >
                <RadioCard title="Person" value="person" />
                <RadioCard title="Business" value="business" />
              </RadioGroup>
            </Flex>
          )}
          <Flex stack gap={2}>
            {isIntWire && (
              <Input
                {...form.register("swiftCode")}
                placeholder="SWIFT/BIC code"
                label="SWIFT/BIC code"
              ></Input>
            )}
            {(isDomWire || isACH) && (
              <Input
                {...form.register("routingNumber")}
                placeholder="Routing number"
                label="Routing number"
              ></Input>
            )}
            {showBankLookup && (
              <BankLookUp
                onSuccess={(bankInfo) => {
                  form.bulkUpdate({
                    bankName: bankInfo?.name,
                    bankAddress: {
                      ...omit(bankInfo?.address! ?? {}, [
                        "id",
                        "__typename",
                        "country",
                      ]),
                      countryCode: bankInfo?.address?.country?.code,
                    } as Omit<AddressAttributeInput, "id">,
                  });
                }}
                onError={() => form.setInputValue("bankName", "")}
                code={(isIntWire ? input.swiftCode : input.routingNumber) || ""}
                type={isIntWire ? "swift" : "aba"}
              />
            )}
          </Flex>
          <Input
            {...form.register("accountNumber")}
            placeholder="Account number"
            label={isIBAN ? "Recipient IBAN" : "Account number"}
          ></Input>

          {(isACH || isDomWire) && (
            <SelectExperimental
              value={input.accountType}
              options={[
                { label: "Business Checking", value: "business_checking" },
                { label: "Personal Checking", value: "personal_checking" },
                { label: "Business Savings", value: "business_savings" },
                { label: "Personal Savings", value: "personal_savings" },
              ]}
              placeholder="Account type"
              label="Account type"
              onChange={(value) => {
                form.setInputValue("accountType", value as string);
              }}
            ></SelectExperimental>
          )}
        </Flex>

        <Flex direction="column" gap={4}>
          <Text size="xs" weight={"semi"} color="light" case={"uppercase"}>
            Recipient Details
          </Text>
          {/* {isIntWire && (
            <PhoneNumberInput
              onChange={(value) =>
                form.setInputValue("recipientPhone", value as PhoneNumber)
              }
              value={input.recipientPhone}
              label="Phone number"
              placeholder="(000) 000 - 0000"
            />
          )} */}
          <Flex direction={"column"} gap={1}>
            <SelectExperimental
              labelKey="name"
              valueKey="code"
              renderOption={(option: Country) => (
                <CustomCountryOption option={option} />
              )}
              onChange={(value) =>
                form.setInputValue("recipientCountry", value as string)
              }
              placeholder="Country"
              label="Address"
              options={countries}
              value={input.recipientCountry}
            />
            <Input
              {...form.register("recipientAddress")}
              placeholder="Recipients legal address"
            ></Input>
            <Input
              {...form.register("recipientCity")}
              placeholder="City"
            ></Input>
            {input.recipientCountry === "US" ? (
              <SelectExperimental
                options={americanStates}
                labelKey="name"
                valueKey="name"
                onChange={(value) =>
                  form.setInputValue("recipientState", value as string)
                }
                placeholder="State"
                value={input.recipientState}
              ></SelectExperimental>
            ) : (
              <Input
                {...form.register("recipientState")}
                placeholder="State/Province/Region"
              ></Input>
            )}

            <Input
              {...form.register("recipientZip")}
              placeholder="Postal Code"
            ></Input>
          </Flex>
        </Flex>

        <Flex gap={3}>
          <BackButton to="/payment-type">Back</BackButton>
          <NextButton
            disabled={!sectionValidators.paymentDetails(form.values)}
            to="/amount"
          >
            Continue
          </NextButton>
        </Flex>
      </Flex>
    );
  }
);

PaymentFields.displayName = "PaymentFields";

export default PaymentFields;
