import { Upload } from "components/file-uploader";
import { useToast } from "components/toast";
import { useUploadMutation } from "generated/__generated_graphql";
import React from "react";
import { extractGraphqlErrors } from "utils/helpers";
import { ActionStatus } from "utils/types";

type UseUploadProps = {
  defaultUpload?: Upload;
  onSuccess?: (upload: Upload) => void;
  onError?: (error?: string) => void;
};

type UseUploadReturnType = [
  { status: ActionStatus; upload?: Upload; removeUpload?: () => void },
  (file?: File) => void
];

export default function useUpload(props?: UseUploadProps): UseUploadReturnType {
  const [status, setStatus] = React.useState<ActionStatus>("idle");
  const [, uploadFile] = useUploadMutation();
  const [upload, setUpload] = React.useState<Upload | undefined>(
    props?.defaultUpload
  );
  const notify = useToast();

  function removeUpload() {
    setUpload(undefined);
    setStatus("idle");
  }

  async function handleUpload(file?: File) {
    if (!file) {
      return;
    }
    setStatus("loading");
    const reader = new FileReader();
    reader.onload = async function () {
      const response = await uploadFile({
        name: file?.name!,
        content: reader.result as string,
      });

      const error = extractGraphqlErrors(response, "uploadFile");
      if (error) {
        notify({
          content: "Upload failed. Please try again",
          status: "error",
          position: "top",
          showIcon: true,
        });
        setStatus("error");
        props?.onError?.(error);
        return;
      }

      setStatus("success");
      setUpload(response.data?.uploadFile?.upload!);
      props?.onSuccess?.(response.data?.uploadFile?.upload!);
    };
    reader.readAsDataURL(file);
  }

  return [{ status, upload, removeUpload }, handleUpload];
}
