import { ProfileQuery, User } from "generated/__generated_graphql";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { wayFinder } from "utils/wayfinder";

export default function useWayFinder(user: User) {
  const currentPath = useLocation().pathname;
  const navigate = useNavigate();

  const desiredPath = wayFinder(user, currentPath);

  React.useEffect(() => {
    if (!user) return;
    if (desiredPath === currentPath) return;

    window.location.href = desiredPath;
  }, [user, currentPath]);
}
