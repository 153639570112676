import axios from "axios";
import { differenceInDays, format, formatRelative, isValid } from "date-fns";
import { OperationResult } from "urql";
import { isArray, isEmptyObject, isUndefinedOrNull } from "./assertions";
import { getToken } from "./auth";

export function id() {
  return Math.random().toString(36);
}

export function noop() { }

export const baseServerUrl = () => {
  if (process.env.REACT_APP_ALLOW_API_BASE_OVERRIDE) {
    const url = sessionStorage.get("baseUrl");
    if (url) return url;
  }

  return process.env.REACT_APP_API_BASE || "/";
};

export function getInitials(name?: string, fallback: string = "?") {
  if (!name) return fallback;
  const [first, last] = name.split(" ");
  return `${first[0]}${last?.[0] ?? ""}`;
}

export function hashCode(text: any) {
  const str = String(text);
  let hash = 0;
  let char;
  if (str.trim().length === 0) return hash;
  for (let i = 0; i < str.length; i++) {
    char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    // Convert to 32bit integer
    hash &= hash;
  }

  return Math.abs(hash);
}

export function truncateFileName(
  fileName: string,
  length = 24
): { truncated: string; extension: string; name: string } {
  const fileNameToString = String(fileName);
  const [name, extension] = fileNameToString.split(".");

  const truncatedName = name.substring(0, length);

  return {
    truncated: `${truncatedName}${extension ? `.${extension}` : ""}`,
    extension: extension,
    name: truncatedName,
  };
}



export async function downloadFileFromUrl(
  url: string,
  as: string,
  format: "csv" | "pdf" = "csv",
  useToken: boolean = false
) {
  const token = getToken();
  const api = useToken ? `${url}?token=${token}` : url;

  const a = document.createElement("a");
  await axios
    .get(api, {
      responseType: "blob",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      a.download = `${as}.${format}`;
      const blob = new Blob([response.data], { type: `application/${format}` });
      a.href = URL.createObjectURL(blob);
      a.click();
      URL.revokeObjectURL(a.href);
    });
}




export function formatMoney(value: string | number = 0, symbol: string = "$") {
  let number;
  if (typeof value !== "string" && typeof value !== "number") {
    throw new Error("Please pass in a string or number to this function");
  }
  number = typeof value === "string" ? Number(value) : value;

  number = number
    .toLocaleString(undefined, {
      style: "currency",
      currency: "USD",
    })
    .replace("$", "")
    .replace("US", "");
  return `${symbol}${number}`;
}




export const formatByte = (bytes: number) => {
  return (bytes / 1000000).toFixed(2);
};

export function safeDateFromDateString(date: string) {
  const safeDate = new Date(date);
  return isValid(safeDate) ? safeDate : new Date();
}

export function toPercentage(value: number) {
  const decimalCount = String(value).split(".").length;
  if (decimalCount > 1) {
    return `${value.toFixed(2)}%`;
  }
  return `${value}%`;
}

export function extractGraphqlErrors(response: OperationResult, operationName: string): string | null {


  if (response.error || !response.data || isEmptyObject(response.data)) {
    return "Something went wrong! Please try again";
  }

  const operationResult = response.data[operationName];


  if (!isUndefinedOrNull(operationResult?.errors)) {
    if (isArray(operationResult?.errors) && operationResult?.errors?.length > 0) {
      return operationResult?.errors[0].fullMessage ?? "Something went wrong! Please try again";
    }

    return null;
  }


  return null;
}

export function safeRelativeDateString(actual: Date, relative: Date, dateFormat: string = "MMM dd, yyyy 'at' h:mm a") {
  if (Math.abs(differenceInDays(relative, actual)) >= 6) {
    return format(actual, dateFormat);
  }

  return formatRelative(actual, relative);
}

export function renderCardNumberWithSpaces(cardNumber: string) {
  const cardNumberWithSpaces = cardNumber.replace(/(.{4})/g, "$1 ");

  return cardNumberWithSpaces;
}