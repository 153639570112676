import { createStitches } from "@stitches/react";
import {
  gray,
  mauve,
  slate,
  sage,
  olive,
  sand,
  tomato,
  red,
  crimson,
  pink,
  plum,
  purple,
  violet,
  indigo,
  blue,
  sky,
  mint,
  cyan,
  teal,
  green,
  grass,
  lime,
  yellow,
  amber,
  orange,
  brown,
  bronze,
  gold,
  grayA,
  mauveA,
  slateA,
  sageA,
  oliveA,
  sandA,
  tomatoA,
  redA,
  crimsonA,
  pinkA,
  plumA,
  purpleA,
  violetA,
  indigoA,
  blueA,
  skyA,
  mintA,
  cyanA,
  tealA,
  greenA,
  grassA,
  limeA,
  yellowA,
  amberA,
  orangeA,
  brownA,
  bronzeA,
  goldA,
  whiteA,
  blackA,
  grayDark,
  mauveDark,
  slateDark,
  sageDark,
  oliveDark,
  sandDark,
  tomatoDark,
  redDark,
  crimsonDark,
  pinkDark,
  plumDark,
  purpleDark,
  violetDark,
  indigoDark,
  blueDark,
  skyDark,
  mintDark,
  cyanDark,
  tealDark,
  greenDark,
  grassDark,
  limeDark,
  yellowDark,
  amberDark,
  orangeDark,
  brownDark,
  bronzeDark,
  goldDark,
  grayDarkA,
  mauveDarkA,
  slateDarkA,
  sageDarkA,
  oliveDarkA,
  sandDarkA,
  tomatoDarkA,
  redDarkA,
  crimsonDarkA,
  pinkDarkA,
  plumDarkA,
  purpleDarkA,
  violetDarkA,
  indigoDarkA,
  blueDarkA,
  skyDarkA,
  mintDarkA,
  cyanDarkA,
  tealDarkA,
  greenDarkA,
  grassDarkA,
  limeDarkA,
  yellowDarkA,
  amberDarkA,
  orangeDarkA,
  brownDarkA,
  bronzeDarkA,
  goldDarkA,
} from "@radix-ui/colors";
import type * as Stitches from "@stitches/react";
export type { VariantProps } from "@stitches/react";
export const baseFontStack =
  "-apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'";

export const { styled, css, theme, createTheme, globalCss, keyframes, config } =
  createStitches({
    theme: {
      colors: {
        ...gray,
        ...mauve,
        ...slate,
        ...sage,
        ...olive,
        ...sand,
        ...tomato,
        ...red,
        ...crimson,
        ...pink,
        ...plum,
        ...purple,
        ...violet,
        ...indigo,
        ...blue,
        ...sky,
        ...mint,
        ...cyan,
        ...teal,
        ...green,
        ...grass,
        ...lime,
        ...yellow,
        ...amber,
        ...orange,
        ...brown,
        ...bronze,
        ...gold,

        ...grayA,
        ...mauveA,
        ...slateA,
        ...sageA,
        ...oliveA,
        ...sandA,
        ...tomatoA,
        ...redA,
        ...crimsonA,
        ...pinkA,
        ...plumA,
        ...purpleA,
        ...violetA,
        ...indigoA,
        ...blueA,
        ...skyA,
        ...mintA,
        ...cyanA,
        ...tealA,
        ...greenA,
        ...grassA,
        ...limeA,
        ...yellowA,
        ...amberA,
        ...orangeA,
        ...brownA,
        ...bronzeA,
        ...goldA,

        ...whiteA,
        ...blackA,
        // Semantic colors
        primary: "$green10",
        primaryLight: "$teal3",
        accent: "$gray12",
        accentLight: "$gray3",
        darkText: "$gray12",
        lightText: "$gray10",
        lighterText: "$gray8",
      },

      lineHeights: {
        normal: "normal",
        none: 1,
        shorter: 1.25,
        short: 1.375,
        base: 1.5,
        tall: 1.625,
        taller: "2",
        "3": ".75rem",
        "4": "1rem",
        "5": "1.25rem",
        "6": "1.5rem",
        "7": "1.75rem",
        "8": "2rem",
        "9": "2.25rem",
        "10": "2.5rem",
      },

      fonts: {
        primary: `float pro, ${baseFontStack}`,
        monospace: `Menlo, ${baseFontStack}`,
        regular: `${baseFontStack}`,
        medium: `${baseFontStack}`,
        semibold: `${baseFontStack}`,
        bold: `${baseFontStack}`,
        heavy: `${baseFontStack} `,
        roundedMedium: `swipe rounded medium, ${baseFontStack}`,
        roundedSemibold: `swipe rounded semibold, ${baseFontStack}`,
      },

      shadows: {
        subtle: "0px 1px 2px rgba(20, 20, 0, 0.051)",
        focusRing: `0 0 0 4px ${greenA.greenA4}`,
        focusRingError: `0 0 0 5px ${tomatoA.tomatoA4}`,
        focusRingValid: `0 0 0 5px ${greenA.greenA4}`,
        outline: `0 0 0 1px ${sand.sand3}`,
        xs: "0 0 0 1px rgba(0, 0, 0, 0.05)",
        sm: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
        base: "0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(0, 0, 0, 0.05)",
        md: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.04)",
        lg: "0 10px 15px -3px rgba(0, 0, 0, 0.05), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
        xl: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
        xxl: "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
        max: " 0 0 0 1px rgba(136, 152, 170, .08), 0 15px 35px 0 rgba(49, 49, 93, .1), 0 5px 15px 0 rgba(0, 0, 0, .08)",
        min: "0 5px 15px 0 rgba(0, 0, 0, .05)",
      },

      space: {
        px: '1px',
        0: '0',
        1: '0.25rem',
        2: '0.5rem',
        3: '0.75rem',
        4: '1rem',
        5: '1.25rem',
        6: '1.5rem',
        7: '1.75rem',
        8: '2rem',
        9: '2.25rem',
        10: '2.5rem',
        11: '2.75rem',
        12: '3rem',
        14: '3.5rem',
        16: '4rem',
        20: '5rem',
        24: '6rem',
        28: '7rem',
        32: '8rem',
        36: '9rem',
        40: '10rem',
        44: '11rem',
        48: '12rem',
        52: '13rem',
        56: '14rem',
        60: '15rem',
        64: '16rem',
        72: '18rem',
        80: '20rem',
        96: '24rem',
      },
      sizes: {
        1: "4px",
        2: "8px",
        3: "16px",
        4: "20px",
        5: "24px",
        6: "32px",
        7: "48px",
        8: "64px",
        9: "80px",
      },

      fontSizes: {
        1: "0.50rem",
        2: "0.75rem",
        3: "0.875rem",
        4: "1rem",
        5: "1.125rem",
        6: "1.25rem",
        7: "1.5rem",
        8: "1.875rem",
        9: "2.25rem",
        10: "3rem",
        11: "3.75rem",
        12: "4.5rem",
        13: "6rem",
        14: "8rem",
      },
      radii: {
        1: "4px",
        2: "6px",
        3: "8px",
        4: "12px",
        round: "50%",
        pill: "9999px",
      },
      zIndices: {
        1: "100",
        2: "200",
        3: "300",
        4: "400",
        max: "999",
      },
    },
    media: {
      xs: "(min-width: 320px)",
      sm: "(min-width: 425px)",
      md: "(min-width: 768px)",
      lg: "(min-width: 1204px)",
      xl: "(min-width: 1440px)",
      "2xl": "(min-width: 1536px)",
      motion: "(prefers-reduced-motion)",
      hover: "(any-hover: hover)",
      dark: "(prefers-color-scheme: dark)",
      light: "(prefers-color-scheme: light)",
    },
    utils: {
      p: (value: Stitches.PropertyValue<"padding">) => ({ padding: value }),
      pt: (value: Stitches.PropertyValue<"paddingTop">) => ({
        paddingTop: value,
      }),
      pr: (value: Stitches.PropertyValue<"paddingRight">) => ({
        paddingRight: value,
      }),
      pb: (value: Stitches.PropertyValue<"paddingBottom">) => ({
        paddingBottom: value,
      }),
      pl: (value: Stitches.PropertyValue<"paddingLeft">) => ({
        paddingLeft: value,
      }),
      px: (value: Stitches.PropertyValue<"paddingLeft">) => ({
        paddingLeft: value,
        paddingRight: value,
      }),
      py: (value: Stitches.PropertyValue<"paddingTop">) => ({
        paddingTop: value,
        paddingBottom: value,
      }),

      m: (value: Stitches.PropertyValue<"margin">) => ({ margin: value }),
      mt: (value: Stitches.PropertyValue<"marginTop">) => ({
        marginTop: value,
      }),
      mr: (value: Stitches.PropertyValue<"marginRight">) => ({
        marginRight: value,
      }),
      mb: (value: Stitches.PropertyValue<"marginBottom">) => ({
        marginBottom: value,
      }),
      ml: (value: Stitches.PropertyValue<"marginLeft">) => ({
        marginLeft: value,
      }),
      mx: (value: Stitches.PropertyValue<"marginLeft">) => ({
        marginLeft: value,
        marginRight: value,
      }),
      my: (value: Stitches.PropertyValue<"marginTop">) => ({
        marginTop: value,
        marginBottom: value,
      }),

      ta: (value: Stitches.PropertyValue<"textAlign">) => ({
        textAlign: value,
      }),

      fd: (value: Stitches.PropertyValue<"flexDirection">) => ({
        flexDirection: value,
      }),
      fw: (value: Stitches.PropertyValue<"flexWrap">) => ({ flexWrap: value }),

      ai: (value: Stitches.PropertyValue<"alignItems">) => ({
        alignItems: value,
      }),
      ac: (value: Stitches.PropertyValue<"alignContent">) => ({
        alignContent: value,
      }),
      jc: (value: Stitches.PropertyValue<"justifyContent">) => ({
        justifyContent: value,
      }),
      as: (value: Stitches.PropertyValue<"alignSelf">) => ({
        alignSelf: value,
      }),
      fg: (value: Stitches.PropertyValue<"flexGrow">) => ({ flexGrow: value }),
      fs: (value: Stitches.PropertyValue<"flexShrink">) => ({
        flexShrink: value,
      }),
      fb: (value: Stitches.PropertyValue<"flexBasis">) => ({
        flexBasis: value,
      }),

      bc: (value: Stitches.PropertyValue<"backgroundColor">) => ({
        backgroundColor: value,
      }),

      br: (value: Stitches.PropertyValue<"borderRadius">) => ({
        borderRadius: value,
      }),
      btrr: (value: Stitches.PropertyValue<"borderTopRightRadius">) => ({
        borderTopRightRadius: value,
      }),
      bbrr: (value: Stitches.PropertyValue<"borderBottomRightRadius">) => ({
        borderBottomRightRadius: value,
      }),
      bblr: (value: Stitches.PropertyValue<"borderBottomLeftRadius">) => ({
        borderBottomLeftRadius: value,
      }),
      btlr: (value: Stitches.PropertyValue<"borderTopLeftRadius">) => ({
        borderTopLeftRadius: value,
      }),

      bs: (value: Stitches.PropertyValue<"boxShadow">) => ({
        boxShadow: value,
      }),

      lh: (value: Stitches.PropertyValue<"lineHeight">) => ({
        lineHeight: value,
      }),

      ox: (value: Stitches.PropertyValue<"overflowX">) => ({
        overflowX: value,
      }),
      oy: (value: Stitches.PropertyValue<"overflowY">) => ({
        overflowY: value,
      }),

      pe: (value: Stitches.PropertyValue<"pointerEvents">) => ({
        pointerEvents: value,
      }),

      us: (value: Stitches.PropertyValue<"userSelect">) => ({
        WebkitUserSelect: value,
        userSelect: value,
      }),

      userSelect: (value: Stitches.PropertyValue<"userSelect">) => ({
        WebkitUserSelect: value,
        userSelect: value,
      }),

      size: (value: Stitches.PropertyValue<"width">) => ({
        width: value,
        height: value,
      }),

      appearance: (value: Stitches.PropertyValue<"appearance">) => ({
        WebkitAppearance: value,
        appearance: value,
      }),
      backgroundClip: (value: Stitches.PropertyValue<"backgroundClip">) => ({
        WebkitBackgroundClip: value,
        backgroundClip: value,
      }),
    },
  });

export type CSS = Stitches.CSS<typeof config>;

export const darkTheme = createTheme("dark-theme", {
  colors: {
    ...grayDark,
    ...mauveDark,
    ...slateDark,
    ...sageDark,
    ...oliveDark,
    ...sandDark,
    ...tomatoDark,
    ...redDark,
    ...crimsonDark,
    ...pinkDark,
    ...plumDark,
    ...purpleDark,
    ...violetDark,
    ...indigoDark,
    ...blueDark,
    ...skyDark,
    ...mintDark,
    ...cyanDark,
    ...tealDark,
    ...greenDark,
    ...grassDark,
    ...limeDark,
    ...yellowDark,
    ...amberDark,
    ...orangeDark,
    ...brownDark,
    ...bronzeDark,
    ...goldDark,

    ...grayDarkA,
    ...mauveDarkA,
    ...slateDarkA,
    ...sageDarkA,
    ...oliveDarkA,
    ...sandDarkA,
    ...tomatoDarkA,
    ...redDarkA,
    ...crimsonDarkA,
    ...pinkDarkA,
    ...plumDarkA,
    ...purpleDarkA,
    ...violetDarkA,
    ...indigoDarkA,
    ...blueDarkA,
    ...skyDarkA,
    ...mintDarkA,
    ...cyanDarkA,
    ...tealDarkA,
    ...greenDarkA,
    ...grassDarkA,
    ...limeDarkA,
    ...yellowDarkA,
    ...amberDarkA,
    ...orangeDarkA,
    ...brownDarkA,
    ...bronzeDarkA,
    ...goldDarkA,
  },
});

export const globalStyles = globalCss({

  html: {
    fontSize: 14,

    "@lg": {
      fontSize: "16px",
    },
  },


  "[role=button]": {
    cursor: "pointer",
    userSelect: "none",
  },
  button: {
    cursor: "pointer",
    "&:focus": { outline: "none" },
  },
  ".hide-for-small": {
    "@xs": { display: "none" },
    "@sm": { display: "none" },
  },
  ".hide-for-medium": { "@md": { display: "none" } },
  ".hide-for-large": { "@lg": { display: "none" } },
});
