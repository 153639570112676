import Button from "components/button";
import React from "react";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

type NavButtonProps = React.ComponentProps<typeof Button> & { to?: string };

export function BackButton({
  children,
  prepend = <HiChevronLeft />,
  appearance = "outline",
  size = "sm",
  to = "/",
  ...props
}: NavButtonProps) {
  const navigate = useNavigate();
  return (
    <Button
      onClick={() => navigate(`/dashboard/send-money${to}`)}
      size={size}
      appearance={appearance}
      prepend={prepend}
      {...props}
    >
      {children}
    </Button>
  );
}

export function NextButton({
  children,
  append = <HiChevronRight />,
  appearance = "secondary",
  size = "sm",
  to = "/",
  ...props
}: NavButtonProps) {
  const navigate = useNavigate();
  return (
    <Button
      onClick={() => navigate(`/dashboard/send-money${to}`)}
      size={size}
      appearance={appearance}
      append={append}
      {...props}
    >
      {children}
    </Button>
  );
}
