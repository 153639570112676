import { styled, keyframes } from "stitches/stitches.config";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";

const slideUpAndFade = keyframes({
  "0%": {
    opacity: 0,
    transform: "translateY(2px)",
  },
  "100%": {
    opacity: 1,
    transform: "translateY(0)",
  },
});

const slideRightAndFade = keyframes({
  "0%": {
    opacity: 0,
    transform: "translateX(-2px)",
  },
  "100%": {
    opacity: 1,
    transform: "translateX(0)",
  },
});

const slideDownAndFade = keyframes({
  "0%": {
    opacity: 0,
    transform: "translateY(-2px)",
  },
  "100%": {
    opacity: 1,
    transform: "translateY(0)",
  },
});

const slideLeftAndFade = keyframes({
  "0%": {
    opacity: 0,
    transform: "translateX(2px)",
  },
  "100%": {
    opacity: 1,
    transform: "translateX(0)",
  },
});

export const StyledTrigger = styled(TooltipPrimitive.Trigger, {
  border: "none",
  background: "transparent",
  padding: 0,
  cursor: "pointer",
  outline: "none",
  display: "flex",
  flex: 1,
  textAlign: "left",
});

export const UnstyledContent = TooltipPrimitive.Content;

export const StyledContent = styled(TooltipPrimitive.Content, {
  width: "100%",
  borderRadius: 6,
  px: 16,
  py: 10,
  fontSize: 13,
  lineHeight: 1,
  color: "white",
  fontWeight: 600,
  backgroundColor: "$gray12",
  // boxShadow:
  //   "hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px",
  "@media (prefers-reduced-motion: no-preference)": {
    animationDuration: "400ms",
    animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
    willChange: "transform, opacity",
    '&[data-state="delayed-open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
});

export const StyledArrow = styled(TooltipPrimitive.Arrow, {
  fill: "white",
});

// Exports
export const Provider = TooltipPrimitive.Provider;
export const TooltipRoot = TooltipPrimitive.Root;
