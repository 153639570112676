import Box from "components/box";
import { styled } from "stitches/stitches.config";
import PatternBg from "assets/images/pattern.png";
import { Flex, Stack } from "components/layout";
import Text from "components/text";
import { useNavigate } from "react-router";
import { Link, Route, Routes } from "react-router-dom";
import AccountSelector from "components/account-selector";
import {
  OnboardingDataQuery,
  useOnboardingDataQuery,
  User,
} from "generated/__generated_graphql";
import CompanyInfo from "./components/company-info";
import OwnerInformation from "./components/owner-information";
import VerifyBusiness from "./components/verify-business";
import ReviewDetails from "./components/review-details";
import React from "react";
import Avatar from "components/avatar";
import Loader from "components/loader";
import useWayFinder from "hooks/use-way-finder";
import { HiCheckCircle, HiOutlineCheckCircle } from "react-icons/hi";
import Logo from "components/logo";

const Container = styled(Box, {
  background: "$gray0",
  backgroundImage: `url(${PatternBg})`,
  backgroundSize: "50%",
  backgroundRepeat: "repeat-X",
  backgroundPosition: "top",
  width: "100vw",
  minHeight: "100vh",
  position: "relative",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
});
const Header = styled("header", {
  position: "fixed",
  zIndex: 2,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  background: "$whiteA12",
  height: 80,
  width: "100vw",
  px: 60,
});

const Body = styled(Flex, {
  marginTop: 100,
  marginBottom: 100,
});

const RouteContainer = styled(Stack, {
  background: "white",
  width: 440,
});

const StyledLink = styled(Link, {
  color: "$gray9",
  textDecoration: "none",
  display: "flex",
  gap: "$3",

  variants: { isActive: { true: { color: "$primary" } } },
});

const Nav = styled(Flex, {
  position: "fixed",
  top: 100,
  left: 60,
});

export interface IMenuItem {
  id: string;
  label: string;
  path: string;
  element: any;
  hide?: boolean;
}

export interface OnboardingComponentProps {
  data?: OnboardingDataQuery;
  roleId?: number;
  countryCode?: string;
  fetching: boolean;
  refetchProfile?: () => void;
}

export default function Onboarding() {
  const navigate = useNavigate();
  const activePath = window.location.pathname;

  const [{ data, fetching: fetchingData }, refetchProfile] =
    useOnboardingDataQuery();

  const user = data?.profile as User;
  const readyToSubmit =
    !!data?.profile.business &&
    Object.keys(data.profile.business.checklist).length === 0;

  function onSwitchSuccess() {
    navigate(window.location.pathname);
  }

  const onboardingData: IMenuItem[] = [
    {
      id: "company-info",
      label: "Company Info",
      path: "/onboarding/company-info",
      element: CompanyInfo,
    },
    {
      id: "verify-business",
      label: "Verify Business",
      path: "/onboarding/verify-business",
      element: VerifyBusiness,
    },
    {
      id: "owner-information",
      label: "Owner Information",
      path: "/onboarding/owner-information",
      element: OwnerInformation,
    },
    // {
    //   id: "additional-information",
    //   label: "Additional Information",
    //   path: "/onboarding/additional-information",
    //   element: OwnerInformation,
    // },
    {
      id: "review-details",
      label: "Review & Submit",
      path: "/onboarding/review-details",
      element: ReviewDetails,
      hide: !readyToSubmit,
    },
  ];

  useWayFinder(data?.profile as User);

  if (fetchingData)
    return (
      <Flex css={{ height: "80vh" }} justify={"center"} align="center">
        <Loader size="sm" color="$gray12"></Loader>
      </Flex>
    );

  return (
    <Container>
      <Header>
        <Logo width={160} />

        <AccountSelector
          renderSelector={() => (
            <Flex align="center">
              <Avatar color="green" size="md" name={user?.firstName}></Avatar>
            </Flex>
          )}
          user={user}
          extended={false}
          onSwitchSuccess={onSwitchSuccess}
        />
      </Header>
      <Body gap={16} className="content">
        <Box css={{ width: 280 }}>
          <Nav stack gap={4}>
            {onboardingData.map((menu) => {
              const isActive = activePath === menu.path;
              const isComplete = false;

              if (menu.hide) return null;

              return (
                <StyledLink isActive={isActive} key={menu.id} to={menu.path}>
                  {isComplete ? <HiCheckCircle /> : <HiOutlineCheckCircle />}
                  <Text color={"inherit"} size="sm">
                    {menu.label}
                  </Text>
                </StyledLink>
              );
            })}
          </Nav>
        </Box>

        <RouteContainer>
          <Box className="scroll">
            <Routes>
              {onboardingData.map(({ element: Element, path }, index) => {
                path = `/${path.split("/")[2]}`;
                return (
                  <Route
                    key={index}
                    path={path as string}
                    element={<Element data={data} />}
                  ></Route>
                );
              })}
            </Routes>
          </Box>
        </RouteContainer>
      </Body>
    </Container>
  );
}
