import { gray } from "@radix-ui/colors";
import Box from "components/box";
import React from "react";
import { styled } from "stitches/stitches.config";

export type SeparatorProps = {
  spacing?: number | string;
  color?: string;
  direction?: "horizontal" | "vertical";
  dashed?: boolean;
} & React.ComponentProps<typeof Box>;

const Separator = React.forwardRef<HTMLDivElement, SeparatorProps>(
  (
    { spacing = 16, color = gray.gray3, css, direction = "horizontal", dashed },
    ref
  ) => {
    return (
      <Box
        position="relative"
        ref={ref}
        css={{
          height: direction === "horizontal" ? 1 : "100%",
          width: direction === "horizontal" ? "100%" : 1,
          backgroundColor: dashed ? "none" : color,
          backgroundImage: dashed
            ? `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='${gray.gray5}' stroke-width='4' stroke-dasharray='2%2c 8' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e")`
            : "none",
          my: direction === "horizontal" ? spacing : 0,
          mx: direction === "horizontal" ? 0 : spacing,
          ...css,
        }}
      ></Box>
    );
  }
);

Separator.displayName = "Separator";

export default Separator;
