import Box from "components/box";
import { Flex, Stack } from "components/layout";
import { styled } from "stitches/stitches.config";

export const AlertWrapper = styled(Box, {
  display: "inline-flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "$2",
  width: "100%",
  boxShadow: "$min",
  gap: "$2",

  borderRadius: 8,
  color: "White",
  variants: {
    variant: {
      dark: { backgroundColor: "$gray12" },
      success: { backgroundColor: "$green11" },
      error: { backgroundColor: "$tomato11" },
      warning: { backgroundColor: "$amber10" },
      info: { backgroundColor: "$sky11" },
      default: {
        backgroundColor: "white",
        color: "$gray12",
        boxShadow: "$subtle",
        ".close-icon": { color: "$gray10" },
      },
    },
    size: {
      md: { py: 12, px: 16 },
      sm: { py: 8, pl: 8, pr: 8 },
    },
  },

  defaultVariants: {
    variant: "default",
    size: "sm",
  },
});

export const AlertContent = styled(Flex, {
  textAlign: "left",
  fontSize: 15,
  color: "inherit",
  whiteSpace: "nowrap",
});

export const AlertButton = styled("button", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "$4",
  height: "$4",
  borderRadius: "50%",
  cursor: "pointer",
  background: "none",
  color: "inherit",
  border: "none",
  padding: 0,

  "&:hover": {
    background: "$grayA2",
    outline: "none",
  },
  "&:active": { outline: "none" },
  transition: "all 0.3s",
});
