import { Flex } from "components/layout";
import Modal from "components/modal";
import PinCodeInput from "components/pin-code-input";
import Text from "components/text";

export default function VerifySmsToken() {
  return (
    <>
      <Modal.Body css={{ width: 540 }}>
        <Flex stack gap={6}>
          <Flex stack gap={2}>
            <Text color="black">We have sent a code to +233268350007</Text>
            <Text lineHeight={"tall"} size="xs" color="light">
              Enter the 6-digit verification code generated.
            </Text>
          </Flex>
          <PinCodeInput
            size="md"
            css={{ width: 300 }}
            inputMode="tel"
            type="number"
            name="as"
            fields={6}
          />
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Cancel>Cancel</Modal.Cancel>
        <Modal.Confirm>Submit</Modal.Confirm>
      </Modal.Footer>
    </>
  );
}
