import * as RadixAccordion from "@radix-ui/react-accordion";
import React from "react";
import { Content, Item, Root, Trigger } from "./accordion.styles";
import { AccordionProps } from "./accordion.types";
import { HiChevronDown, HiChevronRight, HiMinus, HiPlus } from "react-icons/hi";
import Text from "components/text";
import Separator from "components/separator";

export function Header({
  children,
  ...props
}: React.ComponentProps<typeof Trigger>) {
  return (
    <RadixAccordion.Header>
      <Trigger {...props}>
        {children}
        <HiChevronDown className="icon" style={{ flexShrink: 0 }} size={20} />
      </Trigger>
    </RadixAccordion.Header>
  );
}

const _Accordion = ({
  css,
  children,
  type = "single",

  ...props
}: AccordionProps) => {
  return (
    <Root {...(props as any)} type={type} css={css}>
      {children}
    </Root>
  );
};

const Accordion = _Accordion as typeof _Accordion & {
  Item: typeof Item;
  Header: typeof Header;
  Content: typeof Content;
};

Accordion.Item = Item;
Accordion.Header = Header;
Accordion.Content = Content;

export default Accordion;
