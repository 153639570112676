import { SharedColorStyles } from "components/_settings/styles/SharedColorStyles";
import { css, styled } from "stitches/stitches.config";

export const SharedButtonStyles = css({
  userSelect: "none",
  display: "flex",
  flexShrink: 0,
  flexGrow: 0,
  outline: "none",
  alignItems: "center",
  transition: "all .3s",
  cursor: "pointer",
  fontFamily: "$medium",
  fontWeight: 500,
  whiteSpace: "nowrap",

  "&:disabled": {
    cursor: "text",
    background: "$gray3",
    color: "$gray8",
  },

  "&:focus": { boxShadow: "$focusRing" },

  defaultVariants: {
    appearance: "primary",
    size: "md",
    align: "center",
    shape: "sharp",
  },

  variants: {
    appearance: {
      primary: {
        background: "$lime9",
        color: "$lime12",
        ".loader": { borderTopColor: "white", borderRightColor: "white" },
      },
      secondary: {
        background: "$gray12",
        color: "white",
        ".loader": { borderTopColor: "white", borderRightColor: "white" },
      },
      link: {
        background: "transparent",
        padding: 0,
      },
      unstyled: {
        background: "none",
        padding: "0px !important",
        height: "auto !important",

        "&:focus": { outline: "none", boxShadow: "none" },
      },
      ghost: {
        background: "transparent",
        color: "$gray12",
        ".loader": { borderTopColor: "$gray10", borderRightColor: "$gray10" },
      },
      outline: {
        background: "White",
        boxShadow: "$subtle",
        border: "1.5px solid $gray5",
        color: "$gray12",
        ".loader": { borderTopColor: "$gray10", borderRightColor: "$gray10" },
      },
      flushed: {
        background: "$gray3",
        color: "$gray12",
        ".loader": { borderTopColor: "$gray12", borderRightColor: "$gray12" },
      },
      danger: {
        background: "$tomato10",
        color: "White",
        ".loader": { borderTopColor: "white", borderRightColor: "white" },
      },
      warning: { background: "$amber10", color: "$amber12" },
    },
    size: {
      xs: {
        height: 30,
        fontSize: 13,
        borderRadius: 4,
        px: 14,
      },
      sm: {
        height: 32,
        fontSize: 14,
        borderRadius: 8,
        px: 14,
      },
      md: {
        height: 40,
        fontSize: 14,
        borderRadius: 9,
        px: 14,
      },
      lg: {
        height: 44,
        fontSize: 14,
        borderRadius: 8,
        px: 8,
      },
      xl: {
        height: 56,
        fontSize: 16,
        borderRadius: 8,
        px: 8,
      },
    },
    shape: {
      pill: {
        borderRadius: `9999px !important`,
      },
      rounded: {},
      sharp: {
        borderRadius: `0px !important`,
      },
    },
    align: {
      left: { justifyContent: "start" },
      center: { justifyContent: "center" },
      right: { justifyContent: "end" },
    },
  },
});

export const StyledButton = styled(
  "button",
  SharedColorStyles,
  SharedButtonStyles,
  {
    appearance: "none",
    border: "none",
    background: "none",
    userSelect: "none",

    ".icon": { color: "inherit" },
    '&[data-hidden="true"]': {
      visibility: "hidden",
    },
  }
);
