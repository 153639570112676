import React from "react";
import Flex from "components/layout/flex";
import Text from "components/text/text";
import Box from "components/box";
import { Indicator, StyledRadio } from "./radio-card.styles";
import { RadioCardProps } from "./radio-card.types";
import { Stack } from "components/layout";

const RadioCard = React.forwardRef<HTMLButtonElement, RadioCardProps>(
  ({ title, description, icon, children, ...props }, ref) => {
    return (
      <StyledRadio ref={ref} {...props}>
        <Flex stretchX direction="row" justify="between" align={"center"}>
          <Flex direction="column" gap={2}>
            {icon && <Box className="icon">{icon}</Box>}
            <Flex direction={"column"} gap={2}>
              {title && (
                <Text
                  as="div"
                  color="inherit"
                  lineHeight={"base"}
                  size="sm"
                  weight="medium"
                >
                  {title}
                </Text>
              )}
              {description && (
                <Text
                  size="xs"
                  lineHeight="tall"
                  className="description"
                  color="light"
                  as="div"
                >
                  {description}
                </Text>
              )}
            </Flex>
          </Flex>

          <Indicator></Indicator>
        </Flex>
        {children && (
          <Box stretchX css={{ marginTop: 12 }}>
            {children}
          </Box>
        )}
      </StyledRadio>
    );
  }
);

RadioCard.displayName = "RadioCard";

export default RadioCard;
