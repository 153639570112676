import Button from "components/button";
import useControllable from "hooks/useControllable";
import React, { useReducer } from "react";
import { isUndefinedOrNull } from "utils/assertions";
import { ID, ReducerAction } from "utils/types";
import Modal from "./modal";
import { ModalButtonProps } from "./modal.types";

enum STEP_MODAL_ACTIONS {
  NEXT = "NEXT",
}

type StepModalState = {
  currentScreen: ID;
};

const ModalContext = React.createContext<{
  screen: ID;
  goToScreen: (screenId: ID) => void;
  onOpenChange?: (open: boolean) => void;
} | null>(null);

export function useStepModal() {
  const context = React.useContext(ModalContext);
  if (!context) {
    throw new Error("useStepModal must be used within a StepModal");
  }
  return context;
}

function reducer(
  state: StepModalState,
  action: ReducerAction<STEP_MODAL_ACTIONS>
) {
  switch (action.type) {
    case "NEXT":
      return { currentScreen: action.payload };
    default:
      return state;
  }
}

export function Next({
  children,
  to,
  appearance = "secondary",
  ...props
}: ModalButtonProps & { to?: ID }) {
  const context = useStepModal()!;

  if (!context)
    throw new Error("Modal Next button must be used inside a StepModal");

  function next() {
    if (!isUndefinedOrNull(to)) context.goToScreen(to!);
  }

  return (
    <Button appearance={appearance} size="sm" onClick={next} {...props}>
      {children}
    </Button>
  );
}

type StepModalProps = React.ComponentProps<typeof Modal> & {
  currentScreen?: ID;
  defaultScreen?: ID;
};

export default function StepModal({
  children,
  title,
  currentScreen,
  defaultScreen,
  onOpenChange,
  ...props
}: StepModalProps) {
  function getDefaultScreen() {
    if (isUndefinedOrNull(currentScreen) && isUndefinedOrNull(defaultScreen)) {
      return (React.Children.toArray(children)[0] as any).props.screenId;
    }

    return defaultScreen;
  }

  const [screen, setScreen] = useControllable({
    value: currentScreen,
    defaultValue: getDefaultScreen(),
  });

  const [state, dispatch] = useReducer(reducer, {
    currentScreen: currentScreen,
  });

  const currentChild = React.Children.toArray(children).filter((child) => {
    // console.log(child);
    return (child as any).props.screenId === screen;
  });

  function goToScreen(screenId: ID) {
    setScreen(screenId);
  }

  return (
    <ModalContext.Provider value={{ screen, goToScreen, onOpenChange }}>
      <Modal onOpenChange={onOpenChange} {...props} title={title}>
        {currentChild}
      </Modal>
    </ModalContext.Provider>
  );
}
