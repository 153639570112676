import Box from "components/box";
import { Flex } from "components/layout";
import { styled } from "stitches/stitches.config";

export const Wrapper = styled(Box, {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  width: "100%",
  fontFamily: "inherit",
  fontWeight: "inherit",

  // "&.add-on": { height: "100%" },

  defaultVariants: {
    align: "center",
  },
  variants: {
    align: {
      left: { justifyContent: "start" },
      center: { justifyContent: "center" },
      right: { justifyContent: "end" },
    },
  },
});

export const AddOn = styled(Flex, {
  alignItems: "center",
  justifyContent: "center",
  "&.prepend": { mr: 4 },
  "&.append": { ml: 4 },
});
