import React from "react";
import { styled } from "stitches/stitches.config";

const TableRow = styled("tr", {
  "&:hover": {
    background: "$gray1",
    cursor: "pointer",
  },

  variants: {
    selected: {
      true: {
        background: "$gray2",
      },
    },
    isHeader: {
      true: {
        "&:hover": {
          background: "none",
        },
      },
    },
  },
});

export default TableRow;
