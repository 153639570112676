import { SharedColorStyles } from "components/_settings/styles/SharedColorStyles";
import { styled } from "stitches/stitches.config";

export const StyledButton = styled("button", SharedColorStyles, {
  px: 0,
  border: "none",
  appearance: "none",
  flexShrink: 0,
  borderRadius: "$round",
  transition: "all .3s ease",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "transparent",

  "&:focus": { outline: "none" },
  "&:disabled": { cursor: "not-allowed" },
  ".icon-button-icon": { fontSize: "inherit" },

  defaultVariants: { size: "sm", shape: "circle" },
  variants: {
    shape: {
      circle: {},
      square: {},
    },
    size: {
      "2xs": {
        size: 16,
        fontSize: 14,
      },
      xs: {
        size: 24,
        fontSize: 14,
      },
      sm: {
        size: 32,
        fontSize: 16,
      },
      md: {
        size: 40,
        fontSize: 16,
      },
      lg: {
        size: 44,
        fontSize: 18,
      },
      xl: {
        size: 56,
        fontSize: 18,
      },
    },
  },
});
