import { SharedButtonStyles } from "components/button";
import { SharedColorStyles } from "components/_settings/styles/SharedColorStyles";
import { Link } from "react-router-dom";
import { styled } from "stitches/stitches.config";

export const StyledLink = styled(Link, SharedColorStyles, SharedButtonStyles, {
  textDecoration: "none",
});

export const StyledAnchor = styled("a", SharedColorStyles, SharedButtonStyles, {
  textDecoration: "none",
});
