import React from "react";
import { Wrapper } from "./checkbox.styles";
import { VariantProps, CSS } from "stitches/stitches.config";
import { Flex } from "components/layout";
import { HiCheck } from "react-icons/hi";
import Text from "components/text";

interface ICheckBox {
  label?: React.ReactNode;
  body?: React.ReactNode;
  css?: CSS;
}

type CheckBoxProps = ICheckBox &
  VariantProps<typeof Wrapper> &
  React.InputHTMLAttributes<HTMLInputElement>;

const CheckBox = ({
  css,
  body,
  id,
  shape,
  label,
  children,
  ...props
}: CheckBoxProps) => {
  return (
    <Wrapper css={css} htmlFor={id} shape={shape}>
      <input type="checkbox" {...props} />
      <Flex align="center" justify="center" className="custom-checkbox">
        <HiCheck size={10} strokeWidth={2} className="checkmark" />
      </Flex>
      {body && <div className="body label">{body}</div>}
      {label && (
        <Text color="light" className="label" as="div" size="sm">
          {label}
        </Text>
      )}
      {children}
    </Wrapper>
  );
};

CheckBox.displayName = "CheckBox";

export default CheckBox;
