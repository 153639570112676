import React from "react";
import { styled, css } from "stitches/stitches.config";

const sharedCellStyles = css({
  fontFamily: "$medium",
  fontSize: 14,
  letterSpacing: 0.4,
  textAlign: "left",
});

export const HeaderCell = styled("th", sharedCellStyles, {
  color: "$gray10",
  fontSize: 12,
  borderBottom: "1px solid $gray2",
  py: 12,
  px: 24,
  fontWeight: 500,
});
export const DataCell = styled("td", sharedCellStyles, {
  borderBottom: "1px solid $gray2",
  py: 6,
  px: 24,

  variants: {
    isCellInLastRow: { true: { borderBottom: "none" } },
  },
});
