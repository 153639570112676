import { useRect } from "@reach/rect";
import ActionDropdown from "components/action-dropdown/action-dropdown";
import BalanceChart from "components/balance-chart-card/balance-chart";
import Button from "components/button";
import Card from "components/card";
import FundingInstructions from "components/funding-instructions";
import { Flex } from "components/layout";
import LinkButton from "components/link-button";
import Modal from "components/modal";
import Text from "components/text";
import { BankAccount, DailyBalance } from "generated/__generated_graphql";
import React from "react";
import { HiChevronDown, HiPlus } from "react-icons/hi";
import { HiOutlineDocumentCheck, HiOutlineDocumentText } from "react-icons/hi2";
import { styled } from "stitches/stitches.config";
import { mergeRefs } from "utils/dom";
import { baseServerUrl, formatMoney } from "utils/helpers";
import toast from "react-hot-toast";
import useRestQuery from "hooks/use-rest-query";

const Head = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "column",
  py: 24,
  px: 24,
  gap: 20,
  // background: "$gray2",
  // margin: "0 auto",
  // borderBottom: "1px solid $gray3",
});

type AccountBalanceCardProps = {
  bankAccount: BankAccount;
  balances?: Array<DailyBalance>;
};

const AccountBalanceCard = React.forwardRef<
  HTMLDivElement,
  AccountBalanceCardProps
>(({ bankAccount, balances = [] }, ref) => {
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const rect = useRect(wrapperRef);
  const wrapperWidth = rect?.width || 100;
  const wrapperHeight = rect?.height || 100;

  const { handleOnOpenChange, open } = Modal.useModal();
  const [{ utils }, fetch] = useRestQuery({
    requiresAuth: true,
    responseType: "blob",
  });

  async function downloadDocument(document: string, fileName: string) {
    const promise = fetch({
      url: `${baseServerUrl()}/account/${bankAccount.id}/${document}`,
      onSuccess: (response) => {
        utils.downloadFile(response, fileName, "pdf");
      },
    });

    toast.promise(promise, {
      loading: "Downloading document...",
      success: "Document downloaded",
      error: "Error downloading document",
    });
  }

  return (
    <>
      <FundingInstructions
        open={open}
        onOpenChange={handleOnOpenChange}
        account={bankAccount}
      />
      <Card
        ref={mergeRefs(wrapperRef, ref)}
        css={{ ".card-body": { padding: 0 } }}
      >
        <Flex justify={"between"} stack>
          <Head>
            <Flex align="center" className="title" stack gap={2}>
              <Text size="sm" color="light">
                Available Balance
              </Text>
              <Text
                color="black"
                size="3xl"
                weight={"medium"}
                fontFamily={"haffer"}
              >
                {formatMoney(bankAccount?.balance)}
              </Text>
            </Flex>

            <Flex gap={2} align={"center"} className="actions">
              <Button
                onClick={handleOnOpenChange}
                className="action-button"
                appearance={"flushed"}
                size="xs"
                append={<HiPlus />}
              >
                Fund account
              </Button>

              <ActionDropdown
                actions={[
                  {
                    label: "Wire details",
                    description: "Download wire details",
                    icon: <HiOutlineDocumentText size={16} />,
                    action: () =>
                      downloadDocument(
                        "wire_details",
                        `float-wire-details-${bankAccount.uuid}`
                      ),
                  },

                  {
                    label: "Bank confirmation letter",
                    description: "Download bank confirmation letter",
                    icon: <HiOutlineDocumentCheck size={16} />,
                    action: () =>
                      downloadDocument(
                        "bank_letter",
                        `float-bank-confirmation-letter-${bankAccount.uuid}`
                      ),
                  },
                ]}
                trigger={
                  <Button
                    className="action-button"
                    appearance={"flushed"}
                    size="sm"
                    append={<HiChevronDown size={16} />}
                  >
                    Documents
                  </Button>
                }
              ></ActionDropdown>
            </Flex>
          </Head>

          <Flex css={{ height: 150 }} justify="end" stack>
            <BalanceChart
              height={140}
              variant="light"
              balances={balances}
              wrapperWidth={wrapperWidth}
            />
          </Flex>
        </Flex>
      </Card>
    </>
  );
});

AccountBalanceCard.displayName = "AccountBalanceCard";

export default AccountBalanceCard;
