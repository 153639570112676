import Avatar from "components/avatar";
import { Stack } from "components/layout";
import { ITableColumn } from "components/table";
import Tag from "components/tag";
import Text from "components/text";
import { User } from "generated/__generated_graphql";
import { TeamTableData } from "./teams.types";

export const ADMIN_TEXT =
  "Admins have full access to bank accounts, cards and can invite other users to your team.";
export const CONTROLLER_TEXT =
  "Financial Controllers have limited access to your accounts. They can only send money and upload receipts.";
export const BOOKKEEPER_TEXT =
  "Bookkeepers have limited permissions, They have read-only access to your account.";

export const roleDescription: any = {
  "1": ADMIN_TEXT,
  "3": CONTROLLER_TEXT,
  "2": BOOKKEEPER_TEXT,
};

export const RoleEnum = {
  ADMIN: 1,
  BOOKEEPER: 2,
  FINANCIAL_CONTROLLER: 3,
};

export const TEAM_TABLE_COLUMNS: Array<ITableColumn<User>> = [
  {
    title: "User",
    key: "name",
    dataIndex: "name",
    render: (name: keyof TeamTableData, record: TeamTableData) => (
      <Stack isInline>
        <Avatar size={"sm"} variant={"light"} name={name}></Avatar>
        <Stack spacing={6}>
          <Text>{name}</Text>
          <Text color={"light"}>{record.email}</Text>
        </Stack>
      </Stack>
    ),
  },
  {
    title: "Role",
    key: "role",
    dataIndex: "role",
    render: (role) => (
      <Tag color={"teal"} variant="light" size={"sm"} shape="pill">
        {role?.name}
      </Tag>
    ),
  },
];
