import Box from "components/box";
import { Flex } from "components/layout";
import React from "react";
import { CSS, styled, VariantProps } from "stitches/stitches.config";
import Avatar from "./avatar";

const Wrapper = styled(Flex, {});

type AvatarGroupProps = React.ComponentProps<typeof Wrapper> & {
  spacing?: number;
  count?: number;
  size?: any;
};

const AvatarGroup = React.forwardRef<HTMLDivElement, AvatarGroupProps>(
  ({ children, spacing = -12, count, size = "sm", ...props }, ref) => {
    const childrenCount = React.Children.count(children);
    const remainder = count ? childrenCount - count! : 0;

    return (
      <Wrapper ref={ref} {...props}>
        {React.Children.map(children, (child, index) => {
          if (count && index >= count) {
            return null;
          }
          return React.cloneElement(child as React.ReactElement, {
            css: {
              zIndex: 100 - index,
              border: "3px solid white",
              "&:not(:first-child)": { marginLeft: spacing },
            } as CSS,
            key: index,
            size: size,
          });
        })}

        {/* {remainder ? (
          <Avatar
            variant={"solid"}
            color="gray"
            css={{
              zIndex: 100 - childrenCount,
              border: "3px solid white",
              "&:not(:first-child)": { marginLeft: spacing },
            }}
            size={size}
          >
            +{remainder}
          </Avatar>
        ) : null} */}
      </Wrapper>
    );
  }
);

AvatarGroup.displayName = "AvatarGroup";

export default AvatarGroup;
