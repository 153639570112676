import { green } from "@radix-ui/colors";
import { Wrapper } from "components/append-prepend-wrapper";
import Button from "components/button";
import { Flex } from "components/layout";
import LinkButton from "components/link-button";
import Text from "components/text";
import React from "react";
import { HiOutlineCheckCircle } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { formatMoney } from "utils/helpers";
import { SendMoneyComponentProps } from "../move-money.types";
import TransactionTimeline from "./transaction-timeline";

const TransactionSuccess = React.forwardRef<
  HTMLDivElement,
  SendMoneyComponentProps
>(({ form }, ref) => {
  const input = form.values;
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!form.values.recipientId) {
      navigate("/dashboard/send-money");
    }
  }, []);

  return (
    <Flex ref={ref} stack gap={16}>
      <Flex gap={4} align="center" justify={"center"} stack>
        <HiOutlineCheckCircle color={green.green10} size={30} />

        <Flex gap={2} stack>
          <Text lineHeight={"tall"} size="2xl" color="black" align="center">
            {formatMoney(input.amount)} sent to {input.recipientName}
          </Text>
          <Text size="sm" color="light" align="center">
            The money should arrive within 1-3 business days.
          </Text>
        </Flex>
      </Flex>

      {/* <TransactionTimeline /> */}

      <Flex gap={2} stack justify="center">
        <LinkButton href="/dashboard" appearance="secondary">
          Go back to dashboard
        </LinkButton>
        <LinkButton href="/dashboard/send-money" appearance={"ghost"}>
          Make another payment
        </LinkButton>
      </Flex>
    </Flex>
  );
});

TransactionSuccess.displayName = "TransactionSuccess";
export default TransactionSuccess;
