export interface Country {
  capital?: string;
  continent?: string;
  code?: string | null;
  currencyCode?: string | null;
  phoneCode?: string | null;
  emoji?: string;
  emojiFlag?: string | null;
  emojiU?: string;
  languages?: string[];
  name?: string | null;
  native?: string;
  phone?: string;
}

export const countries: Country[] = [
  {
    name: "Andorra",
    native: "Andorra",
    phoneCode: "376",
    continent: "EU",
    capital: "Andorra la Vella",
    currencyCode: "EUR",
    languages: ["ca"],
    emojiFlag: "🇦🇩",
    emojiU: "U+1F1E6 U+1F1E9",
    code: "AD",
  },
  {
    name: "United Arab Emirates",
    native: "دولة الإمارات العربية المتحدة",
    phoneCode: "971",
    continent: "AS",
    capital: "Abu Dhabi",
    currencyCode: "AED",
    languages: ["ar"],
    emojiFlag: "🇦🇪",
    emojiU: "U+1F1E6 U+1F1EA",
    code: "AE",
  },
  {
    name: "Afghanistan",
    native: "افغانستان",
    phoneCode: "93",
    continent: "AS",
    capital: "Kabul",
    currencyCode: "AFN",
    languages: ["ps", "uz", "tk"],
    emojiFlag: "🇦🇫",
    emojiU: "U+1F1E6 U+1F1EB",
    code: "AF",
  },
  {
    name: "Antigua and Barbuda",
    native: "Antigua and Barbuda",
    phoneCode: "1268",
    continent: "NA",
    capital: "Saint John's",
    currencyCode: "XCD",
    languages: ["en"],
    emojiFlag: "🇦🇬",
    emojiU: "U+1F1E6 U+1F1EC",
    code: "AG",
  },
  {
    name: "Anguilla",
    native: "Anguilla",
    phoneCode: "1264",
    continent: "NA",
    capital: "The Valley",
    currencyCode: "XCD",
    languages: ["en"],
    emojiFlag: "🇦🇮",
    emojiU: "U+1F1E6 U+1F1EE",
    code: "AI",
  },
  {
    name: "Albania",
    native: "Shqipëria",
    phoneCode: "355",
    continent: "EU",
    capital: "Tirana",
    currencyCode: "ALL",
    languages: ["sq"],
    emojiFlag: "🇦🇱",
    emojiU: "U+1F1E6 U+1F1F1",
    code: "AL",
  },
  {
    name: "Armenia",
    native: "Հայաստան",
    phoneCode: "374",
    continent: "AS",
    capital: "Yerevan",
    currencyCode: "AMD",
    languages: ["hy", "ru"],
    emojiFlag: "🇦🇲",
    emojiU: "U+1F1E6 U+1F1F2",
    code: "AM",
  },
  {
    name: "Angola",
    native: "Angola",
    phoneCode: "244",
    continent: "AF",
    capital: "Luanda",
    currencyCode: "AOA",
    languages: ["pt"],
    emojiFlag: "🇦🇴",
    emojiU: "U+1F1E6 U+1F1F4",
    code: "AO",
  },
  {
    name: "Antarctica",
    native: "Antarctica",
    phoneCode: "672",
    continent: "AN",
    capital: "",
    currencyCode: "",
    languages: [],
    emojiFlag: "🇦🇶",
    emojiU: "U+1F1E6 U+1F1F6",
    code: "AQ",
  },
  {
    name: "Argentina",
    native: "Argentina",
    phoneCode: "54",
    continent: "SA",
    capital: "Buenos Aires",
    currencyCode: "ARS",
    languages: ["es", "gn"],
    emojiFlag: "🇦🇷",
    emojiU: "U+1F1E6 U+1F1F7",
    code: "AR",
  },
  {
    name: "American Samoa",
    native: "American Samoa",
    phoneCode: "1684",
    continent: "OC",
    capital: "Pago Pago",
    currencyCode: "USD",
    languages: ["en", "sm"],
    emojiFlag: "🇦🇸",
    emojiU: "U+1F1E6 U+1F1F8",
    code: "AS",
  },
  {
    name: "Austria",
    native: "Österreich",
    phoneCode: "43",
    continent: "EU",
    capital: "Vienna",
    currencyCode: "EUR",
    languages: ["de"],
    emojiFlag: "🇦🇹",
    emojiU: "U+1F1E6 U+1F1F9",
    code: "AT",
  },
  {
    name: "Australia",
    native: "Australia",
    phoneCode: "61",
    continent: "OC",
    capital: "Canberra",
    currencyCode: "AUD",
    languages: ["en"],
    emojiFlag: "🇦🇺",
    emojiU: "U+1F1E6 U+1F1FA",
    code: "AU",
  },
  {
    name: "Aruba",
    native: "Aruba",
    phoneCode: "297",
    continent: "NA",
    capital: "Oranjestad",
    currencyCode: "AWG",
    languages: ["nl", "pa"],
    emojiFlag: "🇦🇼",
    emojiU: "U+1F1E6 U+1F1FC",
    code: "AW",
  },
  {
    name: "Åland",
    native: "Åland",
    phoneCode: "358",
    continent: "EU",
    capital: "Mariehamn",
    currencyCode: "EUR",
    languages: ["sv"],
    emojiFlag: "🇦🇽",
    emojiU: "U+1F1E6 U+1F1FD",
    code: "AX",
  },
  {
    name: "Azerbaijan",
    native: "Azərbaycan",
    phoneCode: "994",
    continent: "AS",
    capital: "Baku",
    currencyCode: "AZN",
    languages: ["az"],
    emojiFlag: "🇦🇿",
    emojiU: "U+1F1E6 U+1F1FF",
    code: "AZ",
  },
  {
    name: "Bosnia and Herzegovina",
    native: "Bosna i Hercegovina",
    phoneCode: "387",
    continent: "EU",
    capital: "Sarajevo",
    currencyCode: "BAM",
    languages: ["bs", "hr", "sr"],
    emojiFlag: "🇧🇦",
    emojiU: "U+1F1E7 U+1F1E6",
    code: "BA",
  },
  {
    name: "Barbados",
    native: "Barbados",
    phoneCode: "1246",
    continent: "NA",
    capital: "Bridgetown",
    currencyCode: "BBD",
    languages: ["en"],
    emojiFlag: "🇧🇧",
    emojiU: "U+1F1E7 U+1F1E7",
    code: "BB",
  },
  {
    name: "Bangladesh",
    native: "Bangladesh",
    phoneCode: "880",
    continent: "AS",
    capital: "Dhaka",
    currencyCode: "BDT",
    languages: ["bn"],
    emojiFlag: "🇧🇩",
    emojiU: "U+1F1E7 U+1F1E9",
    code: "BD",
  },
  {
    name: "Belgium",
    native: "België",
    phoneCode: "32",
    continent: "EU",
    capital: "Brussels",
    currencyCode: "EUR",
    languages: ["nl", "fr", "de"],
    emojiFlag: "🇧🇪",
    emojiU: "U+1F1E7 U+1F1EA",
    code: "BE",
  },
  {
    name: "Burkina Faso",
    native: "Burkina Faso",
    phoneCode: "226",
    continent: "AF",
    capital: "Ouagadougou",
    currencyCode: "XOF",
    languages: ["fr", "ff"],
    emojiFlag: "🇧🇫",
    emojiU: "U+1F1E7 U+1F1EB",
    code: "BF",
  },
  {
    name: "Bulgaria",
    native: "България",
    phoneCode: "359",
    continent: "EU",
    capital: "Sofia",
    currencyCode: "BGN",
    languages: ["bg"],
    emojiFlag: "🇧🇬",
    emojiU: "U+1F1E7 U+1F1EC",
    code: "BG",
  },
  {
    name: "Bahrain",
    native: "‏البحرين",
    phoneCode: "973",
    continent: "AS",
    capital: "Manama",
    currencyCode: "BHD",
    languages: ["ar"],
    emojiFlag: "🇧🇭",
    emojiU: "U+1F1E7 U+1F1ED",
    code: "BH",
  },
  {
    name: "Burundi",
    native: "Burundi",
    phoneCode: "257",
    continent: "AF",
    capital: "Bujumbura",
    currencyCode: "BIF",
    languages: ["fr", "rn"],
    emojiFlag: "🇧🇮",
    emojiU: "U+1F1E7 U+1F1EE",
    code: "BI",
  },
  {
    name: "Benin",
    native: "Bénin",
    phoneCode: "229",
    continent: "AF",
    capital: "Porto-Novo",
    currencyCode: "XOF",
    languages: ["fr"],
    emojiFlag: "🇧🇯",
    emojiU: "U+1F1E7 U+1F1EF",
    code: "BJ",
  },
  {
    name: "Saint Barthélemy",
    native: "Saint-Barthélemy",
    phoneCode: "590",
    continent: "NA",
    capital: "Gustavia",
    currencyCode: "EUR",
    languages: ["fr"],
    emojiFlag: "🇧🇱",
    emojiU: "U+1F1E7 U+1F1F1",
    code: "BL",
  },
  {
    name: "Bermuda",
    native: "Bermuda",
    phoneCode: "1441",
    continent: "NA",
    capital: "Hamilton",
    currencyCode: "BMD",
    languages: ["en"],
    emojiFlag: "🇧🇲",
    emojiU: "U+1F1E7 U+1F1F2",
    code: "BM",
  },
  {
    name: "Brunei",
    native: "Negara Brunei Darussalam",
    phoneCode: "673",
    continent: "AS",
    capital: "Bandar Seri Begawan",
    currencyCode: "BND",
    languages: ["ms"],
    emojiFlag: "🇧🇳",
    emojiU: "U+1F1E7 U+1F1F3",
    code: "BN",
  },
  {
    name: "Bolivia",
    native: "Bolivia",
    phoneCode: "591",
    continent: "SA",
    capital: "Sucre",
    currencyCode: "BOB,BOV",
    languages: ["es", "ay", "qu"],
    emojiFlag: "🇧🇴",
    emojiU: "U+1F1E7 U+1F1F4",
    code: "BO",
  },
  {
    name: "Bonaire",
    native: "Bonaire",
    phoneCode: "5997",
    continent: "NA",
    capital: "Kralendijk",
    currencyCode: "USD",
    languages: ["nl"],
    emojiFlag: "🇧🇶",
    emojiU: "U+1F1E7 U+1F1F6",
    code: "BQ",
  },
  {
    name: "Brazil",
    native: "Brasil",
    phoneCode: "55",
    continent: "SA",
    capital: "Brasília",
    currencyCode: "BRL",
    languages: ["pt"],
    emojiFlag: "🇧🇷",
    emojiU: "U+1F1E7 U+1F1F7",
    code: "BR",
  },
  {
    name: "Bahamas",
    native: "Bahamas",
    phoneCode: "1242",
    continent: "NA",
    capital: "Nassau",
    currencyCode: "BSD",
    languages: ["en"],
    emojiFlag: "🇧🇸",
    emojiU: "U+1F1E7 U+1F1F8",
    code: "BS",
  },
  {
    name: "Bhutan",
    native: "ʼbrug-yul",
    phoneCode: "975",
    continent: "AS",
    capital: "Thimphu",
    currencyCode: "BTN,INR",
    languages: ["dz"],
    emojiFlag: "🇧🇹",
    emojiU: "U+1F1E7 U+1F1F9",
    code: "BT",
  },
  {
    name: "Bouvet Island",
    native: "Bouvetøya",
    phoneCode: "47",
    continent: "AN",
    capital: "",
    currencyCode: "NOK",
    languages: ["no", "nb", "nn"],
    emojiFlag: "🇧🇻",
    emojiU: "U+1F1E7 U+1F1FB",
    code: "BV",
  },
  {
    name: "Botswana",
    native: "Botswana",
    phoneCode: "267",
    continent: "AF",
    capital: "Gaborone",
    currencyCode: "BWP",
    languages: ["en", "tn"],
    emojiFlag: "🇧🇼",
    emojiU: "U+1F1E7 U+1F1FC",
    code: "BW",
  },
  {
    name: "Belarus",
    native: "Белару́сь",
    phoneCode: "375",
    continent: "EU",
    capital: "Minsk",
    currencyCode: "BYN",
    languages: ["be", "ru"],
    emojiFlag: "🇧🇾",
    emojiU: "U+1F1E7 U+1F1FE",
    code: "BY",
  },
  {
    name: "Belize",
    native: "Belize",
    phoneCode: "501",
    continent: "NA",
    capital: "Belmopan",
    currencyCode: "BZD",
    languages: ["en", "es"],
    emojiFlag: "🇧🇿",
    emojiU: "U+1F1E7 U+1F1FF",
    code: "BZ",
  },
  {
    name: "Canada",
    native: "Canada",
    phoneCode: "1",
    continent: "NA",
    capital: "Ottawa",
    currencyCode: "CAD",
    languages: ["en", "fr"],
    emojiFlag: "🇨🇦",
    emojiU: "U+1F1E8 U+1F1E6",
    code: "CA",
  },
  {
    name: "Cocos [Keeling] Islands",
    native: "Cocos (Keeling) Islands",
    phoneCode: "61",
    continent: "AS",
    capital: "West Island",
    currencyCode: "AUD",
    languages: ["en"],
    emojiFlag: "🇨🇨",
    emojiU: "U+1F1E8 U+1F1E8",
    code: "CC",
  },
  {
    name: "Democratic Republic of the Congo",
    native: "République démocratique du Congo",
    phoneCode: "243",
    continent: "AF",
    capital: "Kinshasa",
    currencyCode: "CDF",
    languages: ["fr", "ln", "kg", "sw", "lu"],
    emojiFlag: "🇨🇩",
    emojiU: "U+1F1E8 U+1F1E9",
    code: "CD",
  },
  {
    name: "Central African Republic",
    native: "Ködörösêse tî Bêafrîka",
    phoneCode: "236",
    continent: "AF",
    capital: "Bangui",
    currencyCode: "XAF",
    languages: ["fr", "sg"],
    emojiFlag: "🇨🇫",
    emojiU: "U+1F1E8 U+1F1EB",
    code: "CF",
  },
  {
    name: "Republic of the Congo",
    native: "République du Congo",
    phoneCode: "242",
    continent: "AF",
    capital: "Brazzaville",
    currencyCode: "XAF",
    languages: ["fr", "ln"],
    emojiFlag: "🇨🇬",
    emojiU: "U+1F1E8 U+1F1EC",
    code: "CG",
  },
  {
    name: "Switzerland",
    native: "Schweiz",
    phoneCode: "41",
    continent: "EU",
    capital: "Bern",
    currencyCode: "CHE,CHF,CHW",
    languages: ["de", "fr", "it"],
    emojiFlag: "🇨🇭",
    emojiU: "U+1F1E8 U+1F1ED",
    code: "CH",
  },
  {
    name: "Ivory Coast",
    native: "Côte d'Ivoire",
    phoneCode: "225",
    continent: "AF",
    capital: "Yamoussoukro",
    currencyCode: "XOF",
    languages: ["fr"],
    emojiFlag: "🇨🇮",
    emojiU: "U+1F1E8 U+1F1EE",
    code: "CI",
  },
  {
    name: "Cook Islands",
    native: "Cook Islands",
    phoneCode: "682",
    continent: "OC",
    capital: "Avarua",
    currencyCode: "NZD",
    languages: ["en"],
    emojiFlag: "🇨🇰",
    emojiU: "U+1F1E8 U+1F1F0",
    code: "CK",
  },
  {
    name: "Chile",
    native: "Chile",
    phoneCode: "56",
    continent: "SA",
    capital: "Santiago",
    currencyCode: "CLF,CLP",
    languages: ["es"],
    emojiFlag: "🇨🇱",
    emojiU: "U+1F1E8 U+1F1F1",
    code: "CL",
  },
  {
    name: "Cameroon",
    native: "Cameroon",
    phoneCode: "237",
    continent: "AF",
    capital: "Yaoundé",
    currencyCode: "XAF",
    languages: ["en", "fr"],
    emojiFlag: "🇨🇲",
    emojiU: "U+1F1E8 U+1F1F2",
    code: "CM",
  },
  {
    name: "China",
    native: "中国",
    phoneCode: "86",
    continent: "AS",
    capital: "Beijing",
    currencyCode: "CNY",
    languages: ["zh"],
    emojiFlag: "🇨🇳",
    emojiU: "U+1F1E8 U+1F1F3",
    code: "CN",
  },
  {
    name: "Colombia",
    native: "Colombia",
    phoneCode: "57",
    continent: "SA",
    capital: "Bogotá",
    currencyCode: "COP",
    languages: ["es"],
    emojiFlag: "🇨🇴",
    emojiU: "U+1F1E8 U+1F1F4",
    code: "CO",
  },
  {
    name: "Costa Rica",
    native: "Costa Rica",
    phoneCode: "506",
    continent: "NA",
    capital: "San José",
    currencyCode: "CRC",
    languages: ["es"],
    emojiFlag: "🇨🇷",
    emojiU: "U+1F1E8 U+1F1F7",
    code: "CR",
  },
  {
    name: "Cuba",
    native: "Cuba",
    phoneCode: "53",
    continent: "NA",
    capital: "Havana",
    currencyCode: "CUC,CUP",
    languages: ["es"],
    emojiFlag: "🇨🇺",
    emojiU: "U+1F1E8 U+1F1FA",
    code: "CU",
  },
  {
    name: "Cape Verde",
    native: "Cabo Verde",
    phoneCode: "238",
    continent: "AF",
    capital: "Praia",
    currencyCode: "CVE",
    languages: ["pt"],
    emojiFlag: "🇨🇻",
    emojiU: "U+1F1E8 U+1F1FB",
    code: "CV",
  },
  {
    name: "Curacao",
    native: "Curaçao",
    phoneCode: "5999",
    continent: "NA",
    capital: "Willemstad",
    currencyCode: "ANG",
    languages: ["nl", "pa", "en"],
    emojiFlag: "🇨🇼",
    emojiU: "U+1F1E8 U+1F1FC",
    code: "CW",
  },
  {
    name: "Christmas Island",
    native: "Christmas Island",
    phoneCode: "61",
    continent: "AS",
    capital: "Flying Fish Cove",
    currencyCode: "AUD",
    languages: ["en"],
    emojiFlag: "🇨🇽",
    emojiU: "U+1F1E8 U+1F1FD",
    code: "CX",
  },
  {
    name: "Cyprus",
    native: "Κύπρος",
    phoneCode: "357",
    continent: "EU",
    capital: "Nicosia",
    currencyCode: "EUR",
    languages: ["el", "tr", "hy"],
    emojiFlag: "🇨🇾",
    emojiU: "U+1F1E8 U+1F1FE",
    code: "CY",
  },
  {
    name: "Czech Republic",
    native: "Česká republika",
    phoneCode: "420",
    continent: "EU",
    capital: "Prague",
    currencyCode: "CZK",
    languages: ["cs", "sk"],
    emojiFlag: "🇨🇿",
    emojiU: "U+1F1E8 U+1F1FF",
    code: "CZ",
  },
  {
    name: "Germany",
    native: "Deutschland",
    phoneCode: "49",
    continent: "EU",
    capital: "Berlin",
    currencyCode: "EUR",
    languages: ["de"],
    emojiFlag: "🇩🇪",
    emojiU: "U+1F1E9 U+1F1EA",
    code: "DE",
  },
  {
    name: "Djibouti",
    native: "Djibouti",
    phoneCode: "253",
    continent: "AF",
    capital: "Djibouti",
    currencyCode: "DJF",
    languages: ["fr", "ar"],
    emojiFlag: "🇩🇯",
    emojiU: "U+1F1E9 U+1F1EF",
    code: "DJ",
  },
  {
    name: "Denmark",
    native: "Danmark",
    phoneCode: "45",
    continent: "EU",
    capital: "Copenhagen",
    currencyCode: "DKK",
    languages: ["da"],
    emojiFlag: "🇩🇰",
    emojiU: "U+1F1E9 U+1F1F0",
    code: "DK",
  },
  {
    name: "Dominica",
    native: "Dominica",
    phoneCode: "1767",
    continent: "NA",
    capital: "Roseau",
    currencyCode: "XCD",
    languages: ["en"],
    emojiFlag: "🇩🇲",
    emojiU: "U+1F1E9 U+1F1F2",
    code: "DM",
  },
  {
    name: "Dominican Republic",
    native: "República Dominicana",
    phoneCode: "1809,1829,1849",
    continent: "NA",
    capital: "Santo Domingo",
    currencyCode: "DOP",
    languages: ["es"],
    emojiFlag: "🇩🇴",
    emojiU: "U+1F1E9 U+1F1F4",
    code: "DO",
  },
  {
    name: "Algeria",
    native: "الجزائر",
    phoneCode: "213",
    continent: "AF",
    capital: "Algiers",
    currencyCode: "DZD",
    languages: ["ar"],
    emojiFlag: "🇩🇿",
    emojiU: "U+1F1E9 U+1F1FF",
    code: "DZ",
  },
  {
    name: "Ecuador",
    native: "Ecuador",
    phoneCode: "593",
    continent: "SA",
    capital: "Quito",
    currencyCode: "USD",
    languages: ["es"],
    emojiFlag: "🇪🇨",
    emojiU: "U+1F1EA U+1F1E8",
    code: "EC",
  },
  {
    name: "Estonia",
    native: "Eesti",
    phoneCode: "372",
    continent: "EU",
    capital: "Tallinn",
    currencyCode: "EUR",
    languages: ["et"],
    emojiFlag: "🇪🇪",
    emojiU: "U+1F1EA U+1F1EA",
    code: "EE",
  },
  {
    name: "Egypt",
    native: "مصر‎",
    phoneCode: "20",
    continent: "AF",
    capital: "Cairo",
    currencyCode: "EGP",
    languages: ["ar"],
    emojiFlag: "🇪🇬",
    emojiU: "U+1F1EA U+1F1EC",
    code: "EG",
  },
  {
    name: "Western Sahara",
    native: "الصحراء الغربية",
    phoneCode: "212",
    continent: "AF",
    capital: "El Aaiún",
    currencyCode: "MAD,DZD,MRU",
    languages: ["es"],
    emojiFlag: "🇪🇭",
    emojiU: "U+1F1EA U+1F1ED",
    code: "EH",
  },
  {
    name: "Eritrea",
    native: "ኤርትራ",
    phoneCode: "291",
    continent: "AF",
    capital: "Asmara",
    currencyCode: "ERN",
    languages: ["ti", "ar", "en"],
    emojiFlag: "🇪🇷",
    emojiU: "U+1F1EA U+1F1F7",
    code: "ER",
  },
  {
    name: "Spain",
    native: "España",
    phoneCode: "34",
    continent: "EU",
    capital: "Madrid",
    currencyCode: "EUR",
    languages: ["es", "eu", "ca", "gl", "oc"],
    emojiFlag: "🇪🇸",
    emojiU: "U+1F1EA U+1F1F8",
    code: "ES",
  },
  {
    name: "Ethiopia",
    native: "ኢትዮጵያ",
    phoneCode: "251",
    continent: "AF",
    capital: "Addis Ababa",
    currencyCode: "ETB",
    languages: ["am"],
    emojiFlag: "🇪🇹",
    emojiU: "U+1F1EA U+1F1F9",
    code: "ET",
  },
  {
    name: "Finland",
    native: "Suomi",
    phoneCode: "358",
    continent: "EU",
    capital: "Helsinki",
    currencyCode: "EUR",
    languages: ["fi", "sv"],
    emojiFlag: "🇫🇮",
    emojiU: "U+1F1EB U+1F1EE",
    code: "FI",
  },
  {
    name: "Fiji",
    native: "Fiji",
    phoneCode: "679",
    continent: "OC",
    capital: "Suva",
    currencyCode: "FJD",
    languages: ["en", "fj", "hi", "ur"],
    emojiFlag: "🇫🇯",
    emojiU: "U+1F1EB U+1F1EF",
    code: "FJ",
  },
  {
    name: "Falkland Islands",
    native: "Falkland Islands",
    phoneCode: "500",
    continent: "SA",
    capital: "Stanley",
    currencyCode: "FKP",
    languages: ["en"],
    emojiFlag: "🇫🇰",
    emojiU: "U+1F1EB U+1F1F0",
    code: "FK",
  },
  {
    name: "Micronesia",
    native: "Micronesia",
    phoneCode: "691",
    continent: "OC",
    capital: "Palikir",
    currencyCode: "USD",
    languages: ["en"],
    emojiFlag: "🇫🇲",
    emojiU: "U+1F1EB U+1F1F2",
    code: "FM",
  },
  {
    name: "Faroe Islands",
    native: "Føroyar",
    phoneCode: "298",
    continent: "EU",
    capital: "Tórshavn",
    currencyCode: "DKK",
    languages: ["fo"],
    emojiFlag: "🇫🇴",
    emojiU: "U+1F1EB U+1F1F4",
    code: "FO",
  },
  {
    name: "France",
    native: "France",
    phoneCode: "33",
    continent: "EU",
    capital: "Paris",
    currencyCode: "EUR",
    languages: ["fr"],
    emojiFlag: "🇫🇷",
    emojiU: "U+1F1EB U+1F1F7",
    code: "FR",
  },
  {
    name: "Gabon",
    native: "Gabon",
    phoneCode: "241",
    continent: "AF",
    capital: "Libreville",
    currencyCode: "XAF",
    languages: ["fr"],
    emojiFlag: "🇬🇦",
    emojiU: "U+1F1EC U+1F1E6",
    code: "GA",
  },
  {
    name: "United Kingdom",
    native: "United Kingdom",
    phoneCode: "44",
    continent: "EU",
    capital: "London",
    currencyCode: "GBP",
    languages: ["en"],
    emojiFlag: "🇬🇧",
    emojiU: "U+1F1EC U+1F1E7",
    code: "GB",
  },
  {
    name: "Grenada",
    native: "Grenada",
    phoneCode: "1473",
    continent: "NA",
    capital: "St. George's",
    currencyCode: "XCD",
    languages: ["en"],
    emojiFlag: "🇬🇩",
    emojiU: "U+1F1EC U+1F1E9",
    code: "GD",
  },
  {
    name: "Georgia",
    native: "საქართველო",
    phoneCode: "995",
    continent: "AS",
    capital: "Tbilisi",
    currencyCode: "GEL",
    languages: ["ka"],
    emojiFlag: "🇬🇪",
    emojiU: "U+1F1EC U+1F1EA",
    code: "GE",
  },
  {
    name: "French Guiana",
    native: "Guyane française",
    phoneCode: "594",
    continent: "SA",
    capital: "Cayenne",
    currencyCode: "EUR",
    languages: ["fr"],
    emojiFlag: "🇬🇫",
    emojiU: "U+1F1EC U+1F1EB",
    code: "GF",
  },
  {
    name: "Guernsey",
    native: "Guernsey",
    phoneCode: "44",
    continent: "EU",
    capital: "St. Peter Port",
    currencyCode: "GBP",
    languages: ["en", "fr"],
    emojiFlag: "🇬🇬",
    emojiU: "U+1F1EC U+1F1EC",
    code: "GG",
  },
  {
    name: "Ghana",
    native: "Ghana",
    phoneCode: "233",
    continent: "AF",
    capital: "Accra",
    currencyCode: "GHS",
    languages: ["en"],
    emojiFlag: "🇬🇭",
    emojiU: "U+1F1EC U+1F1ED",
    code: "GH",
  },
  {
    name: "Gibraltar",
    native: "Gibraltar",
    phoneCode: "350",
    continent: "EU",
    capital: "Gibraltar",
    currencyCode: "GIP",
    languages: ["en"],
    emojiFlag: "🇬🇮",
    emojiU: "U+1F1EC U+1F1EE",
    code: "GI",
  },
  {
    name: "Greenland",
    native: "Kalaallit Nunaat",
    phoneCode: "299",
    continent: "NA",
    capital: "Nuuk",
    currencyCode: "DKK",
    languages: ["kl"],
    emojiFlag: "🇬🇱",
    emojiU: "U+1F1EC U+1F1F1",
    code: "GL",
  },
  {
    name: "Gambia",
    native: "Gambia",
    phoneCode: "220",
    continent: "AF",
    capital: "Banjul",
    currencyCode: "GMD",
    languages: ["en"],
    emojiFlag: "🇬🇲",
    emojiU: "U+1F1EC U+1F1F2",
    code: "GM",
  },
  {
    name: "Guinea",
    native: "Guinée",
    phoneCode: "224",
    continent: "AF",
    capital: "Conakry",
    currencyCode: "GNF",
    languages: ["fr", "ff"],
    emojiFlag: "🇬🇳",
    emojiU: "U+1F1EC U+1F1F3",
    code: "GN",
  },
  {
    name: "Guadeloupe",
    native: "Guadeloupe",
    phoneCode: "590",
    continent: "NA",
    capital: "Basse-Terre",
    currencyCode: "EUR",
    languages: ["fr"],
    emojiFlag: "🇬🇵",
    emojiU: "U+1F1EC U+1F1F5",
    code: "GP",
  },
  {
    name: "Equatorial Guinea",
    native: "Guinea Ecuatorial",
    phoneCode: "240",
    continent: "AF",
    capital: "Malabo",
    currencyCode: "XAF",
    languages: ["es", "fr"],
    emojiFlag: "🇬🇶",
    emojiU: "U+1F1EC U+1F1F6",
    code: "GQ",
  },
  {
    name: "Greece",
    native: "Ελλάδα",
    phoneCode: "30",
    continent: "EU",
    capital: "Athens",
    currencyCode: "EUR",
    languages: ["el"],
    emojiFlag: "🇬🇷",
    emojiU: "U+1F1EC U+1F1F7",
    code: "GR",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    native: "South Georgia",
    phoneCode: "500",
    continent: "AN",
    capital: "King Edward Point",
    currencyCode: "GBP",
    languages: ["en"],
    emojiFlag: "🇬🇸",
    emojiU: "U+1F1EC U+1F1F8",
    code: "GS",
  },
  {
    name: "Guatemala",
    native: "Guatemala",
    phoneCode: "502",
    continent: "NA",
    capital: "Guatemala City",
    currencyCode: "GTQ",
    languages: ["es"],
    emojiFlag: "🇬🇹",
    emojiU: "U+1F1EC U+1F1F9",
    code: "GT",
  },
  {
    name: "Guam",
    native: "Guam",
    phoneCode: "1671",
    continent: "OC",
    capital: "Hagåtña",
    currencyCode: "USD",
    languages: ["en", "ch", "es"],
    emojiFlag: "🇬🇺",
    emojiU: "U+1F1EC U+1F1FA",
    code: "GU",
  },
  {
    name: "Guinea-Bissau",
    native: "Guiné-Bissau",
    phoneCode: "245",
    continent: "AF",
    capital: "Bissau",
    currencyCode: "XOF",
    languages: ["pt"],
    emojiFlag: "🇬🇼",
    emojiU: "U+1F1EC U+1F1FC",
    code: "GW",
  },
  {
    name: "Guyana",
    native: "Guyana",
    phoneCode: "592",
    continent: "SA",
    capital: "Georgetown",
    currencyCode: "GYD",
    languages: ["en"],
    emojiFlag: "🇬🇾",
    emojiU: "U+1F1EC U+1F1FE",
    code: "GY",
  },
  {
    name: "Hong Kong",
    native: "香港",
    phoneCode: "852",
    continent: "AS",
    capital: "City of Victoria",
    currencyCode: "HKD",
    languages: ["zh", "en"],
    emojiFlag: "🇭🇰",
    emojiU: "U+1F1ED U+1F1F0",
    code: "HK",
  },
  {
    name: "Heard Island and McDonald Islands",
    native: "Heard Island and McDonald Islands",
    phoneCode: "61",
    continent: "AN",
    capital: "",
    currencyCode: "AUD",
    languages: ["en"],
    emojiFlag: "🇭🇲",
    emojiU: "U+1F1ED U+1F1F2",
    code: "HM",
  },
  {
    name: "Honduras",
    native: "Honduras",
    phoneCode: "504",
    continent: "NA",
    capital: "Tegucigalpa",
    currencyCode: "HNL",
    languages: ["es"],
    emojiFlag: "🇭🇳",
    emojiU: "U+1F1ED U+1F1F3",
    code: "HN",
  },
  {
    name: "Croatia",
    native: "Hrvatska",
    phoneCode: "385",
    continent: "EU",
    capital: "Zagreb",
    currencyCode: "HRK",
    languages: ["hr"],
    emojiFlag: "🇭🇷",
    emojiU: "U+1F1ED U+1F1F7",
    code: "HR",
  },
  {
    name: "Haiti",
    native: "Haïti",
    phoneCode: "509",
    continent: "NA",
    capital: "Port-au-Prince",
    currencyCode: "HTG,USD",
    languages: ["fr", "ht"],
    emojiFlag: "🇭🇹",
    emojiU: "U+1F1ED U+1F1F9",
    code: "HT",
  },
  {
    name: "Hungary",
    native: "Magyarország",
    phoneCode: "36",
    continent: "EU",
    capital: "Budapest",
    currencyCode: "HUF",
    languages: ["hu"],
    emojiFlag: "🇭🇺",
    emojiU: "U+1F1ED U+1F1FA",
    code: "HU",
  },
  {
    name: "Indonesia",
    native: "Indonesia",
    phoneCode: "62",
    continent: "AS",
    capital: "Jakarta",
    currencyCode: "IDR",
    languages: ["id"],
    emojiFlag: "🇮🇩",
    emojiU: "U+1F1EE U+1F1E9",
    code: "ID",
  },
  {
    name: "Ireland",
    native: "Éire",
    phoneCode: "353",
    continent: "EU",
    capital: "Dublin",
    currencyCode: "EUR",
    languages: ["ga", "en"],
    emojiFlag: "🇮🇪",
    emojiU: "U+1F1EE U+1F1EA",
    code: "IE",
  },
  {
    name: "Israel",
    native: "יִשְׂרָאֵל",
    phoneCode: "972",
    continent: "AS",
    capital: "Jerusalem",
    currencyCode: "ILS",
    languages: ["he", "ar"],
    emojiFlag: "🇮🇱",
    emojiU: "U+1F1EE U+1F1F1",
    code: "IL",
  },
  {
    name: "Isle of Man",
    native: "Isle of Man",
    phoneCode: "44",
    continent: "EU",
    capital: "Douglas",
    currencyCode: "GBP",
    languages: ["en", "gv"],
    emojiFlag: "🇮🇲",
    emojiU: "U+1F1EE U+1F1F2",
    code: "IM",
  },
  {
    name: "India",
    native: "भारत",
    phoneCode: "91",
    continent: "AS",
    capital: "New Delhi",
    currencyCode: "INR",
    languages: ["hi", "en"],
    emojiFlag: "🇮🇳",
    emojiU: "U+1F1EE U+1F1F3",
    code: "IN",
  },
  {
    name: "British Indian Ocean Territory",
    native: "British Indian Ocean Territory",
    phoneCode: "246",
    continent: "AS",
    capital: "Diego Garcia",
    currencyCode: "USD",
    languages: ["en"],
    emojiFlag: "🇮🇴",
    emojiU: "U+1F1EE U+1F1F4",
    code: "IO",
  },
  {
    name: "Iraq",
    native: "العراق",
    phoneCode: "964",
    continent: "AS",
    capital: "Baghdad",
    currencyCode: "IQD",
    languages: ["ar", "ku"],
    emojiFlag: "🇮🇶",
    emojiU: "U+1F1EE U+1F1F6",
    code: "IQ",
  },
  {
    name: "Iran",
    native: "ایران",
    phoneCode: "98",
    continent: "AS",
    capital: "Tehran",
    currencyCode: "IRR",
    languages: ["fa"],
    emojiFlag: "🇮🇷",
    emojiU: "U+1F1EE U+1F1F7",
    code: "IR",
  },
  {
    name: "Iceland",
    native: "Ísland",
    phoneCode: "354",
    continent: "EU",
    capital: "Reykjavik",
    currencyCode: "ISK",
    languages: ["is"],
    emojiFlag: "🇮🇸",
    emojiU: "U+1F1EE U+1F1F8",
    code: "IS",
  },
  {
    name: "Italy",
    native: "Italia",
    phoneCode: "39",
    continent: "EU",
    capital: "Rome",
    currencyCode: "EUR",
    languages: ["it"],
    emojiFlag: "🇮🇹",
    emojiU: "U+1F1EE U+1F1F9",
    code: "IT",
  },
  {
    name: "Jersey",
    native: "Jersey",
    phoneCode: "44",
    continent: "EU",
    capital: "Saint Helier",
    currencyCode: "GBP",
    languages: ["en", "fr"],
    emojiFlag: "🇯🇪",
    emojiU: "U+1F1EF U+1F1EA",
    code: "JE",
  },
  {
    name: "Jamaica",
    native: "Jamaica",
    phoneCode: "1876",
    continent: "NA",
    capital: "Kingston",
    currencyCode: "JMD",
    languages: ["en"],
    emojiFlag: "🇯🇲",
    emojiU: "U+1F1EF U+1F1F2",
    code: "JM",
  },
  {
    name: "Jordan",
    native: "الأردن",
    phoneCode: "962",
    continent: "AS",
    capital: "Amman",
    currencyCode: "JOD",
    languages: ["ar"],
    emojiFlag: "🇯🇴",
    emojiU: "U+1F1EF U+1F1F4",
    code: "JO",
  },
  {
    name: "Japan",
    native: "日本",
    phoneCode: "81",
    continent: "AS",
    capital: "Tokyo",
    currencyCode: "JPY",
    languages: ["ja"],
    emojiFlag: "🇯🇵",
    emojiU: "U+1F1EF U+1F1F5",
    code: "JP",
  },
  {
    name: "Kenya",
    native: "Kenya",
    phoneCode: "254",
    continent: "AF",
    capital: "Nairobi",
    currencyCode: "KES",
    languages: ["en", "sw"],
    emojiFlag: "🇰🇪",
    emojiU: "U+1F1F0 U+1F1EA",
    code: "KE",
  },
  {
    name: "Kyrgyzstan",
    native: "Кыргызстан",
    phoneCode: "996",
    continent: "AS",
    capital: "Bishkek",
    currencyCode: "KGS",
    languages: ["ky", "ru"],
    emojiFlag: "🇰🇬",
    emojiU: "U+1F1F0 U+1F1EC",
    code: "KG",
  },
  {
    name: "Cambodia",
    native: "Kâmpŭchéa",
    phoneCode: "855",
    continent: "AS",
    capital: "Phnom Penh",
    currencyCode: "KHR",
    languages: ["km"],
    emojiFlag: "🇰🇭",
    emojiU: "U+1F1F0 U+1F1ED",
    code: "KH",
  },
  {
    name: "Kiribati",
    native: "Kiribati",
    phoneCode: "686",
    continent: "OC",
    capital: "South Tarawa",
    currencyCode: "AUD",
    languages: ["en"],
    emojiFlag: "🇰🇮",
    emojiU: "U+1F1F0 U+1F1EE",
    code: "KI",
  },
  {
    name: "Comoros",
    native: "Komori",
    phoneCode: "269",
    continent: "AF",
    capital: "Moroni",
    currencyCode: "KMF",
    languages: ["ar", "fr"],
    emojiFlag: "🇰🇲",
    emojiU: "U+1F1F0 U+1F1F2",
    code: "KM",
  },
  {
    name: "Saint Kitts and Nevis",
    native: "Saint Kitts and Nevis",
    phoneCode: "1869",
    continent: "NA",
    capital: "Basseterre",
    currencyCode: "XCD",
    languages: ["en"],
    emojiFlag: "🇰🇳",
    emojiU: "U+1F1F0 U+1F1F3",
    code: "KN",
  },
  {
    name: "North Korea",
    native: "북한",
    phoneCode: "850",
    continent: "AS",
    capital: "Pyongyang",
    currencyCode: "KPW",
    languages: ["ko"],
    emojiFlag: "🇰🇵",
    emojiU: "U+1F1F0 U+1F1F5",
    code: "KP",
  },
  {
    name: "South Korea",
    native: "대한민국",
    phoneCode: "82",
    continent: "AS",
    capital: "Seoul",
    currencyCode: "KRW",
    languages: ["ko"],
    emojiFlag: "🇰🇷",
    emojiU: "U+1F1F0 U+1F1F7",
    code: "KR",
  },
  {
    name: "Kuwait",
    native: "الكويت",
    phoneCode: "965",
    continent: "AS",
    capital: "Kuwait City",
    currencyCode: "KWD",
    languages: ["ar"],
    emojiFlag: "🇰🇼",
    emojiU: "U+1F1F0 U+1F1FC",
    code: "KW",
  },
  {
    name: "Cayman Islands",
    native: "Cayman Islands",
    phoneCode: "1345",
    continent: "NA",
    capital: "George Town",
    currencyCode: "KYD",
    languages: ["en"],
    emojiFlag: "🇰🇾",
    emojiU: "U+1F1F0 U+1F1FE",
    code: "KY",
  },
  {
    name: "Kazakhstan",
    native: "Қазақстан",
    phoneCode: "76,77",
    continent: "AS",
    capital: "Astana",
    currencyCode: "KZT",
    languages: ["kk", "ru"],
    emojiFlag: "🇰🇿",
    emojiU: "U+1F1F0 U+1F1FF",
    code: "KZ",
  },
  {
    name: "Laos",
    native: "ສປປລາວ",
    phoneCode: "856",
    continent: "AS",
    capital: "Vientiane",
    currencyCode: "LAK",
    languages: ["lo"],
    emojiFlag: "🇱🇦",
    emojiU: "U+1F1F1 U+1F1E6",
    code: "LA",
  },
  {
    name: "Lebanon",
    native: "لبنان",
    phoneCode: "961",
    continent: "AS",
    capital: "Beirut",
    currencyCode: "LBP",
    languages: ["ar", "fr"],
    emojiFlag: "🇱🇧",
    emojiU: "U+1F1F1 U+1F1E7",
    code: "LB",
  },
  {
    name: "Saint Lucia",
    native: "Saint Lucia",
    phoneCode: "1758",
    continent: "NA",
    capital: "Castries",
    currencyCode: "XCD",
    languages: ["en"],
    emojiFlag: "🇱🇨",
    emojiU: "U+1F1F1 U+1F1E8",
    code: "LC",
  },
  {
    name: "Liechtenstein",
    native: "Liechtenstein",
    phoneCode: "423",
    continent: "EU",
    capital: "Vaduz",
    currencyCode: "CHF",
    languages: ["de"],
    emojiFlag: "🇱🇮",
    emojiU: "U+1F1F1 U+1F1EE",
    code: "LI",
  },
  {
    name: "Sri Lanka",
    native: "śrī laṃkāva",
    phoneCode: "94",
    continent: "AS",
    capital: "Colombo",
    currencyCode: "LKR",
    languages: ["si", "ta"],
    emojiFlag: "🇱🇰",
    emojiU: "U+1F1F1 U+1F1F0",
    code: "LK",
  },
  {
    name: "Liberia",
    native: "Liberia",
    phoneCode: "231",
    continent: "AF",
    capital: "Monrovia",
    currencyCode: "LRD",
    languages: ["en"],
    emojiFlag: "🇱🇷",
    emojiU: "U+1F1F1 U+1F1F7",
    code: "LR",
  },
  {
    name: "Lesotho",
    native: "Lesotho",
    phoneCode: "266",
    continent: "AF",
    capital: "Maseru",
    currencyCode: "LSL,ZAR",
    languages: ["en", "st"],
    emojiFlag: "🇱🇸",
    emojiU: "U+1F1F1 U+1F1F8",
    code: "LS",
  },
  {
    name: "Lithuania",
    native: "Lietuva",
    phoneCode: "370",
    continent: "EU",
    capital: "Vilnius",
    currencyCode: "EUR",
    languages: ["lt"],
    emojiFlag: "🇱🇹",
    emojiU: "U+1F1F1 U+1F1F9",
    code: "LT",
  },
  {
    name: "Luxembourg",
    native: "Luxembourg",
    phoneCode: "352",
    continent: "EU",
    capital: "Luxembourg",
    currencyCode: "EUR",
    languages: ["fr", "de", "lb"],
    emojiFlag: "🇱🇺",
    emojiU: "U+1F1F1 U+1F1FA",
    code: "LU",
  },
  {
    name: "Latvia",
    native: "Latvija",
    phoneCode: "371",
    continent: "EU",
    capital: "Riga",
    currencyCode: "EUR",
    languages: ["lv"],
    emojiFlag: "🇱🇻",
    emojiU: "U+1F1F1 U+1F1FB",
    code: "LV",
  },
  {
    name: "Libya",
    native: "‏ليبيا",
    phoneCode: "218",
    continent: "AF",
    capital: "Tripoli",
    currencyCode: "LYD",
    languages: ["ar"],
    emojiFlag: "🇱🇾",
    emojiU: "U+1F1F1 U+1F1FE",
    code: "LY",
  },
  {
    name: "Morocco",
    native: "المغرب",
    phoneCode: "212",
    continent: "AF",
    capital: "Rabat",
    currencyCode: "MAD",
    languages: ["ar"],
    emojiFlag: "🇲🇦",
    emojiU: "U+1F1F2 U+1F1E6",
    code: "MA",
  },
  {
    name: "Monaco",
    native: "Monaco",
    phoneCode: "377",
    continent: "EU",
    capital: "Monaco",
    currencyCode: "EUR",
    languages: ["fr"],
    emojiFlag: "🇲🇨",
    emojiU: "U+1F1F2 U+1F1E8",
    code: "MC",
  },
  {
    name: "Moldova",
    native: "Moldova",
    phoneCode: "373",
    continent: "EU",
    capital: "Chișinău",
    currencyCode: "MDL",
    languages: ["ro"],
    emojiFlag: "🇲🇩",
    emojiU: "U+1F1F2 U+1F1E9",
    code: "MD",
  },
  {
    name: "Montenegro",
    native: "Црна Гора",
    phoneCode: "382",
    continent: "EU",
    capital: "Podgorica",
    currencyCode: "EUR",
    languages: ["sr", "bs", "sq", "hr"],
    emojiFlag: "🇲🇪",
    emojiU: "U+1F1F2 U+1F1EA",
    code: "ME",
  },
  {
    name: "Saint Martin",
    native: "Saint-Martin",
    phoneCode: "590",
    continent: "NA",
    capital: "Marigot",
    currencyCode: "EUR",
    languages: ["en", "fr", "nl"],
    emojiFlag: "🇲🇫",
    emojiU: "U+1F1F2 U+1F1EB",
    code: "MF",
  },
  {
    name: "Madagascar",
    native: "Madagasikara",
    phoneCode: "261",
    continent: "AF",
    capital: "Antananarivo",
    currencyCode: "MGA",
    languages: ["fr", "mg"],
    emojiFlag: "🇲🇬",
    emojiU: "U+1F1F2 U+1F1EC",
    code: "MG",
  },
  {
    name: "Marshall Islands",
    native: "M̧ajeļ",
    phoneCode: "692",
    continent: "OC",
    capital: "Majuro",
    currencyCode: "USD",
    languages: ["en", "mh"],
    emojiFlag: "🇲🇭",
    emojiU: "U+1F1F2 U+1F1ED",
    code: "MH",
  },
  {
    name: "North Macedonia",
    native: "Северна Македонија",
    phoneCode: "389",
    continent: "EU",
    capital: "Skopje",
    currencyCode: "MKD",
    languages: ["mk"],
    emojiFlag: "🇲🇰",
    emojiU: "U+1F1F2 U+1F1F0",
    code: "MK",
  },
  {
    name: "Mali",
    native: "Mali",
    phoneCode: "223",
    continent: "AF",
    capital: "Bamako",
    currencyCode: "XOF",
    languages: ["fr"],
    emojiFlag: "🇲🇱",
    emojiU: "U+1F1F2 U+1F1F1",
    code: "ML",
  },
  {
    name: "Myanmar [Burma]",
    native: "မြန်မာ",
    phoneCode: "95",
    continent: "AS",
    capital: "Naypyidaw",
    currencyCode: "MMK",
    languages: ["my"],
    emojiFlag: "🇲🇲",
    emojiU: "U+1F1F2 U+1F1F2",
    code: "MM",
  },
  {
    name: "Mongolia",
    native: "Монгол улс",
    phoneCode: "976",
    continent: "AS",
    capital: "Ulan Bator",
    currencyCode: "MNT",
    languages: ["mn"],
    emojiFlag: "🇲🇳",
    emojiU: "U+1F1F2 U+1F1F3",
    code: "MN",
  },
  {
    name: "Macao",
    native: "澳門",
    phoneCode: "853",
    continent: "AS",
    capital: "",
    currencyCode: "MOP",
    languages: ["zh", "pt"],
    emojiFlag: "🇲🇴",
    emojiU: "U+1F1F2 U+1F1F4",
    code: "MO",
  },
  {
    name: "Northern Mariana Islands",
    native: "Northern Mariana Islands",
    phoneCode: "1670",
    continent: "OC",
    capital: "Saipan",
    currencyCode: "USD",
    languages: ["en", "ch"],
    emojiFlag: "🇲🇵",
    emojiU: "U+1F1F2 U+1F1F5",
    code: "MP",
  },
  {
    name: "Martinique",
    native: "Martinique",
    phoneCode: "596",
    continent: "NA",
    capital: "Fort-de-France",
    currencyCode: "EUR",
    languages: ["fr"],
    emojiFlag: "🇲🇶",
    emojiU: "U+1F1F2 U+1F1F6",
    code: "MQ",
  },
  {
    name: "Mauritania",
    native: "موريتانيا",
    phoneCode: "222",
    continent: "AF",
    capital: "Nouakchott",
    currencyCode: "MRU",
    languages: ["ar"],
    emojiFlag: "🇲🇷",
    emojiU: "U+1F1F2 U+1F1F7",
    code: "MR",
  },
  {
    name: "Montserrat",
    native: "Montserrat",
    phoneCode: "1664",
    continent: "NA",
    capital: "Plymouth",
    currencyCode: "XCD",
    languages: ["en"],
    emojiFlag: "🇲🇸",
    emojiU: "U+1F1F2 U+1F1F8",
    code: "MS",
  },
  {
    name: "Malta",
    native: "Malta",
    phoneCode: "356",
    continent: "EU",
    capital: "Valletta",
    currencyCode: "EUR",
    languages: ["mt", "en"],
    emojiFlag: "🇲🇹",
    emojiU: "U+1F1F2 U+1F1F9",
    code: "MT",
  },
  {
    name: "Mauritius",
    native: "Maurice",
    phoneCode: "230",
    continent: "AF",
    capital: "Port Louis",
    currencyCode: "MUR",
    languages: ["en"],
    emojiFlag: "🇲🇺",
    emojiU: "U+1F1F2 U+1F1FA",
    code: "MU",
  },
  {
    name: "Maldives",
    native: "Maldives",
    phoneCode: "960",
    continent: "AS",
    capital: "Malé",
    currencyCode: "MVR",
    languages: ["dv"],
    emojiFlag: "🇲🇻",
    emojiU: "U+1F1F2 U+1F1FB",
    code: "MV",
  },
  {
    name: "Malawi",
    native: "Malawi",
    phoneCode: "265",
    continent: "AF",
    capital: "Lilongwe",
    currencyCode: "MWK",
    languages: ["en", "ny"],
    emojiFlag: "🇲🇼",
    emojiU: "U+1F1F2 U+1F1FC",
    code: "MW",
  },
  {
    name: "Mexico",
    native: "México",
    phoneCode: "52",
    continent: "NA",
    capital: "Mexico City",
    currencyCode: "MXN",
    languages: ["es"],
    emojiFlag: "🇲🇽",
    emojiU: "U+1F1F2 U+1F1FD",
    code: "MX",
  },
  {
    name: "Malaysia",
    native: "Malaysia",
    phoneCode: "60",
    continent: "AS",
    capital: "Kuala Lumpur",
    currencyCode: "MYR",
    languages: ["ms"],
    emojiFlag: "🇲🇾",
    emojiU: "U+1F1F2 U+1F1FE",
    code: "MY",
  },
  {
    name: "Mozambique",
    native: "Moçambique",
    phoneCode: "258",
    continent: "AF",
    capital: "Maputo",
    currencyCode: "MZN",
    languages: ["pt"],
    emojiFlag: "🇲🇿",
    emojiU: "U+1F1F2 U+1F1FF",
    code: "MZ",
  },
  {
    name: "Namibia",
    native: "Namibia",
    phoneCode: "264",
    continent: "AF",
    capital: "Windhoek",
    currencyCode: "NAD,ZAR",
    languages: ["en", "af"],
    emojiFlag: "🇳🇦",
    emojiU: "U+1F1F3 U+1F1E6",
    code: "NA",
  },
  {
    name: "New Caledonia",
    native: "Nouvelle-Calédonie",
    phoneCode: "687",
    continent: "OC",
    capital: "Nouméa",
    currencyCode: "XPF",
    languages: ["fr"],
    emojiFlag: "🇳🇨",
    emojiU: "U+1F1F3 U+1F1E8",
    code: "NC",
  },
  {
    name: "Niger",
    native: "Niger",
    phoneCode: "227",
    continent: "AF",
    capital: "Niamey",
    currencyCode: "XOF",
    languages: ["fr"],
    emojiFlag: "🇳🇪",
    emojiU: "U+1F1F3 U+1F1EA",
    code: "NE",
  },
  {
    name: "Norfolk Island",
    native: "Norfolk Island",
    phoneCode: "672",
    continent: "OC",
    capital: "Kingston",
    currencyCode: "AUD",
    languages: ["en"],
    emojiFlag: "🇳🇫",
    emojiU: "U+1F1F3 U+1F1EB",
    code: "NF",
  },
  {
    name: "Nigeria",
    native: "Nigeria",
    phoneCode: "234",
    continent: "AF",
    capital: "Abuja",
    currencyCode: "NGN",
    languages: ["en"],
    emojiFlag: "🇳🇬",
    emojiU: "U+1F1F3 U+1F1EC",
    code: "NG",
  },
  {
    name: "Nicaragua",
    native: "Nicaragua",
    phoneCode: "505",
    continent: "NA",
    capital: "Managua",
    currencyCode: "NIO",
    languages: ["es"],
    emojiFlag: "🇳🇮",
    emojiU: "U+1F1F3 U+1F1EE",
    code: "NI",
  },
  {
    name: "Netherlands",
    native: "Nederland",
    phoneCode: "31",
    continent: "EU",
    capital: "Amsterdam",
    currencyCode: "EUR",
    languages: ["nl"],
    emojiFlag: "🇳🇱",
    emojiU: "U+1F1F3 U+1F1F1",
    code: "NL",
  },
  {
    name: "Norway",
    native: "Norge",
    phoneCode: "47",
    continent: "EU",
    capital: "Oslo",
    currencyCode: "NOK",
    languages: ["no", "nb", "nn"],
    emojiFlag: "🇳🇴",
    emojiU: "U+1F1F3 U+1F1F4",
    code: "NO",
  },
  {
    name: "Nepal",
    native: "नपल",
    phoneCode: "977",
    continent: "AS",
    capital: "Kathmandu",
    currencyCode: "NPR",
    languages: ["ne"],
    emojiFlag: "🇳🇵",
    emojiU: "U+1F1F3 U+1F1F5",
    code: "NP",
  },
  {
    name: "Nauru",
    native: "Nauru",
    phoneCode: "674",
    continent: "OC",
    capital: "Yaren",
    currencyCode: "AUD",
    languages: ["en", "na"],
    emojiFlag: "🇳🇷",
    emojiU: "U+1F1F3 U+1F1F7",
    code: "NR",
  },
  {
    name: "Niue",
    native: "Niuē",
    phoneCode: "683",
    continent: "OC",
    capital: "Alofi",
    currencyCode: "NZD",
    languages: ["en"],
    emojiFlag: "🇳🇺",
    emojiU: "U+1F1F3 U+1F1FA",
    code: "NU",
  },
  {
    name: "New Zealand",
    native: "New Zealand",
    phoneCode: "64",
    continent: "OC",
    capital: "Wellington",
    currencyCode: "NZD",
    languages: ["en", "mi"],
    emojiFlag: "🇳🇿",
    emojiU: "U+1F1F3 U+1F1FF",
    code: "NZ",
  },
  {
    name: "Oman",
    native: "عمان",
    phoneCode: "968",
    continent: "AS",
    capital: "Muscat",
    currencyCode: "OMR",
    languages: ["ar"],
    emojiFlag: "🇴🇲",
    emojiU: "U+1F1F4 U+1F1F2",
    code: "OM",
  },
  {
    name: "Panama",
    native: "Panamá",
    phoneCode: "507",
    continent: "NA",
    capital: "Panama City",
    currencyCode: "PAB,USD",
    languages: ["es"],
    emojiFlag: "🇵🇦",
    emojiU: "U+1F1F5 U+1F1E6",
    code: "PA",
  },
  {
    name: "Peru",
    native: "Perú",
    phoneCode: "51",
    continent: "SA",
    capital: "Lima",
    currencyCode: "PEN",
    languages: ["es"],
    emojiFlag: "🇵🇪",
    emojiU: "U+1F1F5 U+1F1EA",
    code: "PE",
  },
  {
    name: "French Polynesia",
    native: "Polynésie française",
    phoneCode: "689",
    continent: "OC",
    capital: "Papeetē",
    currencyCode: "XPF",
    languages: ["fr"],
    emojiFlag: "🇵🇫",
    emojiU: "U+1F1F5 U+1F1EB",
    code: "PF",
  },
  {
    name: "Papua New Guinea",
    native: "Papua Niugini",
    phoneCode: "675",
    continent: "OC",
    capital: "Port Moresby",
    currencyCode: "PGK",
    languages: ["en"],
    emojiFlag: "🇵🇬",
    emojiU: "U+1F1F5 U+1F1EC",
    code: "PG",
  },
  {
    name: "Philippines",
    native: "Pilipinas",
    phoneCode: "63",
    continent: "AS",
    capital: "Manila",
    currencyCode: "PHP",
    languages: ["en"],
    emojiFlag: "🇵🇭",
    emojiU: "U+1F1F5 U+1F1ED",
    code: "PH",
  },
  {
    name: "Pakistan",
    native: "Pakistan",
    phoneCode: "92",
    continent: "AS",
    capital: "Islamabad",
    currencyCode: "PKR",
    languages: ["en", "ur"],
    emojiFlag: "🇵🇰",
    emojiU: "U+1F1F5 U+1F1F0",
    code: "PK",
  },
  {
    name: "Poland",
    native: "Polska",
    phoneCode: "48",
    continent: "EU",
    capital: "Warsaw",
    currencyCode: "PLN",
    languages: ["pl"],
    emojiFlag: "🇵🇱",
    emojiU: "U+1F1F5 U+1F1F1",
    code: "PL",
  },
  {
    name: "Saint Pierre and Miquelon",
    native: "Saint-Pierre-et-Miquelon",
    phoneCode: "508",
    continent: "NA",
    capital: "Saint-Pierre",
    currencyCode: "EUR",
    languages: ["fr"],
    emojiFlag: "🇵🇲",
    emojiU: "U+1F1F5 U+1F1F2",
    code: "PM",
  },
  {
    name: "Pitcairn Islands",
    native: "Pitcairn Islands",
    phoneCode: "64",
    continent: "OC",
    capital: "Adamstown",
    currencyCode: "NZD",
    languages: ["en"],
    emojiFlag: "🇵🇳",
    emojiU: "U+1F1F5 U+1F1F3",
    code: "PN",
  },
  {
    name: "Puerto Rico",
    native: "Puerto Rico",
    phoneCode: "1787,1939",
    continent: "NA",
    capital: "San Juan",
    currencyCode: "USD",
    languages: ["es", "en"],
    emojiFlag: "🇵🇷",
    emojiU: "U+1F1F5 U+1F1F7",
    code: "PR",
  },
  {
    name: "Palestine",
    native: "فلسطين",
    phoneCode: "970",
    continent: "AS",
    capital: "Ramallah",
    currencyCode: "ILS",
    languages: ["ar"],
    emojiFlag: "🇵🇸",
    emojiU: "U+1F1F5 U+1F1F8",
    code: "PS",
  },
  {
    name: "Portugal",
    native: "Portugal",
    phoneCode: "351",
    continent: "EU",
    capital: "Lisbon",
    currencyCode: "EUR",
    languages: ["pt"],
    emojiFlag: "🇵🇹",
    emojiU: "U+1F1F5 U+1F1F9",
    code: "PT",
  },
  {
    name: "Palau",
    native: "Palau",
    phoneCode: "680",
    continent: "OC",
    capital: "Ngerulmud",
    currencyCode: "USD",
    languages: ["en"],
    emojiFlag: "🇵🇼",
    emojiU: "U+1F1F5 U+1F1FC",
    code: "PW",
  },
  {
    name: "Paraguay",
    native: "Paraguay",
    phoneCode: "595",
    continent: "SA",
    capital: "Asunción",
    currencyCode: "PYG",
    languages: ["es", "gn"],
    emojiFlag: "🇵🇾",
    emojiU: "U+1F1F5 U+1F1FE",
    code: "PY",
  },
  {
    name: "Qatar",
    native: "قطر",
    phoneCode: "974",
    continent: "AS",
    capital: "Doha",
    currencyCode: "QAR",
    languages: ["ar"],
    emojiFlag: "🇶🇦",
    emojiU: "U+1F1F6 U+1F1E6",
    code: "QA",
  },
  {
    name: "Réunion",
    native: "La Réunion",
    phoneCode: "262",
    continent: "AF",
    capital: "Saint-Denis",
    currencyCode: "EUR",
    languages: ["fr"],
    emojiFlag: "🇷🇪",
    emojiU: "U+1F1F7 U+1F1EA",
    code: "RE",
  },
  {
    name: "Romania",
    native: "România",
    phoneCode: "40",
    continent: "EU",
    capital: "Bucharest",
    currencyCode: "RON",
    languages: ["ro"],
    emojiFlag: "🇷🇴",
    emojiU: "U+1F1F7 U+1F1F4",
    code: "RO",
  },
  {
    name: "Serbia",
    native: "Србија",
    phoneCode: "381",
    continent: "EU",
    capital: "Belgrade",
    currencyCode: "RSD",
    languages: ["sr"],
    emojiFlag: "🇷🇸",
    emojiU: "U+1F1F7 U+1F1F8",
    code: "RS",
  },
  {
    name: "Russia",
    native: "Россия",
    phoneCode: "7",
    continent: "EU",
    capital: "Moscow",
    currencyCode: "RUB",
    languages: ["ru"],
    emojiFlag: "🇷🇺",
    emojiU: "U+1F1F7 U+1F1FA",
    code: "RU",
  },
  {
    name: "Rwanda",
    native: "Rwanda",
    phoneCode: "250",
    continent: "AF",
    capital: "Kigali",
    currencyCode: "RWF",
    languages: ["rw", "en", "fr"],
    emojiFlag: "🇷🇼",
    emojiU: "U+1F1F7 U+1F1FC",
    code: "RW",
  },
  {
    name: "Saudi Arabia",
    native: "العربية السعودية",
    phoneCode: "966",
    continent: "AS",
    capital: "Riyadh",
    currencyCode: "SAR",
    languages: ["ar"],
    emojiFlag: "🇸🇦",
    emojiU: "U+1F1F8 U+1F1E6",
    code: "SA",
  },
  {
    name: "Solomon Islands",
    native: "Solomon Islands",
    phoneCode: "677",
    continent: "OC",
    capital: "Honiara",
    currencyCode: "SBD",
    languages: ["en"],
    emojiFlag: "🇸🇧",
    emojiU: "U+1F1F8 U+1F1E7",
    code: "SB",
  },
  {
    name: "Seychelles",
    native: "Seychelles",
    phoneCode: "248",
    continent: "AF",
    capital: "Victoria",
    currencyCode: "SCR",
    languages: ["fr", "en"],
    emojiFlag: "🇸🇨",
    emojiU: "U+1F1F8 U+1F1E8",
    code: "SC",
  },
  {
    name: "Sudan",
    native: "السودان",
    phoneCode: "249",
    continent: "AF",
    capital: "Khartoum",
    currencyCode: "SDG",
    languages: ["ar", "en"],
    emojiFlag: "🇸🇩",
    emojiU: "U+1F1F8 U+1F1E9",
    code: "SD",
  },
  {
    name: "Sweden",
    native: "Sverige",
    phoneCode: "46",
    continent: "EU",
    capital: "Stockholm",
    currencyCode: "SEK",
    languages: ["sv"],
    emojiFlag: "🇸🇪",
    emojiU: "U+1F1F8 U+1F1EA",
    code: "SE",
  },
  {
    name: "Singapore",
    native: "Singapore",
    phoneCode: "65",
    continent: "AS",
    capital: "Singapore",
    currencyCode: "SGD",
    languages: ["en", "ms", "ta", "zh"],
    emojiFlag: "🇸🇬",
    emojiU: "U+1F1F8 U+1F1EC",
    code: "SG",
  },
  {
    name: "Saint Helena",
    native: "Saint Helena",
    phoneCode: "290",
    continent: "AF",
    capital: "Jamestown",
    currencyCode: "SHP",
    languages: ["en"],
    emojiFlag: "🇸🇭",
    emojiU: "U+1F1F8 U+1F1ED",
    code: "SH",
  },
  {
    name: "Slovenia",
    native: "Slovenija",
    phoneCode: "386",
    continent: "EU",
    capital: "Ljubljana",
    currencyCode: "EUR",
    languages: ["sl"],
    emojiFlag: "🇸🇮",
    emojiU: "U+1F1F8 U+1F1EE",
    code: "SI",
  },
  {
    name: "Svalbard and Jan Mayen",
    native: "Svalbard og Jan Mayen",
    phoneCode: "4779",
    continent: "EU",
    capital: "Longyearbyen",
    currencyCode: "NOK",
    languages: ["no"],
    emojiFlag: "🇸🇯",
    emojiU: "U+1F1F8 U+1F1EF",
    code: "SJ",
  },
  {
    name: "Slovakia",
    native: "Slovensko",
    phoneCode: "421",
    continent: "EU",
    capital: "Bratislava",
    currencyCode: "EUR",
    languages: ["sk"],
    emojiFlag: "🇸🇰",
    emojiU: "U+1F1F8 U+1F1F0",
    code: "SK",
  },
  {
    name: "Sierra Leone",
    native: "Sierra Leone",
    phoneCode: "232",
    continent: "AF",
    capital: "Freetown",
    currencyCode: "SLL",
    languages: ["en"],
    emojiFlag: "🇸🇱",
    emojiU: "U+1F1F8 U+1F1F1",
    code: "SL",
  },
  {
    name: "San Marino",
    native: "San Marino",
    phoneCode: "378",
    continent: "EU",
    capital: "City of San Marino",
    currencyCode: "EUR",
    languages: ["it"],
    emojiFlag: "🇸🇲",
    emojiU: "U+1F1F8 U+1F1F2",
    code: "SM",
  },
  {
    name: "Senegal",
    native: "Sénégal",
    phoneCode: "221",
    continent: "AF",
    capital: "Dakar",
    currencyCode: "XOF",
    languages: ["fr"],
    emojiFlag: "🇸🇳",
    emojiU: "U+1F1F8 U+1F1F3",
    code: "SN",
  },
  {
    name: "Somalia",
    native: "Soomaaliya",
    phoneCode: "252",
    continent: "AF",
    capital: "Mogadishu",
    currencyCode: "SOS",
    languages: ["so", "ar"],
    emojiFlag: "🇸🇴",
    emojiU: "U+1F1F8 U+1F1F4",
    code: "SO",
  },
  {
    name: "Suriname",
    native: "Suriname",
    phoneCode: "597",
    continent: "SA",
    capital: "Paramaribo",
    currencyCode: "SRD",
    languages: ["nl"],
    emojiFlag: "🇸🇷",
    emojiU: "U+1F1F8 U+1F1F7",
    code: "SR",
  },
  {
    name: "South Sudan",
    native: "South Sudan",
    phoneCode: "211",
    continent: "AF",
    capital: "Juba",
    currencyCode: "SSP",
    languages: ["en"],
    emojiFlag: "🇸🇸",
    emojiU: "U+1F1F8 U+1F1F8",
    code: "SS",
  },
  {
    name: "São Tomé and Príncipe",
    native: "São Tomé e Príncipe",
    phoneCode: "239",
    continent: "AF",
    capital: "São Tomé",
    currencyCode: "STN",
    languages: ["pt"],
    emojiFlag: "🇸🇹",
    emojiU: "U+1F1F8 U+1F1F9",
    code: "ST",
  },
  {
    name: "El Salvador",
    native: "El Salvador",
    phoneCode: "503",
    continent: "NA",
    capital: "San Salvador",
    currencyCode: "SVC,USD",
    languages: ["es"],
    emojiFlag: "🇸🇻",
    emojiU: "U+1F1F8 U+1F1FB",
    code: "SV",
  },
  {
    name: "Sint Maarten",
    native: "Sint Maarten",
    phoneCode: "1721",
    continent: "NA",
    capital: "Philipsburg",
    currencyCode: "ANG",
    languages: ["nl", "en"],
    emojiFlag: "🇸🇽",
    emojiU: "U+1F1F8 U+1F1FD",
    code: "SX",
  },
  {
    name: "Syria",
    native: "سوريا",
    phoneCode: "963",
    continent: "AS",
    capital: "Damascus",
    currencyCode: "SYP",
    languages: ["ar"],
    emojiFlag: "🇸🇾",
    emojiU: "U+1F1F8 U+1F1FE",
    code: "SY",
  },
  {
    name: "Swaziland",
    native: "Swaziland",
    phoneCode: "268",
    continent: "AF",
    capital: "Lobamba",
    currencyCode: "SZL",
    languages: ["en", "ss"],
    emojiFlag: "🇸🇿",
    emojiU: "U+1F1F8 U+1F1FF",
    code: "SZ",
  },
  {
    name: "Turks and Caicos Islands",
    native: "Turks and Caicos Islands",
    phoneCode: "1649",
    continent: "NA",
    capital: "Cockburn Town",
    currencyCode: "USD",
    languages: ["en"],
    emojiFlag: "🇹🇨",
    emojiU: "U+1F1F9 U+1F1E8",
    code: "TC",
  },
  {
    name: "Chad",
    native: "Tchad",
    phoneCode: "235",
    continent: "AF",
    capital: "N'Djamena",
    currencyCode: "XAF",
    languages: ["fr", "ar"],
    emojiFlag: "🇹🇩",
    emojiU: "U+1F1F9 U+1F1E9",
    code: "TD",
  },
  {
    name: "French Southern Territories",
    native: "Territoire des Terres australes et antarctiques fr",
    phoneCode: "262",
    continent: "AN",
    capital: "Port-aux-Français",
    currencyCode: "EUR",
    languages: ["fr"],
    emojiFlag: "🇹🇫",
    emojiU: "U+1F1F9 U+1F1EB",
    code: "TF",
  },
  {
    name: "Togo",
    native: "Togo",
    phoneCode: "228",
    continent: "AF",
    capital: "Lomé",
    currencyCode: "XOF",
    languages: ["fr"],
    emojiFlag: "🇹🇬",
    emojiU: "U+1F1F9 U+1F1EC",
    code: "TG",
  },
  {
    name: "Thailand",
    native: "ประเทศไทย",
    phoneCode: "66",
    continent: "AS",
    capital: "Bangkok",
    currencyCode: "THB",
    languages: ["th"],
    emojiFlag: "🇹🇭",
    emojiU: "U+1F1F9 U+1F1ED",
    code: "TH",
  },
  {
    name: "Tajikistan",
    native: "Тоҷикистон",
    phoneCode: "992",
    continent: "AS",
    capital: "Dushanbe",
    currencyCode: "TJS",
    languages: ["tg", "ru"],
    emojiFlag: "🇹🇯",
    emojiU: "U+1F1F9 U+1F1EF",
    code: "TJ",
  },
  {
    name: "Tokelau",
    native: "Tokelau",
    phoneCode: "690",
    continent: "OC",
    capital: "Fakaofo",
    currencyCode: "NZD",
    languages: ["en"],
    emojiFlag: "🇹🇰",
    emojiU: "U+1F1F9 U+1F1F0",
    code: "TK",
  },
  {
    name: "East Timor",
    native: "Timor-Leste",
    phoneCode: "670",
    continent: "OC",
    capital: "Dili",
    currencyCode: "USD",
    languages: ["pt"],
    emojiFlag: "🇹🇱",
    emojiU: "U+1F1F9 U+1F1F1",
    code: "TL",
  },
  {
    name: "Turkmenistan",
    native: "Türkmenistan",
    phoneCode: "993",
    continent: "AS",
    capital: "Ashgabat",
    currencyCode: "TMT",
    languages: ["tk", "ru"],
    emojiFlag: "🇹🇲",
    emojiU: "U+1F1F9 U+1F1F2",
    code: "TM",
  },
  {
    name: "Tunisia",
    native: "تونس",
    phoneCode: "216",
    continent: "AF",
    capital: "Tunis",
    currencyCode: "TND",
    languages: ["ar"],
    emojiFlag: "🇹🇳",
    emojiU: "U+1F1F9 U+1F1F3",
    code: "TN",
  },
  {
    name: "Tonga",
    native: "Tonga",
    phoneCode: "676",
    continent: "OC",
    capital: "Nuku'alofa",
    currencyCode: "TOP",
    languages: ["en", "to"],
    emojiFlag: "🇹🇴",
    emojiU: "U+1F1F9 U+1F1F4",
    code: "TO",
  },
  {
    name: "Turkey",
    native: "Türkiye",
    phoneCode: "90",
    continent: "AS",
    capital: "Ankara",
    currencyCode: "TRY",
    languages: ["tr"],
    emojiFlag: "🇹🇷",
    emojiU: "U+1F1F9 U+1F1F7",
    code: "TR",
  },
  {
    name: "Trinidad and Tobago",
    native: "Trinidad and Tobago",
    phoneCode: "1868",
    continent: "NA",
    capital: "Port of Spain",
    currencyCode: "TTD",
    languages: ["en"],
    emojiFlag: "🇹🇹",
    emojiU: "U+1F1F9 U+1F1F9",
    code: "TT",
  },
  {
    name: "Tuvalu",
    native: "Tuvalu",
    phoneCode: "688",
    continent: "OC",
    capital: "Funafuti",
    currencyCode: "AUD",
    languages: ["en"],
    emojiFlag: "🇹🇻",
    emojiU: "U+1F1F9 U+1F1FB",
    code: "TV",
  },
  {
    name: "Taiwan",
    native: "臺灣",
    phoneCode: "886",
    continent: "AS",
    capital: "Taipei",
    currencyCode: "TWD",
    languages: ["zh"],
    emojiFlag: "🇹🇼",
    emojiU: "U+1F1F9 U+1F1FC",
    code: "TW",
  },
  {
    name: "Tanzania",
    native: "Tanzania",
    phoneCode: "255",
    continent: "AF",
    capital: "Dodoma",
    currencyCode: "TZS",
    languages: ["sw", "en"],
    emojiFlag: "🇹🇿",
    emojiU: "U+1F1F9 U+1F1FF",
    code: "TZ",
  },
  {
    name: "Ukraine",
    native: "Україна",
    phoneCode: "380",
    continent: "EU",
    capital: "Kyiv",
    currencyCode: "UAH",
    languages: ["uk"],
    emojiFlag: "🇺🇦",
    emojiU: "U+1F1FA U+1F1E6",
    code: "UA",
  },
  {
    name: "Uganda",
    native: "Uganda",
    phoneCode: "256",
    continent: "AF",
    capital: "Kampala",
    currencyCode: "UGX",
    languages: ["en", "sw"],
    emojiFlag: "🇺🇬",
    emojiU: "U+1F1FA U+1F1EC",
    code: "UG",
  },
  {
    name: "U.S. Minor Outlying Islands",
    native: "United States Minor Outlying Islands",
    phoneCode: "1",
    continent: "OC",
    capital: "",
    currencyCode: "USD",
    languages: ["en"],
    emojiFlag: "🇺🇲",
    emojiU: "U+1F1FA U+1F1F2",
    code: "UM",
  },
  {
    name: "United States",
    native: "United States",
    phoneCode: "1",
    continent: "NA",
    capital: "Washington D.C.",
    currencyCode: "USD,USN,USS",
    languages: ["en"],
    emojiFlag: "🇺🇸",
    emojiU: "U+1F1FA U+1F1F8",
    code: "US",
  },
  {
    name: "Uruguay",
    native: "Uruguay",
    phoneCode: "598",
    continent: "SA",
    capital: "Montevideo",
    currencyCode: "UYI,UYU",
    languages: ["es"],
    emojiFlag: "🇺🇾",
    emojiU: "U+1F1FA U+1F1FE",
    code: "UY",
  },
  {
    name: "Uzbekistan",
    native: "O‘zbekiston",
    phoneCode: "998",
    continent: "AS",
    capital: "Tashkent",
    currencyCode: "UZS",
    languages: ["uz", "ru"],
    emojiFlag: "🇺🇿",
    emojiU: "U+1F1FA U+1F1FF",
    code: "UZ",
  },
  {
    name: "Vatican City",
    native: "Vaticano",
    phoneCode: "379",
    continent: "EU",
    capital: "Vatican City",
    currencyCode: "EUR",
    languages: ["it", "la"],
    emojiFlag: "🇻🇦",
    emojiU: "U+1F1FB U+1F1E6",
    code: "VA",
  },
  {
    name: "Saint Vincent and the Grenadines",
    native: "Saint Vincent and the Grenadines",
    phoneCode: "1784",
    continent: "NA",
    capital: "Kingstown",
    currencyCode: "XCD",
    languages: ["en"],
    emojiFlag: "🇻🇨",
    emojiU: "U+1F1FB U+1F1E8",
    code: "VC",
  },
  {
    name: "Venezuela",
    native: "Venezuela",
    phoneCode: "58",
    continent: "SA",
    capital: "Caracas",
    currencyCode: "VES",
    languages: ["es"],
    emojiFlag: "🇻🇪",
    emojiU: "U+1F1FB U+1F1EA",
    code: "VE",
  },
  {
    name: "British Virgin Islands",
    native: "British Virgin Islands",
    phoneCode: "1284",
    continent: "NA",
    capital: "Road Town",
    currencyCode: "USD",
    languages: ["en"],
    emojiFlag: "🇻🇬",
    emojiU: "U+1F1FB U+1F1EC",
    code: "VG",
  },
  {
    name: "U.S. Virgin Islands",
    native: "United States Virgin Islands",
    phoneCode: "1340",
    continent: "NA",
    capital: "Charlotte Amalie",
    currencyCode: "USD",
    languages: ["en"],
    emojiFlag: "🇻🇮",
    emojiU: "U+1F1FB U+1F1EE",
    code: "VI",
  },
  {
    name: "Vietnam",
    native: "Việt Nam",
    phoneCode: "84",
    continent: "AS",
    capital: "Hanoi",
    currencyCode: "VND",
    languages: ["vi"],
    emojiFlag: "🇻🇳",
    emojiU: "U+1F1FB U+1F1F3",
    code: "VN",
  },
  {
    name: "Vanuatu",
    native: "Vanuatu",
    phoneCode: "678",
    continent: "OC",
    capital: "Port Vila",
    currencyCode: "VUV",
    languages: ["bi", "en", "fr"],
    emojiFlag: "🇻🇺",
    emojiU: "U+1F1FB U+1F1FA",
    code: "VU",
  },
  {
    name: "Wallis and Futuna",
    native: "Wallis et Futuna",
    phoneCode: "681",
    continent: "OC",
    capital: "Mata-Utu",
    currencyCode: "XPF",
    languages: ["fr"],
    emojiFlag: "🇼🇫",
    emojiU: "U+1F1FC U+1F1EB",
    code: "WF",
  },
  {
    name: "Samoa",
    native: "Samoa",
    phoneCode: "685",
    continent: "OC",
    capital: "Apia",
    currencyCode: "WST",
    languages: ["sm", "en"],
    emojiFlag: "🇼🇸",
    emojiU: "U+1F1FC U+1F1F8",
    code: "WS",
  },
  {
    name: "Kosovo",
    native: "Republika e Kosovës",
    phoneCode: "377,381,383,386",
    continent: "EU",
    capital: "Pristina",
    currencyCode: "EUR",
    languages: ["sq", "sr"],
    emojiFlag: "🇽🇰",
    emojiU: "U+1F1FD U+1F1F0",
    code: "XK",
  },
  {
    name: "Yemen",
    native: "اليَمَن",
    phoneCode: "967",
    continent: "AS",
    capital: "Sana'a",
    currencyCode: "YER",
    languages: ["ar"],
    emojiFlag: "🇾🇪",
    emojiU: "U+1F1FE U+1F1EA",
    code: "YE",
  },
  {
    name: "Mayotte",
    native: "Mayotte",
    phoneCode: "262",
    continent: "AF",
    capital: "Mamoudzou",
    currencyCode: "EUR",
    languages: ["fr"],
    emojiFlag: "🇾🇹",
    emojiU: "U+1F1FE U+1F1F9",
    code: "YT",
  },
  {
    name: "South Africa",
    native: "South Africa",
    phoneCode: "27",
    continent: "AF",
    capital: "Pretoria",
    currencyCode: "ZAR",
    languages: ["af", "en", "nr", "st", "ss", "tn", "ts", "ve", "xh", "zu"],
    emojiFlag: "🇿🇦",
    emojiU: "U+1F1FF U+1F1E6",
    code: "ZA",
  },
  {
    name: "Zambia",
    native: "Zambia",
    phoneCode: "260",
    continent: "AF",
    capital: "Lusaka",
    currencyCode: "ZMW",
    languages: ["en"],
    emojiFlag: "🇿🇲",
    emojiU: "U+1F1FF U+1F1F2",
    code: "ZM",
  },
  {
    name: "Zimbabwe",
    native: "Zimbabwe",
    phoneCode: "263",
    continent: "AF",
    capital: "Harare",
    currencyCode: "USD,ZAR,BWP,GBP,AUD,CNY,INR,JPY",
    languages: ["en", "sn", "nd"],
    emojiFlag: "🇿🇼",
    emojiU: "U+1F1FF U+1F1FC",
    code: "ZW",
  },
];
