import Box from "components/box";
import React from "react";
import { StyledLink, MenuItemWrapper } from "./menu-item.styles";
import { MenuItemProps } from "./menu-item.type";
import { useLocation, useNavigate } from "react-router-dom";
import { Flex, Stack } from "components/layout";
import { HiChevronRight, HiChevronDown } from "react-icons/hi";
import Text from "components/text";

const MenuItem = React.forwardRef<HTMLDivElement, MenuItemProps>(
  ({ data, css, handleOnClick, open = false }, ref) => {
    const { id, label, subMenu, icon: Icon, path } = data;

    const location = useLocation();
    const navigate = useNavigate();
    const pathName = location.pathname;
    const hasSubMenu = subMenu && subMenu.length > 0;

    function IsPathParent() {
      if (!hasSubMenu) {
        return false;
      }
      const subMenuPaths = subMenu.map((item) => {
        return item.path;
      });
      return subMenuPaths.includes(pathName);
    }

    const isActive = pathName === path;

    const onClick = () => {
      if (hasSubMenu) {
        const initialTo = subMenu[0].path;
        navigate(initialTo as string);
        handleOnClick?.(id);
        return;
      }

      navigate(path as string);
      handleOnClick?.(id);
    };

    return (
      <>
        <MenuItemWrapper
          ref={ref}
          active={isActive}
          hasSubMenu={hasSubMenu}
          css={css}
          onClick={onClick}
        >
          <Flex align="center" justify="between" css={{ width: "100%" }}>
            <Flex align="center">
              {Icon && (
                <Box className="icon-wrapper">
                  <Icon style={{ strokeWidth: "4px" }} className="icon" />
                </Box>
              )}
              <Text
                size={"sm"}
                color={"inherit"}
                className="label"
                weight={isActive ? "medium" : "medium"}
              >
                {label}
              </Text>
            </Flex>
            {hasSubMenu &&
              (open ? (
                <HiChevronDown className="chevron" />
              ) : (
                <HiChevronRight className="chevron" />
              ))}
          </Flex>
        </MenuItemWrapper>
        {(open || IsPathParent()) && (
          <Stack css={{ paddingLeft: 28 }} spacing={0}>
            {subMenu?.map((item) => {
              const isActive = pathName === item.path;
              return (
                <StyledLink to={item.path as string} key={item.label}>
                  <MenuItemWrapper isSubItem active={isActive}>
                    <Flex align="center" css={{ width: "100%" }}>
                      <Text
                        size="sm"
                        weight={isActive ? "medium" : "medium"}
                        color={"inherit"}
                      >
                        {item.label}
                      </Text>
                    </Flex>
                  </MenuItemWrapper>
                </StyledLink>
              );
            })}
          </Stack>
        )}
      </>
    );
  }
);

MenuItem.displayName = "MenuItem";

export default MenuItem;
