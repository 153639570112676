import React from "react";
import AppendPrependWrapper from "../append-prepend-wrapper";
import { StyledAnchor, StyledLink } from "./link-button.styles";
import { ButtonProps } from "./link-button.types";

const LinkButton = React.forwardRef<HTMLAnchorElement, ButtonProps>(
  ({ children, append, prepend, linkType, href = "", ...anchorProps }, ref) => {
    function renderBody() {
      return (
        <AppendPrependWrapper append={append} prepend={prepend}>
          {children}
        </AppendPrependWrapper>
      );
    }

    if (linkType === "link") {
      return (
        <StyledLink {...anchorProps} ref={ref} to={href}>
          {renderBody()}
        </StyledLink>
      );
    }

    return (
      <StyledAnchor href={href} ref={ref} {...anchorProps}>
        {renderBody()}
      </StyledAnchor>
    );
  }
);

LinkButton.displayName = "LinkButton";

export default LinkButton;
