import CountryChip from "components/country-chip";
import { Flex } from "components/layout";
import Text from "components/text";
import { Country } from "generated/__generated_graphql";

export const CustomCountryOption = ({ option }: { option: Country }) => {
  return (
    <Flex align="center" gap="2">
      <CountryChip country={(option?.code as any) || "US"}></CountryChip>
      <Text size="sm" weight={"regular"} color={"dark"}>
        {option.name}
      </Text>
    </Flex>
  );
};
