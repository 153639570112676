import ActionDropdown from "components/action-dropdown/action-dropdown";
import Button from "components/button";
import { BaseCard } from "components/card/card";
import { Flex } from "components/layout";
import Page from "components/page/page";
import Text from "components/text";
import React from "react";
import { HiChevronDown, HiOutlineExclamationCircle } from "react-icons/hi";
import { styled } from "stitches/stitches.config";
import FundYieldAccount from "./components/fund-yield-account";
import useModal from "components/modal/use-modal";
import Table, { ITableColumn } from "components/table";
import BalanceChart from "components/balance-chart-card/balance-chart";
import { useAccountPageQuery } from "generated/__generated_graphql";

const Wrapper = styled(BaseCard, {
  ".stats": {
    borderRight: "1px solid $gray3",
  },
  ".stat": {
    py: 32,
    pr: 60,
    pl: 24,
  },

  ".stat:not(:last-child ) ": {
    borderBottom: "1px solid $gray3",
  },

  ".disclaimer": {
    p: 16,
    px: 24,
    borderTop: "1px solid $gray3",
  },
});

const COLUMNS: Array<ITableColumn> = [
  { title: "Date", key: "date" },
  { title: "Description", key: "description" },
  { title: "Amount", key: "amount" },
  { title: "Balance", key: "balance" },
];

export default function YieldAccount() {
  const { open: showFundAccount, setOpen: setShowFundAccount } = useModal();

  const [{ data }] = useAccountPageQuery({
    variables: { accountId: "26" },
  });

  const balances = data?.getLast30DaysBalances;

  return (
    <>
      <FundYieldAccount
        onOpenChange={setShowFundAccount}
        open={showFundAccount}
      ></FundYieldAccount>
      <Page>
        <Flex gap={8} stack>
          <Flex align={"center"} justify={"between"}>
            <Text size={"2xl"} color="black">
              Treasury
            </Text>

            <ActionDropdown
              actions={[
                {
                  label: "Fund account",
                  action: () => setShowFundAccount(true),
                  description: "Transfer funds into your Yield Account",
                },
                {
                  label: "Withdraw funds",
                  action: () => setShowFundAccount(true),
                  description: "Transfer funds out of your Yield Account",
                },
              ]}
              trigger={
                <Button
                  size="sm"
                  appearance="secondary"
                  append={<HiChevronDown />}
                >
                  Move funds
                </Button>
              }
            ></ActionDropdown>
          </Flex>
          <Wrapper stretchX stack>
            <Flex stretchX>
              <Flex className="stats" stack>
                <Flex className="stat" stack gap={2}>
                  <Text>Balance</Text>
                  <Text
                    color="black"
                    size="2xl"
                    weight="regular"
                    fontFamily={"haffer"}
                  >
                    $7,568,000.00
                  </Text>
                </Flex>

                <Flex className="stat" stack gap={2}>
                  <Text>Net 7-day yield</Text>
                  <Text
                    color="black"
                    size="2xl"
                    weight="regular"
                    fontFamily={"haffer"}
                  >
                    4.62%
                  </Text>
                </Flex>

                <Flex className="stat" stack gap={2}>
                  <Text>Last month’s return</Text>
                  <Text
                    color="black"
                    size="2xl"
                    weight="regular"
                    fontFamily={"haffer"}
                  >
                    $1,434.89
                  </Text>
                </Flex>
              </Flex>
              <Flex stretchX>
                <BalanceChart
                  variant="light"
                  height={200}
                  wrapperWidth={1000}
                  balances={balances}
                />
              </Flex>
            </Flex>
            <Flex css={{ color: "$lime10" }} gap={2} className="disclaimer">
              <HiOutlineExclamationCircle
                style={{ rotate: "180deg" }}
                size={30}
              />
              <Text lineHeight={"tall"} size="xs" color="light">
                We've partnered with Helium Advisors LLC, an SEC-registered
                investment adviser, to bring you investing features. All
                investment advisory services are provided by Helium. We receive
                compensation as a percentage of assets managed by Helium for
                promoting Helium’s investment advisory services.
              </Text>
            </Flex>
          </Wrapper>

          <Text color="black" size="2xl">
            Activity
          </Text>
          <Table columns={COLUMNS} data={[]}></Table>
        </Flex>
      </Page>
    </>
  );
}
