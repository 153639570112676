import { Flex } from "components/layout";
import Modal from "components/modal";
import PhoneNumberInput from "components/phone-number-input";
import Text from "components/text";

export default function SmsAuthSetup() {
  return (
    <>
      <Modal.Body css={{ width: 540 }}>
        <Flex stack gap={6}>
          <Flex stack gap={2}>
            <Text color="black">Phone number</Text>
            <Text lineHeight={"tall"} size="xs" color="light">
              Enter the mobile number for a device you trust. We will text you a
              7-digit security code to verify your phone.
            </Text>
          </Flex>

          <PhoneNumberInput label="Enter phone number" />
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Next to="auth-type">Back</Modal.Next>
        <Modal.Confirm>Request token</Modal.Confirm>
      </Modal.Footer>
    </>
  );
}
