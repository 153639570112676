import React from "react";
import { CSS } from "stitches/stitches.config";
import * as Dialog from "@radix-ui/react-dialog";
import {
  DialogOverlay,
  DialogContent,
  DialogTitle,
  DialogBody,
  DialogFooter,
  DialogScreen,
  DialogClose,
} from "./modal.styles";
import Text from "components/text";
import Button from "components/button";
import { ModalButtonProps, ModalProps } from "./modal.types";
import { Next, useStepModal } from "./step-modal";
import { ID } from "utils/types";
import { HiX } from "react-icons/hi";
import useModal from "./use-modal";

export default function Modal({
  title,
  children,
  variant = "modal",
  closable = true,
  hideFooter,
  onCancel,
  onConfirm,
  open,
  onOpenChange,
  hideTitle,
}: ModalProps) {
  const _hideFooter = hideFooter || (!onCancel && !onConfirm);

  return (
    <Dialog.Root onOpenChange={onOpenChange} open={open}>
      <Dialog.Portal>
        <DialogOverlay />
        <DialogContent variant={variant}>
          {!title || hideTitle ? null : (
            <DialogTitle>
              <Text size="lg" color="black" as="div">
                {title}
              </Text>
            </DialogTitle>
          )}
          {children}

          {closable && (
            <Dialog.Close asChild>
              <DialogClose>
                <HiX size={18} />
              </DialogClose>
            </Dialog.Close>
          )}
        </DialogContent>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

function ConfirmButton({
  children,
  appearance = "primary",
  ...props
}: ModalButtonProps) {
  return (
    <Button {...props} appearance={appearance} size="md">
      {children}
    </Button>
  );
}

function CancelButton({ children, ...props }: ModalButtonProps) {
  return (
    <Button
      css={{
        border: "none",
        background: "$gray4",
        color: "$gray12",
        fontWeight: 600,
      }}
      {...props}
      appearance="ghost"
      size="md"
    >
      {children}
    </Button>
  );
}

function Screen({
  children,
  screenId,
  ...props
}: React.ComponentProps<typeof DialogScreen> & { screenId: ID }) {
  return <DialogScreen {...props}>{children}</DialogScreen>;
}

Modal.Footer = DialogFooter;
Modal.Body = DialogBody;
Modal.Title = DialogTitle;
Modal.Confirm = ConfirmButton;
Modal.Cancel = CancelButton;
Modal.Prev = ConfirmButton;
Modal.Next = Next;
Modal.Screen = Screen;
Modal.useStepModal = useStepModal;
Modal.useModal = useModal;
