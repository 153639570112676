import Box from "components/box";
import { SharedInputStyles } from "components/input";
import { Flex } from "components/layout";
import Text from "components/text";
import { HiCheck, HiSelector, HiX } from "react-icons/hi";
import {
  components,
  ControlProps,
  GroupBase,
  MenuProps,
  MultiValueProps,
  OptionProps,
  PlaceholderProps,
  Props,
  SingleValueProps,
} from "react-select";
import { styled } from "stitches/stitches.config";

declare module "react-select/dist/declarations/src/Select" {
  export interface Props<
    Option,
    IsMulti extends boolean,
    Group extends GroupBase<Option>
  > {
    hasError?: boolean;
    required?: boolean;
    showCheckmark?: boolean;
    data?: [];
    labelKey?: string;
    valueKey?: string;
    filterOutSelected?: boolean;
    isCreatable?: boolean;
    isClearable?: boolean;
    defaultOpen?: boolean;
    hideErrorMessage?: boolean;
    appearance?: any;
    size?: any;
    menuFooter?: (props?: any) => React.ReactNode;
    menuHeader?: (props?: any) => React.ReactNode;
    renderValue?: (value?: any) => React.ReactNode;
    renderOption?: (option?: any, meta?: any) => React.ReactNode;
    menuSize?: number | string;
  }
}

export const StyledControl = styled(components.Control, SharedInputStyles, {
  flexWrap: "wrap",
  position: "relative",
  boxSizing: "border-box",

  ".float-select__input": {
    fontFamily: "$medium !important",
    fontWeight: "500 !important",
  },

  ".float-select__value-container": { height: "100%", px: 12 },
  ".float-select__placeholder": { margin: 0 },
});

const StyledDropdownIndicator = styled(components.DropdownIndicator, {
  color: "$gray10",
  px: 8,
  display: "flex",
  alignItems: "center",
});

const StyledClearIndicator = styled(components.ClearIndicator, {
  color: "$gray10",
  px: 8,
  display: "flex",
  alignItems: "center",
});

const StyledMenu = styled(components.Menu, {
  pointerEvents: "auto",
  boxShadow: "$base !important",
  borderRadius: "8px !important",
  zIndex: 1,
});

const MenuWrapper = styled(Box, {
  px: 4,
  py: 2,
  pointerEvents: "auto",
  boxShadow: "$min !important",
  borderRadius: "8px !important",
  backgroundColor: "White",
});

const StyledOption = styled(components.Option, {
  display: "flex",
  padding: "10px 8px",
  borderRadius: 6,

  ".check": {
    color: "$green10",
    strokeWidth: 1,
  },

  variants: {
    isDisabled: { true: { color: "$gray6" } },
    isFocused: { true: { background: "$gray2", outline: "none" } },
    isSelected: { true: { background: "$gray2", color: "$gray12" } },
  },

  compoundVariants: [
    {
      isSelected: true,
      isFocused: true,
      css: { background: "$gray2", color: "$gray12" },
    },
  ],
});

const StyledSingleValue = styled(components.SingleValue, {
  color: "$gray12 !important",
});

const StyledMultiValue = styled(components.MultiValue, {
  background: "$gray2 !important",
  borderRadius: "6px !important",
  color: "$gray12 !important",
});

export const CustomMultiValue = ({ children, ...props }: MultiValueProps) => {
  return (
    <StyledMultiValue {...props}>
      <Text color="inherit">{children}</Text>
    </StyledMultiValue>
  );
};

export const CustomSingleValue = ({ children, ...props }: SingleValueProps) => {
  const { renderValue } = props.selectProps;

  return (
    <StyledSingleValue {...props}>
      {renderValue ? (
        renderValue?.(props.data)
      ) : (
        <Text color="inherit" size="inherit">
          {children}
        </Text>
      )}
    </StyledSingleValue>
  );
};

export const CustomOption = ({ children, ...props }: OptionProps) => {
  const { renderOption, showCheckmark = true } = props.selectProps;

  return (
    <StyledOption {...props}>
      <Flex align={"center"} stretchX justify="between">
        {renderOption ? (
          renderOption?.(props.data, props)
        ) : (
          <Text case={"capitalize"} color="black" weight={"medium"} size="sm">
            {children}
          </Text>
        )}
        {props.isSelected && showCheckmark && (
          <HiCheck strokeWidth={1} size={16} className="check" />
        )}
      </Flex>
    </StyledOption>
  );
};

export const CustomControl = ({
  selectProps,
  children,
  ...props
}: ControlProps) => {
  const { appearance, size, hasError, isDisabled } = selectProps;
  return (
    <StyledControl
      hasError={hasError}
      appearance={appearance}
      size={size}
      isDisabled={isDisabled}
      {...(props as any)}
    >
      {children}
    </StyledControl>
  );
};

export const CustomDropdownIndicator = (props: any) => {
  return (
    <StyledDropdownIndicator {...props}>
      <HiSelector size={18} />
    </StyledDropdownIndicator>
  );
};

export const CustomClearIndicator = (props: any) => {
  return (
    <StyledClearIndicator {...props}>
      <HiX size={16} />
    </StyledClearIndicator>
  );
};

export const CustomPlaceholder = ({ children, ...props }: PlaceholderProps) => {
  return (
    <components.Placeholder {...props}>
      <Text color="lighter" size="inherit">
        {children}
      </Text>
    </components.Placeholder>
  );
};

export const CustomMenu = ({ children, ...props }: MenuProps) => {
  const { menuFooter, menuHeader, menuSize } = props.selectProps;

  return (
    <StyledMenu {...props}>
      {menuHeader?.(props)}
      <MenuWrapper css={{ minWidth: menuSize }}>{children}</MenuWrapper>
      {menuFooter?.(props)}
    </StyledMenu>
  );
};
