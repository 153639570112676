import {
  JournalEntry,
  useTransactionsPageQuery,
} from "generated/__generated_graphql";
import Box from "components/box";
import Page from "components/page/page";
import RecentTransactions from "pages/accounts/components/recent-transactions";

const TransactionPage = () => {
  const [{ data, fetching }] = useTransactionsPageQuery();

  const transactions = data?.journalEntries.data;

  return (
    <Page isLoading={fetching} pageTitle="Transactions">
      <Box css={{ marginBottom: 50 }}>
        <RecentTransactions transactions={transactions as JournalEntry[]} />
      </Box>
    </Page>
  );
};

export default TransactionPage;
