import { keyframes, styled } from "stitches/stitches.config";

const loadingAnimation = keyframes({
  from: { transform: "rotate(0deg)" },
  to: { transform: "rotate(360deg)" },
});

export const LoaderWrap = styled("div", {
  variants: {
    full: {
      true: {
        height: "100%",
        width: "100%",
        display: "grid",
        placeItems: "center",
      },
    },
  },
});

export const StyledLoader = styled("div", {
  background: "transparent",
  borderRadius: "50%",
  borderStyle: "solid",
  animation: `${loadingAnimation} .45s infinite linear`,
  flexShrink: 0,
  willChange: "transform",

  defaultVariants: { size: "md" },

  variants: {
    size: {
      xxs: {
        height: 13,
        width: 13,
        borderWidth: 1.5,
      },

      xs: {
        height: 16,
        width: 16,
        borderWidth: 2,
      },
      sm: {
        height: 24,
        width: 24,
        borderWidth: 2.5,
      },
      md: {
        height: 32,
        width: 32,
        borderWidth: 3,
      },
      lg: {
        height: 40,
        width: 40,
        borderWidth: 3,
      },
    },
  },
});
