import React from "react";
import Button from "components/button";
import { Flex } from "components/layout";
import Table from "components/table";
import Text from "components/text";
import TwoFactorAuthSetup from "components/two-factor-auth-setup";
import { HiOutlineShieldCheck, HiRefresh, HiShieldCheck } from "react-icons/hi";
import { RowItem, tableStyles } from "../settings.styles";
import { useAppProvider } from "providers/app-provider/app-provider";
import Tag from "components/tag";
import CurrencyInput from "components/currency-input/currency-input";
import ChangePassword from "components/change-password";
import { useSecurityPageQuery } from "generated/__generated_graphql";
import { activeSessionColumns, sessionsTableColumns } from "../settings.data";

export default function Security() {
  const [{ fetching, data }] = useSecurityPageQuery();
  const { user } = useAppProvider();
  const [showPasswordReset, setShowPasswordReset] = React.useState(false);
  const [show2FA, setShow2FA] = React.useState(false);
  const mfaEnabled = user?.mfaStatus?.app;

  const sessions = data?.sessions?.data || [];
  const activeSessions = data?.activeSessions?.data || [];

  return (
    <>
      <TwoFactorAuthSetup
        onOpenChange={(open) => setShow2FA(open)}
        open={show2FA}
      />
      <ChangePassword
        onOpenChange={(open) => setShowPasswordReset(open)}
        open={showPasswordReset}
      />
      <Flex gap={7} stack>
        <RowItem>
          <Flex align="start" gap={2} stack className="key">
            <Text weight={"semi"} color="black">
              Password
            </Text>
            <Text size="xs" color="light">
              Protect your account with a unique password.
            </Text>
          </Flex>

          <Button
            onClick={() => setShowPasswordReset(true)}
            shape="pill"
            size="sm"
            appearance={"secondary"}
          >
            Change password
          </Button>
        </RowItem>

        <RowItem>
          <Flex align="start" gap={2} stack className="key">
            <Text weight={"semi"} color="black">
              Two factor authentication
            </Text>
            <Text lineHeight={"tall"} size="xs" color="light">
              Add another layer of security to your float account by using a
              code on your mobile phone or SMS
            </Text>
          </Flex>

          <Flex gap={2} align={"center"}>
            {mfaEnabled && (
              <Tag
                shape="pill"
                variant={"light"}
                color="green"
                prependIcon={<HiOutlineShieldCheck />}
              >
                Enabled
              </Tag>
            )}
            <Button
              append={mfaEnabled ? <HiRefresh size={16} /> : null}
              onClick={() => setShow2FA(true)}
              size="sm"
              appearance={mfaEnabled ? "flushed" : "secondary"}
              shape="pill"
            >
              {mfaEnabled
                ? " Reset 2 factor authentication"
                : "Enable Two Factor Authentication"}
            </Button>
          </Flex>
        </RowItem>

        <RowItem>
          <Flex align="start" gap={2} stack className="key">
            <Text weight={"semi"} color="black">
              Authenticate Payments
            </Text>
            <Text lineHeight={"tall"} size="xs" color="light">
              Require additional authentication for payments and transfers.
            </Text>
          </Flex>

          <Flex stack align="start" gap={4}>
            <Flex gap={2} align="center">
              {mfaEnabled && (
                <Tag
                  prependIcon={<HiOutlineShieldCheck />}
                  // size="sm"
                  variant={"light"}
                  color="green"
                  shape="pill"
                >
                  Enabled
                </Tag>
              )}
              <Flex css={{ color: "$gray9" }} align="center" gap={1}>
                <HiOutlineShieldCheck />
                <Text lineHeight={"tall"} size="xs" color="inherit">
                  Requires 2FA to be enabled
                </Text>
              </Flex>
              {/* <CurrencyInput size="sm" /> */}
            </Flex>
          </Flex>
        </RowItem>

        <RowItem>
          <Flex stretchX stack gap={6}>
            <Flex className="item-wrapper">
              <Flex align="start" gap={2} stack className="key">
                <Text weight={"semi"} color="black">
                  Active Sessions
                </Text>
                <Text lineHeight={"tall"} size="xs" color="light">
                  Active sessions with {user?.email}
                </Text>
              </Flex>
              {/* 
              <Button size="sm" appearance={"secondary"} shape="pill">
                Logout of all sessions
              </Button> */}
            </Flex>

            <Table
              loading={fetching}
              css={tableStyles}
              columns={activeSessionColumns}
              data={activeSessions}
            ></Table>
          </Flex>
        </RowItem>

        <RowItem>
          <Flex stretchX stack gap={6}>
            <Flex className="item-wrapper">
              <Flex align="start" gap={2} stack className="key">
                <Text weight={"semi"} color="black">
                  Login Activity
                </Text>
                <Text lineHeight={"tall"} size="xs" color="light">
                  A history of all login activity with {user?.email}
                </Text>
              </Flex>

              {/* <Button size="sm" appearance={"ghost"}>
              Logout of all sessions
            </Button> */}
            </Flex>

            <Table
              loading={fetching}
              css={tableStyles}
              columns={sessionsTableColumns}
              data={sessions}
            ></Table>
          </Flex>
        </RowItem>
      </Flex>
    </>
  );
}
