import React from "react";
import { cx } from "utils/dom";
import { LoaderWrap, StyledLoader } from "./loader.styles";
import { IProps } from "./loader.types";

const Loader = React.forwardRef<HTMLDivElement, IProps>(
  ({ className, size, color = "teal", css, full }, ref) => {
    return (
      <LoaderWrap full={full}>
        <StyledLoader
          size={size}
          ref={ref}
          css={{
            borderColor: `${color} ${color} $whiteA4 $whiteA4`,
            ...css,
          }}
          className={cx(["loader", className])}
        ></StyledLoader>
      </LoaderWrap>
    );
  }
);

Loader.displayName = "Loader";

export default Loader;
