import { Root, Fallback, Image } from "@radix-ui/react-avatar";
import { styled } from "stitches/stitches.config";
import Flex from "components/layout/flex";
import { SharedColorStyles } from "components/_settings/styles/SharedColorStyles";

export const AvatarRoot = styled(Root, SharedColorStyles, {
  display: "flex",
  position: "relative",
  alignItems: "center",
  justifyContent: "center",
  flexShrink: 0,
  overflow: "hidden",
  fontFamily: "$semibold",

  ".icon": {
    fontSize: 20,
    color: "inherit",
  },
  variants: {
    size: {
      "2xs": {
        size: 15,
        fontSize: 10,
        ".icon": {
          fontSize: 10,
          color: "inherit",
        },
      },

      xs: {
        size: 18,
        fontSize: 10,
        ".icon": {
          fontSize: 10,
          color: "inherit",
        },
      },

      sm: {
        size: 24,
        fontSize: 11,
        ".icon": {
          fontSize: 10,
          color: "inherit",
        },
      },
      md: {
        size: 32,
        fontSize: 14,
      },
      lg: {
        size: 40,
        fontSize: 14,
        ".icon": {
          fontSize: 24,
          color: "inherit",
        },
      },
      xl: {
        size: 48,
        fontSize: 16,
        ".icon": {
          fontSize: 28,
          color: "inherit",
        },
      },
      "2xl": { size: 64, fontSize: 20 },
    },
    shape: {
      round: { borderRadius: "$round" },
      square: { borderRadius: "25%" },
    },
  },

  defaultVariants: {
    size: "lg",
    shape: "round",
  },
});

export const Filter = styled(Flex, {
  height: "100%",
  width: "100%",
  background:
    "linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)",
  opacity: 0.4,
  position: "absolute",
});

export const AvatarImage = styled(Image, {
  size: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  objectFit: "cover",
  borderRadius: "inherit",
  flexShrink: 0,
});
export const AvatarFallback = styled(Fallback, {
  size: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "inherit",
  textTransform: "uppercase",
  flexShrink: 0,
  fontFamily: "$semibold",
  fontWeight: 600,
});

export const DefaultIconContainer = styled(Flex, {
  background: "none",
  size: "60%",
  overflow: "hidden",
  borderRadius: "$round",
  flexShrink: 0,
});
