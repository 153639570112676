import React from "react";
import PageTitle from "components/page-title/page-title";
import Table from "components/table/table";

import Box from "components/box";
import {
  Statement,
  useGetStatementsQuery,
} from "generated/__generated_graphql";
import Page from "components/page/page";
import { useToast } from "components/toast";
import { baseServerUrl, downloadFileFromUrl } from "utils/helpers";
import { Flex } from "components/layout";
import Button from "components/button";
import Loader from "components/loader";
import { HiDownload } from "react-icons/hi";
import Text from "components/text";
import { format } from "date-fns";
import { ITableColumn } from "components/table";
import useRestQuery from "hooks/use-rest-query";
import { toast } from "react-hot-toast";
import { useAppProvider } from "providers/app-provider/app-provider";

export const STATEMENTS_TABLE_COLUMNS: Array<ITableColumn<Statement>> = [
  {
    title: "Period",
    key: "startDate",
    dataIndex: "startDate",

    render: (date: Date, item: any) => (
      <Box css={{ py: 12 }}>
        <Text weight={"medium"}>
          {format(new Date(date), "dd MMM")} -{" "}
          {format(new Date(item?.endDate), "dd MMM, yyyy")}
        </Text>
      </Box>
    ),
  },

  {
    title: "",
    key: "url",
    dataIndex: "url",
    render: (ur, object) => (
      <Flex justify={"end"}>
        <StatementDownloadButton statement={object} />
      </Flex>
    ),
  },
];

export function StatementDownloadButton({
  statement,
}: {
  statement: Statement;
}) {
  const [{ utils }, fetch] = useRestQuery({
    requiresAuth: true,
    responseType: "blob",
  });

  const { user } = useAppProvider();
  const bankAccountId = user?.business?.bankAccounts[0]?.id;

  const urlParams = new URLSearchParams({
    from: statement.startDate,
    to: statement.endDate,
    bank_account_id: String(bankAccountId),
  });

  async function downloadStatement() {
    const promise = fetch({
      url: `${baseServerUrl()}/statement/account?${urlParams.toString()}`,
      onSuccess: (response) => {
        utils.downloadFile(
          response,
          `float-account-statement-${user?.business?.name}-from${statement.startDate}-to${statement.endDate}`,
          "pdf"
        );
      },
    });

    toast.promise(promise, {
      loading: "Downloading statement...",
      success: "Statement downloaded",
      error: "Error downloading statement",
    });
  }

  return (
    <Button onClick={downloadStatement} appearance="unstyled">
      <HiDownload size={20} />
    </Button>
  );
}

const StatementPage: React.FC = () => {
  const [statements] = useGetStatementsQuery();

  return (
    <Page pageTitle="Statements">
      <Table
        data={statements.data?.getStatements || []}
        columns={STATEMENTS_TABLE_COLUMNS}
        title="Statements"
        loading={statements.fetching}
      />
    </Page>
  );
};

export default StatementPage;
