import { Flex } from "components/layout";
import Modal from "components/modal";
import { useStepModal } from "components/modal/step-modal";
import RadioCard from "components/radio-card";
import RadioGroup from "components/radio-group";
import Tag from "components/tag";

export default function SelectCardType() {
  const ctx = useStepModal();

  return (
    <>
      <Modal.Body css={{ width: 480 }}>
        <RadioGroup>
          <RadioCard
            title="Virtual Card"
            value="virtual"
            description="Securely enable spending with beautiful physical or virtual cards. Great for travel or general spending."
          ></RadioCard>

          <RadioCard
            disabled
            title="Physical Card"
            value="physical"
            description="Request a physical debit or credit card to be mailed to you. This is limited to 1 card per employee. Make purchases online, in-store or through an ATM."
          >
            <Flex>
              <Tag size="xs">Coming soon</Tag>
            </Flex>
          </RadioCard>
        </RadioGroup>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Cancel onClick={() => ctx?.onOpenChange?.(false)}>
          Cancel
        </Modal.Cancel>
        <Modal.Next to="card-details-form">Continue</Modal.Next>
      </Modal.Footer>
    </>
  );
}
