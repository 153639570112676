import Button from "components/button";
import Input from "components/input";
import { Flex, Stack } from "components/layout";
import Text from "components/text";
import React from "react";
import { EMAIL_VALIDATION_REGEX } from "utils/constants";
import { useForm } from "react-hook-form";
import { OnboardingComponentProps } from "../onboarding";
import { useNavigate } from "react-router-dom";
import { useToast } from "components/toast/use-toast";
import {
  BusinessDirector,
  useSaveBusinessMutation,
} from "generated/__generated_graphql";
import Loader from "components/loader";
import BusinessDirectorForm from "./business-director-form";
import {
  HiArrowLeft,
  HiArrowRight,
  HiOutlinePlusCircle,
  HiOutlineUserAdd,
  HiPlusCircle,
} from "react-icons/hi";
import Avatar from "components/avatar";
import { noop } from "utils/helpers";
import { Helmet } from "react-helmet";
import Persona, { Client } from "persona";
import Modal, { ModalProps } from "components/modal";
import { ActionStatus } from "utils/types";

export function PersonaIquiry({
  onComplete,
  refId,
  open,
  ...props
}: {
  onComplete?(payload: any): void;
  refId: string;
} & ModalProps) {
  const [status, setStatus] = React.useState<ActionStatus>("loading");

  function setReady() {
    setStatus("success");
  }

  return (
    <Modal {...props} open={open}>
      <Modal.Body
        css={{
          height: 650,
          width: 400,
          padding: 0,
          iframe: {
            height: "100%",
            width: "100%",
          },
        }}
      >
        {status === "loading" && (
          <Flex stretchY align={"center"} justify={"center"}>
            <Loader size={"sm"} color="$gray12" />
          </Flex>
        )}

        <Persona.Inquiry
          onReady={setReady}
          templateId="itmpl_gFKWuDaE5wEGqNgYsp5ZNDHW"
          referenceId={refId}
          onComplete={onComplete}
        ></Persona.Inquiry>
      </Modal.Body>
    </Modal>
  );
}

const OwnerInformation = React.forwardRef<
  HTMLDivElement,
  OnboardingComponentProps
>(({ data }, ref) => {
  const [showEmptyForm, setShowEmptyForm] = React.useState(false);
  const navigate = useNavigate();

  const business = data?.profile.business;
  const businessDirectors = business?.businessDirectors || [];
  const hasBusinessDirectors = businessDirectors?.length > 0;

  // const persona = React.useMemo(() => {
  //   return new Persona.Client({
  //     templateId: "itmpl_gFKWuDaE5wEGqNgYsp5ZNDHW",
  //     referenceId: "ref_123",
  //     environment: "production",
  //     onComplete: (payload) => {
  //       console.log(payload);
  //     },
  //   });
  // }, []);

  return (
    <Flex direction={"column"} gap={16}>
      <Flex ref={ref} direction="column" gap={1}>
        <Text weight={"semi"} size="xl" color="black">
          Add business owner(s) and control person
        </Text>
        <Text size="sm" color="light" weight="regular" lineHeight="tall">
          Add information for (1) anyone who owns, directly or indirectly, 25%
          or more of the business and (2) a control person. <br />
          <br /> A control person is generally someone who has a significant
          ability to control, manage, or direct the business’s activities (like
          a CEO, CFO, president, or treasurer). <br /> <br /> If you include the
          control person because they own 25% or more of the business, you do
          not need to include them twice.
        </Text>
      </Flex>

      <Flex gap={8} direction={"column"}>
        {businessDirectors?.map((director, index) => {
          return (
            <BusinessDirectorForm
              // persona={persona}
              data={data}
              index={index}
              director={director as BusinessDirector}
              key={`${director.email}-${index}`}
            />
          );
        })}
        {(!hasBusinessDirectors || showEmptyForm) && (
          <BusinessDirectorForm
            onSave={() => setShowEmptyForm(false)}
            index={99999}
            onDelete={() => setShowEmptyForm(false)}
            data={data}
          />
        )}
        {!showEmptyForm && hasBusinessDirectors && (
          <Button
            onClick={() => setShowEmptyForm(true)}
            align={"left"}
            css={{ border: "none", height: 60, boxShadow: "$base" }}
            appearance={"outline"}
          >
            <Flex align={"center"} gap={4}>
              <Avatar size="md" color="gray">
                <HiOutlinePlusCircle size={20} />
              </Avatar>
              <Text size="sm" color="dark">
                Add another owner
              </Text>
            </Flex>
          </Button>
        )}
      </Flex>

      <Flex gap={3} justify={"start"}>
        <Button
          onClick={() => navigate("/onboarding/verify-business")}
          prepend={<HiArrowLeft size={16} />}
          size={"md"}
          appearance="outline"
        >
          Back
        </Button>
        <Button
          onClick={() => navigate("/onboarding/review-details")}
          append={<HiArrowRight size={16} />}
          appearance={"secondary"}
        >
          Continue
        </Button>
      </Flex>
    </Flex>
  );
});

OwnerInformation.displayName = "OwnerInformation";

export default OwnerInformation;
