import React, { useState } from "react";

export default function useModal(removeTriggerFocus?: boolean) {
  const [open, setOpen] = useState(false);
  const originalFocusedElement = React.useRef(null);

  const handleOnOpenChange = React.useCallback(() => {
    setOpen((prev) => (prev = !prev));
  }, [open]);

  React.useEffect(() => {
    const focusElement = () => {
      if (open) {
        originalFocusedElement.current =
          document && (document.activeElement as any);
      } else {
        (originalFocusedElement.current as unknown as HTMLElement)?.focus();
      }
    };

    if (!removeTriggerFocus) {
      focusElement();
    }
  }, [open, removeTriggerFocus]);

  return {
    open,
    setOpen,
    handleOnOpenChange,
  };
}
