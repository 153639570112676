import React from "react";
import Button from "components/button";
import CheckBox from "components/checkbox/checkbox";
import { Flex } from "components/layout";
import Modal, { ModalProps } from "components/modal";
import StepModal from "components/modal/step-modal";
import Separator from "components/separator";
import Text from "components/text";
import {
  useRequestYieldAccountMutation,
  useYieldAgreementsQuery,
} from "generated/__generated_graphql";
import { FundAccount } from "./fund-yield-account";
import { useStepModal } from "components/modal/step-modal";
import { extractGraphqlErrors } from "utils/helpers";
import toast from "react-hot-toast";
import { HiOutlineCheckCircle, HiOutlinePaperClip } from "react-icons/hi";
import YieldImage from "assets/images/yield-banner-sq.jpg";
import Loader from "components/loader";
import { useAppProvider } from "providers/app-provider/app-provider";

interface Props extends ModalProps {}

function IntroScreen() {
  const context = useStepModal();
  return (
    <>
      <Flex>
        <Flex css={{ height: 600, flex: 1, overflow: "hidden" }}>
          <img
            style={{ objectFit: "cover" }}
            src={YieldImage}
            alt="yield illustration"
          />
        </Flex>
        <Flex css={{ p: 36, flex: 1 }} stack>
          <Flex stack gap={2}>
            <Text color="black" size="2xl" lineHeight={"tall"}>
              Earn up to 4.7% on your idle cash
            </Text>
            <Text color="dark" lineHeight={"tall"}>
              We've partnered with Helium Advisors LLC, an SEC-registered
              investment adviser, to bring you investing features. All
              investment advisory services are provided by Helium. We receive
              compensation as a percentage of assets managed by Helium for
              promoting Helium’s investment advisory services. <br /> <br />
              Our partnership with Helium gives us an incentive to refer you to
              Helium instead of another investment adviser that is not a partner
              of ours. This conflict of interest affects our ability to provide
              you with unbiased, objective information about the services of
              Helium. This could mean that the services of another investment
              adviser with whom we are not partnered may be more appropriate for
              you than those of Helium.
            </Text>
            <Flex
              stack
              gap={4}
              css={{
                marginTop: 8,
                listStyle: "none",
                padding: 0,
                li: {
                  lineHeight: 1.5,
                  display: "flex",
                  gap: 8,

                  ".icon": {
                    flexShrink: 0,
                    marginTop: 4,
                    size: 16,
                    color: "$green10",
                  },
                },
              }}
              as="ul"
            >
              <Text as="li">
                <HiOutlineCheckCircle className="icon" />
                Competitive interest rates: Earn up to 4% interest on your
                balance, making your idle cash work harder for you.
              </Text>
              <Text as="li">
                <HiOutlineCheckCircle className="icon" />
                FDIC protection up to $2.5 million: Enjoy unparalleled security
                and peace of mind with exceptional FDIC protection.
              </Text>
              <Text as="li">
                <HiOutlineCheckCircle className="icon" />
                Powered by BNY Mellon: Benefit from the stability and expertise
                of a renowned financial institution.
              </Text>
              <Text as="li">
                <HiOutlineCheckCircle className="icon" />
                Easy access to your funds: Withdraw your funds at any time,
                providing you with the flexibility you deserve
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Modal.Footer>
        <Modal.Cancel
          onClick={() => context.onOpenChange?.(false)}
          appearance={"flushed"}
        >
          Cancel
        </Modal.Cancel>
        <Modal.Confirm onClick={() => context.goToScreen("terms")}>
          Continue
        </Modal.Confirm>
      </Modal.Footer>
    </>
  );
}

function AgreementScreen() {
  const context = Modal.useStepModal();
  const [{ data, fetching }] = useYieldAgreementsQuery();
  const [{ fetching: settingUp }, setupYield] =
    useRequestYieldAccountMutation();
  const agreements = data?.yieldAccountAgreements;
  const [agreed, setAgreed] = React.useState(false);
  const { user } = useAppProvider();
  const yieldAccountStatus = user?.business?.yieldAccountStatus;
  const isPending = ["pending", "processing"].includes(
    yieldAccountStatus ?? ""
  );

  async function setUp() {
    const response = await setupYield({});
    const errors = extractGraphqlErrors(response, "setupYieldAccount");

    if (errors) {
      toast.error("Failed to setup yield account");
      return;
    }

    toast.success("Yield account setup successfully");
    context.onOpenChange?.(false);
  }

  if (fetching) {
    return (
      <Flex css={{ height: 600 }} justify={"center"} align={"center"}>
        <Loader></Loader>
      </Flex>
    );
  }

  return (
    <>
      <Flex>
        <Flex css={{ flex: 6, padding: 24 }} stack>
          <Flex gap={4} stack>
            <Text lineHeight={"tall"}>
              You are electronically signing the Helium Client Account
              Agreements which include your agreement to:
            </Text>

            <Flex css={{ listStyle: "none", padding: 0 }} stack gap={2} as="ul">
              <Flex
                stretchX
                align={"center"}
                css={{
                  color: "$gray9",

                  py: 8,
                  textDecoration: "none",
                }}
                gap={2}
              >
                <HiOutlineCheckCircle />
                <Text case="capitalize" as="li">
                  Establish a brokerage account and margin account with Pershing
                  Advisor Solutions;
                </Text>
              </Flex>

              <Flex
                stretchX
                align={"center"}
                css={{
                  color: "$gray9",

                  py: 8,
                  textDecoration: "none",
                }}
                gap={2}
              >
                <HiOutlineCheckCircle />
                <Text case="capitalize" as="li">
                  Establish a checking account associated with your brokerage
                  account with Pershing Advisor Solutions.
                </Text>
              </Flex>

              <Flex
                stretchX
                align={"start"}
                css={{
                  color: "$gray9",

                  py: 8,
                  textDecoration: "none",
                }}
                gap={2}
              >
                <HiOutlineCheckCircle style={{ flexShrink: 0 }} />
                <Text lineHeight={"tall"} case="capitalize" as="li">
                  If you are opening an Individual Retirement Account, you agree
                  to the terms outlined in the Pershing LLC IRA Adoption
                  Agreement, Pershing LLC Roth IRA Custodial Account Agreement,
                  and Plan Documents;
                </Text>
              </Flex>

              <Flex
                stretchX
                align={"center"}
                css={{
                  color: "$gray9",

                  py: 8,
                  textDecoration: "none",
                }}
                gap={2}
              >
                <HiOutlineCheckCircle style={{ flexShrink: 0 }} />
                <Text lineHeight={"tall"} case="capitalize" as="li">
                  Allow Helium Advisors LLC to buy and sell investments for you;
                </Text>
              </Flex>

              <Flex
                stretchX
                align={"center"}
                css={{
                  color: "$gray9",

                  py: 8,
                  textDecoration: "none",
                }}
                gap={2}
              >
                <HiOutlineCheckCircle style={{ flexShrink: 0 }} />
                <Text lineHeight={"tall"} case="capitalize" as="li">
                  Delegate proxy voting authority to Helium;
                </Text>
              </Flex>

              <Flex
                stretchX
                align={"center"}
                css={{
                  color: "$gray9",

                  py: 8,
                  textDecoration: "none",
                }}
                gap={2}
              >
                <HiOutlineCheckCircle style={{ flexShrink: 0 }} />
                <Text lineHeight={"tall"} case="capitalize" as="li">
                  Receive account-related communication (including quarterly
                  statements) electronically;
                </Text>
              </Flex>

              <Flex
                stretchX
                align={"center"}
                css={{
                  color: "$gray9",

                  py: 8,
                  textDecoration: "none",
                }}
                gap={2}
              >
                <HiOutlineCheckCircle style={{ flexShrink: 0 }} />
                <Text lineHeight={"tall"} case="capitalize" as="li">
                  Resolve disputes with Helium and Pershing through arbitration.
                </Text>
              </Flex>
            </Flex>

            <Text lineHeight={"tall"}>
              You acknowledge that when a company in which you own shares
              through your brokerage account asks for your name, address, and
              the number of shares you own in order to communicate with you, we
              are obligated to provide this information to them unless you
              object in writing. Please note that some issuers do not allow you
              to opt-out. <br /> <br />
              You also affirm that you are of full legal age in the state or
              jurisdiction in which you reside and have the capacity to enter
              into this agreement. Further, you acknowledge that you have read,
              understood, and agree to the terms outlined in Helium's Privacy
              Policy, Form CRS, and Form ADV Part 2A Appendix 1 Wrap Fee Program
              Brochure, as well as Pershing’s Terms and Conditions and Corestone
              Agreement.
            </Text>
          </Flex>
          <Separator spacing={24} />
          <Flex gap={2}>
            <CheckBox
              checked={agreed}
              onChange={(e) => setAgreed(e.target.checked)}
              css={{ marginTop: 5 }}
            ></CheckBox>
            <Text lineHeight={"tall"}>
              I have read and agree to all the terms and conditions above.
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <Modal.Footer css={{ alignItems: "center" }}>
        <Modal.Cancel
          onClick={() => context.onOpenChange?.(false)}
          appearance={"flushed"}
        >
          Cancel
        </Modal.Cancel>
        {isPending ? (
          <Text css={{ fontStyle: "italic" }}>Pending approval</Text>
        ) : (
          <Modal.Confirm
            disabled={!agreed}
            isLoading={settingUp}
            onClick={setUp}
          >
            I agree to the terms and conditions
          </Modal.Confirm>
        )}
      </Modal.Footer>
    </>
  );
}

export default function YieldSetup({ ...props }: Props) {
  return (
    <StepModal
      defaultScreen={"intro"}
      {...props}
      title={
        <Text color="black" size={"lg"}>
          Setup Yield Account
        </Text>
      }
      variant={"modal"}
    >
      <Modal.Screen screenId={"intro"}>
        <IntroScreen />
      </Modal.Screen>
      <Modal.Screen css={{ width: 900 }} screenId={"terms"}>
        <AgreementScreen />
      </Modal.Screen>
    </StepModal>
  );
}
