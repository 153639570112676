import Box from "components/box";
import { styled } from "stitches/stitches.config";
import PatternBg from "assets/images/pattern.png";
import { Flex, Stack } from "components/layout";
import Text from "components/text";
import { useEffect } from "react";
import Input from "components/input";
import { EMAIL_VALIDATION_REGEX } from "utils/constants";
import Button from "components/button";
import { Link } from "react-router-dom";
import Avatar from "components/avatar";
import { FiMail } from "react-icons/fi";

import * as Sentry from "@sentry/react";
import { useForgotPasswordMutation } from "generated/__generated_graphql";
import { useState } from "react";
import { HiAtSymbol } from "react-icons/hi";
import floatLogo from "assets/images/float-logo-final.svg";
import useForm from "hooks/useForm";

const StyledContainer = styled(Box, {
  background: "$gray2",
  backgroundImage: `url(${PatternBg})`,
  backgroundSize: "50% 50%",
  backgroundRepeat: "repeat-X",
  backgroundPosition: "top",
  width: "100vw",
  minHeight: "100vh",
  position: "relative",
  py: 60,

  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  flexDirection: "column",
});

const ContentWrapper = styled(Flex, {
  width: 380,
  height: "calc(100vh - 120px)",
});

const FormContainer = styled(Flex, {
  background: "white",
  boxShadow: "$subtle",
  p: 28,
  borderRadius: 18,
});

interface ForgotPasswordFieldValues {
  email: string;
}

export default function ForgotPassword() {
  const { register, values, onSubmit } = useForm({ fields: { email: "" } });

  const [emailSent, setEmailSent] = useState(false);

  const [{ fetching: loading }, sendPasswordLink] = useForgotPasswordMutation();

  const forgotPassword = async () => {
    try {
      await sendPasswordLink({ email: values.email });
      setEmailSent(true);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    analytics.page("Auth", "Forgot password");
  }, []);

  return (
    <StyledContainer>
      <ContentWrapper
        gap={9}
        justify={"center"}
        align="center"
        direction={"column"}
      >
        <img width={100} src={floatLogo} alt="float-logo" />
        <FormContainer direction="column">
          {emailSent && (
            <Flex
              direction="column"
              align={"center"}
              justify={"center"}
              gap="5"
            >
              <Flex direction={"column"} align="center" gap={2}>
                <Avatar size="lg" color={"gray"}>
                  <HiAtSymbol size={18} />
                </Avatar>
                <Stack css={{ mt: 5 }} spacing={10}>
                  <Text align="center" size="lg" color="black">
                    Email sent!
                  </Text>
                  <Text
                    align="center"
                    size="sm"
                    lineHeight="base"
                    color="light"
                    weight="regular"
                  >
                    We have sent a password reset link to your email. <br />{" "}
                    Check your spam folder if you can't find it.
                  </Text>
                </Stack>
              </Flex>

              <Text as="a" color="primary" size="sm" href="/login">
                Return to Login
              </Text>
            </Flex>
          )}
          {!emailSent && (
            <Flex direction={"column"} gap={8}>
              <Flex direction={"column"} align="center" gap={2}>
                <Text size="lg" color="black">
                  Reset password
                </Text>
                <Text
                  size="sm"
                  color="light"
                  weight="regular"
                  lineHeight={"base"}
                  align={"center"}
                >
                  Enter your email address to get instructions for resetting
                  your password.
                </Text>
              </Flex>

              <form
                className="wrapper"
                noValidate
                onSubmit={(e) => onSubmit(e, forgotPassword)}
              >
                <Flex direction="column" align="center" gap={2}>
                  <Input
                    required
                    type="email"
                    placeholder="Enter email"
                    {...register("email")}
                  ></Input>

                  <Button
                    css={{ width: "100%" }}
                    size={"md"}
                    disabled={loading}
                    type="submit"
                    isLoading={loading}
                  >
                    Reset password
                  </Button>
                </Flex>
              </form>

              <Text
                align={"center"}
                as="a"
                size="sm"
                color="primary"
                href="/login"
              >
                Return to Login
              </Text>
            </Flex>
          )}
        </FormContainer>
      </ContentWrapper>
    </StyledContainer>
  );
}
