import Avatar from "components/avatar";
import Button from "components/button";
import DateInput from "components/date-input";
import FileUploader from "components/file-uploader";
import IconButton from "components/icon-button";
import Input from "components/input";
import { Flex } from "components/layout";
import Loader from "components/loader";
import NumberInput from "components/number-input";
import PhoneNumberInput from "components/phone-number-input/phone-number-input";
import SelectExperimental from "components/select/select-experimental";
import Text from "components/text";
import { useToast } from "components/toast";
import {
  BusinessDirector,
  BusinessDirectorAttributeInput,
  OnboardingDataQuery,
  useSaveBusinessDirectorMutation,
  useSaveBusinessMutation,
} from "generated/__generated_graphql";
import useCopyToClipboard from "hooks/use-copy-to-clipboard";
import useUpload from "hooks/use-upload";
import useForm from "hooks/useForm";
import React from "react";
import {
  HiDotsHorizontal,
  HiDuplicate,
  HiOutlineBadgeCheck,
  HiPencil,
  HiPencilAlt,
  HiTrash,
} from "react-icons/hi";
import { styled } from "stitches/stitches.config";
import { countries } from "utils/countries";
import { extractGraphqlErrors } from "utils/helpers";
import { objectKeys, omit, pick, split } from "utils/object";
import { americanStates } from "utils/states";
import { Client } from "persona";
import { CustomCountryOption } from "./custom-country-option";
import CheckBox from "components/checkbox/checkbox";
import Sticker from "components/sticker";
import Separator from "components/separator";
import { PersonaIquiry } from "./owner-information";
import Modal from "components/modal";

const Wrapper = styled(Flex, {
  px: 16,
  py: 16,
  background: "White",
  boxShadow: "$base",
  borderRadius: 3,
});

type FormMode = "edit" | "preview";

const jobTitles = ["CEO", "COO", "CFO", "CTO", "CPO", "CSO", "Founder"];

const citizenshipStatuses = [
  "US Citizen",
  "Permanent Resident",
  "Non Resident",
];
const identificationTypes = ["Passport", "Drivers License", "Other"];

type BusinessDirectorFormProps = {
  shared?: boolean;
  _mode?: FormMode;
  data?: OnboardingDataQuery;
  index?: number;
  submitting?: boolean;
  submit?(data?: unknown): void;
  onSave?(): void;
  onDelete?(): void;
  director?: BusinessDirector;
  persona?: Client;
};

const BusinessDirectorForm = React.forwardRef<
  HTMLDivElement,
  BusinessDirectorFormProps
>(
  (
    { _mode = "edit", director, onDelete, onSave, index, shared, persona },
    ref
  ) => {
    const [formVisibility, setFormVisibility] = React.useState({
      basicInfo: true,
      moreInfo: false,
    });

    const notify = useToast();
    const copyFormLink = useCopyToClipboard();
    const [showPersona, setShowPersona] = React.useState(false);

    const [{ fetching: saving }, save] = useSaveBusinessMutation();
    const [{ fetching: savingSharedDirector }, saveSharedDirector] =
      useSaveBusinessDirectorMutation();
    const [{ fetching: deleting }, deleteDirectorMutation] =
      useSaveBusinessMutation();

    const [{ upload, removeUpload, status }, uploadDocument] = useUpload({
      defaultUpload: director?.businessDirectorDocuments?.[0]?.upload,
    });

    const {
      register,
      onChange,
      setInputValue,
      values,
      errors,
      validateField,
      formIsComplete,
      onSubmit,
    } = useForm({
      fields: {
        firstName: director?.firstName!,
        lastName: director?.lastName!,
        dob: director?.dob || "2005-01-01",
        email: director?.email!,
        isControlPerson: !!director?.isControlPerson,
        title: director?.title!,
        identificationType: director?.identificationType,
        phoneNumberAttributes: {
          countryCode: director?.phoneNumber?.countryCode! || "US",
          value: director?.phoneNumber?.value! || "",
        },
        ssn: director?.ssn,
        citizenshipStatus: director?.citizenshipStatus,
        ownershipPercentage: director?.ownershipPercentage!,
        state: director?.address?.state,
        postalCode: director?.address?.postalCode,
        countryCode: director?.address?.country?.code! || "US",
        line1: director?.address?.line1!,
        city: director?.address?.city!,
        verificationId: director?.verificationId || "verification-id",
      },
      optional: ["identificationType", "verificationId"],
      dependencies: {
        citizenshipStatus: ["ssn"],
      },
      validators: {
        ssn: (value, values) => {
          if (
            ["us_citizen", "permanent_resident"].includes(
              values?.citizenshipStatus!
            ) &&
            !value
          ) {
            return "SSN is required for US Citizens and US Residents";
          }

          return "";
        },
      },
    });

    const canSaveBasicInfo =
      !!values.firstName &&
      !!values.lastName &&
      !!values.email &&
      !!values.title &&
      !!values.ownershipPercentage;

    const basicInfoKeys: Array<keyof typeof values> = [
      "firstName",
      "lastName",
      "email",
      "title",
      "ownershipPercentage",
    ];

    const addressFields: Array<keyof typeof values> = [
      "line1",
      "city",
      "state",
      "postalCode",
      "countryCode",
    ];

    const [basicInfoFields] = split(values, basicInfoKeys);

    const hasBasicInfo = !!director;
    const showMoreInfoMessage =
      !formVisibility.moreInfo && !director?.citizenshipStatus;

    async function deleteDirector() {
      if (!director) {
        onDelete?.();
        return;
      }

      const response = await deleteDirectorMutation({
        businessDetails: {
          businessDirectorsAttributes: [
            { id: director?.id, _destroy: true, ...basicInfoFields },
          ],
        },
      });

      const error = extractGraphqlErrors(response, "saveBusiness");

      if (error) {
        notify({
          content: "Could not delete director! Please try again.",
          status: "error",
          position: "top",
          showIcon: true,
        });

        return;
      }
    }

    async function saveBusiness() {
      const payload = hasBasicInfo
        ? ({
            ...omit(values, addressFields),
            id: director?.id,
            addressAttributes: pick(values, addressFields),
            businessDirectorDocumentsAttributes: upload
              ? [
                  {
                    id: director?.businessDirectorDocuments?.[0]?.id,
                    uploadId: upload?.id as number,
                  },
                ]
              : [],
          } as BusinessDirectorAttributeInput)
        : basicInfoFields;

      try {
        const response =
          shared && hasBasicInfo
            ? await saveSharedDirector({
                uuid: director?.uuid!,
                details: payload,
              })
            : await save({
                businessDetails: {
                  businessDirectorsAttributes: [payload],
                },
              });
        const error = extractGraphqlErrors(
          response,
          shared ? "saveBusinessDirector" : "saveBusiness"
        );
        if (error) {
          notify({
            content: error,
            status: "error",
            position: "top",
            showIcon: true,
          });
          return;
        }

        setFormVisibility({ basicInfo: false, moreInfo: false });
        onSave?.();
      } catch (error) {
        notify({
          content: "Something went wrong. Please try again",
          status: "error",
          position: "top",
          showIcon: true,
        });
        console.error(error);
      }
    }

    function Header() {
      return (
        <Flex justify={"between"} stretchX>
          {director ? (
            <Flex align={"center"} gap={4}>
              <Avatar
                variant={"outline"}
                size="md"
                name={`${director?.firstName}${director?.lastName}`}
              ></Avatar>
              <Flex direction={"column"} gap={1}>
                <Text color="dark" size="sm">
                  {director?.firstName} {director?.lastName}
                </Text>
                <Text weight={"regular"} color="light" size="sm">
                  {director?.title}, {director?.email}
                </Text>
              </Flex>
            </Flex>
          ) : (
            <Text>New owner profile</Text>
          )}

          {!shared && (
            <Flex gap={2}>
              {director && (
                <IconButton
                  onClick={() =>
                    setFormVisibility({ basicInfo: true, moreInfo: true })
                  }
                  color="gray"
                  variant={"light"}
                >
                  <HiPencil />
                </IconButton>
              )}
              <IconButton
                onClick={deleteDirector}
                disabled={deleting}
                color="gray"
                variant={"light"}
              >
                {deleting ? (
                  <Loader color="$tomato10" size="xs" />
                ) : (
                  <HiTrash />
                )}
              </IconButton>
            </Flex>
          )}
        </Flex>
      );
    }

    if (hasBasicInfo && !formVisibility.moreInfo && !shared) {
      return (
        <Wrapper
          className="preview"
          ref={ref}
          direction={"column"}
          gap={5}
          stretchX
        >
          <Header />

          {showMoreInfoMessage && (
            <>
              <Text
                color="dark"
                weight={"regular"}
                lineHeight={"tall"}
                size="sm"
              >
                We need some info about {director?.firstName}. This includes
                uploading a form of ID. If you are {director?.firstName}, please
                complete the form. If not, please share the form link with{" "}
                {director?.firstName} to complete.
              </Text>

              <Flex align="center" gap={2}>
                <Button
                  onClick={() =>
                    copyFormLink(
                      `global.float.co/onboarding/add-director/${director?.uuid}`,
                      "Link copied"
                    )
                  }
                  appearance="outline"
                  size="sm"
                >
                  Copy link
                </Button>
                <Button
                  onClick={() =>
                    setFormVisibility({ basicInfo: false, moreInfo: true })
                  }
                  appearance={"ghost"}
                  size="sm"
                >
                  I will fill it out
                </Button>
              </Flex>
            </>
          )}
        </Wrapper>
      );
    }

    return (
      <Wrapper ref={ref} gap={8} direction={"column"}>
        <Header />

        <form
          onSubmit={(e) => {
            e.preventDefault();
            hasBasicInfo ? onSubmit(e, saveBusiness) : saveBusiness();
          }}
        >
          <Flex gap={6} direction={"column"}>
            {!shared && (
              // basic info
              <>
                <Flex gap={2}>
                  <Input
                    {...register("firstName")}
                    label="First name"
                    placeholder="First name"
                  ></Input>
                  <Input
                    {...register("lastName")}
                    label="Last name"
                    placeholder="Last name"
                  ></Input>
                </Flex>
                <Input
                  {...register("email")}
                  label="Email"
                  placeholder="Email"
                ></Input>
                <SelectExperimental
                  value={values.title}
                  onChange={(value) => setInputValue("title", value)}
                  options={jobTitles.map((title) => ({
                    label: title,
                    value: title,
                  }))}
                  placeholder="Job title"
                  label="Job title"
                />
                <NumberInput
                  value={values.ownershipPercentage!}
                  onValueChange={(value) =>
                    setInputValue("ownershipPercentage", value)
                  }
                  error={errors.ownershipPercentage}
                  onBlur={validateField}
                  max={100}
                  append={<Text color="light">%</Text>}
                  label="Ownership percentage"
                  placeholder="Ownership percentage"
                ></NumberInput>

                <Sticker
                  gap={1}
                  stack
                  color={"lime"}
                  variant={"outline"}
                  // css={{ px: 16, py: 16 }}
                >
                  <Flex css={{ py: 8 }} align={"center"}>
                    <CheckBox
                      onChange={(e) =>
                        setInputValue("isControlPerson", e.target.checked)
                      }
                      checked={!!values.isControlPerson}
                    >
                      <Text color={"inherit"}>
                        This person is the control person
                      </Text>
                    </CheckBox>
                  </Flex>
                  <Separator
                    css={{ backgroundColor: "$lime6" }}
                    spacing={0}
                  ></Separator>
                  <Flex css={{ py: 4 }} align={"center"}>
                    <Text
                      color="inherit"
                      lineHeight={"tall"}
                      weight={"regular"}
                    >
                      This person is responsible for the relationship with Float
                      and managing the account
                    </Text>
                  </Flex>
                </Sticker>
              </>
            )}

            {(formVisibility.moreInfo || shared) && (
              // more info
              <>
                <SelectExperimental
                  value={values.citizenshipStatus}
                  error={errors.citizenshipStatus}
                  onChange={(value) =>
                    setInputValue("citizenshipStatus", value)
                  }
                  placeholder="Citizenship status"
                  label="Citizenship status"
                  options={citizenshipStatuses.map((status) => ({
                    label: status,
                    value: status.toLowerCase().split(" ").join("_"),
                  }))}
                />
                {["permanent_resident", "us_citizen"].includes(
                  values.citizenshipStatus || ""
                ) && (
                  <Input
                    {...register("ssn")}
                    label="Social Security Number"
                    placeholder="123-45-6789"
                  ></Input>
                )}
                {["permanent_resident", "non_resident"].includes(
                  values.citizenshipStatus || ""
                ) && (
                  <SelectExperimental
                    onChange={(value) => setInputValue("countryCode", value)}
                    renderOption={(option) => (
                      <CustomCountryOption option={option} />
                    )}
                    labelKey="name"
                    valueKey="code"
                    options={countries}
                    label="Country of citizenship"
                    placeholder="Country of citizenship"
                    value={values.countryCode}
                  ></SelectExperimental>
                )}
                <Input
                  type={"date"}
                  max={"2005-01-01" as any}
                  name="dob"
                  value={values.dob}
                  onChange={onChange}
                  error={errors.dob}
                  onBlur={validateField}
                  label="Date of birth"
                  placeholder="Date of birth"
                ></Input>
                <PhoneNumberInput
                  error={errors.phoneNumberAttributes}
                  value={{
                    countryCode: values.phoneNumberAttributes?.countryCode,
                    value: values.phoneNumberAttributes?.value,
                  }}
                  onChange={(value) =>
                    setInputValue("phoneNumberAttributes", value)
                  }
                  label="Phone number"
                  countries={countries}
                ></PhoneNumberInput>

                <Flex gap={1} direction={"column"}>
                  <Input
                    label="Valid US Address"
                    required
                    type="text"
                    placeholder="Address"
                    {...register("line1")}
                  />
                  {values?.countryCode === "US" ? (
                    <SelectExperimental
                      required
                      options={americanStates}
                      onChange={(value: string) => {
                        setInputValue("state", value);
                      }}
                      value={values.state}
                      valueKey="name"
                      labelKey="name"
                      placeholder="State"
                      error={errors.state}
                    />
                  ) : (
                    <Input
                      {...register("state")}
                      placeholder="State/Province/Region"
                    ></Input>
                  )}
                  <Input required placeholder="City" {...register("city")} />
                  <Input
                    {...register("postalCode")}
                    required
                    placeholder="Postal Code"
                  />
                </Flex>

                <Separator spacing={0} />

                <Flex stack gap={5}>
                  <Flex stack gap={2}>
                    <Text case={"uppercase"} weight={"semi"} size={"xs"}>
                      Identity Verification
                    </Text>
                    <Text size="sm" color={"light"} lineHeight={"tall"}>
                      By clicking the button below, you consent to Persona, our
                      vendor, collecting and processing your personal
                      information for the purpose of verifying your identity.
                      You can read more about how we use your personal
                      information in our Privacy Policy.
                    </Text>
                  </Flex>
                  {showPersona && (
                    <PersonaIquiry
                      onOpenChange={(value) => setShowPersona(value)}
                      open={showPersona}
                      refId={`ref_${director?.id}`}
                      onComplete={(payload) => {
                        setInputValue("verificationId", payload.inquiryId);
                        setShowPersona(false);
                      }}
                    />
                  )}
                  {!!values.verificationId ? (
                    <Sticker
                      gap={4}
                      stack
                      align={"start"}
                      color={"lime"}
                      variant={"light"}
                      css={{ px: 16, py: 16 }}
                    >
                      <HiOutlineBadgeCheck size={24} />
                      <Flex stack gap={1}>
                        <Text size={"sm"} weight={"semi"} color="inherit">
                          Identity successfully verified
                        </Text>
                        <Text lineHeight={"tall"}>
                          You have successfully completed your identity
                          verification with our 3rd party vendor, Persona. You
                          can now continue with your application.
                        </Text>
                      </Flex>
                      <Button
                        appearance={"secondary"}
                        onClick={() => setShowPersona(true)}
                        size={"xs"}
                      >
                        Retry verification
                      </Button>
                    </Sticker>
                  ) : (
                    <Button
                      prepend={<HiOutlineBadgeCheck size={20} />}
                      onClick={() => setShowPersona(true)}
                      // shape={"pill"}
                      css={{ alignSelf: "flex-start" }}
                      appearance={"secondary"}
                    >
                      Verify Identity
                    </Button>
                  )}
                </Flex>

                {/* <SelectExperimental
                required
                name="identificationType"
                options={identificationTypes.map((type) => ({
                  label: type,
                  value: type.toLowerCase().split(" ").join("_"),
                }))}
                onChange={(value: string) => {
                  setInputValue("identificationType", value);
                }}
                value={values.identificationType}
                label="Identification type"
                placeholder="Identification type"
                error={errors.identificationType}
              />
              <FileUploader
                id={`director-document-${index}`}
                label="Upload document"
                onRemove={removeUpload}
                onRetry={uploadDocument}
                status={status}
                upload={upload}
                onChange={uploadDocument}
              /> */}
              </>
            )}
            <Button
              disabled={hasBasicInfo ? !formIsComplete : !canSaveBasicInfo}
              type="submit"
              isLoading={saving || savingSharedDirector}
              appearance={"primary"}
              size={"md"}
            >
              {director ? "Save" : "Add owner"}
            </Button>
          </Flex>
        </form>
      </Wrapper>
    );
  }
);

BusinessDirectorForm.displayName = "BusinessDirectorForm";

export default BusinessDirectorForm;
