import { Flex } from "components/layout";
import { REVERSAL_NARRATION } from "components/move-money/move-money.utils";
import PaymentIndicator from "components/payment-indicator";
import Text from "components/text";
import { JournalEntry } from "generated/__generated_graphql";
import React from "react";
import { formatMoney } from "utils/helpers";

type PaymentSummaryItemProps = { transaction?: JournalEntry };

const PaymentSummaryItem = React.forwardRef<
  HTMLDivElement,
  PaymentSummaryItemProps
>(({ transaction }, ref) => {
  const isCredit = transaction?.transactionType === "credit";
  const isDebit = transaction?.transactionType === "debit";
  const isReversal = !!transaction?.originalEntryId;

  function getDescription() {
    if (isReversal) {
      return `Reversal for transfer to ${
        transaction?.moneyTransfer?.recipient?.name
      } •• ${transaction?.moneyTransfer?.bankAccount?.accountNumber?.slice(
        -4
      )}`;
    }

    if (isCredit) {
      return transaction?.origin?.bank_data?.bank_name;
    }

    if (isDebit) {
      return `${transaction?.destination?.bank_name}`;
    }
  }

  return (
    <Flex ref={ref} justify={"between"}>
      <Flex gap={5}>
        <PaymentIndicator transaction={transaction} />

        <Flex gap={2} stack>
          <Text weight={"semi"} color="black">
            {transaction?.transactionType === "credit"
              ? transaction.origin?.name
              : transaction?.destination?.name}
          </Text>
          <Text size="sm" color="light">
            {getDescription()}
          </Text>
        </Flex>
      </Flex>

      <Text fontFamily={"haffer"} weight={"semi"} color="black">
        {formatMoney(transaction?.amount)}
      </Text>
    </Flex>
  );
});

PaymentSummaryItem.displayName = "PendingPayment";

export default PaymentSummaryItem;
