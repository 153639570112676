import Box from "components/box";
import { styled } from "stitches/stitches.config";
import PatternBg from "assets/images/pattern.png";
import { Stack } from "components/layout";
import Text from "components/text";
import { useParams, Link } from "react-router-dom";
import Input from "components/input";
import Button from "components/button";
import { FiCheck } from "react-icons/fi";
import Avatar from "components/avatar";
import { useState } from "react";
import useForm from "hooks/useForm";
import {
  CountriesQuery,
  useCountriesQuery,
  useResetPasswordMutation,
} from "generated/__generated_graphql";
import { useToast } from "components/toast/use-toast";
import * as Sentry from "@sentry/react";
import React from "react";

const StyledContainer = styled(Box, {
  background: "$sand2",
  width: "100vw",
  minHeight: "100vh",
  position: "relative",

  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  flexDirection: "column",
  ".bg": {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100vw",

    zIndex: 1,
  },
  ".footer": {
    mt: "auto",
    mb: 30,
  },
});

const StyledBox = styled(Box, {
  mt: 80,
  background: "$sand1",
  boxShadow: "$subtle",
  width: 450,
  p: 48,
  borderRadius: 8,
  zIndex: 2,

  ".wrapper": {
    mt: 48,
  },
});

const StyledLink = styled(Link, {
  textDecoration: "none",
  fontFamily: "$medium",
  color: "$primary",
});

export default function NotSupportedCountry() {
  const [{ data: countries, fetching }] = useCountriesQuery({
    variables: {
      all: true,
    },
  });
  const [countryData, setCountryData] = React.useState<any>();

  React.useEffect(() => {
    if (countries) {
      const countryCode = sessionStorage.getItem("signedUpCountry");
      const country = countries.countries.find(
        (country) => country.code === countryCode
      );
      setCountryData(country);
    }
  }, [countries]);
  return (
    <StyledContainer>
      <img className="bg" src={PatternBg} alt="" />
      <StyledBox>
        <Stack align="center" spacing={30}>
          <Avatar variant="solid" size={"2xl"} css={{ background: "$teal11" }}>
            <Text
              align="center"
              size="2xl"
              weight="semi"
              color="black"
              css={{ fontSize: 140 }}
            >
              {countryData?.emojiFlag}
            </Text>
          </Avatar>
          <Stack css={{ mt: 5 }} spacing={10}>
            <Text align="center" size="xl" weight="semi" color="black">
              We are not available in your country at the moment
            </Text>
            <Text
              align="center"
              size="xs"
              lineHeight="taller"
              color="light"
              weight="regular"
            >
              Float does not support operations in {countryData?.name} at the
              moment. We would notify you once we're live in {countryData?.name}
              .
            </Text>
          </Stack>
          <Button appearance="secondary" size={"md"} css={{ width: "100%" }}>
            <Text size="xs" color="white" weight="bold">
              Notify Me
            </Text>
          </Button>
          <Stack spacing={12} align="center">
            <Text size="xs" color="light" weight="regular">
              Already have an account?
              <StyledLink to="/"> Login </StyledLink>{" "}
            </Text>
          </Stack>
        </Stack>
      </StyledBox>

      <Stack className="footer" isInline spacing={10}>
        <Text size="xs" weight="regular" color="light">
          Swipe Technologies, Inc. &nbsp; •
        </Text>
        <Text size="xs" weight="regular" color="light" as={"a"} href="/">
          Privacy Policy &nbsp; •
        </Text>
        <Text size="xs" weight="regular" color="light" as={"a"} href="/">
          Platform Agreement
        </Text>
      </Stack>
    </StyledContainer>
  );
}
