import Box from "components/box";
import Header from "components/header";
import { Flex } from "components/layout";
import FixedPageContainer from "components/layout/fixed-page-container";
import Loader from "components/loader";
import React from "react";

type PageProps = {
  isLoading?: boolean;
  children?: React.ReactNode;
  allowMargin?: boolean;
  pageTitle?: React.ReactNode;
  showHeader?: boolean;
};

const Page = React.forwardRef<HTMLDivElement, PageProps>(
  (
    { isLoading, children, allowMargin = true, pageTitle, showHeader = true },
    ref
  ) => {
    if (isLoading) {
      return (
        <Flex align={"center"} justify="center" css={{ height: "90vh" }}>
          <Loader size={"sm"} color="$gray12"></Loader>
        </Flex>
      );
    }

    return (
      <Flex stack gap={7} css={{ marginBottom: allowMargin ? 100 : 0 }}>
        {showHeader && <Header pageTitle={pageTitle} />}

        <FixedPageContainer>{children}</FixedPageContainer>
      </Flex>
    );
  }
);

Page.displayName = "Page";

export default Page;
