import { styled } from "@stitches/react";
import Box from "components/box";
import { CSSProperties } from "react";

export const buttonDefaultStyles: CSSProperties = {
  borderRadius: "8px",
  height: "36px",
  fontSize: "14px",
};
export const StyledConfirmText = styled(Box, {
  fontSize: 16,
  color: "#90908C",
  lineHeight: "28px",
});
