import { Flex } from "components/layout";
import React from "react";
import Text from "components/text";
import Box from "components/box";
import useOutsideClick from "hooks/useOutsideClick";
import {
  HiOutlineExclamationCircle,
  HiOutlineInformationCircle,
} from "react-icons/hi";
import Avatar from "components/avatar";
import { motion } from "framer-motion";

const transition: { duration: number; ease: number[] } = {
  duration: 0.6,
  ease: [0.6, 0.01, -0.05, 0.9],
};

export default function InfoTooltip({
  children,
  title,
  description,
  containerRect,
}: {
  children: React.ReactNode;
  title: string;
  description: string;
  containerRect: DOMRect;
}) {
  return <Box css={{ width: "100%" }}>{children}</Box>;
}
