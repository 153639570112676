import React from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "components/avatar";
import Box from "components/box";
import Flex from "components/layout/flex";
import Stack from "components/layout/stack";
import Text from "components/text/text";
import { setToken } from "utils/auth";
import {
  StyledDropdownTrigger,
  StyledDropdownContent,
  StyledDropdown,
  StyledItem,
  Wrapper,
} from "./account-selector.styles";
import {
  useSwitchBusinessMutation,
  useLogoutMutation,
  User,
} from "generated/__generated_graphql";
import { useToast } from "components/toast/use-toast";
import Separator from "components/separator";
import {
  HiOutlineCog,
  HiOutlineLogout,
  HiOutlineSupport,
  HiPlus,
  HiSelector,
} from "react-icons/hi";
import { extractGraphqlErrors } from "utils/helpers";
import { wayFinder } from "utils/wayfinder";

interface AccountSelectorProps {
  size?: "sm" | "lg" | "md";
  user: User;
  extended?: boolean;
  onSwitchSuccess?: (val?: any) => void;
  renderSelector?(state?: any): React.ReactNode;
}

export const AccountSelector: React.FC<AccountSelectorProps> = ({
  size = "md",
  user,
  extended = true,
  onSwitchSuccess,
  renderSelector,
}) => {
  const navigate = useNavigate();
  const toast = useToast();
  const [open, setOpen] = React.useState(false);
  const [{ fetching }, switchBusiness] = useSwitchBusinessMutation();

  const businesses = user?.businesses || [];
  const currentBusiness = user?.business;
  const username = user?.name;
  const email = user?.email;

  async function onSwitchBusiness(id: number) {
    try {
      const response = await switchBusiness({ businessId: id });

      const errors = extractGraphqlErrors(response, "switchBusiness");

      if (errors) {
        return toast({ content: errors, status: "error" });
      }

      onSwitchSuccess?.(response.data?.switchBusiness);

      const path = wayFinder(response?.data?.switchBusiness?.user as User);

      window.location.href = path;
    } catch {
      toast({ content: "Something went wrong", status: "error" });
    }
  }

  return (
    <Box>
      <StyledDropdown open={open} onOpenChange={(value) => setOpen(value)}>
        <StyledDropdownTrigger asChild>
          {renderSelector ? (
            renderSelector()
          ) : (
            <Wrapper
              className="wrapper"
              wrap={"noWrap"}
              justify={"between"}
              align="center"
            >
              <Flex gap={3} align="center">
                <Avatar
                  variant="light"
                  size={size}
                  name={currentBusiness?.name.charAt(0) || "N"}
                />
                <Flex gap={1} stack>
                  <Text className="business-name" weight={"medium"} size="sm">
                    {currentBusiness?.name || "None"}
                  </Text>
                  {/* <Text
                    className="business-name"
                    color="light"
                    weight={"medium"}
                    size="xs"
                  >
                    {email}
                  </Text> */}
                </Flex>
              </Flex>

              <HiSelector
                style={{ flexShrink: 0 }}
                className="chevron"
              ></HiSelector>
            </Wrapper>
          )}
        </StyledDropdownTrigger>

        <StyledDropdownContent alignOffset={48} sideOffset={10} align="start">
          {/* selector head */}

          <Flex className="selector-header" align="center" justify="between">
            <Stack spacing={4} direction="column">
              <Flex gap={3}>
                <Avatar
                  size="sm"
                  variant="outline"
                  color="gray"
                  name={user.firstName}
                ></Avatar>
                <Flex gap={1} stack>
                  <Text size="sm" color="black">
                    {username}
                  </Text>
                  <Text weight={"regular"} size="xs" color="light">
                    {email}
                  </Text>
                </Flex>
              </Flex>
            </Stack>
          </Flex>

          {businesses?.length > 0 && (
            <>
              <Separator spacing={0}></Separator>

              <Box className="selector-businesses">
                {businesses
                  ?.sort((a, b) => (b.id === user?.business?.id ? 1 : -1))
                  .map((business) => (
                    <StyledItem
                      asChild
                      key={business.id}
                      onSelect={() => onSwitchBusiness(business.id)}
                      disabled={business.id === currentBusiness?.id || fetching}
                    >
                      <Flex align={"center"} gap={3}>
                        <Avatar
                          variant={"outline"}
                          name={business?.name.charAt(0) || "N"}
                          size="sm"
                        ></Avatar>
                        <Text size={"sm"} className="item-label">
                          {business.name}
                        </Text>
                      </Flex>
                    </StyledItem>
                  ))}
              </Box>

              <Separator spacing={0}></Separator>
            </>
          )}

          <Box className="selector-body">
            {/* <StyledItem onClick={routeTo}>
              <HiPlus className="item-icon" size={24} />
              <Text className="item-label">Add new business</Text>
            </StyledItem> */}

            {user.business?.state === "approved" && (
              <StyledItem
                onClick={() => {
                  setOpen(false);
                  navigate("/dashboard/settings/profile");
                }}
              >
                <HiOutlineCog className="item-icon" size={24} />
                <Text className="item-label">Settings</Text>
              </StyledItem>
            )}

            <a target={"_blank"} rel="noreferrer" href="https://help.float.co">
              <StyledItem>
                <HiOutlineSupport className="item-icon" size={24} />
                <Text className="item-label">Help Center</Text>
              </StyledItem>
            </a>
            <StyledItem onClick={() => navigate("/logout")}>
              <HiOutlineLogout className="item-icon" size={24} />
              <Text className="item-label">Logout</Text>
            </StyledItem>
          </Box>
        </StyledDropdownContent>
      </StyledDropdown>
    </Box>
  );
};

export default AccountSelector;
