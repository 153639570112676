import { Flex } from "components/layout";
import { TableDetailProps } from "components/table-detail/table-detail";
import React from "react";
import { ID } from "utils/types";
import Table from "./table";
import { ITable, TableRow } from "./table.types";

type TableWithDetailCardProps<T> = ITable & {
  detail?: any;
};

function TableWithDetailCard<T>({
  detail: Detail,
  columns,
  data,
  ...props
}: TableWithDetailCardProps<T>) {
  const [selectedRow, setSelectedRow] = React.useState<ID | null>(null);

  const visibleColumns = React.useMemo(() => {
    return selectedRow ? columns.slice(0, columns.length - 2) : columns;
  }, [selectedRow]);

  function onRowClick(e: any, row: TableRow<T>) {
    if (!Detail) return;
    setSelectedRow((prev) => (prev === row.id ? null : row.id));
  }

  const selected = React.useMemo(() => {
    return data.find((row) => row.id === selectedRow);
  }, [selectedRow, data]);

  return (
    <Flex gap={3} align="start">
      <Table
        data={data}
        columns={visibleColumns}
        css={{ flex: 1 }}
        onRow={{ click: onRowClick }}
        selectedRow={selectedRow}
        {...props}
      ></Table>
      {Detail && selectedRow && (
        <Flex
          stack
          className="table-detail"
          css={{
            top: 16,
            right: 0,
            width: "auto",
            minHeight: 200,
            position: "sticky",

            // position: "absolute",
            filter: "drop-shadow(0px 20px 48px rgba(0, 0, 0, 0.14))",
            background: "white",
            borderRadius: 6,
          }}
        >
          <Detail onClose={() => setSelectedRow(null)} data={selected}></Detail>
        </Flex>
      )}
    </Flex>
  );
}

TableWithDetailCard.displayName = "TableWithDetailCard";

export default TableWithDetailCard;
