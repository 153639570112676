export function runAnalytics(user: any) {
  window.analytics?.identify(`${user?.id}`, {
    name: user?.name,
    email: user?.email,
    business: {
      id: `${user?.business?.id}`,
      name: user?.business?.name,
    },
    logins: 5,
  });
}
