import React from "react";
import Button from "components/button";
import { Flex } from "components/layout";
import Loader from "components/loader";
import Modal from "components/modal";
import { useStepModal } from "components/modal/step-modal";
import PinCodeInput from "components/pin-code-input";
import Separator from "components/separator";
import Sticker from "components/sticker";
import Text from "components/text";
import { useToast } from "components/toast";
import {
  useConfirmMfaTokenMutation,
  useGetAuthenticatorConfigQuery,
} from "generated/__generated_graphql";
import { extractGraphqlErrors } from "utils/helpers";

type Props = {
  onSuccess?: () => void;
};
export default function AuthenticatorSetup({ onSuccess }: Props) {
  const context = useStepModal();
  const notify = useToast();
  const [token, setToken] = React.useState("");

  const [{ fetching, data, error }, refetch] = useGetAuthenticatorConfigQuery();
  const [{ fetching: confirming }, confirmToken] = useConfirmMfaTokenMutation();

  const qrImage = data?.getAuthenticatorConfig?.qrCode;
  const secret = data?.getAuthenticatorConfig?.secretCode;

  async function onConfirm() {
    try {
      const response = await confirmToken({ type: "app" as any, token });
      const errors = extractGraphqlErrors(response, "confirmMfaToken");
      if (errors) {
        notify({ content: errors, position: "top", status: "error" });
        return;
      }

      notify({
        content: "Two Factor Authentication has been enabled",
        position: "top",
        status: "dark",
        closable: false,
        showIcon: true,
      });
      onSuccess?.();
    } catch (error) {
      console.error(error);
    }
  }

  if (error || fetching) {
    return (
      <Modal.Body css={{ width: 540 }}>
        <Flex stack align="center" justify={"center"} css={{ height: 400 }}>
          {fetching && <Loader size="xs" color="$gray12" />}

          {error && !fetching && (
            <Flex stack gap={2}>
              <Text align={"center"}>Failed to load data</Text>
              <Button
                isLoading={fetching}
                onClick={() => refetch()}
                size="sm"
                appearance={"ghost"}
              >
                Try again
              </Button>
            </Flex>
          )}
        </Flex>
      </Modal.Body>
    );
  }

  return (
    <>
      <Modal.Body css={{ width: 540 }}>
        <Flex stack>
          <Flex stack gap={2}>
            <Text color="black">Install an authenticator app</Text>
            <Text lineHeight={"tall"} size="xs" color="light">
              Use any authenticator app that you have. If you don’t have one
              installed, we recommend Authy.
            </Text>
          </Flex>

          <Separator />

          <Flex stack gap={6}>
            <Flex stack gap={2}>
              <Text color="black">Scan the QR Code</Text>
              <Text lineHeight={"tall"} size="xs" color="light">
                Open the authenticator app and scan the image below using your
                phone's camera.
              </Text>
            </Flex>

            <Flex gap={4} align="center">
              {qrImage ? <img width={120} src={qrImage} alt="" /> : null}

              <Flex align={"start"} stack gap={2}>
                <Text lineHeight={"tall"} size="xs" color="light">
                  If you can’t access your camera, manually enter this key.
                </Text>

                <Sticker
                  css={{ border: "none" }}
                  variant={"light"}
                  color="blue"
                >
                  <Text
                    fontFamily={"monospace"}
                    align={"center"}
                    css={{
                      width: "100%",
                      letterSpacing: 1.5,
                      "&:hover": { textDecoration: "underline" },
                    }}
                    color="inherit"
                  >
                    {secret}
                  </Text>
                </Sticker>
              </Flex>
            </Flex>
          </Flex>

          <Separator />

          <Flex stack gap={6}>
            <Flex stack gap={2}>
              <Text color="black">Verify code</Text>
              <Text size="xs" color="light">
                Enter the 6-digit verification code generated.
              </Text>
            </Flex>

            <PinCodeInput
              onChange={(value) => setToken(value)}
              size="md"
              css={{ width: 300 }}
              inputMode="tel"
              type="number"
              name="as"
              fields={6}
            />
          </Flex>
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Cancel onClick={() => context.goToScreen("auth-type")}>
          Go back
        </Modal.Cancel>
        <Modal.Confirm
          onClick={onConfirm}
          disabled={token.length !== 6}
          isLoading={confirming}
        >
          Submit token
        </Modal.Confirm>
      </Modal.Footer>
    </>
  );
}
