import React from "react";
import { styled } from "stitches/stitches.config";
import { range } from "utils/array";

type MeterProps = {
  count?: number;
  progress?: number;
  activeColor?: string;
} & React.ComponentProps<typeof Svg>;

const Rect = styled("rect", {
  fill: "$gray5",
  width: 2,
  display: "flex",
  height: "100%",

  variants: {
    active: {
      true: {
        fill: "$gray11",
      },
    },
  },
});

const Svg = styled("svg", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

const Meter = React.forwardRef<SVGSVGElement, MeterProps>(
  ({ count = 50, progress = 0, activeColor, ...props }, ref) => {
    const numberofActiveRects = Math.floor((progress / 100) * count);

    return (
      <Svg {...props} ref={ref} height={8}>
        {range(1, numberofActiveRects).map((i) => {
          return (
            <Rect
              rx={1}
              ry={1}
              active={true}
              key={i}
              x={i * 4}
              fill={activeColor}
            />
          );
        })}
        {range(1, count - numberofActiveRects).map((i) => {
          return (
            <Rect rx={1} ry={1} key={i} x={(numberofActiveRects + i) * 4} />
          );
        })}
      </Svg>
    );
  }
);

Meter.displayName = "Meter";

export default Meter;
