import Box from "components/box";
import { Flex } from "components/layout";
import MenuBar from "components/menu-bar";
import { styled } from "stitches/stitches.config";
import { ILayoutProps } from "utils/types";

const sidebarWidth = 240;

const PageContainer = styled(Flex, {
  background: "$gray12",
  height: "100vh",
  // p: 16,
  overflow: "hidden",
});

const FixedContainer = styled(Box, {
  width: 60,
  // marginRight: 16,
  flexShrink: 0,

  "@lg": { width: sidebarWidth, display: "block" },
});
const SidebarContainer = styled(Box, {
  position: "fixed",
  width: 60,

  "@lg": { width: sidebarWidth, display: "block" },
});
const MainContainer = styled(Box, {
  background: "$gray2",
  // borderRadius: 16,
  overflow: "scroll",
  width: `calc(100vw - ${60}px)`,

  "@lg": { width: `calc(100vw - ${sidebarWidth}px)` },
});

export default function PageWithSidebarLayout({ children }: ILayoutProps) {
  return (
    <PageContainer>
      <FixedContainer className="sidebar-container">
        <SidebarContainer>
          <MenuBar></MenuBar>
        </SidebarContainer>
      </FixedContainer>
      <MainContainer className="main-conatiner">{children}</MainContainer>
    </PageContainer>
  );
}
