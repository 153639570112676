import axios from "axios";
import { Flex } from "components/layout";
import Loader from "components/loader";
import TransactionTimeline from "components/move-money/components/transaction-timeline";
import PaymentIndicator from "components/payment-indicator";
import Separator from "components/separator";
import TableDetail from "components/table-detail";
import { TableDetailProps } from "components/table-detail/table-detail";
import Text from "components/text";
import { useToast } from "components/toast";
import { JournalEntry } from "generated/__generated_graphql";
import useRestQuery from "hooks/use-rest-query";
import React from "react";
import {
  HiArrowSmDown,
  HiArrowSmUp,
  HiOutlineDocument,
  HiOutlineDocumentDownload,
  HiOutlineDocumentText,
  HiOutlineReceiptTax,
  HiX,
} from "react-icons/hi";
import { getToken } from "utils/auth";
import { baseServerUrl, downloadFileFromUrl, formatMoney } from "utils/helpers";
import { ActionStatus } from "utils/types";

type TransactionDetailProps = TableDetailProps<JournalEntry>;

const TransactionDetail = React.forwardRef<
  HTMLDivElement,
  TransactionDetailProps
>(({ data: transaction, ...props }, ref) => {
  const isCredit = transaction?.transactionType === "credit";
  const isDebit = transaction?.transactionType === "debit";
  const isRefund = !!transaction?.originalEntryId;
  const isCard = transaction?.paymentMethod === "card";

  const [downloadStatus, setDownloadStatus] =
    React.useState<ActionStatus>("idle");

  const allowReceiptDownload =
    isDebit && !isCard && !!transaction.moneyTransfer;

  const url = `${baseServerUrl()}/receipt/${transaction?.id}`;
  const notify = useToast();

  async function downloadReceipt() {
    setDownloadStatus("loading");
    try {
      const response = await axios.get(url, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });

      const a = document.createElement("a");
      a.className = "download-link";
      a.download = `float-receipt${transaction?.reference}`;
      const blob = new Blob([response.data], { type: "application/pdf" });
      a.href = URL.createObjectURL(blob);
      a.click();

      setDownloadStatus("idle");

      notify({ content: "Receipt downloaded" });
    } catch (error) {
      notify({ content: "Unable to download receipt", status: "error" });
      setDownloadStatus("idle");
    }
  }

  if (!transaction) return null;

  return (
    <TableDetail
      actions={
        <Flex gap={2}>
          {allowReceiptDownload && (
            <TableDetail.Action>
              {downloadStatus === "loading" ? (
                <Loader size="xs" color="$gray10" />
              ) : (
                <HiOutlineDocumentDownload
                  onClick={downloadReceipt}
                  size={20}
                />
              )}
            </TableDetail.Action>
          )}
        </Flex>
      }
      {...props}
    >
      <Flex css={{ width: 440, flexShrink: 0 }} stretchX stack gap={8}>
        <Flex gap={8} css={{ px: 24, py: 24 }} stack>
          <Flex justify={"between"}>
            <Flex stack gap={2}>
              <Text case={"capitalize"} color="light" size="xs">
                {isRefund
                  ? "Refund"
                  : transaction?.paymentMethod?.split("_").join(" ")}
              </Text>
              <Text size="2xl" color="black" fontFamily={"haffer"}>
                {formatMoney(transaction?.amount)}
              </Text>
            </Flex>

            <PaymentIndicator useIcon size={"xl"} transaction={transaction!} />
          </Flex>

          <TransactionTimeline transaction={transaction} />
        </Flex>
      </Flex>
    </TableDetail>
  );
});

TransactionDetail.displayName = "TransactionDetail";

export default TransactionDetail;
