import { CSS, css, styled } from "stitches/stitches.config";
import Box from "components/box";
import { gray } from "@radix-ui/colors";

export const autoFillSelectors =
  "input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus,input:-internal-autofill-selected";

export const autoFillResetStyles = (bgColor: string): CSS => ({
  webkitTextFillColor: "$gray12",
  webkitBoxShadow: `0 0 0px 1000px ${bgColor} inset`,
  boxShadow: `0 0 0px 1000px ${bgColor} inset`,
});

export const SharedInputStyles = css({
  borderRadius: "$1",
  width: "100%",
  overflow: "hidden",
  transition: "all 0.3s ease",
  display: "flex",
  alignItems: "center",

  ".input-el": {
    fontFamily: "$medium",
    fontWeight: 500,
    fontSize: "inherit",
    color: "$gray12",
    width: "100%",
    border: "none",
    background: "transparent",
    appearance: "none",
    height: "100%",
    padding: 0,
    "&:focus": { outline: "none" },
  },

  defaultVariants: {
    appearance: "outline",
    size: "md",
  },
  variants: {
    size: {
      xs: {
        minHeight: 24,
        fontSize: "$2",

        ".input-el": {
          minHeight: 20,
        },
      },
      sm: {
        minHeight: 32,
        fontSize: "$3",

        ".input-el": {
          minHeight: 28,
        },
      },
      md: {
        minHeight: 40,
        fontSize: "$3",
        ".input-el": {
          minHeight: 36,
        },
      },
      lg: {
        minHeight: 44,
        fontSize: "$4",
        ".input-el": {
          minHeight: 40,
        },
      },
      xl: {
        minHeight: 60,
        fontSize: 16,

        ".input-el": {
          minHeight: 56,
        },
      },
    },
    hasError: {
      true: {
        borderColor: "$tomato7 !important",
        background: "$tomato2",
        "&:focus-within": { boxShadow: "$focusRingError" },
      },
    },
    appearance: {
      outline: {
        background: "white",
        border: "2px solid $gray3",
        // boxShadow: "$subtle",

        "&:focus-within": {
          boxShadow: "$focusRing",
          borderColor: "$gray4",
        },
      },
      flushed: {
        background: "$gray2",
        color: "$gray12",
        border: "1.5px solid $gray2",

        "&:focus-within": {
          boxShadow: "$focusRing",
          border: "1.5px solid $green7",
          backgroundColor: "white",

          [autoFillSelectors]: autoFillResetStyles("white"),
        },

        [autoFillSelectors]: autoFillResetStyles(gray.gray2),
      },
      ghost: {
        background: "none",
        border: "none",

        "&:focus-within": {
          boxShadow: "none",
          borderColor: "none",
        },
      },
    },

    isDisabled: { true: {} },
  },
  compoundVariants: [
    {
      isDisabled: true,
      appearance: "outline",
      css: {
        background: "$gray2",

        ".input-el": { color: "$gray8" },
      },
    },

    {
      isDisabled: true,
      appearance: "flushed",
      css: {
        background: "$gray2",
        color: "$gray9",

        ".input-el": { color: "$gray9" },
      },
    },
  ],
});

export const StyledContainer = styled(Box, {
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "$2",
  // position: "relative",
});

export const StyledInputWrapper = styled("div", SharedInputStyles, {
  position: "relative",
  px: 12,
  ".password-toggle": {
    height: "inherit",
    width: "100%",
    appearance: "none",
    background: "none",
    border: "none",
    fontSize: "inherit",
    fontFamily: "$medium",
    color: "$gray9",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "capitalize",
    fontWeight: 600,
    px: "$3",
    "&:focus": { color: "$green10" },
  },
});

export const StyledInput = styled("input", {
  "&::placeholder": { color: "$gray8" },
});
