import Box from "components/box";
import { Flex } from "components/layout";
import Tooltip from "components/tooltip";
import useCopyToClipboard from "hooks/use-copy-to-clipboard";
import React from "react";

type Props = React.ComponentProps<typeof Box> & { value?: string };

const CopyWrapper = React.forwardRef(
  ({ children, value, ...props }: Props, ref) => {
    const [copyText, setCopyText] = React.useState("Click to copy");
    const [open, setOpen] = React.useState(false);

    const copy = useCopyToClipboard();

    function copyValue() {
      if (value) {
        copy(value, "copied", false);
        setCopyText("Copied!");
      }
    }

    React.useEffect(() => {
      if (!open) {
        setCopyText("Click to copy");
      }
    }, [open]);

    return (
      <Flex
        onMouseEnter={() => !!value && setOpen(true)}
        onMouseLeave={() => open && setOpen(false)}
        onClick={copyValue}
        {...props}
      >
        <Tooltip open={open} content={copyText}>
          <>{children}</>
        </Tooltip>
      </Flex>
    );
  }
);

CopyWrapper.displayName = "CopyWrapper";

export default CopyWrapper;
