import { Flag } from "components/flags";
import React from "react";
import { styled } from "stitches/stitches.config";

const Wrapper = styled("span", {
  flexShrink: 0,
});

type CountryChipProps = React.ComponentProps<typeof Wrapper> & {
  country?: keyof typeof Flag;
  size?: number | string;
};

const CountryChip = React.forwardRef<HTMLDivElement, CountryChipProps>(
  ({ children, country = "US", size = 16, ...props }, ref) => {
    const _Flag = Flag[country] || Flag.US;

    return (
      <Wrapper css={{ svg: { width: size } }} ref={ref} {...props}>
        <_Flag />
      </Wrapper>
    );
  }
);

CountryChip.displayName = "CountryChip";
export default CountryChip;
