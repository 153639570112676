import React from "react";
import { cx } from "utils/dom";
import Flex from "../layout/flex";
import { AddOn, Wrapper } from "./append-prepend-wrapper.styles";
import { IProps } from "./append-prepend-wrapper.types";

const AppendPrependWrapper = React.forwardRef<
  HTMLDivElement,
  IProps & React.ComponentProps<typeof Wrapper>
>(({ append, prepend, children, className, align }, ref) => {
  return (
    <Wrapper
      align={align}
      ref={ref}
      className={cx([className, "append-prepend-wrapper"])}
    >
      {prepend && <AddOn className="add-on prepend">{prepend}</AddOn>}
      {children}
      {append && <AddOn className="add-on append">{append}</AddOn>}
    </Wrapper>
  );
});

AppendPrependWrapper.displayName = "AppendPrependWrapper";

export default AppendPrependWrapper;
