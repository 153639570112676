import Box from "components/box";
import EmptyState from "components/empty-state";
import { Flex, Stack } from "components/layout";
import React from "react";
import { DataCell, HeaderCell } from "./components/table-cell";
import TableControls from "./components/table-controls";
import TableRow from "./components/table-row";
import { TableCard, TableElement } from "./table.styles";
import { ITable } from "./table.types";
import Pagination from "components/pagination/pagination";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { range } from "utils/array";
import Separator from "components/separator";
import Text from "components/text";
import { CSS } from "stitches/stitches.config";
import TransactionDetail from "components/transaction-detail";
import { ID } from "utils/types";
import TableDetail from "components/table-detail";
import { HiArrowSmDown, HiArrowSmUp, HiX } from "react-icons/hi";
import useTable from "./use-table";

const TableComp = React.forwardRef<HTMLDivElement, ITable & { css?: CSS }>(
  (
    {
      columns,
      data,
      loading,
      rowActions,
      emptyStateProps,
      title,
      css,
      renderRow,
      DetailComponent,
      onRow,
      selectedRow,
    },
    ref
  ) => {
    const { rows, headings } = useTable({ data, columns });

    const emptyStateData = {
      description: "There's nothing to see here",
      ...emptyStateProps,
    };

    return (
      <Box stretchX css={css} ref={ref}>
        <Flex align={"start"} position={"relative"}>
          <TableCard className="table-card">
            <TableElement>
              <thead>
                <TableRow isHeader className="table-header-row">
                  {headings.map((heading, index) => {
                    return (
                      <HeaderCell className="header-cell" key={index}>
                        {heading.render}
                      </HeaderCell>
                    );
                  })}
                  {rowActions && (
                    <HeaderCell className="header-cell"></HeaderCell>
                  )}
                </TableRow>
              </thead>
              <tbody>
                {loading && (
                  <>
                    {range(0, 10).map((i) => (
                      <TableRow key={i}>
                        {headings.map((heading, index) => {
                          return (
                            <DataCell key={index} css={{ mb: 10, py: 12 }}>
                              <Skeleton height={10} />
                            </DataCell>
                          );
                        })}
                      </TableRow>
                    ))}
                  </>
                )}

                {!loading &&
                  rows.map((row, index) => {
                    const isCellInLastRow: boolean = index === data.length - 1;

                    if (renderRow) {
                      return renderRow(row, index, isCellInLastRow);
                    }

                    return (
                      <TableRow
                        selected={selectedRow === row.id}
                        onClick={(e) => onRow?.click?.(e, row)}
                        className="table-row"
                        key={index}
                      >
                        {row.cells.map((cell) => {
                          return (
                            <DataCell
                              className="data-cell"
                              key={cell.column}
                              isCellInLastRow={isCellInLastRow}
                            >
                              {cell.render}
                            </DataCell>
                          );
                        })}
                        {rowActions && (
                          <DataCell
                            isCellInLastRow={isCellInLastRow}
                            className="data-cell"
                            css={{
                              textAlign: "right",
                            }}
                          >
                            {rowActions?.(row)}
                          </DataCell>
                        )}
                      </TableRow>
                    );
                  })}
              </tbody>
            </TableElement>

            {!loading && data.length === 0 && (
              <Flex css={{ minHeight: 200 }} align="center" justify={"center"}>
                <EmptyState {...emptyStateData}></EmptyState>
              </Flex>
            )}
          </TableCard>
        </Flex>
      </Box>
    );
  }
);

TableComp.displayName = "Table";

const Table = TableComp as typeof TableComp & {
  Row: typeof TableRow;
  Cell: typeof DataCell;
  HeaderCell: typeof HeaderCell;
};

export default Table;

Table.Row = TableRow;
Table.Cell = DataCell;
Table.HeaderCell = HeaderCell;
