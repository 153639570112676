import { Flex } from "components/layout";
import { REVERSAL_NARRATION } from "components/move-money/move-money.utils";
import PaymentIndicator from "components/payment-indicator";
import Table, { ITableColumn } from "components/table";
import TableWithDetailCard from "components/table/table-with-detail-card";
import Tag from "components/tag";
import Text from "components/text";
import TransactionDetail from "components/transaction-detail";
import { format } from "date-fns";
import { JournalEntry } from "generated/__generated_graphql";
import React from "react";
import {
  HiArrowLeft,
  HiArrowRight,
  HiGlobeAlt,
  HiLibrary,
  HiOutlineCreditCard,
  HiOutlineRewind,
} from "react-icons/hi";
import { formatMoney } from "utils/helpers";

function getTransactionPaymentMethod(txn: JournalEntry) {
  const isCredit = txn.transactionType === "credit";

  if (!!txn.originalEntryId) {
    return (
      <>
        <HiOutlineRewind size={20} />
        <Text>Refund</Text>
      </>
    );
  }

  if (txn.paymentMethod === "card") {
    const card = txn?.cardEvent?.card;
    return (
      <>
        <HiOutlineCreditCard size={16} />
        <Text>
          {card?.nickname} •• {card?.lastFour}
        </Text>
      </>
    );
  }

  if (txn.paymentMethod === "ach") {
    return (
      <>
        {isCredit ? <HiArrowRight /> : <HiArrowLeft />}
        <Text>ACH {isCredit ? "In" : "Out"}</Text>
      </>
    );
  }

  if (txn.paymentMethod === "domestic_wire" || txn.paymentMethod === "bank") {
    return (
      <>
        {isCredit ? <HiArrowRight /> : <HiArrowLeft />}
        <Text>Domestic Wire</Text>
      </>
    );
  }

  if (txn.paymentMethod === "international_wire") {
    return (
      <>
        <HiGlobeAlt></HiGlobeAlt>
        <Text>International Wire</Text>
      </>
    );
  }

  return (
    <>
      <HiLibrary />
      <Text>Wire</Text>
    </>
  );
}

const columns: Array<ITableColumn<JournalEntry>> = [
  {
    dataIndex: "createdAt",
    title: "Date",
    key: "createdAt",
    render: (value, transaction) => {
      const date =
        transaction.transactionType === "credit"
          ? transaction.createdAt
          : transaction.createdAt;
      return <Text color="light">{format(new Date(date), "MMM d")}</Text>;
    },
  },

  {
    dataIndex: "destination",
    title: "From / To",
    key: "destination",
    render: (_, transaction) => {
      return (
        <Flex css={{ py: 5 }} align={"center"} gap={3}>
          <PaymentIndicator
            size="md"
            transaction={transaction}
          ></PaymentIndicator>
          <Text color="black">
            {transaction.transactionType === "credit"
              ? transaction.origin.name
              : `${transaction.destination.name}`}
          </Text>

          {["cancelled", "returned"].includes(transaction.state!) && (
            <Tag
              css={{ background: "white" }}
              variant={"light"}
              color="tomato"
              size="2xs"
            >
              {transaction.state}
            </Tag>
          )}

          {!!transaction.originalEntryId &&
            transaction.transactionType === "credit" && (
              <Tag
                css={{ background: "white" }}
                variant={"light"}
                color="cyan"
                size="2xs"
              >
                Refund
              </Tag>
            )}
        </Flex>
      );
    },
  },

  {
    dataIndex: "amount",
    title: "Amount",
    key: "amount",
    render: (value, transaction) => (
      <Text
        // weight={"regular"}
        numeric
        color={transaction.transactionType === "credit" ? "primary" : "dark"}
        size="sm"
      >
        {formatMoney(value)}
      </Text>
    ),
  },

  {
    dataIndex: "balance",
    title: "Balance",
    key: "balance",
    render: (value) => (
      <Text numeric weight={"medium"} color="light" size="sm">
        {formatMoney(value)}
      </Text>
    ),
  },

  // {
  //   dataIndex: "reference",
  //   title: "Reference",
  //   key: "reference",
  //   render: (value) => (
  //     <Text color="light" fontFamily={"monospace"}>
  //       {value}
  //     </Text>
  //   ),
  // },

  {
    dataIndex: "paymentMethod",
    title: "Payment Method",
    key: "paymentMethod",
    render: (_, txn) => (
      <Flex align="center" gap={1} css={{ color: "$gray8" }}>
        {getTransactionPaymentMethod(txn)}
      </Flex>
    ),
  },
];

type RecentTransactionsProps = {
  transactions?: Array<JournalEntry>;
};

const RecentTransactions = React.forwardRef<
  HTMLDivElement,
  RecentTransactionsProps
>(({ transactions = [] }, ref) => {
  return (
    <TableWithDetailCard
      detail={TransactionDetail}
      title="Pending Transactions"
      data={transactions}
      columns={columns}
    />
  );
});

RecentTransactions.displayName = "Recent Transactions";

export default RecentTransactions;
