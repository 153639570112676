import { Flex } from "components/layout";
import { MenuItemProps } from "components/menu-item";
import Tooltip from "components/tooltip";
import React from "react";
import { Link } from "react-router-dom";
import { styled, VariantProps } from "stitches/stitches.config";

const Wrapper = styled(Link, {
  color: "white",
  size: 36,
  borderRadius: 12,
  background: "$whiteA4",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  variants: {
    active: {
      true: {
        background: "$whiteA8",
      },
    },
  },
});

type Props = MenuItemProps & VariantProps<typeof Wrapper>;
const MenuTile = React.forwardRef<HTMLDivElement, Props>(
  ({ data, active }, ref) => {
    const Icon = data?.icon;
    return (
      <Tooltip placement="right" content={data.label}>
        <Flex align="center" justify={"center"} ref={ref}>
          <Wrapper active={active} to={data.path ?? ""}>
            {Icon ? <Icon size={20} /> : null}
          </Wrapper>
        </Flex>
      </Tooltip>
    );
  }
);

MenuTile.displayName = "MenuTile";

export default MenuTile;
