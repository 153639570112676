import { toast } from "./toast-manager";
import { ToastCreateOptions } from "./toast.types";

export function useToast() {
  const toastClosure = (toastOptions: ToastCreateOptions) => {
    toast.show(toastOptions);
  };

  toastClosure.destroy = toast.destroy;

  return toastClosure;
}
