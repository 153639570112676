import Box from "components/box";
import { Flex } from "components/layout";
import Text from "components/text";
import React from "react";
import {
  Actions,
  StyledSubtitle,
  StyledTitle,
  Wrapper,
} from "./page-title.styles";
import { PageTitleProps } from "./page-title.types";

const PageTitle = React.forwardRef<HTMLDivElement, PageTitleProps>(
  ({ title, subTitle, adjacentActions, css, size, actions }, ref) => {
    return (
      <Wrapper
        className="page-title"
        align="center"
        justify="between"
        css={css}
        ref={ref}
      >
        <Box>
          <Flex justify="start" align="center">
            {title && (
              <Text color={"black"} size={size}>
                {title}
              </Text>
            )}
            {adjacentActions}
          </Flex>
          {subTitle && <StyledSubtitle size={size}>{subTitle}</StyledSubtitle>}
        </Box>
        <Actions align="center" justify="end">
          {actions}
          {/* {! && <PendingActionsNotifier />} */}
        </Actions>
      </Wrapper>
    );
  }
);

PageTitle.displayName = "PageTitle";

export default PageTitle;
