import Button from "components/button";
import { Flex, Grid, GridItem } from "components/layout";

import {
  BankAccount,
  JournalEntry,
  useAccountPageQuery,
} from "generated/__generated_graphql";

import Modal from "components/modal";
import Page from "components/page/page";
import Text from "components/text";
import AccountBalanceCard from "pages/accounts/components/account-balance-card";
import AccountDetailCard from "pages/accounts/components/account-detail-card";
import PendingTransactions from "pages/accounts/components/pending-transactions";
import RecentTransactions from "pages/accounts/components/recent-transactions";
import { useAppProvider } from "providers/app-provider/app-provider";
import { isAfter, isBefore } from "date-fns";
import { splitScheduled } from "components/move-money/move-money.utils";
import { useParams } from "react-router-dom";

const AccountPage = () => {
  const params = useParams();

  const [{ fetching, data }] = useAccountPageQuery({
    variables: {
      accountId: params?.id as string,
    },
  });

  const account = data?.getBankAccountByUuid as BankAccount;
  const transactions =
    (data?.getBankAccountByUuid?.journalEntries as Array<JournalEntry>) || [];

  const scheduledTransactions = splitScheduled(transactions).scheduled;
  const nonScheduledTransactions = splitScheduled(transactions).nonScheduled;

  const balances = data?.getLast30DaysBalances;

  return (
    <Page isLoading={fetching} pageTitle={`${account?.name}`}>
      <Flex stack gap={8}>
        <Grid>
          <GridItem colSpan={{ "@initial": 12, "@sm": 12, "@xl": 12 }}>
            <AccountBalanceCard
              balances={balances}
              bankAccount={account}
            ></AccountBalanceCard>
          </GridItem>

          {/* <GridItem colSpan={{ "@sm": 5, "@xl": 4 }}>
            <AccountDetailCard bankAccount={account}></AccountDetailCard>
          </GridItem> */}
        </Grid>

        {scheduledTransactions.length > 0 && (
          <Flex stack gap={5}>
            <Text color="black" size="md">
              Scheduled Payments
            </Text>
            <PendingTransactions transactions={scheduledTransactions} />
          </Flex>
        )}

        <Flex stack gap={5}>
          <Text color="black" size="md">
            Recent Transactions
          </Text>
          <RecentTransactions transactions={nonScheduledTransactions} />
        </Flex>
      </Flex>
    </Page>
  );
};

export default AccountPage;
