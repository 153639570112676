import Avatar from "components/avatar";
import Box from "components/box";
import { Flex, Stack } from "components/layout";
import Text from "components/text";
import { styled } from "stitches/stitches.config";
import Tag from "components/tag";
import Accordion from "components/accordion";
import { HiOutlineClock, HiOutlineExclamationCircle } from "react-icons/hi";
import { useNavigate } from "react-router";
import { useProfileQuery, User } from "generated/__generated_graphql";
import Page from "components/page/page";
import AccountSelector from "components/account-selector";
import floatLogo from "assets/images/float-logo-final.svg";
import Separator from "components/separator";
import { cyan, gray } from "@radix-ui/colors";
import useWayFinder from "hooks/use-way-finder";
import Logo from "components/logo";

const Wrapper = styled(Box, {
  background: "$gray1",
  height: "100vh",

  ".approval-header": {
    padding: "15px 60px",
    background: "White",
    marginBottom: 100,
  },
  ".content-wrapper": {
    width: 900,
    margin: "0 auto",
  },
  ".message": {
    px: 24,
    py: 24,
    background: "White",
    boxShadow: "$subtle",
    borderRadius: 12,
  },
  ".logo": {
    width: 42,
    marginBottom: 18,
  },
  ".faq": {
    padding: "32px",
    background: "White",
    boxShadow: "$subtle",
    borderRadius: 8,
  },
});

const faqs = [
  {
    value: "activation-period",
    title: "How long will my account activation take?",
    description:
      "It usually takes less than 24 hours to approve accounts. However due to a huge backlog of applications, we may take a little longer to approve your account.",
  },
  {
    value: "pre-activation",
    title: "Do I need to do anything while I wait?",
    description:
      "You only have to keep running your business as usual. You can also get your other business bank accounts ready to add on to your dashboard in the meantime.",
  },
  {
    value: "post-activation",
    title: "What do I do after my account is activated?",
    description:
      "You will receive an email once your account is active. After you log in, you should have access to a complete dashboard. You can apply for transaction limit increases and credit.",
  },
];

const WaitApproval: React.FC = () => {
  const navigate = useNavigate();
  const [{ fetching, data }] = useProfileQuery();

  useWayFinder(data?.profile as User);

  return (
    <Page showHeader={false} isLoading={fetching} allowMargin={false}>
      <Wrapper>
        <Flex className="approval-header" justify="between" align="center">
          <Logo width={140} height={100} />
          <AccountSelector user={data?.profile as User} extended={false} />
        </Flex>

        <Flex
          direction={"column"}
          align="center"
          justify="center"
          className="content-wrapper"
        >
          <Flex className="message" direction="column" gap={4} align="start">
            <Flex gap={2} align={"center"}>
              <Tag
                prependIcon={<HiOutlineClock size="18" />}
                color="cyan"
                variant="light"
                shape={"pill"}
                size="sm"
              >
                Under review
              </Tag>
            </Flex>

            <Flex direction={"column"} gap={2}>
              <Text color="black" size="sm">
                Your application is being reviewed
              </Text>
              <Text
                size="sm"
                color="light"
                weight={"regular"}
                lineHeight="tall"
              >
                We are reviewing your account information and will get in touch
                with you shortly. This generally takes 24 hours, but in rare
                cases can take up to 2 working days. Need help?{" "}
                <Text size="inherit" as="span" color="primary">
                  Contact support
                </Text>
              </Text>
            </Flex>
          </Flex>

          {/* <Separator spacing={32}></Separator> */}

          {/* <Flex stretchX direction={"column"} gap={3}>
            <Flex gap={2} align="center">
       
              <Text size="md">Frequently Asked Questions</Text>
            </Flex>

            <Box stretchX className="faq">
              <Accordion
                spacing="md"
                type="multiple"
                defaultValue={["activation-period"]}
                tabs={faqs.map((faq) => {
                  return {
                    value: faq.value,
                    title: (
                      <Text color="black" size="sm">
                        {faq.title}
                      </Text>
                    ),
                    content: (
                      <Text
                        color="light"
                        size="sm"
                        lineHeight="tall"
                        css={{ mt: 8 }}
                        weight="regular"
                      >
                        {faq.description}
                      </Text>
                    ),
                  };
                })}
              />
            </Box>
          </Flex> */}

          <Stack
            color="light"
            isInline
            justify="center"
            align="center"
            spacing={5}
            css={{ marginTop: 150, paddingBottom: 50 }}
          >
            <Text size="xs" color="light">
              © Swipe Technologies, Inc
            </Text>
            <Text size="xs" color="light">
              .
            </Text>
            <Text
              color="light"
              size="xs"
              as={"a"}
              href="https://float.co/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </Text>
            <Text size="xs" color="light">
              .
            </Text>
            <Text
              color="light"
              size="xs"
              as={"a"}
              href="https://float.co/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              Platform Agreement
            </Text>
          </Stack>
        </Flex>
      </Wrapper>
    </Page>
  );
};

export default WaitApproval;
