import Flex from "components/layout/flex";
import { styled } from "stitches/stitches.config";

export const Wrapper = styled(Flex, {
  width: "100%",
  pt: 20,
  pb: 24,
  defaultVariants: { margin: "marginBottom" },
});

export const StyledTitle = styled("h1", {
  fontFamily: "$medium",
  fontSize: 20,
  color: "$gray12",
  marginLeft: 0,
  letterSpacing: 0.3,
  variants: {
    size: {
      xl: { fontSize: 24 },
      lg: { fontSize: 18 },
      md: { fontSize: 16 },
      sm: { fontSize: 14 },
    },
  },
  defaultVariants: { size: "lg" },
});

export const StyledSubtitle = styled("p", {
  color: "$gray9",
  fontSize: 14,
  letterSpacing: 0.3,
  margin: 0,
  variants: {
    size: {
      xl: { marginTop: 10 },
      lg: { marginTop: 10 },
      md: { marginTop: 5 },
      sm: { marginTop: 0 },
    },
  },
  defaultVariants: { size: "lg" },
});

export const Actions = styled(Flex, {});
