import Box from "components/box";
import Button from "components/button";
import Input from "components/input";
import { Flex, Stack } from "components/layout";
import Loader from "components/loader";
import ActionMessage from "components/message/action-message";
import Modal, { ModalProps } from "components/modal";
import StepModal from "components/modal/step-modal";
import NativeSelect from "components/native-select";
import SelectExperimental from "components/select/select-experimental";
import Separator from "components/separator";
import Sticker from "components/sticker";
import Tag from "components/tag";
import Text from "components/text";
import { useToast } from "components/toast";
import {
  useCreatePersonMutation,
  User,
  useRolesQuery,
  useUpdatePersonMutation,
} from "generated/__generated_graphql";
import useForm from "hooks/useForm";
import React from "react";
import { styled } from "stitches/stitches.config";
import { isUndefinedOrNull } from "utils/assertions";
import { extractGraphqlErrors } from "utils/helpers";
import { pick } from "utils/object";
import { roleDescription } from "../teams.data";
import { TeamTableData } from "../teams.types";

const FormWrapper = styled(Box, { maxWidth: 400 });

type TeamModalProps = ModalProps & {
  teamMember?: User | null;
  onSuccess?(userData?: any): void;
  onError?(): void;
};

const TeamModal: React.FC<TeamModalProps> = ({
  open,
  teamMember,
  onOpenChange,
  onSuccess,
}) => {
  const isCreate = isUndefinedOrNull(teamMember?.id);
  const [{ data, fetching }] = useRolesQuery();
  const roles = data?.roles;

  const defaultMember = {
    ...pick(teamMember!, ["firstName", "email", "lastName"]),
  };

  const [{ fetching: isCreating }, createPerson] = useCreatePersonMutation();
  const [{ fetching: isUpdating }, updatePerson] = useUpdatePersonMutation();

  const { getFieldProps, values, onSubmit, formIsComplete, setInputValue } =
    useForm({
      fields: {
        firstName: "",
        lastName: "",
        email: "",
        roleId: teamMember?.role?.id ?? 1,
        ...defaultMember,
      },

      refreshToken: teamMember?.id,
    });

  const toast = useToast();

  async function handleOnCreate() {
    const payload = { ...values, roleId: Number(values.roleId) };
    try {
      const response = await createPerson({ ...payload });
      const error = extractGraphqlErrors(response, "createPerson");
      if (error) {
        toast({ content: error, status: "error" });
        return;
      }
      onSuccess?.();
      onOpenChange?.(false);
      toast({
        content: "User created successfully",
        status: "success",
        position: "top",
      });
    } catch {
      toast({ content: "Something went wrong", status: "error" });
    }
  }

  async function handleOnEdit() {
    const payload = {
      ...values,
      id: teamMember?.id!,
      roleId: Number(values.roleId),
    };
    try {
      const response = await updatePerson({ ...payload });
      const error = extractGraphqlErrors(response, "updatePerson");
      if (error) {
        toast({ content: error, status: "error", position: "top" });
        return;
      }
      onSuccess?.();
      onOpenChange?.(false);
      toast({ content: "User updated successfully", status: "success" });
    } catch {
      toast({ content: "Something went wrong", status: "error" });
    }
  }

  async function handleSubmit() {
    const operation = isCreate ? handleOnCreate : handleOnEdit;
    operation();
  }

  return (
    <StepModal
      title="Add Team Member"
      onOpenChange={onOpenChange}
      open={open}
      hideFooter
      variant={"drawer"}
    >
      <Modal.Screen screenId={"form"}>
        <form onSubmit={(e) => onSubmit(e, handleSubmit)}>
          <Modal.Body css={{ width: 400 }}>
            <FormWrapper>
              {fetching && (
                <Flex css={{ height: 200 }} justify="center" align="center">
                  <Loader color="$gray12" size="xs"></Loader>
                </Flex>
              )}
              {!fetching && (
                <Flex stack gap={8}>
                  <Flex stack gap={3} className="form-components">
                    <Flex gap={2}>
                      <Input
                        {...getFieldProps("firstName")}
                        placeholder="First name"
                        label="First name"
                      ></Input>
                      <Input
                        {...getFieldProps("lastName")}
                        placeholder="Last name"
                        label="Last name"
                      ></Input>
                    </Flex>

                    <Input
                      {...getFieldProps("email")}
                      placeholder="Email"
                      label="Email"
                    ></Input>

                    <Flex stack gap={2}>
                      <SelectExperimental
                        value={values.roleId}
                        renderOption={(option) => {
                          const isAdmin = option.name.toLowerCase() === "admin";
                          return (
                            <Flex stack gap={2}>
                              <Flex gap={3} align="center">
                                <Text color="black">{option.name}</Text>
                                <Tag
                                  size="2xs"
                                  variant={"outline"}
                                  color={isAdmin ? "orange" : "teal"}
                                >
                                  {isAdmin ? "Full Access" : "Limited Access"}
                                </Tag>
                              </Flex>
                              <Text lineHeight={"tall"} size="xs">
                                {roleDescription[option.id]}
                              </Text>
                            </Flex>
                          );
                        }}
                        onChange={(value) =>
                          setInputValue("roleId", value as any)
                        }
                        options={roles}
                        labelKey="name"
                        valueKey="id"
                        placeholder="Role"
                        label="Role"
                      ></SelectExperimental>
                      <Text size="xs" color="light" lineHeight={"tall"}>
                        {roleDescription[values.roleId]}
                      </Text>
                    </Flex>
                  </Flex>

                  {/* <Flex stack>
                    <Text size="xs" color="light" lineHeight={"tall"}>
                      {roleDescription[values.roleId]}
                    </Text>

                    <Separator />

                    <Flex justify={"between"}>
                      <Text color="light" size={"xs"}>
                        Bank accounts
                      </Text>

                      <Flex stack gap={2}>
                        <Text size="xs">
                          Has access to all company accounts.
                        </Text>
                        <Text size="xs">Can download statements.</Text>
                      </Flex>
                    </Flex>

                    <Separator />

                    <Flex justify={"between"}>
                      <Text color="light" size={"xs"}>
                        Payments
                      </Text>

                      <Flex stack gap={2}>
                        <Text size="xs">
                          Has access to all company accounts.
                        </Text>
                        <Text size="xs">Can download statements.</Text>
                      </Flex>
                    </Flex>

                    <Separator />

                    <Flex justify={"between"}>
                      <Text color="light" size={"xs"}>
                        Team
                      </Text>

                      <Flex stack gap={2}>
                        <Text size="xs">
                          Has access to all company accounts.
                        </Text>
                        <Text size="xs">Can download statements.</Text>
                      </Flex>
                    </Flex>
                  </Flex> */}
                </Flex>
              )}
            </FormWrapper>
          </Modal.Body>
          {!fetching && (
            <Modal.Footer>
              <Modal.Cancel
                onClick={() => onOpenChange?.(false)}
                appearance={"outline"}
                size="sm"
              >
                Cancel
              </Modal.Cancel>

              <Modal.Confirm
                isLoading={isCreate ? isCreating : isUpdating}
                size="sm"
                disabled={!formIsComplete}
                type="submit"
              >
                {isCreate ? "Add team member" : "Update team member"}
              </Modal.Confirm>
            </Modal.Footer>
          )}
        </form>
      </Modal.Screen>
    </StepModal>
  );
};

export default TeamModal;
