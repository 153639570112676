import AppendPrependWrapper from "components/append-prepend-wrapper";
import InputError from "components/input-error";
import InputLabel from "components/input-label";
import Text from "components/text";
import React from "react";
import { cx } from "utils/dom";
import {
  StyledContainer,
  StyledInput,
  StyledInputWrapper,
} from "./input.styles";
import { InputProps } from "./input.types";

const Input = React.forwardRef<HTMLDivElement, InputProps>((props, ref) => {
  const {
    appearance,
    append,
    renderLabel,
    prepend,
    size,
    label,
    error,
    css,
    id,
    type,
    required,
    disabled,
    wrapperStyle,
    hideErrorMessage,
    className,
    inputRef,
    name,
    description,
    ...inputProps
  } = props;

  const [inputType, setInputType] = React.useState(type);

  function toggleInputType() {
    if (inputType === "password") {
      setInputType("text");
      return;
    }

    setInputType("password");
  }
  return (
    <StyledContainer className={cx([className, "input"])} ref={ref} css={css}>
      {label && (
        <InputLabel required={required} htmlFor={name}>
          {label}
        </InputLabel>
      )}
      {renderLabel && renderLabel}
      <StyledInputWrapper
        className="input-wrapper"
        appearance={appearance}
        size={size}
        hasError={!!error}
        isDisabled={disabled}
        css={wrapperStyle}
      >
        <AppendPrependWrapper
          append={
            type === "password" ? (
              <button
                onClick={toggleInputType}
                className="password-toggle"
                type="button"
              >
                {inputType === "password" ? "show" : "hide"}
              </button>
            ) : (
              append
            )
          }
          prepend={prepend}
        >
          <StyledInput
            disabled={disabled}
            className="input-el"
            type={inputType}
            id={name}
            name={name}
            ref={inputRef}
            {...inputProps}
            // required={required}
          ></StyledInput>
        </AppendPrependWrapper>
      </StyledInputWrapper>
      {error && !hideErrorMessage && <InputError>{error}</InputError>}
      {description && (
        <Text
          weight="regular"
          lineHeight={"tall"}
          color="light"
          as="div"
          size="xs"
        >
          {description}
        </Text>
      )}
    </StyledContainer>
  );
});

Input.displayName = "Input";

export default Input;
