import { Stack } from "components/layout";
import Text from "components/text";
import { EmptyStateProps } from "./empty-state.types";

const EmptyState: React.FC<EmptyStateProps> = ({
  title,
  description,
  primaryAction,
  secondaryAction,
}) => {
  return (
    <Stack css={{ maxWidth: 420 }} align={"center"} justify="center">
      <Stack justify={"center"} spacing={4}>
        {title && (
          <Text align={"center"} size="md">
            {title}
          </Text>
        )}
        {description && (
          <Text
            weight={"regular"}
            lineHeight="tall"
            align={"center"}
            size={"sm"}
            color="light"
          >
            {description}
          </Text>
        )}
      </Stack>
      <Stack justify={"center"} direction="row">
        {secondaryAction && secondaryAction}
        {primaryAction && primaryAction}
      </Stack>
    </Stack>
  );
};

export default EmptyState;
