import React from "react";
import { cx } from "utils/dom";
import { getInitials, hashCode } from "utils/helpers";
import { sand } from "@radix-ui/colors";
import {
  AvatarFallback,
  AvatarImage,
  AvatarRoot,
  Filter,
  DefaultIconContainer,
} from "./avatar.styles";
import { AvatarProps } from "./avatar.types";
import { colors } from "./avatar.data";

/**
 * Water. Earth. Fire. Air.
 *
 * My grandmother used to tell me stories about the old days, a time of peace when the Avatar kept balance between the Water Tribes, Earth Kingdom, Fire Nation, and Air Nomads.
 *
 * But that all changed when the Fire Nation attacked.
 *
 * Only the Avatar mastered all four elements. Only he could stop the ruthless firebenders.
 *
 * But when the world needed him most, he vanished.
 *
 * A hundred years have passed and the Fire Nation is nearing victory in the War.
 *
 * Two years ago, my father and the men of my tribe journeyed to the Earth Kingdom to help fight against the Fire Nation, leaving me and my brother to look after our tribe.
 *
 * Some people believe that the Avatar was never reborn into the Air Nomads, and that the cycle is broken.
 *
 * But I haven't lost hope. I still believe that somehow, the Avatar will return to save the world.
 *
 * ![](https://media.giphy.com/media/kNdqCDCeJk9ig/giphy.gif)
 *
 * ![](https://giffiles.alphacoders.com/211/211340.gif)
 */
const Avatar = React.forwardRef<HTMLDivElement, AvatarProps>(
  (
    {
      name,
      className,
      variant,
      color,
      size,
      shape,
      src,
      css,
      children,
      filter,
      ...props
    },
    ref
  ) => {
    const defaultColor = React.useMemo(() => {
      const hash = hashCode(name);
      return colors[hash % colors.length];
    }, [name]);

    return (
      <AvatarRoot
        variant={variant ?? "light"}
        color={color ?? (defaultColor as any)}
        size={size}
        shape={shape}
        className={cx([className, "avatar"])}
        css={css}
        ref={ref}
        {...props}
      >
        {filter && <Filter className="filter"></Filter>}
        <AvatarImage src={src}></AvatarImage>
        <AvatarFallback>
          {name && !children ? getInitials(name) : children}
        </AvatarFallback>
      </AvatarRoot>
    );
  }
);

Avatar.displayName = "Avatar";

export default Avatar;
