import { Flex } from "components/layout";
import { ITableColumn } from "components/table";
import Text from "components/text";
import { format, formatRelative } from "date-fns";
import { Session } from "generated/__generated_graphql";
import { FaChrome, FaFirefoxBrowser, FaSafari } from "react-icons/fa";
import { FiChrome } from "react-icons/fi";
import { HiDeviceMobile } from "react-icons/hi";

function getBrowserLogo(session: Session) {
  if (session.browser?.includes("Chrome")) {
    return <FiChrome />;
  }

  if (session.browser?.includes("Firefox")) {
    return <FaFirefoxBrowser />;
  }

  if (session.browser?.includes("Safari")) {
    return <FaSafari />;
  }

  return <FaChrome />;
}

export const sessionsTableColumns: Array<ITableColumn<Session>> = [
  {
    title: "Location",
    key: "location",
    dataIndex: "country",
    render: (_, session) => (
      <Flex css={{ py: 2 }} stack gap={2}>
        <Text>{session.country}</Text>
        <Text size="xs" color="light">
          {session.city}
        </Text>
      </Flex>
    ),
  },
  {
    title: "Date & time",
    key: "time",
    dataIndex: "createdAt",
    render: (_, session) => (
      <Flex css={{ py: 2 }} stack gap={2}>
        <Text>{format(new Date(session.createdAt), "MMM dd, yyyy")}</Text>
        <Text size="xs" color="light">
          {format(new Date(session.createdAt), "hh:mm a")}
        </Text>
      </Flex>
    ),
  },
  {
    title: "Browser",
    key: "browser",
    dataIndex: "browser",
    render: (_, session) => (
      <Flex gap={2} align="center" css={{ color: "$gray9" }}>
        {getBrowserLogo(session)}
        <Text>{session.browser}</Text>
      </Flex>
    ),
  },
  {
    title: "IP Address",
    key: "ip",
    dataIndex: "ip",
    render: (_, session) => <Text fontFamily={"monospace"}>{session.ip}</Text>,
  },

  {
    title: "Device",
    key: "device",
    dataIndex: "device",
    render: (device) => (
      <Flex>
        <Text>{device}</Text>
      </Flex>
    ),
  },
];

export const activeSessionColumns: Array<ITableColumn<Session>> = [
  ...sessionsTableColumns,
  {
    title: "Last active",
    key: "lastActivity",
    dataIndex: "lastActive",
    render: (_, session) => (
      <Text css={{ "&:first-letter": { textTransform: "uppercase" } }}>
        {formatRelative(new Date(session.lastActive), new Date())}
      </Text>
    ),
  },
];
