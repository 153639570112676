import Button from "components/button";
import Input, { getDateString } from "components/input";
import { Flex, Stack } from "components/layout";
import SelectExperimental from "components/select/select-experimental";
import Text from "components/text";
import Textarea from "components/textarea";
import React from "react";
import {
  useSaveBusinessMutation,
  Country,
  SaveBusinessInput,
  AddressAttributeInput,
} from "generated/__generated_graphql";
import Loader from "components/loader";
import { CustomCountryOption } from "./custom-country-option";
import { OnboardingComponentProps } from "../onboarding";
import useForm from "hooks/useForm";
import { useNavigate } from "react-router-dom";
import { useToast } from "components/toast";
import InfoTooltip from "./info-tooltip";
import PhoneNumberInput from "components/phone-number-input/phone-number-input";
import Sticker from "components/sticker";
import { HiArrowRight, HiOutlineExclamationCircle } from "react-icons/hi";
import { extractGraphqlErrors } from "utils/helpers";
import CountryChip from "components/country-chip";
import { omit, pick } from "utils/object";

const businessTypes = [
  { label: "Sole Proprietorship", value: "sole_proprietorship" },
  { label: "Partnership", value: "partnership" },
  { label: "Limited Liability Company", value: "llc" },
  { label: "Limited Liability Partnership", value: "llp" },
  { label: "C Corporation", value: "c_corporation" },
  { label: "Non-Profit", value: "non_profit" },
  { label: "S Corporation", value: "s_corporation" },
];

const CompanyInfo = React.forwardRef<HTMLDivElement, OnboardingComponentProps>(
  ({ data, refetchProfile }, ref) => {
    const navigate = useNavigate();
    const toast = useToast();
    const containerRef = React.useRef<HTMLDivElement>(null);

    const business = data?.profile.business;
    const industries = data?.industries;
    const states = data?.getStates;
    const countries = data?.countries;
    const businessTypes = data?.businessTypes;

    const form = useForm({
      fields: {
        name: business?.name,
        businessAlias: business?.businessAlias,
        website: business?.website,
        industryType: business?.industryType,
        dateOfIncorporation: getDateString(
          new Date(business?.dateOfIncorporation!)
        ),
        city: business?.address?.city!,
        state: business?.address?.state,
        countryCode: business?.address?.country?.code! ?? "US",
        postalCode: business?.address?.postalCode!,
        line1: business?.address?.line1!,

        email: business?.email,
        description: business?.description,
        businessType: business?.businessType,
        phoneNumberAttributes: {
          value: business?.phoneNumber?.value || "",
          countryCode: business?.phoneNumber?.countryCode || "US",
        },
      },
      optional: ["businessAlias", "website"],
      validators: {
        phoneNumberAttributes: (value) => {
          if (!value.value || value.value.length < 9) {
            return "Enter a valid phone number";
          }

          return "";
        },
      },
    });

    const {
      values,
      setInputValue,
      onSubmit,
      register,
      errors,
      formIsComplete,
      hasErrors,
      validateField,
    } = form;

    function notifyError(error?: string) {
      toast({
        content: error ?? "something went wrong",
        status: "error",
        showIcon: true,
        position: "top",
      });
    }

    const [{ fetching: isSubmitting }, submit] = useSaveBusinessMutation();

    async function submitCompanyInfo() {
      const payload: SaveBusinessInput = {
        ...omit(values, [
          "city",
          "state",
          "line1",
          "countryCode",
          "postalCode",
        ]),
        addressAttributes: {
          ...(pick(values, [
            "city",
            "state",
            "line1",
            "countryCode",
            "postalCode",
          ]) as AddressAttributeInput),
        },
      };

      try {
        const response = await submit({ businessDetails: payload });
        const error = extractGraphqlErrors(response, "saveBusiness");

        if (error) {
          notifyError(error);
          return;
        }

        navigate("/onboarding/verify-business");
      } catch (error) {
        notifyError();
      }
    }

    return (
      <Flex direction="column" gap={16} ref={containerRef}>
        <Flex gap={3} stack>
          <Text size="xl" weight={"semi"} color="black">
            Company Info
          </Text>
          <Text size="sm" color="light">
            Tell us more about your business
          </Text>
        </Flex>

        <form onSubmit={(e) => onSubmit(e, submitCompanyInfo)}>
          <Flex direction="column" align="start" gap={4}>
            <InfoTooltip
              title="Business name"
              description="We will verify this information to confirm it matches the name provided on your business registration. Using falsified documents can get you permanently banned on Float."
              containerRect={
                containerRef?.current?.getBoundingClientRect() as DOMRect
              }
            >
              <Input
                required
                label="Business name"
                type="text"
                placeholder="Enter business name"
                {...register("name")}
              />
            </InfoTooltip>
            <Input
              label="Alias (Optional)"
              type="text"
              placeholder="Enter alias"
              {...register("businessAlias")}
            />
            <SelectExperimental
              required
              onChange={(value) =>
                setInputValue("businessType", value as string)
              }
              value={values.businessType}
              error={errors.businessType}
              valueKey="value"
              labelKey="name"
              options={businessTypes}
              label="Business type"
              placeholder="Business type"
            ></SelectExperimental>
            <Input
              {...register("website")}
              label="Company website"
              placeholder="Company website"
            ></Input>

            <Input
              required
              label="Business email"
              type="email"
              placeholder="Eg: enquires@float.co"
              {...register("email")}
            />
            <Input
              required
              label="Date of incorporation"
              type="date"
              placeholder="dd-mm-yyyy"
              {...register("dateOfIncorporation")}
            />
            <Stack css={{ width: "100%" }} spacing={10}>
              <InfoTooltip
                title="Unsupported businesses "
                description="Float does not support businesses that participate in the following: Gambling, Crypto of any kind, Trading, Pornography or sex work distribution of any kind, bootlegging, trafficking or participation in any unethical businesses that goes against the laws in all countries Float operates in.
                Read more about this (hyperlink)"
                containerRect={
                  containerRef?.current?.getBoundingClientRect() as DOMRect
                }
              >
                <Flex gap={1} direction="column">
                  <SelectExperimental
                    required
                    options={industries}
                    onChange={(value: string) => {
                      setInputValue("industryType", value);
                    }}
                    value={values.industryType}
                    placeholder="Select industry"
                    labelKey="name"
                    valueKey="name"
                    label="Industry"
                    error={errors.industryType}
                  />
                  {/* <Sticker variant={"light"} color="cyan">
                    <HiOutlineExclamationCircle style={{ rotate: "180deg" }} />
                    <Text color="inherit" size="xs">
                      Learn more about our platform policies here
                    </Text>
                  </Sticker> */}
                </Flex>
              </InfoTooltip>
            </Stack>

            <Stack css={{ width: "100%" }} spacing={6}>
              <Input
                required
                type="text"
                label="Valid US Address"
                placeholder="Address"
                {...register("line1")}
              />

              {values?.countryCode === "US" ? (
                <SelectExperimental
                  required
                  options={states}
                  onChange={(value: string) => {
                    setInputValue("state", value);
                  }}
                  value={values.state}
                  valueKey="name"
                  labelKey="name"
                  placeholder="State"
                  error={errors.state}
                />
              ) : (
                <Input
                  {...register("state")}
                  placeholder="State/Province/Region"
                ></Input>
              )}

              <Input required placeholder="City" {...register("city")} />
              <Input
                {...register("postalCode")}
                placeholder="Postal Code"
              ></Input>
            </Stack>

            <PhoneNumberInput
              countries={countries || []}
              required
              placeholder="Phone Number"
              inputMode="numeric"
              label="Phone"
              error={errors.phoneNumberAttributes}
              onChange={(value) =>
                setInputValue("phoneNumberAttributes", {
                  value: value.value!,
                  countryCode: value.countryCode!,
                })
              }
              value={{
                value: values.phoneNumberAttributes?.value,
                countryCode: values.phoneNumberAttributes?.countryCode,
              }}
            />

            <Textarea
              placeholder="Give a brief description of your business..."
              label="Business description"
              required
              {...register("description")}
            />
            <Flex>
              <Text size={"xs"} weight={"regular"} lineHeight={"tall"}>
                To help the government fight the funding of terrorism and money
                laundering activities, federal law requires all financial
                institutions to obtain, verify, and record information that
                identifies each person who opens an account. What this means for
                you: when you open an account, we will ask for your name,
                address, date of birth, and other information that will allow us
                to identify you. We may also ask to see a copy of your driver’s
                licenses or other identifying documents.
              </Text>
            </Flex>
            <Button
              append={<HiArrowRight size={16} />}
              size={"md"}
              type="submit"
              isLoading={isSubmitting}
              disabled={!formIsComplete}
              appearance="secondary"
            >
              Continue
            </Button>
          </Flex>
        </form>
      </Flex>
    );
  }
);

CompanyInfo.displayName = "CompanyInfo";

export default CompanyInfo;
