import Box from "components/box";
import { Flex } from "components/layout";
import React from "react";
import { styled } from "stitches/stitches.config";

export const BaseCard = styled(Flex, {
  flexDirection: "column",
  background: "white",
  borderRadius: 6,
});

export const CardWrapper = styled("div", {
  background: "white",
  // boxShadow: "$subtle",
  borderRadius: 6,
  width: "100%",
});

export const CardTitle = styled(Flex, {
  p: 24,
});

export const CardBody = styled(Box, { p: 24 });

type CardProps = Omit<React.ComponentProps<typeof CardWrapper>, "title"> & {
  title?: React.ReactNode;
};

const Card = React.forwardRef<HTMLDivElement, CardProps>(
  ({ children, title, ...props }, ref) => {
    return (
      <CardWrapper {...props} ref={ref}>
        {title && <CardTitle>{title}</CardTitle>}

        <CardBody className="card-body">{children}</CardBody>
      </CardWrapper>
    );
  }
);

Card.displayName = "Card";

export default Card;
