import axios, { AxiosRequestConfig } from "axios";
import React from "react";
import { getToken } from "utils/auth";
import { ActionStatus } from "utils/types";

type RestQueryOptions = {
  onSuccess?: (data: any) => void;
  onError?: (error: any) => void;
  requiresAuth?: boolean;
} & AxiosRequestConfig;

type RestQueryResult = {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  error: unknown;
  utils: {
    downloadFile: (
      data: any,
      fileName: string,
      fileType: "pdf" | "csv"
    ) => void;
  };
};

export default function useRestQuery({
  url,
  method = "GET",
  onSuccess,
  onError,
  headers,
  requiresAuth,
  ...config
}: RestQueryOptions) {
  const [response, setResponse] = React.useState<any>(null);
  const [status, setStatus] = React.useState<ActionStatus>("idle");
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState<unknown>(null);

  const isLoading = status === "loading";
  const isSuccess = status === "success";
  const isError = status === "error";

  const downloadFile = React.useCallback(
    (data: any, fileName: string, fileType: "pdf" | "csv") => {
      const blob = new Blob([data], { type: `application/${fileType}` });
      const a = document.createElement("a");
      a.download = fileName;
      a.href = URL.createObjectURL(blob);
      a.click();

      URL.revokeObjectURL(a.href);
    },
    []
  );

  const fetch = React.useCallback(
    async (options?: RestQueryOptions) => {
      setStatus("loading");

      const authHeader = requiresAuth
        ? { Authorization: `Bearer ${getToken()}` }
        : {};

      const fetchOptions: RestQueryOptions = {
        url: options?.url || url,
        method: options?.method || method,
        onSuccess: options?.onSuccess || onSuccess,
        onError: options?.onError || onError,
        headers: options?.headers || headers,
      };

      try {
        const response = await axios({
          method: fetchOptions.method,
          url: fetchOptions.url,
          headers: { ...fetchOptions.headers, ...authHeader },
          ...config,
        });

        setResponse(response);
        if (response.statusText !== "OK") {
          setStatus("error");
          setError(response.data);
          fetchOptions.onError?.(response.data);
          return {
            ...response,
            error: response?.data?.error || "something went wrong",
          };
        }

        setStatus("success");
        setData(response.data);
        fetchOptions.onSuccess?.(response.data);
        return { ...response, error: null };
      } catch (error) {
        setStatus("error");

        setError(error);
        fetchOptions.onError?.(error);
        throw error;
      }
    },
    [url]
  );

  const utils = React.useMemo(
    () => ({
      downloadFile,
    }),
    [url, method]
  );

  return [{ isLoading, isSuccess, isError, data, error, utils }, fetch] as [
    RestQueryResult,
    typeof fetch
  ];
}
