import React from "react";
import AppendPrependWrapper from "../append-prepend-wrapper";
import Loader from "../loader/loader";
import { StyledButton } from "./button.styles";
import { ButtonProps } from "./button.types";

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      prepend,
      append,
      isLoading,
      align,
      type = "button",
      ...buttonProps
    },
    ref
  ) => {
    return (
      <StyledButton type={type} {...buttonProps} ref={ref}>
        <AppendPrependWrapper
          align={align}
          prepend={isLoading ? <Loader size="xs"></Loader> : prepend}
          append={append}
        >
          {children}
        </AppendPrependWrapper>
      </StyledButton>
    );
  }
);

Button.displayName = "Button";

export default Button;
