import { styled } from "stitches/stitches.config";

export const Input = styled("input", {
  opacity: 0,
  pointerEvents: 0,
  position: "absolute",
});

export const Label = styled("label", {
  display: "flex",
  alignItems: "center",
  px: 8,
  border: "1.5px dashed $gray4",
  background: "$gray2",
  borderRadius: 3,
  width: "100%",
  height: 40,
  cursor: "pointer",
  transition: "all .5s",
  color: "$gray9",

  ".uploader-container": {
    width: "100%",
    ".main-section, .actions": { width: "auto" },
  },

  ".uploader-text": {
    span: {
      color: "$green9",
      fontFamily: "$medium",
    },
  },

  ".uploader-icon": { fontSize: 20, marginRight: 8 },

  variants: {
    state: {
      idle: {},
      error: {
        border: "2px solid $tomato4",
        color: "$tomato9",
        background: "$tomato2",
      },
      success: {
        border: "none",
        color: "$gray11",
      },
      loading: {},
    },
    disabled: {
      true: {
        background: "$gray2",
        border: "2px dashed $gray4",
        color: "$gray7",

        ".uploader-text": {
          span: {
            color: "$gray7",
            fontFamily: "$regular",
          },
        },
      },
    },

    ghost: {
      true: {
        height: "fit-content",
        padding: "0",
        border: "none",
        background: "transparent",

        '&[data-disabled="true"]': {
          cursor: "default !important",
        },
      },
    },
  },
});
