import Modal, { ModalProps } from "components/modal";
import StepModal from "components/modal/step-modal";
import React from "react";
import AuthTypeSelectStep from "./steps/auth-type-select-step";
import AuthenticatorSetup from "./steps/authenticator-setup";
import ConfirmPassword from "./steps/confirm-password";
import SmsAuthSetup from "./steps/sms-auth-setup";
import VerifySmsToken from "./steps/verify-sms-token";

type Props = ModalProps;

const TwoFactorAuthSetup = React.forwardRef<HTMLDivElement, Props>(
  ({ onOpenChange, ...props }, ref) => {
    return (
      <StepModal
        {...props}
        variant={"drawer"}
        onOpenChange={onOpenChange}
        defaultScreen="password"
        title="Two Factor Authentication"
      >
        <Modal.Screen screenId="password">
          <ConfirmPassword />
        </Modal.Screen>

        <Modal.Screen screenId="auth-type">
          <AuthTypeSelectStep />
        </Modal.Screen>

        <Modal.Screen screenId="authenticator-setup">
          <AuthenticatorSetup onSuccess={() => onOpenChange?.(false)} />
        </Modal.Screen>

        <Modal.Screen screenId="sms-auth-setup">
          <SmsAuthSetup />
        </Modal.Screen>

        <Modal.Screen screenId="verify-sms-token">
          <VerifySmsToken />
        </Modal.Screen>
      </StepModal>
    );
  }
);

TwoFactorAuthSetup.displayName = "TwoFactorAuthSetup";

export default TwoFactorAuthSetup;
