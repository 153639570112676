import Box from "components/box";
import { styled } from "stitches/stitches.config";

export const MenuContainer = styled(Box, {
  position: "relative",
  background: "$gray0",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  borderRight: "1px solid $gray0",
  ".misc-menu": { marginBottom: "$4" },
  ".menu-wrapper-lg": {
    px: 12,
    py: 24,
    display: "none",

    "@lg": { display: "block" },
  },

  ".menu-wrapper-sm": {
    display: "flex",
    "@lg": { display: "none" },
  },
});
