import { baseFontStack, styled } from "stitches/stitches.config";

export const Text = styled("p", {
  textDecoration: "none",
  letterSpacing: 0.1,

  ".highlight": { color: "$primary", fontFamily: "inherit" },

  defaultVariants: {
    lineHeight: "normal",
    align: "left",
    weight: "medium",
    color: "dark",
    size: "sm",
    fontFamily: "sfPro",
  },

  variants: {
    numeric: {
      true: {
        fontVariantNumeric: "tabular-nums",
      },
    },
    size: {
      inherit: { fontSize: "inherit" },
      "2xs": { fontSize: "$1" },
      xs: { fontSize: "$2" },
      sm: { fontSize: "$3" },
      md: { fontSize: "$4" },
      lg: { fontSize: "$5" },
      xl: { fontSize: "$6" },
      "2xl": { fontSize: "$7" },
      "3xl": { fontSize: "$8" },
      "4xl": { fontSize: "$9" },
      "5xl": { fontSize: "$10" },
      "6xl": { fontSize: "$11" },
      "7xl": { fontSize: "$12" },
      "8xl": { fontSize: "$13" },
      "9xl": { fontSize: "$14" },
    },
    color: {
      dark: { color: "$gray11" },
      black: { color: "$gray12" },
      light: { color: "$gray9" },
      lighter: { color: "$gray8" },
      primary: { color: "$primary" },
      white: { color: "white" },
      success: { color: "$green10" },
      error: { color: "$tomato10" },
      warning: { color: "$yellow10" },
      inherit: { color: "inherit" },
    },
    lineHeight: {
      normal: { lineHeight: "$normal" },
      none: { lineHeight: "$none" },
      shorter: { lineHeight: "$shorter" },
      short: { lineHeight: "$short" },
      base: { lineHeight: "$base" },
      tall: { lineHeight: "$tall" },
      taller: { lineHeight: "$taller" },
    },

    align: {
      center: { textAlign: "center" },
      left: { textAlign: "left" },
      right: { textAlign: "center" },
    },

    fontFamily: {
      haffer: {},
      sfPro: {},
      monospace: { fontFamily: "$monospace" },
    },

    weight: {
      regular: {},
      medium: {},
      semi: {},
      bold: {},
      heavy: {},
      monospace: {},
    },

    rounded: {
      true: {},
    },

    case: {
      uppercase: { textTransform: "uppercase" },
      lowercase: { textTransform: "lowercase" },
      capitalize: { textTransform: "capitalize" },
    },

    underline: { true: { textDecoration: "underline" } },
  },

  compoundVariants: [
    { weight: "medium", rounded: true, css: { fontFamily: "$roundedMedium" } },
    { weight: "semi", rounded: true, css: { fontFamily: "$roundedSemibold" } },

    {
      fontFamily: "haffer",
      weight: "regular",
      css: { fontFamily: `haffer regular, ${baseFontStack}` },
    },
    {
      fontFamily: "haffer",
      weight: "medium",
      css: { fontFamily: `haffer medium, ${baseFontStack}` },
    },
    {
      fontFamily: "haffer",
      weight: "semi",
      css: { fontFamily: `haffer semi, ${baseFontStack}` },
    },
    {
      fontFamily: "haffer",
      weight: "bold",
      css: { fontFamily: `haffer bold, ${baseFontStack}` },
    },
    {
      fontFamily: "haffer",
      weight: "heavy",
      css: { fontFamily: `haffer heavy, ${baseFontStack}` },
    },

    {
      fontFamily: "sfPro",
      weight: "regular",
      css: { fontFamily: "$regular", fontWeight: 400 },
    },
    {
      fontFamily: "sfPro",
      weight: "medium",
      css: { fontFamily: "$medium", fontWeight: 500 },
    },
    {
      fontFamily: "sfPro",
      weight: "semi",
      css: { fontFamily: "$semibold", fontWeight: 600 },
    },
    {
      fontFamily: "sfPro",
      weight: "bold",
      css: { fontFamily: "$bold", fontWeight: 700 },
    },
    {
      fontFamily: "sfPro",
      weight: "heavy",
      css: { fontFamily: "$heavy", fontWeight: 800 },
    },
  ],
});

export default Text;
