import React from "react";
import { cx } from "utils/dom";
import { StyledLabel } from "./input-label.styles";
import { InputLabelProps } from "./input-label.types";

const InputLabel = React.forwardRef<HTMLLabelElement, InputLabelProps>(
  ({ required, children, htmlFor, css, className }, ref) => {
    return (
      <StyledLabel
        className={cx(["input-label", className])}
        css={css}
        htmlFor={htmlFor}
        ref={ref}
      >
        {children}
        {required && <span>*</span>}
      </StyledLabel>
    );
  }
);

InputLabel.displayName = "InputLabel";

export default InputLabel;
