import BlankPageLayout from "layouts/blank-page-layout";
import AccountPage from "pages/accounts/account";
import OverviewPage from "pages/overview/overview";
import Settings from "pages/settings/settings";
import StatementPage from "pages/statements/statements";
import TransactionPage from "pages/transactions/transactions";
import Payments from "pages/payments/payments";
import MoveMoney from "components/move-money/move-money";
import CardsPage from "pages/cards/cards";
import Accounts from "pages/accounts/accounts";
import YieldAccount from "pages/accounts/yield-account";

interface IRoute {
  id?: string;
  path?: string;
  layout?: any;
  element?: any;
  provider?: any;
  isAvailable?: boolean;
  isIndex?: boolean;
}

const dashboardRoutes: Array<IRoute> = [
  {
    path: "/cards",
    element: CardsPage,
  },
  {
    path: "/send-money/*",
    element: MoveMoney,
    layout: BlankPageLayout,
  },
  {
    path: "/cash",
    element: Accounts,
  },
  {
    path: "/yield",
    element: YieldAccount,
  },
  {
    path: "/payments",
    element: Payments,
  },
  {
    path: "/cash/account/:id",
    element: AccountPage,
  },
  {
    path: "/statements",
    element: StatementPage,
  },
  {
    path: "/transactions",
    element: TransactionPage,
  },
  // {
  //   path: "/teams",
  //   element: Teams,
  // },
  {
    path: "/settings/*",
    element: Settings,
    layout: BlankPageLayout,
  },
  {
    path: "/",
    isIndex: true,
    element: OverviewPage,
  },
];

export default dashboardRoutes;
