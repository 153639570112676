import Accordion from "components/accordion";
import Button from "components/button";
import CopyWrapper from "components/copy-wrapper";
import { Flex } from "components/layout";
import Modal, { ModalProps } from "components/modal";
import Separator from "components/separator";
import Sticker from "components/sticker";
import Text from "components/text";
import { BankAccount } from "generated/__generated_graphql";
import useRestQuery from "hooks/use-rest-query";
import { useAppProvider } from "providers/app-provider/app-provider";
import React from "react";
import { FaEthereum } from "react-icons/fa";
import {
  HiOutlineDocumentDownload,
  HiOutlineExclamation,
  HiOutlineExclamationCircle,
} from "react-icons/hi";
import { styled } from "stitches/stitches.config";
import { getToken } from "utils/auth";
import { baseServerUrl } from "utils/helpers";

const Wrapper = styled("div", {
  ".item": {
    marginBottom: 24,
    borderRadius: 12,
    border: "1px solid $gray3",
    px: 16,
    py: 12,
  },

  ".content": {
    pt: 24,
  },

  ".address": {
    background: "$gray2",
    color: "$gray9",
    borderRadius: 6,
    px: 8,
    py: 10,

    ".address-string": {
      letterSpacing: 0.2,
    },

    "&:hover": {
      ".address-string": {
        // textDecoration: "underline",
        cursor: "pointer",
      },
    },
  },

  ".key": {
    width: 160,
  },

  ".account-details": {
    "*": { fontSize: "13px !important" },
  },
});

type FundingInstructionsType = ModalProps & { account: BankAccount };

export default function FundingInstructions({
  account,
  ...props
}: FundingInstructionsType) {
  const { user } = useAppProvider();
  const address = user?.business?.address;
  const bankAddress = account?.bankAddress;

  const [{ isLoading, utils }, fetch] = useRestQuery({
    url: `${baseServerUrl()}/account/${account.id}/wire_details`,
    method: "GET",
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });

  async function downloadAsPDF() {
    const { data, error } = await fetch({
      onSuccess(response) {
        utils.downloadFile(
          response,
          `float-wire-details-${account.uuid}`,
          "pdf"
        );
      },
    });
  }

  return (
    <Modal variant={"drawer"} title="Fund account" {...props}>
      <Modal.Body css={{ width: 500 }}>
        <Wrapper>
          <Accordion defaultValue="wire" type="single">
            <Accordion.Item className="item" value="wire">
              <Accordion.Header>
                <Flex gap={2} stack>
                  <Text color="black">Wire / ACH Transfers</Text>
                  <Text size="xs" color="light" lineHeight="tall">
                    Use these details to send both domestic wires and ACH
                    transfers to your float account.
                  </Text>
                </Flex>
              </Accordion.Header>
              <Accordion.Content className="content account-details">
                <Flex gap={4} stack>
                  <Flex>
                    <Text color="light" className="key">
                      Account name
                    </Text>
                    <Text color="black">{account?.accountName}</Text>
                  </Flex>
                  <Flex>
                    <Text color="light" className="key">
                      Account number
                    </Text>
                    <Text color="black">{account?.accountNumber}</Text>
                  </Flex>
                  <Flex>
                    <Text color="light" className="key">
                      Account type
                    </Text>
                    <Text color="black">Checking</Text>
                  </Flex>
                  <Flex>
                    <Text color="light" className="key">
                      Address
                    </Text>
                    <Text color="black" lineHeight={"tall"}>
                      {address?.line1} <br />
                      {address?.city}, {address?.state} {address?.postalCode}
                    </Text>
                  </Flex>

                  {account.instructions && (
                    <Sticker
                      css={{ px: 16, br: 4 }}
                      stack
                      gap={1}
                      align={"start"}
                      variant={"outline"}
                      color={"lime"}
                    >
                      <HiOutlineExclamationCircle size={20} className="icon" />
                      <Flex stack>
                        <Text
                          weight={"semi"}
                          color="inherit"
                          lineHeight={"tall"}
                        >
                          Add this memo to your wire transfer
                        </Text>
                        <Text color="inherit" lineHeight={"tall"}>
                          {account?.instructions}
                        </Text>
                      </Flex>
                    </Sticker>
                  )}
                </Flex>

                <Separator />

                <Flex gap={4} stack>
                  <Flex>
                    <Text color="light" className="key">
                      Bank Name
                    </Text>
                    <Text color="black">{account?.bankName}</Text>
                  </Flex>
                  <Flex>
                    <Text color="light" className="key">
                      Routing Number
                    </Text>
                    <Text color="black">{account?.bankCode}</Text>
                  </Flex>

                  <Flex>
                    <Text color="light" className="key">
                      Bank Address
                    </Text>
                    <Text color="black" lineHeight={"tall"}>
                      {bankAddress?.line1} <br />
                      {bankAddress?.city}, {bankAddress?.state}{" "}
                      {bankAddress?.postalCode}
                    </Text>
                  </Flex>
                </Flex>

                <Separator color="white" />

                <Button
                  isLoading={isLoading}
                  onClick={downloadAsPDF}
                  size="sm"
                  shape={"pill"}
                  appearance={"secondary"}
                  append={<HiOutlineDocumentDownload />}
                >
                  Download as PDF
                </Button>
              </Accordion.Content>
            </Accordion.Item>

            {(account?.addresses || []).length > 0 && (
              <Accordion.Item className="item" value="usdc">
                <Accordion.Header>
                  <Flex gap={2} stack>
                    <Text color="black">
                      Deposit Digital Dollars (USDC Only)
                    </Text>
                    <Text size="xs" color="light" lineHeight="tall">
                      You can use this address to receive digital dollar
                      transfers directly into your account.
                    </Text>
                  </Flex>
                </Accordion.Header>
                <Accordion.Content className="content">
                  <Flex gap={2} stack>
                    <CopyWrapper value={account?.addresses?.[0]?.address || ""}>
                      <Flex stretchX justify={"between"} className="address">
                        <Text size="xs" className="address-string">
                          {account?.addresses?.[0]?.address}
                        </Text>
                        <FaEthereum />
                      </Flex>
                    </CopyWrapper>
                    <Sticker variant={"outline"} color="tomato">
                      <Flex align="center" gap={4}>
                        <HiOutlineExclamation
                          size={20}
                          style={{ flexShrink: 0 }}
                        />
                        <Text lineHeight={"tall"} size="xs" color="inherit">
                          This address can only receive USDC from the Ethereum
                          network. Don’t send USDC from other networks, any
                          ERC-20s or NFTs, or it may result in a loss of funds.
                        </Text>
                      </Flex>
                    </Sticker>
                  </Flex>
                </Accordion.Content>
              </Accordion.Item>
            )}
          </Accordion>
        </Wrapper>
      </Modal.Body>
    </Modal>
  );
}
