import AppendPrependWrapper from "components/append-prepend-wrapper";
import InputError from "components/input-error";
import InputLabel from "components/input-label";
import Flex from "components/layout/flex";
import React from "react";
import { VariantProps } from "stitches/stitches.config";
import { IInput } from "utils/types";
import { Count, StyledTextArea, StyledContainer } from "./textarea.styles";

type TextAreaProps = Omit<React.ComponentProps<typeof StyledTextArea>, "size"> &
  VariantProps<typeof StyledTextArea> &
  IInput & {
    prepend?: React.ReactNode;
    append?: React.ReactNode;
    showCount?: boolean;
  };

const Textarea = React.forwardRef<HTMLDivElement, TextAreaProps>(
  (
    {
      label,
      error,
      css,
      id,
      required,
      appearance,
      showCount,
      max,
      onChange,
      value,
      disabled,
      append,
      prepend,
      ...props
    },
    ref
  ) => {
    return (
      <StyledContainer css={css} ref={ref}>
        {(showCount || label) && (
          <Flex css={{ marginBottom: 8 }} align="center" justify="between">
            {label && (
              <InputLabel
                css={{ marginBottom: 0 }}
                required={required}
                htmlFor={id}
              >
                {label}
              </InputLabel>
            )}

            {showCount && (
              <Count>
                <>
                  ({(value as string)?.length ?? 0}/{max})
                </>
              </Count>
            )}
          </Flex>
        )}

        <StyledTextArea
          className="input-el"
          {...props}
          onChange={onChange}
          value={value}
          id={id}
        ></StyledTextArea>

        {error && <InputError>{error}</InputError>}
      </StyledContainer>
    );
  }
);

Textarea.displayName = "Textarea";

export default Textarea;
