import { styled } from "stitches/stitches.config";

export const Wrapper = styled("label", {
  display: "flex",
  position: "relative",
  alignItems: "center",
  height: 16,
  cursor: "pointer",
  fontSize: "16px",
  "-webkit-user-select": "none",
  "-moz-user-select": "none",
  "-ms-user-select": "none",
  userSelect: "none",
  gap: "$2",

  defaultVariants: {
    shape: "square",
  },

  variants: {
    shape: {
      circle: {
        ".custom-checkbox": {
          borderRadius: "100%",
        },
      },

      square: {
        ".custom-checkbox": {
          borderRadius: 6,
        },
      },
    },
  },

  input: {
    position: "absolute",
    opacity: 0,
    left: 0,
    height: 16,
    width: 16,
    zIndex: 3,
    cursor: "pointer",
  },

  ".label": {
    marginLeft: 8,
  },

  ".custom-checkbox": {
    size: 18,
    overflow: "hidden",
    backgroundColor: "white",
    border: "1.5px solid $gray4",
    transition: "all 0.5s",

    "&:focus-within": {
      boxShadow: "$focusRing",
    },

    ".checkmark": {
      display: "hidden",
      color: "white",
      flexShrink: 0,
    },
  },

  "&:hover input ~ .custom-checkbox": {
    backgroundColor: "$gray3",
  },

  "input:focus ~ .custom-checkbox": {
    boxShadow: "$focusRingCheckbox",
  },

  "input[aria-checked='mixed'] ~ .checkmark": {
    backgroundColor: "white",
    border: "1px solid $teal10",
  },

  "input:checked ~ .custom-checkbox": {
    backgroundColor: "$gray12",
    border: "1px solid $gray12",

    ".checkmark": { display: "block" },
  },
});
