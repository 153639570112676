import Box from "components/box";
import { styled } from "stitches/stitches.config";
import PatternBg from "assets/images/pattern.png";
import { Stack } from "components/layout";
import Text from "components/text";
import { useParams, Link } from "react-router-dom";
import Input from "components/input";
import Button from "components/button";
import { FiCheck } from "react-icons/fi";
import Avatar from "components/avatar";
import { useState } from "react";
import useForm from "hooks/useForm";
import { useResetPasswordMutation } from "generated/__generated_graphql";
import { useToast } from "components/toast/use-toast";
import * as Sentry from "@sentry/react";
import LinkButton from "components/link-button";

const StyledContainer = styled(Box, {
  background: "$sand2",
  width: "100vw",
  minHeight: "100vh",
  position: "relative",

  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  flexDirection: "column",
  ".bg": {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100vw",

    zIndex: 1,
  },
  ".footer": {
    mt: "auto",
    mb: 30,
  },
});

const StyledBox = styled(Box, {
  mt: 80,
  background: "$sand1",
  boxShadow: "$subtle",
  width: 450,
  p: 48,
  borderRadius: 8,
  zIndex: 2,

  ".wrapper": {
    mt: 48,
  },
});

const StyledLink = styled(Link, {
  textDecoration: "none",
  fontFamily: "$medium",
  color: "$primary",
});

export default function ResetPassword() {
  const { token } = useParams();
  const toast = useToast();
  const { values, errors, onChange, hasErrors, onSubmit } = useForm({
    fields: { password: "", confirmPassword: "" },
  });
  const [passwordReset, setPasswordReset] = useState(false);

  const [{ fetching: isReseting }, resetUserPassword] =
    useResetPasswordMutation();

  function notify(message: string) {
    return toast({
      content: message,
      duration: 4000,
      status: "error",
    });
  }

  const resetPassword = async (token: any) => {
    const isPasswordMatch = values.password === values.confirmPassword;
    try {
      if (!isPasswordMatch) {
        notify("Password does not match");
        return;
      }

      const { data } = await resetUserPassword({
        password: values.password,
        token,
      });

      const errorsExist = data?.resetPassword?.errors?.length ?? 0;
      if (errorsExist > 0) {
        const errors = data?.resetPassword?.errors ?? [];
        notify(errors[0].message);
      }

      if (data?.resetPassword?.status === 200) {
        setPasswordReset(true);
      } else {
        notify("Could not reset password, please contact support");
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <StyledContainer>
      <img className="bg" src={PatternBg} alt="" />
      <StyledBox>
        {passwordReset && (
          <Stack align="center" spacing={30}>
            <Avatar
              variant="solid"
              css={{ background: "$gray12" }}
              size={"md"}
              color="gray"
            >
              <FiCheck size={20} color="white" />
            </Avatar>
            <Stack css={{ mt: 5 }} spacing={10}>
              <Text align="center" size="xl" weight="semi" color="black">
                New Password Set
              </Text>
              <Text
                align="center"
                size="sm"
                lineHeight="tall"
                color="light"
                weight="regular"
              >
                Your new password has been set successfully. Proceed to login
                with your new password.
              </Text>
            </Stack>
            <LinkButton
              appearance={"secondary"}
              href="/login"
              size={"md"}
              css={{ width: "100%" }}
            >
              Return to Login
            </LinkButton>
          </Stack>
        )}
        {!passwordReset && (
          <>
            <Stack css={{ mt: 42 }} spacing={10}>
              <Text size="xl" weight="semi" color="black">
                Set new password
              </Text>
              <Text size="sm" color="light" weight="regular">
                Enter a new password for your Float account.
              </Text>
            </Stack>
            <form
              className="wrapper"
              onSubmit={(e) => onSubmit(e, () => resetPassword(token))}
            >
              <Stack align="start" spacing={16}>
                <Input
                  name="password"
                  value={values.password}
                  onChange={onChange}
                  type="password"
                  placeholder="enter your new password"
                  label="New Password"
                  error={errors.password}
                ></Input>
                <Input
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onChange={onChange}
                  type="password"
                  placeholder="conirm your new password"
                  label="Confirm New Password"
                ></Input>

                <Stack spacing={12} stretchX>
                  <Button
                    size={"md"}
                    disabled={hasErrors || isReseting}
                    type="submit"
                    isLoading={isReseting}
                    appearance={"secondary"}
                  >
                    {isReseting ? "Reseting..." : "Set New Password"}
                  </Button>
                  <LinkButton href="/login" size="sm" appearance={"ghost"}>
                    Return to Login
                  </LinkButton>
                </Stack>
              </Stack>
            </form>
          </>
        )}
      </StyledBox>

      {/* <Stack className="footer" isInline spacing={10}>
        <Text size="xs" weight="regular" color="light">
          Swipe Technologies, Inc. &nbsp; •
        </Text>
        <Text size="xs" weight="regular" color="light" as={"a"} href="/">
          Privacy Policy &nbsp; •
        </Text>
        <Text size="xs" weight="regular" color="light" as={"a"} href="/">
          Platform Agreement
        </Text>
      </Stack> */}
    </StyledContainer>
  );
}
