import React from "react";

export default function useCallbackRef<FnType extends (...args: any[]) => any>(
  fn: FnType | undefined,
  deps: React.DependencyList = []
) {
  const ref = React.useRef(fn);

  React.useLayoutEffect(() => {
    ref.current = fn;
  });

  return React.useCallback((...args: any[]) => {
    ref.current?.(...args);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
