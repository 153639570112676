import { gray, grayA } from "@radix-ui/colors";
import Avatar from "components/avatar";
import Box from "components/box";
import Button from "components/button";
import CopyWrapper from "components/copy-wrapper";
import Input from "components/input";
import { Flex } from "components/layout";
import Sticker from "components/sticker";
import Tag from "components/tag";
import Text from "components/text";
import { format, formatRelative } from "date-fns";
import { JournalEntry } from "generated/__generated_graphql";
import React from "react";
import {
  HiArrowSmLeft,
  HiArrowSmRight,
  HiCheckCircle,
  HiClipboardCopy,
  HiDocumentDownload,
  HiLibrary,
  HiOutlineCreditCard,
  HiOutlineDocumentDownload,
  HiOutlineExclamationCircle,
  HiOutlineShoppingCart,
  HiOutlineTag,
  HiReply,
  HiX,
} from "react-icons/hi";
import { keyframes, styled } from "stitches/stitches.config";
import { formatMoney, safeRelativeDateString } from "utils/helpers";

const pulse = keyframes({
  "0%": { transform: `scale(0.95)", boxShadow: "0 0 0 0 ${grayA.grayA7}` },
  "70%": { transform: "scale(1)", boxShadow: "0 0 0 10px rgba(0, 0, 0, 0)" },
  "100%": { transform: "scale(0.95)", boxShadow: "0 0 0 0 rgba(0, 0, 0, 0)" },
});

const Wrapper = styled("ul", {
  padding: 0,
  width: "100%",

  // ".pill": {
  //   border: "none",
  //   borderRadius: 24,
  //   px: 12,
  //   py: 6,
  //   gap: 4,
  //   color: "$gray8",
  //   alignItems: "center",
  // },
});

const IndicatorContainer = styled(Box, {
  display: "flex",
  justifyContent: "center",
  position: "relative",
  width: 80,
  marginLeft: -40,

  "&:after": {
    content: "''",
    height: "100%",
    width: 2,
    backgroundColor: "$gray4",
    position: "absolute",
    top: 0,
    zIndex: -1,
  },
});

const ContentWrapper = styled(Flex, {
  pb: 30,
  flex: "1 1 0%",
});

const Step = styled("li", {
  display: "flex",
  width: "100%",

  variants: {
    isLast: {
      true: {
        [`${IndicatorContainer}`]: {
          "&:after": {
            display: "none",
          },
        },

        [`${ContentWrapper}`]: {
          pb: 0,
        },
      },
    },

    appearance: {
      solid: {},
      dashed: {
        [`${IndicatorContainer}`]: {
          "&:after": {
            backgroundColor: "transparent",
            backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='${gray.gray5}' stroke-width='5' stroke-dasharray='2%2c 8' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e")`,
          },
        },
      },
    },
  },
});

const DefaultIndicator = styled(Flex, {
  size: 12,
  background: "$gray4",
  borderRadius: 10,
  boxShadow: `0 0 0 0 ${grayA.grayA4}`,
  transform: "scale(1)",

  variants: {
    pulse: {
      true: {
        animation: `${pulse} 2s infinite`,
      },
    },
  },
});

type TransactionTimelineProps = {
  transaction: JournalEntry | null;
};

const TransactionTimeline = React.forwardRef<
  HTMLUListElement,
  TransactionTimelineProps
>(({ transaction, ...props }, ref) => {
  if (!transaction) {
    return null;
  }

  const isCredit = transaction?.transactionType === "credit";
  const isDebit = transaction?.transactionType === "debit";
  const isRefund = !!transaction?.originalEntryId;
  const isCard = transaction?.paymentMethod === "card";
  const isFailed = ["cancelled", "returned"].includes(transaction?.state!);

  if (isCard) {
    return (
      <Wrapper>
        <Step>
          <IndicatorContainer>
            <Avatar size="sm" color="gray" variant={"outline"}>
              <HiOutlineCreditCard size={16} />
            </Avatar>
          </IndicatorContainer>
          <ContentWrapper>
            <Flex stretchX gap={2} stack>
              <Text as="div" color="black">
                {transaction.cardEvent?.card.nickname} ••{" "}
                {transaction.cardEvent?.card.lastFour}
              </Text>
              <Text color="light" size="xs">
                {transaction.cardEvent?.card.bankAccount.name}
              </Text>
            </Flex>
          </ContentWrapper>
        </Step>

        <Step isLast>
          <IndicatorContainer>
            <Avatar
              size="sm"
              name={transaction.destination.name?.[0]}
              color="gray"
              variant={"outline"}
            ></Avatar>
          </IndicatorContainer>
          <ContentWrapper>
            <Flex stretchX gap={2} stack>
              <Text as="div" color="black">
                {transaction.destination.name}
              </Text>

              <Text color="light" size="xs">
                {isCredit
                  ? `${transaction?.origin?.name}`
                  : `${safeRelativeDateString(
                      new Date(transaction.createdAt),
                      new Date()
                    )}`}
              </Text>
            </Flex>
          </ContentWrapper>
        </Step>
      </Wrapper>
    );
  }

  if (isRefund) {
    const originalEntry = transaction.originalEntry;

    return (
      <Wrapper>
        <Step>
          <IndicatorContainer>
            <Avatar
              color={"gray"}
              css={{ background: "white" }}
              variant="light"
              size="sm"
            >
              <HiArrowSmLeft size={18} />
            </Avatar>
          </IndicatorContainer>

          <ContentWrapper>
            <Flex stretchX gap={4} stack>
              <Flex align={"start"} gap={3} stack>
                <Flex gap={2} stack>
                  <Text weight={"medium"} as="div" color="light">
                    <Text color="black" as="span" weight={"semi"}>
                      {originalEntry?.moneyTransfer?.requestedBy?.name}
                    </Text>{" "}
                    initiated transfer
                  </Text>

                  <Text size="xs" color="light" as="span">
                    From{" "}
                    {originalEntry?.moneyTransfer?.bankAccount?.accountName} ••{" "}
                    {originalEntry?.moneyTransfer?.bankAccount?.accountNumber?.slice(
                      -4
                    )}
                  </Text>
                </Flex>

                <Flex
                  stack
                  stretchX
                  css={{
                    background: "$gray2",
                    borderRadius: 8,
                    px: 12,
                    py: 12,
                  }}
                >
                  <Flex gap={2} stack>
                    <Avatar color="gray" size="sm">
                      <HiLibrary size={16} />
                    </Avatar>
                    <Flex stack gap={1}>
                      <Text size="xs">
                        {
                          originalEntry?.moneyTransfer?.journalEntry
                            ?.destination.name
                        }
                      </Text>
                      <Text size="xs">
                        {
                          originalEntry?.moneyTransfer?.journalEntry
                            ?.destination.bank_name
                        }
                      </Text>
                      <Text size="xs">
                        {
                          originalEntry?.moneyTransfer?.journalEntry
                            ?.destination.account_number
                        }
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>

                <Text color="light" size="xs">
                  {safeRelativeDateString(
                    new Date(transaction.createdAt),
                    new Date()
                  )}
                </Text>
              </Flex>
            </Flex>
          </ContentWrapper>
        </Step>

        <Step>
          <IndicatorContainer>
            <Avatar color="tomato" css={{ background: "white" }} size="sm">
              <HiOutlineExclamationCircle size={16} />
            </Avatar>
          </IndicatorContainer>
          <ContentWrapper>
            <Flex stretchX gap={2} stack>
              <Text as="div" color="black">
                Transaction canceled
              </Text>
              <Text color="light" size="xs">
                By User
              </Text>
            </Flex>
          </ContentWrapper>
        </Step>

        <Step isLast>
          <IndicatorContainer>
            <Avatar
              color={"gray"}
              css={{ background: "white" }}
              variant="light"
              size="sm"
            >
              <HiArrowSmRight size={18} />
            </Avatar>
          </IndicatorContainer>
          <ContentWrapper>
            <Flex stretchX gap={2} stack>
              <Text as="div" color="black">
                Refund to {transaction.destination.name} ••
                {transaction.destination.account_number.slice(-4)}
              </Text>
              <Text color="light" size="xs">
                {safeRelativeDateString(
                  new Date(transaction.createdAt),
                  new Date()
                )}
              </Text>
            </Flex>
          </ContentWrapper>
        </Step>
      </Wrapper>
    );
  }

  if (isCredit || isDebit) {
    return (
      <Wrapper>
        <Step>
          <IndicatorContainer>
            <Avatar size="sm" color="gray" variant={"outline"}>
              <HiLibrary size={16} />
            </Avatar>
          </IndicatorContainer>
          <ContentWrapper>
            <Flex stretchX gap={2} stack>
              <Text as="div" color="black">
                {isCredit
                  ? `${transaction?.origin?.bank_data?.bank_name}`
                  : `${
                      transaction.bankAccount?.name
                    } •• ${transaction.bankAccount?.accountNumber?.slice(-4)} `}
              </Text>
              <Text color="light" size="xs">
                {isCredit
                  ? `${transaction?.origin?.name}`
                  : `${safeRelativeDateString(
                      new Date(transaction.createdAt),
                      new Date()
                    )}`}
              </Text>
            </Flex>
          </ContentWrapper>
        </Step>

        <Step isLast>
          <IndicatorContainer>
            <Avatar
              size="sm"
              color={isFailed ? "tomato" : "gray"}
              variant={"outline"}
            >
              {isFailed ? (
                <HiOutlineExclamationCircle size={18} />
              ) : (
                <HiArrowSmRight size={18} />
              )}
            </Avatar>
          </IndicatorContainer>
          <ContentWrapper>
            <Flex stretchX gap={2} stack>
              <Text as="div" color="black">
                {isCredit
                  ? `${transaction.destination?.name} ••
                ${transaction.destination?.account_number?.slice(-4)}`
                  : `${transaction.destination.name}`}
              </Text>
              {isCredit && (
                <Text color="light" size="xs">
                  {safeRelativeDateString(
                    new Date(transaction.createdAt),
                    new Date()
                  )}
                </Text>
              )}
              {isDebit && (
                <Flex
                  stack
                  stretchX
                  css={{
                    background: "$gray2",
                    borderRadius: 2,
                    px: 12,
                    py: 12,
                  }}
                >
                  <Flex gap={2} stack>
                    <Avatar color="gray" size="sm">
                      <HiLibrary size={16} />
                    </Avatar>
                    <Flex stack gap={1}>
                      <Text size="xs">{transaction.destination?.name}</Text>
                      <Text size="xs">
                        {transaction.destination?.bank_name}
                      </Text>
                      <Text size="xs">
                        {transaction.destination?.account_number}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              )}
            </Flex>
          </ContentWrapper>
        </Step>
      </Wrapper>
    );
  }

  return null;
});

TransactionTimeline.displayName = "TransactionTimeline";

export default TransactionTimeline;
